import React, { createContext, useContext, useReducer } from "react";

import defaultState from "./default.json";
import miscReducer from "./reducer";
import { getFromLocalOrDefault } from "../Tools";
import { SetSettingsType, StateType } from "../../Types/settings";

export const KEY_MISC = "misc";

const MiscStateContext = createContext<StateType | undefined>(undefined);
const MiscDispatchContext = createContext<SetSettingsType | undefined>(undefined);

export const MiscProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(miscReducer, getFromLocalOrDefault(defaultState, KEY_MISC));

  return (
    <MiscStateContext.Provider value={state}>
      <MiscDispatchContext.Provider value={dispatch}>{children}</MiscDispatchContext.Provider>
    </MiscStateContext.Provider>
  );
};

export const useMiscState = () => {
  const context = useContext(MiscStateContext);
  if (context === undefined) throw new Error("useMiscState must be used within a MiscProvider");
  return context || defaultState;
};
export const useMiscDispatch = () => {
  const context = useContext(MiscDispatchContext);
  if (context === undefined) throw new Error("useMiscDispatch must be used within a MiscProvider");
  return context;
};
