import React, { useCallback, useState } from "react";
import { Button, Modal } from "antd";
import { useI18n } from "../../../../../../i18n";

type Props = {
  resetSelectionStatus: () => void;
};
const ResetSelectionStatusBtn = ({ resetSelectionStatus }: Props) => {
  const [t] = useI18n();

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onValidate = useCallback(() => {
    resetSelectionStatus();
    hideModal();
  }, [resetSelectionStatus, hideModal]);

  return (
    <>
      <Button danger shape="round" onClick={showModal}>
        {t("audioReview.resetStatusBtn")}
      </Button>
      <Modal
        title={t("audioReview.resetStatusModalTitle")}
        open={modalVisible}
        onOk={onValidate}
        onCancel={hideModal}
        okText={t("audioReview.resetStatusModalBtn")}
        cancelText={t("cancel")}>
        {t("audioReview.resetStatusModalText")}
      </Modal>
    </>
  );
};

export default ResetSelectionStatusBtn;
