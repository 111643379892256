import React from "react";
import * as parentStyle from "../../../style.less";
import { Typography, Button } from "antd";
import * as style from "../TabProgress.style.less";
import { useI18n } from "../../../../../../../../i18n";
import classNames from "classnames";

const { Text } = Typography;

type Props = {
  archiveProject: () => void;
};

export default function StepBookCompleted({ archiveProject }: Props) {
  const [t] = useI18n();

  return (
    <div className={classNames(parentStyle.progress, style.stepBookCompleted)}>
      <h4>{t("project.actionBar.progress.title_state_bookCompleted")}</h4>
      <div className={style.legendAndButton}>
        <Text className={style.legend} type="secondary">
          {t("project.actionBar.progress.bookCompleted.legend")}
        </Text>
        <Text className={style.legend} type="secondary">
          {t("project.actionBar.progress.bookCompleted.legendBis")}
        </Text>
        <Button className={style.button} onClick={archiveProject}>
          {t("project.actionBar.progress.bookCompleted.button")}
        </Button>
      </div>
    </div>
  );
}
