@import "../../../../editor/styles/colors.less";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.noResult {
  margin: auto;
  span[role="img"] {
    color: @grey !important;
  }
  .noResultTitle {
    font-size: 0.75em;
    color: @grey;
  }
}

.setStatusBar {
  display: flex;
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2em;
  box-shadow: 0px 5px 15px #00000040;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  .actions {
    display: flex;
    column-gap: 1em;
    align-items: center;
  }
}

.paragraphs {
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 2em;
  overflow-y: auto;

  padding-bottom: 8em;
}

.audioPlayer {
  display: flex;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  left: 0;
  column-gap: 1em;
  padding: 2em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.speedControlsContainer {
  position: relative;
  display: flex;
  .speedControls {
    position: absolute;
    right: 0;
  }
}

.buttonsRow {
  column-gap: 1em;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all; // avoid miss-clicks between buttons

  & > .playPause {
    pointer-events: initial;
    display: grid; // Note: flex breaks child svg  size
    justify-content: center;
    align-items: center;

    padding: 0;
    width: 2.5em !important;
    height: 2.5em;
    font-size: 2em;
  }

  button {
    min-height: unset;
    border-radius: 100% !important;
  }

  :global(.ant-progress) {
    :global(.ant-progress-circle) {
      background-color: white;
    }
  }
}

.rhese {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-content: center;
  border-left: 0.15em solid transparent;
  padding: 0.5em;
  background: #ffffff;

  .anchor {
    position: absolute;
    top: 50%;
    left: -2em;
    background-color: @orange;
    border-radius: 50%;
    width: 0.75em;
    height: 0.75em;
    transform: translateY(-50%);
  }

  .content {
    display: flex;
    align-items: center;
  }

  &[data-selected="true"] {
    border-left: 0.15em solid #3e87dd;
  }

  &[data-rhese-to-play="false"]:not([data-selected="true"], :hover) {
    opacity: 0.5;
  }

  &:hover {
    border-left: 0.15em solid #3e87dd;
    cursor: pointer;
  }
}

.rheseContainer {
  width: 100%;
  display: flex;
  column-gap: 1em;
  flex-direction: row;
  .rheseAudioDuration {
    align-self: center;
    margin-bottom: 0;
    font-size: 0.8em;
  }
}

.playRhese,
.silentIndicator {
  display: flex;
  justify-content: center;
  align-items: center;

  button,
  .fakeBtn {
    display: grid; // Note: flex breaks child svg  size
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2.5em;
    width: 2.5em !important;
  }
  .stopIcon {
    width: 13px;
    * {
      fill: @orange;
    }
  }

  &.silentIndicator svg {
    fill: @grey;
  }
}

.comment {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #fff;

  & > div:not(:last-child) {
    box-shadow: 0px -1px 0px 0px #5b60791f inset;
  }

  .title {
    padding: 2em 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    padding: 2em 4em;
    flex: 1;
    row-gap: 1em;
  }

  .controls {
    background: #fafafa;
    padding: 1.5em 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .playButton {
      font-size: 1.5em;
      padding: 0em;
      height: 2.5em;
      width: 2.5em !important;
      display: grid; // Note: flex breaks child svg  size
      justify-content: center;
      align-items: center;
    }
  }
}

.audioReview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1em;
}

.comment {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.pageNav {
  pointer-events: initial;
  height: 3.5em;
  width: 3.5em !important;
}

.statusSelector {
  min-width: 17em;
}

.iconIgnoredFromSynchro {
  padding-left: 0.3em;
  padding-right: 0.5em;
  font-size: 16px;
}
