import React from "react";
import { Slider } from "antd";
import { useSliderFontSizeStore } from "./context";
import { SliderBaseProps } from "antd/es/slider";

type Props = {
  style: any;
};

const sliderOptions: SliderBaseProps = {
  min: 0.9,
  max: 1.3,
  step: 0.01,
  style: { width: "8em" },
  tooltip: {
    formatter: (n?: number) => ((n || 0) * 100).toFixed(0) + "%",
  },
  marks: {
    "0.9": {
      style: { color: "black" },
      label: <span style={{ fontSize: "1em", lineHeight: "2em" }}>a</span>,
    },
    "1.3": {
      style: { color: "black" },
      label: <span style={{ fontSize: "2em", lineHeight: "1em" }}>a</span>,
    },
  },
};

const SliderFontSize = ({ style }: Props) => {
  const { value, onChange } = useSliderFontSizeStore();
  return (
    <menu className={style}>
      <Slider onChange={onChange} value={value} {...sliderOptions} />
    </menu>
  );
};

export default SliderFontSize;
