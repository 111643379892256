import React, { useCallback, useMemo, useState } from "react";
import { Menu } from "antd";
import { useI18n, useI18nObjectHook } from "../../../../../../../../../i18n";
import {
  useCreateParagraphFromPosition,
  useDeleteCssFromPosition,
  useSubToProject,
} from "../../../../../../api/api";
import { SelectedRheses, SelectedTextPosition } from "../FormattingComp";
import { SubToPages_pages } from "../../../../../../api/SubToPages";
import ModalCustomClasses from "./ModalCustomClasses/ModalCustomClasses";
import { Spinner } from "../../../../../../../../../components/Spinner";
import { getMenuItem, MenuItem } from "../../../../../../../styles/antDesignTheme";
import { ExtendedParagraphKind } from "../../../../../../../../../../__generated__/globalTypes";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_paragraph,
  SubscribeToExtendedPage_extendedPage_paragraph_data_tag,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../../../../api/__generated__/SubscribeToExtendedPage";
import { useUpdateDisplayedPageNumber } from "../../../../../Page";

type Props = {
  projectId: string;
  textContent: string;
  page: SubToPages_pages;
  pageMetadata: SubscribeToExtendedPage_extendedPage;
  selectedRhesesIds: string[];
  selectedTextPosition: SelectedTextPosition;
  createParagraph: (
    paragraphKind: ExtendedParagraphKind,
    paragraphTagId?: SubscribeToExtendedPage_extendedPage_paragraph_data_tag,
  ) => void;
  addSeparator: (rheseId: string) => void;
  setLineBreakAfterRhese: (
    arg: SubscribeToExtendedPage_extendedPage_rhese,
    lineBreakAfterRhese: boolean,
  ) => void;
  setSelectedRhesesContent: React.Dispatch<React.SetStateAction<SelectedRheses>>;
  setIsSegmentationHovered: React.Dispatch<React.SetStateAction<boolean>>;
  applySetNonbreakingSpace: (nonbreakingSpace: boolean) => void;
  setDropdownVisibleChange: (value: boolean) => void;
};

type menuTranslationProps = {
  subMenuTitleTransformsTitle: string;
  titre_1: string;
  titre_2: string;
  titre_3: string;
  titre_4: string;
  titre_5: string;
  titre_6: string;
  subMenuTitleAlign: string;
  space: string;
  center: string;
  right: string;
  subMenuTitleTransformsParagraph: string;
  normal: string;
  dialogue: string;
  intro: string;
  resume: string;
  subMenuTitleException: string;
  email: string;
  citation: string;
  vers: string;
  bold: string;
  text: string;
  didascalie: string;
  mail: string;
  note: string;
  subMenuList: string;
  listUnordered: string;
  listOrdered: string;
  listDefinition: string;
  rheseAction: string;
  addClassesAction: string;
  removeClassesAction: string;
  spaceAction: {
    normal: string;
    unbreakable: string;
  };
  lineSuppression: {
    normal: string;
    afterRhese: string;
  };
  assignPictureToDialogue: string;
};

const ActionMenu = ({
  projectId,
  textContent,
  page,
  pageMetadata,
  selectedRhesesIds,
  selectedTextPosition,
  createParagraph,
  addSeparator,
  setLineBreakAfterRhese,
  setSelectedRhesesContent,
  setIsSegmentationHovered,
  applySetNonbreakingSpace,
  setDropdownVisibleChange,
}: Props) => {
  const [t] = useI18n();
  const { data: projectsData } = useSubToProject({
    id: projectId,
    withInfo: false,
    withAudioInfo: false,
  });
  const paragraphTags = useMemo(() => {
    if (projectsData === undefined) return [];
    const project = projectsData.project.find((p) => p.id === projectId);
    return project?.paragraphTags || [];
  }, [projectsData, projectId]);

  const updateDisplayedPageNumber = useUpdateDisplayedPageNumber({ forceRedirect: true });

  const [applyCreateParagraphFromPosition] = useCreateParagraphFromPosition();
  // const [applyCreateCssFromPosition] = useCreateCssFromPosition();
  const [applyDeleteCssFromPosition] = useDeleteCssFromPosition();

  const [removeClassesLoading, setRemoveClassesLoading] = useState(false);

  const disabledIfSeveralRheses = selectedRhesesIds.length !== 1;

  const selectedRheseId = selectedRhesesIds[0];
  const selectedRhese = pageMetadata.rhese.find(({ data: { id } }) => id === selectedRheseId);
  const selectedText = textContent.substring(
    selectedTextPosition.start - page.start,
    selectedTextPosition.start - page.start + selectedTextPosition.size,
  );

  const isOnlyOneLetterSelected = selectedTextPosition.size === 1;
  const isSpace = selectedText === " ";
  const isNonbreakingSpace = selectedText === "\xa0";

  const selectedParagraphs: {
    starting: SubscribeToExtendedPage_extendedPage_paragraph[];
    ending: SubscribeToExtendedPage_extendedPage_paragraph[];
    included: SubscribeToExtendedPage_extendedPage_paragraph[];
    wrapping: SubscribeToExtendedPage_extendedPage_paragraph[];
  } = {
    starting: [],
    ending: [],
    included: [],
    wrapping: [],
  };

  const paragraphsTagIds: SubscribeToExtendedPage_extendedPage_paragraph_data_tag[] = [];

  pageMetadata.paragraph.map((p) => {
    // If paragraph is wrapping the selection
    if (
      p.anchors[0].utf16Start <= selectedTextPosition.start &&
      p.anchors[0].utf16Start + p.anchors[0].utf16Size >=
        selectedTextPosition.start + selectedTextPosition.size
    ) {
      selectedParagraphs.wrapping.push(p);
      if (p.data.tag) paragraphsTagIds.push(p.data.tag);
    }

    if (
      p.anchors[0].utf16Start >= selectedTextPosition.start &&
      p.anchors[0].utf16Start <= selectedTextPosition.start + selectedTextPosition.size
    ) {
      // paragraph starts in selection
      if (
        p.anchors[0].utf16Start + p.anchors[0].utf16Size >= selectedTextPosition.start &&
        p.anchors[0].utf16Start + p.anchors[0].utf16Size <=
          selectedTextPosition.start + selectedTextPosition.size
      ) {
        // paragraph ends in selection
        selectedParagraphs.included.push(p);
        if (p.data.tag) paragraphsTagIds.push(p.data.tag);
      } else {
        // paragraph ends after selection
        selectedParagraphs.starting.push(p);
        if (p.data.tag) paragraphsTagIds.push(p.data.tag);
      }
    } else {
      // paragraph starts before selection
      if (
        p.anchors[0].utf16Start + p.anchors[0].utf16Size >= selectedTextPosition.start &&
        p.anchors[0].utf16Start + p.anchors[0].utf16Size <=
          selectedTextPosition.start + selectedTextPosition.size
      ) {
        // paragraph ends in selection
        selectedParagraphs.ending.push(p);
        if (p.data.tag) paragraphsTagIds.push(p.data.tag);
      }
    }
  });

  const paragraphTagId = paragraphsTagIds?.length === 1 ? paragraphsTagIds[0] : undefined;

  const menuTranslation: menuTranslationProps = useI18nObjectHook(
    "project.existing.editingMode.formatting.menu",
  );

  const closeDropdown = useCallback(
    () => setDropdownVisibleChange(false),
    [setDropdownVisibleChange],
  );

  const onAddClassesActionClick = useCallback(() => {
    closeDropdown();
    setCustomClassesActionVisible(true);
  }, [closeDropdown]);

  const onRemoveClassesActionClick = useCallback(async () => {
    setRemoveClassesLoading(true);
    await applyDeleteCssFromPosition({
      variables: {
        projectId,
        stringVersion: page.stringVersion,
        size: selectedTextPosition.size,
        start: selectedTextPosition.start,
      },
    });
    setRemoveClassesLoading(false);
  }, [applyDeleteCssFromPosition, selectedTextPosition, projectId, page, setRemoveClassesLoading]);

  const [customClassesActionVisible, setCustomClassesActionVisible] = useState(false);

  const items: MenuItem[] = useMemo(() => {
    return [
      getMenuItem({
        key: "s1",
        label: menuTranslation.subMenuTitleTransformsTitle,
        disabled: selectedTextPosition.size === 0,
        children: [
          getMenuItem({
            key: "1-1",
            label: menuTranslation.titre_1,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.TITRE_1, paragraphTagId);
              closeDropdown();
            },
          }),
          getMenuItem({
            key: "1-2",
            label: menuTranslation.titre_2,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.TITRE_2, paragraphTagId);
              closeDropdown();
            },
          }),
          getMenuItem({
            key: "1-3",
            label: menuTranslation.titre_3,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.TITRE_3, paragraphTagId);
              closeDropdown();
            },
          }),
          getMenuItem({
            key: "1-4",
            label: menuTranslation.titre_4,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.TITRE_4, paragraphTagId);
              closeDropdown();
            },
          }),
          getMenuItem({
            key: "1-5",
            label: menuTranslation.titre_5,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.TITRE_5, paragraphTagId);
              closeDropdown();
            },
          }),
          getMenuItem({
            key: "1-6",
            label: menuTranslation.titre_6,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.TITRE_6, paragraphTagId);
              closeDropdown();
            },
          }),
        ],
      }),
      getMenuItem({
        key: "s2",
        label: menuTranslation.subMenuTitleAlign,
        disabled: selectedTextPosition.size === 0,
        children: [
          getMenuItem({
            key: "2-1",
            label: menuTranslation.space,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.ESPACE, paragraphTagId);
              closeDropdown();
            },
          }),
          getMenuItem({
            key: "2-2",
            label: menuTranslation.center,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.CENTRE, paragraphTagId);
              closeDropdown();
            },
          }),
          getMenuItem({
            key: "2-3",
            label: menuTranslation.right,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.DROITE, paragraphTagId);
              closeDropdown();
            },
          }),
        ],
      }),
      getMenuItem({
        key: "s3",
        label: menuTranslation.subMenuTitleTransformsParagraph,
        disabled: selectedTextPosition.size === 0,
        children: [
          getMenuItem({
            key: "3-1",
            label: menuTranslation.normal,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.NORMAL, paragraphTagId);
              closeDropdown();
            },
          }),
          getMenuItem({
            key: "3-2",
            label: menuTranslation.dialogue,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.DIALOGUE, paragraphTagId);
              closeDropdown();
            },
          }),
          getMenuItem({
            key: "3-3",
            label: menuTranslation.intro,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.INTRO, paragraphTagId);
              closeDropdown();
            },
          }),
          getMenuItem({
            key: "3-4",
            label: menuTranslation.resume,
            onClick: () => {
              createParagraph(ExtendedParagraphKind.RESUME, paragraphTagId);
              closeDropdown();
            },
          }),
          getMenuItem({
            key: "3-s4",
            label: menuTranslation.subMenuList,
            disabled: selectedTextPosition.size === 0,
            children: [
              getMenuItem({
                key: "3-s4-1",
                label: menuTranslation.listUnordered,
                onClick: () => {
                  createParagraph(ExtendedParagraphKind.LIST_UNORDERED, paragraphTagId);
                  closeDropdown();
                },
              }),
              getMenuItem({
                key: "3-s4-2",
                label: menuTranslation.listOrdered,
                onClick: () => {
                  createParagraph(ExtendedParagraphKind.LIST_ORDERED, paragraphTagId);
                  closeDropdown();
                },
              }),
              getMenuItem({
                key: "3-s4-3",
                label: menuTranslation.listDefinition,
                onClick: () => {
                  createParagraph(ExtendedParagraphKind.LIST_DEFINITION, paragraphTagId);
                  closeDropdown();
                },
              }),
            ],
          }),
          getMenuItem({
            key: "3-s5",
            label: menuTranslation.subMenuTitleException,
            disabled: selectedTextPosition.size === 0,
            children: [
              getMenuItem({
                key: "3-s5-1",
                label: menuTranslation.email,
                onClick: () => {
                  createParagraph(ExtendedParagraphKind.EMAIL, paragraphTagId);
                  closeDropdown();
                },
              }),
              getMenuItem({
                key: "3-s5-2",
                label: menuTranslation.citation,
                onClick: () => {
                  createParagraph(ExtendedParagraphKind.CITATION, paragraphTagId);
                  closeDropdown();
                },
              }),
              getMenuItem({
                key: "3-s5-3",
                label: menuTranslation.vers,
                onClick: () => {
                  createParagraph(ExtendedParagraphKind.VERS, paragraphTagId);
                  closeDropdown();
                },
              }),
              getMenuItem({
                key: "3-s5-4",
                label: menuTranslation.bold,
                onClick: () => {
                  createParagraph(ExtendedParagraphKind.GRAS, paragraphTagId);
                  closeDropdown();
                },
              }),
              getMenuItem({
                key: "3-s5-5",
                label: menuTranslation.text,
                onClick: () => {
                  createParagraph(ExtendedParagraphKind.TEXTO, paragraphTagId);
                  closeDropdown();
                },
              }),
              getMenuItem({
                key: "3-s5-6",
                label: menuTranslation.didascalie,
                onClick: () => {
                  createParagraph(ExtendedParagraphKind.DIDASCALIE, paragraphTagId);
                  closeDropdown();
                },
              }),
              getMenuItem({
                key: "3-s5-7",
                label: menuTranslation.mail,
                onClick: () => {
                  createParagraph(ExtendedParagraphKind.COURRIER, paragraphTagId);
                  closeDropdown();
                },
              }),
              getMenuItem({
                key: "3-s5-8",
                label: menuTranslation.note,
                onClick: () => {
                  createParagraph(ExtendedParagraphKind.NOTE, paragraphTagId);
                  closeDropdown();
                },
              }),
            ],
          }),
        ],
      }),
      getMenuItem({
        key: "s4",
        label: menuTranslation.rheseAction,
        disabled: disabledIfSeveralRheses,
        onMouseEnter: () => setIsSegmentationHovered(true),
        onMouseLeave: () => setIsSegmentationHovered(false),
        onClick: () => {
          const rheseId = selectedRhesesIds[0];
          addSeparator(rheseId);
          closeDropdown();
          updateDisplayedPageNumber((page.pageNumber + 1).toString());
        },
      }),
      getMenuItem({
        key: "addClasses",
        label: menuTranslation.addClassesAction,
        disabled: !selectedRhese && !selectedTextPosition.size,
        onClick: onAddClassesActionClick,
      }),
      getMenuItem({
        key: "removeClasses",
        label: removeClassesLoading ? (
          <Spinner small style={{ justifyContent: "flex-start", marginTop: "0.8em" }} />
        ) : (
          menuTranslation.removeClassesAction
        ),
        disabled: !selectedRhese && !selectedTextPosition.size,
        onClick: onRemoveClassesActionClick,
      }),
      getMenuItem({
        key: "7",
        label: isSpace
          ? `${menuTranslation.spaceAction.unbreakable}`
          : `${menuTranslation.spaceAction.normal}`,
        disabled: !isOnlyOneLetterSelected || (!isSpace && !isNonbreakingSpace),
        onClick: () => {
          if (selectedTextPosition.size === 1) {
            applySetNonbreakingSpace(isSpace);
            closeDropdown();
          }
        },
      }),
      getMenuItem({
        key: "8",
        label: selectedRhese?.data.lineBreakAfter
          ? `${menuTranslation?.lineSuppression?.normal}`
          : `${menuTranslation?.lineSuppression?.afterRhese}`,
        disabled: disabledIfSeveralRheses || !selectedRhese,
        onClick: () => {
          if (selectedRhese) {
            setLineBreakAfterRhese(selectedRhese, !selectedRhese.data.lineBreakAfter);
            closeDropdown();
          }
        },
      }),
      getMenuItem({
        key: "s9",
        label: t("tagTheParagraph"),
        disabled: !selectedRhese && !selectedTextPosition.size,
        children: [
          getMenuItem({
            key: "remove",
            label: t("removeTagFromParagraph"),
            disabled: !(paragraphsTagIds.length === 1),
            onClick: () => {
              const selectedParagraph =
                selectedParagraphs.wrapping[0] || selectedParagraphs.included[0];

              applyCreateParagraphFromPosition({
                variables: {
                  stringVersion: page.stringVersion,
                  projectId,
                  size: selectedParagraph.anchors[0].utf16Size,
                  start: selectedParagraph.anchors[0].utf16Start,
                  kind: selectedParagraph.data.kind,
                },
              });
              closeDropdown();
            },
          }),
          ...paragraphTags
            .sort((a, b) => a.createdAt - b.createdAt)
            .map((tag) =>
              getMenuItem({
                key: tag?.id,
                label: tag?.name,
                onClick: () => {
                  const selectedParagraph =
                    selectedParagraphs.wrapping[0] || selectedParagraphs.included[0];

                  applyCreateParagraphFromPosition({
                    variables: {
                      stringVersion: page.stringVersion,
                      projectId,
                      size: selectedParagraph.anchors[0].utf16Size,
                      start: selectedParagraph.anchors[0].utf16Start,
                      kind: selectedParagraph.data.kind,
                      paragraphTagId: tag?.id,
                    },
                  });
                  closeDropdown();
                },
              }),
            ),
        ],
      }),
    ];
  }, [
    menuTranslation.subMenuTitleTransformsTitle,
    menuTranslation.titre_1,
    menuTranslation.titre_2,
    menuTranslation.titre_3,
    menuTranslation.titre_4,
    menuTranslation.titre_5,
    menuTranslation.titre_6,
    menuTranslation.subMenuTitleAlign,
    menuTranslation.space,
    menuTranslation.center,
    menuTranslation.right,
    menuTranslation.subMenuTitleTransformsParagraph,
    menuTranslation.normal,
    menuTranslation.dialogue,
    menuTranslation.intro,
    menuTranslation.resume,
    menuTranslation.subMenuList,
    menuTranslation.listUnordered,
    menuTranslation.listOrdered,
    menuTranslation.listDefinition,
    menuTranslation.subMenuTitleException,
    menuTranslation.email,
    menuTranslation.citation,
    menuTranslation.vers,
    menuTranslation.bold,
    menuTranslation.text,
    menuTranslation.didascalie,
    menuTranslation.mail,
    menuTranslation.note,
    menuTranslation.rheseAction,
    menuTranslation.addClassesAction,
    menuTranslation.removeClassesAction,
    menuTranslation.spaceAction.unbreakable,
    menuTranslation.spaceAction.normal,
    menuTranslation?.lineSuppression?.normal,
    menuTranslation?.lineSuppression?.afterRhese,
    selectedTextPosition.size,
    disabledIfSeveralRheses,
    selectedRhese,
    onAddClassesActionClick,
    removeClassesLoading,
    onRemoveClassesActionClick,
    isSpace,
    isOnlyOneLetterSelected,
    isNonbreakingSpace,
    t,
    paragraphsTagIds.length,
    paragraphTags,
    createParagraph,
    paragraphTagId,
    closeDropdown,
    setIsSegmentationHovered,
    selectedRhesesIds,
    addSeparator,
    updateDisplayedPageNumber,
    page.pageNumber,
    page.stringVersion,
    applySetNonbreakingSpace,
    setLineBreakAfterRhese,
    selectedParagraphs.wrapping,
    selectedParagraphs.included,
    applyCreateParagraphFromPosition,
    projectId,
  ]);

  return (
    <>
      <Menu onClick={() => setSelectedRhesesContent([])} selectable={false} items={items} />
      <ModalCustomClasses
        isVisible={customClassesActionVisible}
        setVisibility={setCustomClassesActionVisible}
        selectedTextPosition={selectedTextPosition}
        projectId={projectId}
        classesMetadata={pageMetadata.customCss}
        stringVersion={page.stringVersion}
      />
    </>
  );
};

export default ActionMenu;
