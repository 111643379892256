import { INITIAL_FONT_SIZE } from "./config";

export const initialState = {
  value: INITIAL_FONT_SIZE,
};

type State = typeof initialState;

export default function reducer(_state: State, action: any): State {
  switch (action.type) {
    case "FONT_SIZE_CHANGED":
      return {
        value: action.value,
      };
    default:
      throw new Error(`Action ${action.type} not handled`);
  }
}
