import React from "react";
import { Input, Modal, Typography } from "antd";
import { useModal } from "./useModal";
import { SelectedTextPosition } from "../../FormattingComp";
import { SubscribeToExtendedPage_extendedPage_customCss } from "../../../../../../../api/__generated__/SubscribeToExtendedPage";
const { Text } = Typography;

type Props = {
  isVisible: boolean;
  setVisibility: (value: boolean) => void;
  selectedTextPosition: SelectedTextPosition;
  classesMetadata: SubscribeToExtendedPage_extendedPage_customCss[];
  projectId: string;
  stringVersion: number;
};

const ModalCustomClasses = ({
  isVisible,
  setVisibility,
  selectedTextPosition,
  classesMetadata,
  projectId,
  stringVersion,
}: Props) => {
  const { inputValue, onInputChange, onOk, onCancel, strings, isOverrideWarning, loading } =
    useModal({
      setVisibility,
      selectedTextPosition,
      classesMetadata,
      projectId,
      stringVersion,
    });

  if (!isVisible) {
    // Unmount the input below for the autoFocus behaviour
    return null;
  }

  return (
    <Modal
      open
      onCancel={onCancel}
      onOk={onOk}
      title={strings.title}
      okText={strings.modalOkLabel}
      okButtonProps={{
        disabled: loading,
      }}>
      {isOverrideWarning && (
        <>
          <Text type="warning" strong={false} style={{ fontWeight: 400 }}>
            <p>{strings.ovverideWarning}</p>
          </Text>
          <br />
        </>
      )}
      <Input
        value={inputValue}
        onChange={onInputChange}
        onPressEnter={onOk}
        placeholder={strings.inputPlaceholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
    </Modal>
  );
};

export default ModalCustomClasses;
