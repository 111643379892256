import React, { useCallback } from "react";
import { SecureRoute } from "@okta/okta-react";
import DefinitionComponent from "../../EditingModes/Definition";
import { subroutesPath } from "./subroutesPath";
import {
  AudioReviewStatus,
  ExtendedDefinitionKind,
} from "../../../../../../../__generated__/globalTypes";
import {
  useCreateDefinitionFromPosition,
  useDeleteDefinition,
  useUpdateDefinition,
} from "../../../api/api";
import { SubToPages_pages } from "../../../api/SubToPages";
import { useProjectContext } from "../../Page";
import { SubscribeToExtendedPage_extendedPage_definition } from "../../../api/__generated__/SubscribeToExtendedPage";

type Props = {
  allPages: SubToPages_pages[];
  projectId: string;
};

export default function SubRouteDefinition({ allPages, projectId }: Props) {
  const { project } = useProjectContext();

  const [applyCreateDefinition] = useCreateDefinitionFromPosition();
  const [applyUpdateDefinition] = useUpdateDefinition();
  const [applyDeleteDefinition] = useDeleteDefinition();

  const createDefinition = useCallback(
    (start, size, kind: ExtendedDefinitionKind, definition: string, imageName?: string) => {
      applyCreateDefinition({
        variables: {
          kind,
          description: definition,
          projectId,
          start,
          size,
          stringVersion: project.stringVersion,
          imageName,
        },
      });
    },
    [applyCreateDefinition, projectId, project.stringVersion],
  );

  const updateDefinition = useCallback(
    (
      definition: SubscribeToExtendedPage_extendedPage_definition,
      kind: ExtendedDefinitionKind,
      description: string,
      imageName?: string,
    ) => {
      applyUpdateDefinition({
        variables: {
          projectId,
          definitionId: definition.data.id,
          kind,
          description,
          imageName,
          audioFile: definition.data.audioFile,
          audioReviewComment: definition.data.audioReviewComment || "",
          audioReviewStatus: definition.data.audioReviewStatus || AudioReviewStatus.VALID,
        },
      });
    },
    [applyUpdateDefinition, projectId],
  );

  const deleteDefinitionMemoized = useCallback(
    (insertOrder: number) =>
      applyDeleteDefinition({
        variables: {
          stringVersion: project.stringVersion,
          projectId,
          insertOrder,
        },
      }),
    [applyDeleteDefinition, project.stringVersion, projectId],
  );

  const renderDef = useCallback(() => {
    return (
      <DefinitionComponent
        projectId={projectId}
        createDefinition={createDefinition}
        updateDefinition={updateDefinition}
        deleteDefinition={deleteDefinitionMemoized}
        pages={allPages}
      />
    );
  }, [deleteDefinitionMemoized, projectId, createDefinition, updateDefinition, allPages]);

  return <SecureRoute exact path={subroutesPath.definition} render={renderDef} />;
}
