import React, { useMemo, useState } from "react";
import { Tabs } from "antd";
import * as style from "./style.less";
import { SubToProject_project } from "../../api/__generated__/SubToProject";
import { useI18n } from "../../../../../i18n";
import TabProjectInfo from "./tabContents/TabProjectInfo";
import TabUserAccess from "./tabContents/TabUserAccess";
import TabProjectConfiguration from "./tabContents/TabProjectConfiguration";
import TabProgress from "./tabContents/TabProgress/TabProgress";
import useProjectProgress from "../../hooks/useProjectProgress";
import { getTabItem, TabItems } from "../../../styles/antDesignTheme";

enum TabsEnum {
  Informations = "Informations",
  Checklist = "Checklist",
  Access = "Access",
  Config = "Config",
  Progress = "Progress",
}

type Props = {
  project: SubToProject_project;
  projectProgressHook: ReturnType<typeof useProjectProgress>;
};

export default function ProjectTabs({ project, projectProgressHook }: Props) {
  const [currentTab, setCurrentTab] = useState(TabsEnum.Informations);
  const [t] = useI18n();
  const i18nKey = "project.actionBar.projectTabs";

  const tabItems: TabItems = useMemo(
    () => [
      getTabItem({
        key: TabsEnum.Informations,
        label: t(`${i18nKey}.info`),
        children: <TabProjectInfo project={project} />,
      }),
      getTabItem({
        key: TabsEnum.Access,
        label: t(`${i18nKey}.access`),
        children: (
          <TabUserAccess
            userBasedAccess={project.userBasedAccess || []}
            roles={project.roles}
            projectId={project.id}
          />
        ),
      }),
      getTabItem({
        key: TabsEnum.Config,
        label: t(`${i18nKey}.config`),
        children: <TabProjectConfiguration project={project} />,
      }),
      getTabItem({
        key: TabsEnum.Progress,
        label: t(`${i18nKey}.progress`),
        children: <TabProgress project={project} projectProgressHook={projectProgressHook} />,
      }),
    ],
    [project, projectProgressHook, t],
  );

  return (
    <Tabs
      className={style.tabs}
      activeKey={currentTab}
      tabPosition={"left"}
      onChange={(key) => setCurrentTab(key as TabsEnum)}
      items={tabItems}
    />
  );
}
