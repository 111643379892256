import React from "react";
import { useI18n } from "../../../../i18n";
import * as style from "./style.less";
import ModalComment from "../../../../components/ModalComment/ModalComment";
import { AudioReviewStatus } from "../../../../../__generated__/globalTypes";

type Props = {
  audioReviewStatus: AudioReviewStatus | undefined | null;
  comment?: string | null;
};

export default function ReviewCommentAndStatus({ audioReviewStatus, comment }: Props) {
  const [t] = useI18n();
  return (
    <>
      <ModalComment value={comment} readOnly />
      {audioReviewStatus && (
        <span className={style.audioReview}>
          {t(`audioReview.${audioReviewStatus.toLowerCase()}`)}
        </span>
      )}
    </>
  );
}
