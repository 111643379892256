import { CloudUploadOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";
import { UploadFile } from "antd/lib/upload/interface";
import classNames from "classnames";
import React, { useCallback } from "react";
import DropZone from "../../../../../../components/DropZone/DropZone";
import { Spinner } from "../../../../../../components/Spinner";
import { getRecordingPresignedUrls } from "../../../../Queries/queries";

type Props = {
  definitionId: string;
  filename: string | null;
  loading: boolean;
  sendFile: (
    definitionId: string,
    putUrl: string,
    filename: string,
    fileBlob: UploadFile<any>,
  ) => void;
  style: any;
};

export default function AudioDropInput({
  definitionId,
  loading,
  sendFile,
  filename,
  style,
}: Props) {
  const client = useApolloClient();

  const getUploadOptions = useCallback(async () => {
    const { data } = await client.query({
      query: getRecordingPresignedUrls,
      variables: { filename: filename || undefined },
      fetchPolicy: "no-cache",
    });
    const res = data?.audioRecording?.presignedUrl;
    if (!res) {
      return { getUrl: null, putUrl: null, filename: null };
    }
    return res;
  }, [client, filename]);

  const onFileSelected = useCallback(
    async (file: UploadFile<any>) => {
      const { putUrl, filename: generatedFilename } = await getUploadOptions();

      sendFile(definitionId, putUrl, filename || generatedFilename, file);
    },
    [definitionId, filename, sendFile, getUploadOptions],
  );

  return (
    <div
      className={classNames(style.audioDropInput, {
        [style.audioDropInputActive]: loading,
      })}>
      <DropZone onFileSelected={onFileSelected}>
        {loading ? <Spinner small /> : <CloudUploadOutlined className={style.uploadIcon} />}
      </DropZone>
    </div>
  );
}
