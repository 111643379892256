import { gql, useMutation } from "@apollo/client";
import {
  CreateDefinitionFromPosition,
  CreateDefinitionFromPositionVariables,
} from "./__generated__/CreateDefinitionFromPosition";
import { DeleteDefinition, DeleteDefinitionVariables } from "./__generated__/DeleteDefinition";
import {
  UpdateExtendedDefinition,
  UpdateExtendedDefinitionVariables,
} from "./__generated__/UpdateExtendedDefinition";

export const useCreateDefinitionFromPosition = () =>
  useMutation<CreateDefinitionFromPosition, CreateDefinitionFromPositionVariables>(
    gql`
      mutation CreateDefinitionFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
        $kind: ExtendedDefinitionKind!
        $description: String!
        $imageName: String
      ) {
        createDefinitionFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
          kind: $kind
          description: $description
          imageName: $imageName
        )
      }
    `,
  );

export const useDeleteDefinition = () =>
  useMutation<DeleteDefinition, DeleteDefinitionVariables>(
    gql`
      mutation DeleteDefinition($projectId: UUID!, $insertOrder: Int!, $stringVersion: Int!) {
        deleteDefinition(
          projectId: $projectId
          insertOrder: $insertOrder
          stringVersion: $stringVersion
        )
      }
    `,
  );

export const useUpdateDefinition = () =>
  useMutation<UpdateExtendedDefinition, UpdateExtendedDefinitionVariables>(
    gql`
      mutation UpdateExtendedDefinition(
        $projectId: UUID!
        $definitionId: UUID!
        $kind: ExtendedDefinitionKind!
        $description: String!
        $imageName: String
        $audioFile: UUID!
        $audioReviewStatus: AudioReviewStatus
        $audioReviewComment: String!
      ) {
        updateExtendedDefinition(
          projectId: $projectId
          definitionId: $definitionId
          kind: $kind
          description: $description
          imageName: $imageName
          audioFile: $audioFile
          audioReviewStatus: $audioReviewStatus
          audioReviewComment: $audioReviewComment
        )
      }
    `,
  );
