import React from "react";
import { useI18n } from "../../../../../../i18n";
import { UserRoles } from "../../../../UserAccess";
import { SubToProject_project_roles } from "../../../api/__generated__/SubToProject";
import * as style from "../style.less";

export default function TabUserAccess({
  userBasedAccess,
  roles,
  projectId,
}: {
  userBasedAccess: string[];
  roles: SubToProject_project_roles[];
  projectId: string;
}) {
  const [t] = useI18n();
  const i18nKey = "project.actionBar.userAccess";
  return (
    <div className={style.userAccess}>
      <h4>{t(`${i18nKey}.title`)}</h4>
      <UserRoles userBasedAccess={userBasedAccess} roles={roles} projectId={projectId} />
    </div>
  );
}
