import React, { useContext } from "react";
import { ClickableWrapper } from "../../../../../components/clickableWrapper/ClickableWrapper";
import { ValidationCheckSvg } from "../../../Assets/ValidationCheckSvg";
import AudioAnalyzerContext from "../../../Contexts/AudioAnalyzerContext";
import * as style from "./style.less";

interface Props {
  recordPaths: string;
  onStart?: () => void;
  onEnd?: () => void;
}

export const SendRecordedRheseToDatabaseButton = ({
  recordPaths,
  onStart = () => {},
  onEnd = () => {},
}: Props) => {
  const { sendRecordedRhesesToDatabase } = useContext(AudioAnalyzerContext);

  const handleClick = async () => {
    onStart();
    const recordPath = Array.isArray(recordPaths)
      ? recordPaths[recordPaths.length - 1]
      : recordPaths;
    let blob = await fetch(recordPath).then((result) => result.blob());
    await sendRecordedRhesesToDatabase(blob);
    onEnd();
  };

  return (
    <ClickableWrapper>
      <div className={style.validationButtonContainer} onClick={handleClick}>
        <div data-testid="validate-button" className={style.validationButtonText}>
          Valider l’enregistrement
        </div>
        <ValidationCheckSvg />
      </div>
    </ClickableWrapper>
  );
};
