@import "../../../styles/fonts.less";
@import "../../../styles/colors.less";
.root {
  display: flex;
  align-items: center;
  position: relative;
  --width: 58.3em;
  --height: 60em;
  --padding-v: calc(var(--width) * 0.11);
  --padding-h: calc(var(--width) * 0.093);
  --column-gap: calc(var(--padding-h) * 2);
}

.root .contentWrapper {
  width: var(--width);
  background: white;
  display: flex;
  flex-direction: column;
}

.root .a4Wrapper {
  font-weight: 400;
  font-family: @classic;
  letter-spacing: 0.14px;
  line-height: 2;
  z-index: 800;
  width: 100%;
  height: calc(var(--height) + (var(--padding-v) * 2));
  overflow-y: hidden;
  padding-top: calc(var(--padding-v) / 2);
}

.overflowChecker {
  flex: 1;
  overflow-y: hidden;
}

.root .content > * {
  box-sizing: border-box;
  width: 100%;
  padding: 0 var(--padding-h);
}

.root {
  :global .rhese {
    box-decoration-break: clone;
    padding: 0.2em 0;
  }
}

.root .topBar {
  display: flex;
  flex-direction: row;
  margin: calc(var(--padding-v) / 2) var(--padding-h) 0 var(--padding-h);
  border-bottom: 1px solid @light-grey;

  .totalPageNumber {
    color: @grey;
    padding-left: 0.5em;
    display: flex;
    align-items: center;
    font-size: 1.5em;
  }

  .pageNumber {
    margin-top: 0.35em;
    height: 2em;
  }

  .totalPageNumber,
  .pageNumber {
    background-color: transparent;
  }

  .chapterName {
    font-size: 1.5em;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.root .showMore {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 5em;

  &:hover {
    cursor: pointer;
  }
}
