import React, { CSSProperties } from "react";

const StopIconSvg = ({
  className,
  style,
  fill = "#E33636",
}: {
  className?: string;
  style?: CSSProperties;
  fill?: string;
}) => {
  return (
    <svg
      className={className}
      style={style}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="4.5" y="4" width="16" height="16" rx="2" fill={fill} />
    </svg>
  );
};

export default StopIconSvg;
