import { Button, InputNumber, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useI18n } from "../../../../../i18n";
import { scrollToUrlPage, useUpdateDisplayedPageNumber } from "../Page";
import * as style from "../../style.less";
import GoToIcn from "../../assets/goTo.svg";
import BookIcn from "../../assets/book.svg";

type GoToPageProps = {
  totalPageNumber: number;
};

export default function GoToPage({ totalPageNumber }: GoToPageProps) {
  const gotToPage = useUpdateDisplayedPageNumber({ forceRedirect: true });
  const [pageNumber, setPageNumber] = useState(1);
  const [t] = useI18n();
  const i18nKey = "project.existing.index.goToPage";
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const displayedPageNumber = queryParams.get("displayedPageNumber");
    if (displayedPageNumber) setPageNumber(Number(displayedPageNumber));
  }, [window.location.search, setPageNumber]);

  return (
    <Popover
      trigger="hover"
      open={isOpen}
      onOpenChange={setIsOpen}
      placement="bottom"
      className={style.goToPage}
      title={
        <div
          style={{
            fontSize: "1.2em",
            padding: "0.6em 1.2em",
            textAlign: "center",
          }}>
          {t(`${i18nKey}.nav`)}
        </div>
      }
      content={
        <div className={style.popoverGoTo}>
          <InputNumber
            size="middle"
            value={pageNumber}
            onChange={(value) => value && setPageNumber(Number(value))}
          />
          <span className={style.popoverGoToSpanCount}>/ {totalPageNumber}</span>
          <Button
            onClick={() => {
              gotToPage(pageNumber.toString());
              scrollToUrlPage();
            }}>
            {t(`${i18nKey}.validation`)} <GoToIcn />
          </Button>
        </div>
      }>
      <Button className={style.bookIcon}>
        <BookIcn />
      </Button>
    </Popover>
  );
}
