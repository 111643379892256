import React from "react";
import BookList from "../../Components/bookList/BookList";
import * as style from "./style.less";
import { UserPanel, useUserPanel } from "../../../../identity/User/Panel";
import history from "../../../../routing/history";

export const NarrationHome = () => {
  const { isUserPanelOpened, closePanel } = useUserPanel();

  const openProject = (projectId: string) => {
    history.replace("/audio/library/edit/" + projectId + "/1");
  };

  return (
    <div className={style.narrationAppContainer}>
      <UserPanel closePanel={closePanel} isUserPanelOpened={isUserPanelOpened} />
      <div className={style.homeContainer}>
        <BookList openProject={openProject} />
      </div>
    </div>
  );
};
