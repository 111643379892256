{
  "name": "frogeditor",
  "version": "1.12.0",
  "description": "",
  "scripts": {
    "test": "NODE_ENV=test jest --env=jsdom",
    "prebuild": "rm -rf dist",
    "debugbuild": "parcel build ./modules/app/index.html --no-optimize",
    "build": "parcel build ./modules/app/index.html",
    "dev": "parcel ./modules/app/index.html",
    "check": "tsc --noEmit",
    "lint": "eslint --ext .js,.jsx,.ts,.tsx ./modules",
    "download-schema": "./node_modules/graphqurl/bin/run $(cat .env.$NODE_ENV | grep GQL_API_ENDPOINT | cut -d '=' -f2) -H \"authorization: Bearer $TOKEN\" --introspect --format json > schema.json",
    "generate-apollo-types": "npm run download-schema && ./node_modules/apollo/bin/run codegen:generate --addTypename --target=typescript --localSchemaFile=schema.json --includes=modules/**/*"
  },
  "author": "",
  "license": "ISC",
  "devDependencies": {
    "@babel/preset-react": "^7.14.5",
    "@babel/preset-typescript": "^7.10.4",
    "@parcel/css": "^1.10.1",
    "@testing-library/react": "^12.1.2",
    "@types/diff": "^5.0.0",
    "@types/graphql": "^14.5.0",
    "@types/lru-cache": "^5.1.1",
    "@types/react-infinite-scroller": "^1.2.3",
    "@typescript-eslint/eslint-plugin": "^5.27.1",
    "@typescript-eslint/parser": "^5.27.1",
    "apollo": "^2.34.0",
    "babel-eslint": "^10.1.0",
    "eslint": "^8.3.0",
    "eslint-plugin-import": "^2.25.3",
    "eslint-plugin-jsx-a11y": "^6.5.1",
    "eslint-plugin-react": "^7.27.1",
    "eslint-plugin-react-hooks": "^4.5.0",
    "global-jsdom": "^8.3.0",
    "graphqurl": "^1.0.1",
    "jest": "^27.3.1",
    "jest-css-modules": "^2.1.0",
    "jest-css-modules-transform": "^4.3.0",
    "jsdom": "^16.7.0",
    "less": "^3.12.2",
    "postcss": "^8.3.11",
    "postcss-modules": "^4.3.1",
    "process": "^0.11.10",
    "serve": "^11.3.2",
    "typescript": "^4.7"
  },
  "dependencies": {
    "@ant-design/icons": "^5.2.1",
    "@apollo/client": "^3.7.3",
    "@okta/okta-auth-js": "^6.5.0",
    "@okta/okta-react": "^6.2.0",
    "@okta/okta-signin-widget": "^6.3.1",
    "@parcel/transformer-less": "^2.8.2",
    "@parcel/transformer-svg-react": "^2.0.1",
    "@sentry/react": "^6.3.1",
    "@sentry/tracing": "^6.3.1",
    "@svgr/parcel-plugin-svgr": "^5.4.0",
    "@swc/helpers": "^0.2.13",
    "@types/jest": "^26.0.14",
    "@types/jsdom": "^16.2.4",
    "@types/jwt-decode": "^2.2.1",
    "@types/lodash": "^4.14.161",
    "@types/react": "^17.0.0",
    "@types/react-dom": "^17.0.0",
    "@types/react-lazyload": "^3.0.0",
    "@types/react-outside-click-handler": "^1.3.0",
    "@types/react-router": "^5.1.8",
    "@types/react-router-dom": "^5.1.5",
    "@types/uuid": "^8.3.0",
    "@xstate/react": "^3.2.2",
    "antd": "^5.8.0",
    "autoprefixer": "^9.8.6",
    "classnames": "^2.3.1",
    "colors": "^1.4.0",
    "diff": "^5.1.0",
    "file-saver": "^2.0.2",
    "framer-motion": "^6.0.0",
    "get-blob-duration": "^1.2.0",
    "history": "^4.0.1",
    "i18next": "^20.3.3",
    "i18next-browser-languagedetector": "^6.1.2",
    "i18next-http-backend": "^1.2.6",
    "jwt-decode": "^2.2.0",
    "less-loader": "^6.2.0",
    "lodash": "^4.17.21",
    "lru-cache": "^7.3.1",
    "moment": "^2.29.4",
    "parcel": "^2.8.2",
    "public": "^0.1.5",
    "react": "^17.0.0",
    "react-contenteditable": "^3.3.5",
    "react-detectable-overflow": "^0.6.2",
    "react-dom": "^17.0.0",
    "react-i18next": "^11.11.2",
    "react-infinite-scroller": "^1.2.6",
    "react-intersection-observer": "^9.5.2",
    "react-outside-click-handler": "^1.3.0",
    "react-router": "^5.2.1",
    "react-router-dom": "^5.2.0",
    "regenerator-runtime": "^0.13.9",
    "style.css": "^1.0.0",
    "usehooks-ts": "^2.9.1",
    "uuid": "^8.3.0",
    "xml-formatter": "^2.3.0",
    "xstate": "^4.38.2"
  },
  "overrides": {
    "graphql": "15.8.0"
  },
  "targets": {
    "default": {
      "context": "browser"
    }
  }
}
