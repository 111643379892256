.homeContainer {
  display: flex;
  flex: 1;
}

.narrationAppContainer {
  position: relative;
  display: flex;
  flex: 1;
  max-height: 100%;
}
