@import "../../../editor/styles/colors.less";

.rheseRoot {
  position: relative;
}

.rheseRoot:hover {
  cursor: pointer;

  .rheseContainer {
    border-right: 3px solid @blue;
    border-left: 3px solid @blue;
  }
}

.rheseRoot:first-child .rheseContainer {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rheseRoot:last-child .rheseContainer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rheseContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0px 1em;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  overflow: hidden;
}

.rheseWhite {
  background-color: @white;
}

.rheseGrey {
  background-color: @lightest-grey;
}

.checkboxContainer {
  padding: 16px;
}

.rhese {
  transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  flex: 1;
  margin: 0px;
  padding: 16px 0px;
  font-family: Roboto;
}

.selectedIcon,
.selectionStart {
  position: absolute;
  top: 50%;
  left: -1em;
  transform: translateX(-50%) translateY(-50%);
}

.selectionStart {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: @orange;
}

.iconIgnoredFromSynchro {
  margin-left: -0.3em;
  padding-left: 0.3em;
  padding-right: 0.9em;
  font-size: 16px;
  &:hover * {
    fill: @orange;
  }
}
