import { gql, useMutation } from "@apollo/client";
import { AddProjectRole, AddProjectRoleVariables } from "./__generated__/AddProjectRole";
import { DeleteProjectRole, DeleteProjectRoleVariables } from "./__generated__/DeleteProjectRole";

export const useAddProjectRole = () =>
  useMutation<AddProjectRole, AddProjectRoleVariables>(gql`
    mutation AddProjectRole(
      $projectId: UUID!
      $userRole: UserRole!
      $userTask: UserTask!
      $userId: String!
    ) {
      addProjectRole(
        userRole: $userRole
        userTask: $userTask
        projectId: $projectId
        userId: $userId
      )
    }
  `);

export const useDeleteProjectRole = () =>
  useMutation<DeleteProjectRole, DeleteProjectRoleVariables>(gql`
    mutation DeleteProjectRole($roleId: UUID!) {
      deleteProjectRole(roleId: $roleId)
    }
  `);
