import { gql, useMutation } from "@apollo/client";
import { AddAudioReview, AddAudioReviewVariables } from "./__generated__/AddAudioReview";
import {
  DeleteAudioReviews,
  DeleteAudioReviewsVariables,
} from "./__generated__/DeleteAudioReviews";
import { UpdateAudioReview, UpdateAudioReviewVariables } from "./__generated__/UpdateAudioReview";

export const useAddAudioReview = () =>
  useMutation<AddAudioReview, AddAudioReviewVariables>(
    gql`
      mutation AddAudioReview(
        $projectId: UUID!
        $rheseId: UUID!
        $status: AudioReviewStatus!
        $comment: String
      ) {
        addAudioReview(
          projectId: $projectId
          review: { rheseId: $rheseId, status: $status, comment: $comment }
        )
      }
    `,
  );

export const useUpdateAudioReview = () =>
  useMutation<UpdateAudioReview, UpdateAudioReviewVariables>(
    gql`
      mutation UpdateAudioReview(
        $status: AudioReviewStatus!
        $comment: String
        $projectId: UUID!
        $reviewId: UUID!
      ) {
        updateAudioReview(
          status: $status
          comment: $comment
          projectId: $projectId
          reviewId: $reviewId
        )
      }
    `,
  );

export const useDeleteAudioReviews = () =>
  useMutation<DeleteAudioReviews, DeleteAudioReviewsVariables>(
    gql`
      mutation DeleteAudioReviews($rheseIds: [UUID!]!, $projectId: UUID!) {
        deleteAudioReviews(rheseIds: $rheseIds, projectId: $projectId)
      }
    `,
  );
