import { History } from "history";
import moment from "moment";
import { useEffect, useRef } from "react";
import { ExportConfigColoration } from "../../../../__generated__/globalTypes";
import { EDITOR_TABS } from "../config";
import { SelectedTextPosition } from "../Project/components/EditingModes/Formatting/components/FormattingComp/FormattingComp";

//https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const normalizeStr = (str: string) => str.normalize("NFD").replace(/\p{Diacritic}/gu, "");

export const isFormatTab = (history: History) => {
  const tabName = (history.location.pathname.match(/[^/]*$/) || [""])[0];
  return tabName === EDITOR_TABS.format;
};

export const buildColorationConfigFromArrayOfString = (arr: string[]): ExportConfigColoration => ({
  syllable: arr?.includes("syllable"),
  phoneme: arr?.includes("phoneme"),
  custom: arr?.includes("custom"),
});

export const getExportDuration = (createdAt: string, completedAt?: string) => {
  if (!createdAt) {
    return "";
  }

  if (!completedAt) {
    completedAt = new Date().toISOString();
  }

  const durationInSeconds = moment(completedAt).diff(createdAt, "seconds");
  if (durationInSeconds < 60) {
    return `${durationInSeconds}s`;
  }

  const durationInMin = moment(completedAt).diff(createdAt, "minute");
  if (durationInMin < 60) {
    return `${durationInMin}min`;
  }

  const durationInHours = moment(completedAt).diff(createdAt, "hours");
  return `${durationInHours}h`;
};

export const isMoreThan24h = (completedAt?: string) => {
  if (!completedAt) {
    return false;
  }

  const durationInHours = moment().diff(completedAt, "hours");
  return durationInHours > 24;
};

export const sortByMetadataStart = (
  a: { anchors: { utf16Start: number }[] },
  b: { anchors: { utf16Start: number }[] },
) => a.anchors[0].utf16Start - b.anchors[0].utf16Start;

export const getMetadataContent = (
  metadata?: { anchors: { utf16Start: number; utf16Size: number }[] },
  pageMetadata?: {
    textContent: string;
    page: {
      anchors: {
        utf16Start: number;
        utf16Size: number;
      }[];
    };
  },
) =>
  metadata &&
  pageMetadata &&
  pageMetadata.textContent.substring(
    metadata.anchors[0].utf16Start - pageMetadata.page.anchors[0].utf16Start,
    metadata.anchors[0].utf16Start -
      pageMetadata.page.anchors[0].utf16Start +
      metadata.anchors[0].utf16Size,
  );

interface Metadata {
  anchors: {
    utf16Start: number;
    utf16Size: number;
  }[];
}

export function getSelectedMetadata<T extends Metadata[]>(
  metadata: T,
  selectedTextPosition: SelectedTextPosition,
): T {
  return metadata.filter(
    (m) =>
      // Start is inside the metadata
      (selectedTextPosition.start >= m.anchors[0].utf16Start &&
        selectedTextPosition.start < m.anchors[0].utf16Start + m.anchors[0].utf16Size) ||
      // End is inside the metadata
      (selectedTextPosition.start + selectedTextPosition.size > m.anchors[0].utf16Start &&
        selectedTextPosition.start + selectedTextPosition.size <=
          m.anchors[0].utf16Start + m.anchors[0].utf16Size) ||
      // Rhese is inside the selection
      (selectedTextPosition.start <= m.anchors[0].utf16Start &&
        selectedTextPosition.start + selectedTextPosition.size >=
          m.anchors[0].utf16Start + m.anchors[0].utf16Size),
  ) as T;
}
