@import "../../../../../../../editor/styles/colors.less";

.root {
  .subContainer {
    &:first-of-type {
      transform: translateX(-30%);
    }
    &:last-of-type {
      transform: translateX(30%);
    }

    .button {
      padding: 0;
      min-width: 2.9em;
      margin-left: 0.5em;
    }

    .icon {
      font-size: 0.7em;
      transform: translateY(-0px);
    }
  }
}
