{
    "authentication": {
        "SignInWidget": {
            "text": "Connecter avec Google"
        }
    },
    "library": {
        "index": {
            "newProject": {
                "title": "Ajouter un livre"
            },
            "projectCard": {
                "menu": {
                    "modifies": "Modifier",
                    "export": " Exporter le projet",
                    "exportNarration": "Exporter le projet pour la narration",
                    "invite": "Inviter un collaborateur",
                    "delete": "Supprimer",
                    "exportEPub": "Export du projet (epub)",
                    "viewExports": "Voir les exports",
                    "resync": "Lancer une re-synchro audio"
                },
                "button": {
                    "date": "Todo Date Format",
                    "title": "Accéder au texte"
                }
            },
            "menu": {
                "signOut": "Se déconnecter"
            },
            "default": {
                "export": {
                    "message": "Export du projet {{name}} en cours",
                    "serverErrorMessage": "Le serveur a renvoyé une erreur",
                    "successNotification": "Télécharger {{name}}",
                    "type": "octet/stream",
                    "errorNotification": "Une erreur est survenue à l'export",
                    "colorationOptions": "Options de coloration",
                    "audioOption": "Audios",
                    "audioOptionCheckbox": "Inclure les audios"
                },
                "exportNarration": {
                    "message": "Export (narration) du projet {{name}} en cours",
                    "serverErrorMessage": "Le serveur a renvoyé une erreur",
                    "successNotification": "Télécharger {{name}}",
                    "type": "octet/stream",
                    "errorNotification": "Une erreur est survenue à l'export",
                    "exportsTabLabel": "Exports"
                },
                "project": {
                    "errorMessage": "L'import du livre à échoué",
                    "successMessage": "Le livre est en cours d'import"
                },
                "navBar": {
                    "title": "Bibliothèque",
                    "tabTitle": "Tous",
                    "errorMessage": "Une erreur est survenue, il est impossible de retrouver la liste des projets pour l'instant"
                }
            }
        },
        "inviteModal": {
            "title": "Inviter un collaborateur",
            "email": "Email du collaborateur",
            "invite": "Inviter"
        }
    },
    "project": {
        "a4Page": {
            "placeholder": "Nommez votre chapitre",
            "showMore": "Afficher plus"
        },
        "actionBar": {
            "stepComp": {
                "raw": "Brut",
                "inProgress": "En préparation",
                "toReview": "À corriger",
                "done": "Terminé"
            },
            "getStepLabel": {
                "formatting": "Segmentation",
                "definition": "Définitions",
                "syllables": "Syllabation",
                "rhese": "Rhésage",
                "dialogues": "Dialogues",
                "narration": "Narration"
            },
            "projectInfo": {
                "title": "Informations",
                "description": "Informations numériques",
                "charsNumber": "Nombre de caractères:",
                "defsNumber": "Nombre de définitions:",
                "imagesNumber": "Nombre d'images:",
                "pageNumber": "Nombre de pages:",
                "rhesesNumber": "Nombre de rhèses:",
                "wordsNumber": "Nombre de mots:",
                "listTitle": "Types de paragraphes",
                "export": "Exporter en CSV"
            },
            "checklist": {
                "title": "Checklist"
            },
            "userAccess": {
                "title": "Collaborateurs"
            },
            "default": {
                "tabPaneTitle": {
                    "preview": "Vue générale",
                    "rhese": "Rhésage",
                    "formatting": "Mise en Page",
                    "definitions": "Mode Définitions",
                    "dialogues": "Mode Dialogues",
                    "syllables": "Mode Syllables",
                    "narration": "Mode Narration"
                }
            },
            "projectTabs": {
                "info": "Informations",
                "checklist": "Checklist",
                "access": "Collaborateurs",
                "config": "Configuration",
                "progress": "Avancement"
            },
            "config": {
                "title": "Configuration des clés",
                "customColoration": {
                    "title": "Configuration de la coloration personnalisée",
                    "add": "Ajouter une nouvelle clée de coloration"
                },
                "customParagraphTag": {
                    "title": "Configuration des étiquettes de paragraphes",
                    "add": "Ajouter une nouvelle étiquette"
                }
            },
            "progress": {
                "title_state_preparation": "En cours de préparation",
                "title_state_narration": "En cours de narration",
                "title_state_narrationComplete": "Narration complète",
                "title_state_narrationAndFixComplete": "Narration et correction complètes",
                "title_state_bookCompleted": "Livre marqué comme étant terminé",
                "preparing": {
                    "title": "En préparation",
                    "legendReadyToNarrate": "Les tâches nécessaires pour la narration sont en cours de préparation",
                    "buttonReadyToNarrate": "Valider pour narration",
                    "legendPreparationComplete": "Les tâches complémentaires sont en cours de préparation",
                    "buttonPreparationComplete": "Valider la préparation"
                },
                "prepared": {
                    "title": "Préparé",
                    "legendTasksForNarrationComplete": "Les tâches nécessaires pour la narration sont terminées",
                    "buttonReadyToNarrate": "Prêt à narrer",
                    "legendPreparationComplete": "Toutes les tâches de préparation sont terminées",
                    "buttonPreparationComplete": "Préparation terminée"
                },
                "narration": {
                    "title": "En narration",
                    "narratedPages": "Pages narrées",
                    "narratedDefinitions": "Définitions narrées",
                    "proofListening": "Proof Listening",
                    "remainingCorrections": "Corrections restantes",
                    "buttonDone": "Marquer le livre comme étant terminé",
                    "markNarrationDone": "Valider la narration",
                    "narrationDone": "Narration validée"
                },
                    "preparationAndNarration": {
                    "markBookComplete": "Marquer le livre comme étant terminé"
                },
                "bookCompleted": {
                    "legend": "Un livre archivé est supprimé à l'affichage dahs la bibliothèque des différents acteurs.",
                    "legendBis": "Pour le désarchiver, contacter le développeur.",
                    "button": "Archiver"
                },
                "bookStateStatus": {
                    "not_ready": "En préparation",
                    "ready_for_narration": "En narration",
                    "narration_complete": "Narration complète",
                    "complete": "Prêt pour la validation",
                    "complete_book": "Terminé",
                    "archived": "Archivé",
                    "resync": "En re-synchro"
                },
                "archived": {
                    "title": "Archivé",
                    "buttonUnarchive": "Désarchiver"
                }
            }
        },
        "existing": {
            "bookRenderer": {
                "paraghapLabel": {
                    "citation": "Citation",
                    "dialogue": "dialogue",
                    "didascalie": "Didascalie",
                    "email": "Email",
                    "intro": "Intro",
                    "normal": "Normal",
                    "note": "Note",
                    "resume": "Résumé",
                    "vers": "Vers",
                    "center": "Centre",
                    "mail": "Courrier",
                    "right": "Droite",
                    "space": "Espace",
                    "bold": "Gras",
                    "text": "Texto",
                    "title1": "Titre1",
                    "title2": "Titre2",
                    "title3": "Titre3",
                    "title4": "Titre4",
                    "title5": "Titre5",
                    "title6": "Titre6",
                    "listUnordered": "Liste non ordonnée",
                    "listOrdered": "Liste ordonnée",
                    "listDefinition": "Liste de définitions"
                }
            },
            "index": {
                "syllableComponent": {
                    "syllables": "Syllabes",
                    "phonemes": "Phonèmes",
                    "custom": "Personnalisée"
                },
                "pageBar": {
                    "mutePage": "Page(s) muette(s)"
                },
                "goToPage": {
                    "nav": "Aller à ...",
                    "validation": "OK"
                }
            },
            "editingMode": {
                "definition": {
                    "buildDefinitionMenu": {
                        "associates": "Associer à la définition",
                        "modifies": " Modifier la définition",
                        "add": "Ajouter une définition",
                        "dissociate": "Dissocier de la définition"
                    },
                    "content": {
                        "all": "Toutes les définitions",
                        "explicit": "Définitions explicites",
                        "implicit": "Définitions implicites",
                        "foot_note": "Notes de bas de page",
                        "image": "Avec image"
                    },
                    "definitionModal": {
                        "message": {
                            "assign": "Assigner à la définition",
                            "update": "Enregistrer les modifications",
                            "create": "Créer une définition"
                        },
                        "radioButton": {
                            "explicit": "Définitions explicites",
                            "implicit": "Définitions implicites",
                            "foot_note": "Notes de bas de page",
                            "image": "Avec image"
                        },
                        "textArea": {
                            "placeholder": "Rédigez la définition..."
                        },
                        "imageName": {
                            "placeholder": "Nom de l'image"
                        }
                    }
                },
                "formatting": {
                    "dropZone": "Ajouter une image",
                    "menu": {
                        "titre_1": "Titre de niveau 1",
                        "titre_2": "Titre de niveau 2",
                        "titre_3": "Titre de niveau 3",
                        "titre_4": "Titre de niveau 4",
                        "normal": "Normal",
                        "dialogue": "Dialogue",
                        "intro": "Introduction",
                        "resume": "Résumé",
                        "email": "Email",
                        "citation": "Citation",
                        "vers": "Vers",
                        "didascalie": "Didascalie",
                        "note": "Note",
                        "subMenuTitleTransformsTitle": "Transformer en titre...",
                        "subMenuTitleAlign": "Aligner...",
                        "space": "Espace",
                        "center": "Au centre",
                        "right": "À droite",
                        "subMenuTitleTransformsParagraph": "Transformer en paragraphe...",
                        "subMenuTitleException": "Exceptions...",
                        "bold": "Gras",
                        "text": "Texto",
                        "mail": "Courrier",
                        "rheseAction": "Segmenter après la rhèse",
                        "spaceAction": {
                            "normal": "Transformer en espace",
                            "unbreakable": "Transformer en espace insécable"
                        },
                        "lineSuppression": {
                            "normal": "Supprimer le retour à la ligne",
                            "afterRhese": "Retourner à la ligne après la rhèse"
                        },
                        "titre_5": "Titre de niveau 5",
                        "titre_6": "Titre de niveau 6",
                        "subMenuList": "Listes...",
                        "listUnordered": "Non ordonnée",
                        "listOrdered": "Ordonnée",
                        "listDefinition": "Définitions",
                        "list": "Liste",
                        "addClassesAction": "Ajouter des classes css custom",
                        "removeClassesAction": "Supprimer les classes css custom"
                    },
                    "lettersCount": {
                        "form": {
                            "name": "basic",
                            "item": {
                                "imageNaming": {
                                    "label": "Nom de l'image",
                                    "name": "fileName"
                                },
                                "validation": "Valider",
                                "imageDescription": {
                                    "label": "Description de l'image",
                                    "name": "description"
                                },
                                "radioButton": {
                                    "normal": "Normal",
                                    "popup": "Popup"
                                }
                            }
                        },
                        "result": "{{lettersCount}} caractères"
                    },
                    "default": {
                        "modal": {
                            "title": "Ajouter une image"
                        },
                        "form": {
                            "imageNaming": {
                                "label": "Nom de l'image",
                                "name": "name"
                            },
                            "validation": "Valider",
                            "imageDescription": {
                                "label": "Description de l'image",
                                "name": "author"
                            },
                            "radioButton": {
                                "normal": "Normal",
                                "popup": "Popup"
                            },
                            "figcaption": {
                                "label": "Légende de l'image",
                                "name": "figcaption"
                            }
                        },
                        "tooltip": {
                            "title": "Ajouter une image"
                        }
                    },
                    "associateToSpeaker": {
                        "title": "Associer au locuteur",
                        "name": "Nom du locuteur",
                        "alphaNumError": "La valeur saisie doit être alphanumérique",
                        "dissociate": "Dissocier du locuteur"
                    },
                    "modalCustomCss": {
                        "title": "Custom CSS classes",
                        "modalOkLabel": "Mettre à jour",
                        "ovverideWarning": "Les éléments sélectionnés ne partagent pas les mêmes classes. La validation d'une nouvelle entrée entraînera la perte des anciennes.",
                        "inputPlaceholder": "ma-classe1, ma-classe2"
                    }
                },
                "narration": {
                    "section": {
                        "minutes": "minutes"
                    },
                    "default": {
                        "title": "Segments audio"
                    }
                },
                "rhese": {
                    "index": {
                        "rhesesRenderer": {
                            "menu": {
                                "merge": "Fusionner les rhese",
                                "creat": "Créer une rhese",
                                "split": "Scinder la rhese"
                            },
                            "edit": "Éditer"
                        }
                    }
                },
                "syllable": {
                    "buildSyllableMenu": {
                        "creat": "Créer une syllabe",
                        "transformMute": "Transformer en lettre(s) muette(s)",
                        "transformNoMute": "Transformer en lettre(s) non muette(s)",
                        "ignore": "Ignorer la syllabe"
                    },
                    "buildPhonemeMenu": {
                        "title": "Assigner à un phonème",
                        "vowel": "Voyelle",
                        "consonant": "Consonne",
                        "complex": "Complexe",
                        "transformMute": "Transformer en lettre(s) muette(s)",
                        "transformNoMute": "Transformer en lettre(s) non muette(s)",
                        "ignore": "Ignorer la phonémique"
                    },
                    "buildCustomColorsMenu": {
                        "assignCustomColor": "Assigner comme",
                        "removeCustomColor": "Enlever la coloration"
                    }
                }
            }
        },
        "search": {
            "getSearchModeLabel": {
                "id": "Identifiant",
                "text": "Text"
            },
            "nbResults": {
                "loading": "En cours de chargement",
                "result": "résultat",
                "results": "résultats"
            }
        },
        "edit": {
            "modalTitle": "Éditer un livre",
            "form": {
                "bookName": "Nom du livre",
                "bookAuthor": "Nom de l'autheur",
                "locale": "Language",
                "modifies": "Modifier"
            }
        },
        "new": {
            "stepOne": {
                "title": "Texte du livre",
                "importType": "Type d'import",
                "successMessage": ": fichier importé avec succès.",
                "errorMessage": ":  échec lors de l'import du fichier..",
                "uploadOutlined": "Rechercher sur votre ordinateur...",
                "import": "Importer",
                "rhesedText": "Texte rhesé",
                "text": "Texte",
                "htmlBook": "HTML",
                "locale": "Langue du livre",
                "jsonImport": "JSON",
                "disableColorationOnSimpleWords": "Désactiver la coloration sur les mots simples"
            },
            "stepTwo": {
                "title": "Titre",
                "titlePlaceholder": "ex. La Peste",
                "author": "Auteur",
                "authorPlaceholder": "ex. Albert Camus",
                "ean": "EAN",
                "eanPlaceholder": "9782070360420",
                "create": "Créer"
            },
            "modalTitle": "Ajouter un livre"
        },
        "importStatus": {
            "failed": {
                "title": "Projet inaccessible",
                "message": "L'import du projet a échoué",
                "action": "Retourner à la liste des projets"
            },
            "inProgress": {
                "title": "Projet inaccessible",
                "message": "L'import du projet est toujours en cours",
                "action": "Retourner à la liste des projets"
            },
            "loading": {
                "title": "Chargement en cours",
                "message": "Merci de patientier quelques instants"
            }
        }
    },
    "userAccess": {
        "userTable": {
            "title": "Rôle",
            "preparer": "Préparateur",
            "correction": "Correcteur"
        },
        "tasksLabels": {
            "definition": "Définition",
            "dialogues": "Dialogues",
            "formatting": "Mise en page",
            "narration": "Narration",
            "rhese": "Rhèsage",
            "syllables": "Syllabation"
        }
    },
    "locale": {
        "fr_FR": "Français",
        "en_GB": "Anglais",
        "it_IT": "Italien",
        "sv_SE": "Suédois"
    },
    "page": "PAGE",
    "paragraphe": "Paragraphe",
    "on": "sur",
    "tagTheParagraph": "Étiquetter",
    "removeTagFromParagraph": "Enlever l'étiquette",
    "selectADefinition": "Sélectionner une définition",
    "associateToDefinition": "Associer à la définition",
    "editor": "Editeur",
    "narrator": "Narrateur",
    "prooflistener": "Contrôleur audio",
    "audioLibrarty": "Bibliothèque audio",
    "GQLError.cannotUpdateRheseContent.message": "Impossible de modifier la rhèse",
    "GQLError.cannotUpdateRheseContent.description": "La rhèse est déjà associée à un fichier audio et ne peut pas être modifiée",
    "select_recorgind_issue_type": "Sélectionner le type de remarque",
    "audioReview.bad_speech": "Prononciation",
    "audioReview.bad_text": "Mauvais texte",
    "audioReview.recording": "Problème d'enregistrement",
    "audioReview.valid": "Ok",
    "audioReview.comment": "Commenter",
    "audioReview.typeYourComment": "Ajouter votre commentaire",
    "audioReview.addAReviewToSelectedRheses": "Ajouter une remarque aux rhèses sélectionnées",
    "cancel": "Annuler",
    "apply": "Appliquer",
    "reset": "Réinitialiser",
    "validate": "Valider",
    "confirm": "Confirmer",
    "warning": "Attention",
    "startOffset": "Décalage du début",
    "endOffset": "Décalage de la fin",
    "audioOffset": "Décaler l'audio",
    "listen": "Écouter",
    "offsetConfigIsInvalid": "Le réglage est invalide",
    "listenToTheRecording": "Écouter l'enregistrement",
    "proofListening.pageNotFullyRecordedTitle": "Cette page du livre n'a pas encore été narrée.",
    "proofListening.pageNotFullyRecordedButtonLabel": "Retour",
    "definitions": "Définitions",
    "rheses": "Rhèses",
    "loadingError": "Une erreur s'est produite lors du chargement",
    "customCssClassesUsed": "Classes CSS personnalisées utilisées",
    "audioReview.bad_text_and_narration": "Pb texte+narration",
    "audioReview.commentName": "Commentaire",
    "audioReview.resetStatusBtn": "Supprimer les remarques",
    "audioReview.resetStatusModalTitle": "Êtes-vous sûr ?",
    "audioReview.resetStatusModalText": "Attention, cette action va supprimer les statuts des rheses sélectionnées, mais aussi leurs commentaires.",
    "audioReview.resetStatusModalBtn": "Supprimer",
    "definitionRecordedAudioUploadSuccess": "Le fichier a bien été enregistré",
    "definitionRecordedAudioUploadError": "Erreur, le fichier n'a pas été enregistré",
    "definitionAudioUploadSuccess": "Le fichier a bien été enregistré",
    "definitionAudioUploadError": "Erreur, le fichier n'a pas été enregistré",
    "definitionAudioUploadErrorSize": "Le fichier ne doit pas dépasser",
    "definitionAudioUploadErrorExtension": "Extension non supportée. Veuillez utiliser les extensions suivantes",
    "filterWithIssues": "Avec problèmes",
    "filterAudioComplete": "Avec audio complet",
    "filterRenarrated": "Renarré",
    "audioReview.renarrated": "Renarré",
    "rheseIgnoreSyncroMenuItemLabel": "Ignorer cette rhèse en synchronisation",
    "rheseUseSyncroMenuItemLabel": "Ok pour synchronisation",
    "rheseUseSyncroDialogTitle": "Validez-vous cette opération ?",
    "rheseUseSyncroDialogOk": "Valider",
    "rheseUseSyncroDialogCancel": "Annuler",
    "rheseIconSyncroTooltip": "Rhèse non synchronisée",
    "forceChange": "Forcer la modification",
    "adminCanForceChange": "Vous pouvez forcer la modification",
    "forcedUpdateWillDeleteAssociatedAudio": "Forcer la mise à jour va supprimer les audios associés",
    "serverHasSentError": "Le serveur a renvoyé une erreur",
    "errorHasBeenSentToTechStaff": "Elle a automatiquement été transmise à l'équipe technique",
    "downloadErrorReport": "Télécharger le rapport d'erreur",
    "tabArchivesLabel": "Archives",
    "exportType_DEFINITIONS": "Définitions",
    "exportType_FULL": "Complet",
    "exportType_HTML": "HTML",
    "exportType_INTO_IMPORTED_EPUB": "ePub",
    "exportType_NARRATION": "Simplifié",
    "exportType_SIMPLE": "Simplifié",

    "tableExportsColumnStatus": "Statut",
    "tableExportsColumnType": "Type",
    "tableExportsColumnProjectName": "Livre",
    "tableExportsColumnAuthor": "Utilisateur",
    "tableExportsColumnCreatedAt": "Démarré à",
    "tableExportsColumnCompletedAt": "Terminé à",
    "tableExportsColumnDuration": "Durée",
    "tableExportsColumnIncludeAudio": "Audio inclus",
    "tableExportsColumnConfig": "Config",
    "tableExportsColumnAction": "Action",
    "tableExportsRetentionInfo": "Les exports réussis restent affichés pour une période de 24h",

    "modalConfirmReSync": "La re-synchronisation audio d'un projet entraîne son blocage durant tout le traitement.\nConfirmez-vous l'action ?",
    "modalConfirmDelete": "Est-ce que vous confirmez la suppression de ce projet ?",
    "overlayReSync": "Projet en cours de re-synchronisation",

    "tabArchivesNoResult": "Pas de livres archivés",

    "proofListeningDataChanged": "Réinitialisation du lecteur audio, dû à un changement de fichier(s) audios",

    "revokeUserAccess": "Révoquer l'accès d'un utilisateur",
    "userEmail": "Email de l'utilisateur",
    "revokeUser": "Révoquer l'utilisateur",
    "filterLabelWith": "Avec",
    "filterLabelWithout": "Sans",
    "proofListening.pageOnlyAccessibleToAdmin": "Cette page n'est accessible qu'aux narrateurs et aux administrateurs car la narration n'est pas terminée.",
    "project.existing.bookRenderer.paraghapLabel.résumé": "Résumé",
    "reload.project": "Recharger le projet"
}
