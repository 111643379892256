import React, { createContext, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { ArrowUpOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Dropdown, List, Menu, Pagination, Row } from "antd";
import debounce from "lodash/debounce";
import * as style from "./style.less";
import { useSearchForRheses } from "../../api/api";
import { scrollToUrlPage, useUpdateDisplayedPageNumber } from "../Page";
import { SubToPages_pages } from "../../api/SubToPages";
import { useI18nObjectHook } from "../../../../../i18n";
import { RheseSearchMode } from "../../../../../../__generated__/globalTypes";
import { getMenuItem, MenuItem } from "../../../styles/antDesignTheme";
import { SearchForRheses_searchForRheses_results } from "../../api/__generated__/SearchForRheses";

const SEARCH_MODES: { [key: string]: SearchModeType } = {
  STRING: "string",
  ID: "id",
};

type SearchModeType = "string" | "id";

type SearchModeLabelTranslateProps = { id: string; text: string };

type nbResultTranslateProps = {
  loading: string;
  result: string;
  results: string;
};

type SearchState = {
  isOpen: boolean;
  searchString: string;
  searchMode: SearchModeType;
  results: Result[];
};

type SearchCommand =
  | {
      id: "SET_SEARCH_STRING";
      payload: string;
    }
  | {
      id: "SET_RESULTS";
      payload: Result[];
    }
  | {
      id: "SET_SEARCH_MODE";
      payload: "string" | "id";
    }
  | {
      id: "OPEN";
    }
  | {
      id: "CLOSE";
    };

type Result = SearchForRheses_searchForRheses_results;

const DEFAULT_SEARCH: SearchState = {
  isOpen: false,
  searchString: "",
  searchMode: "string",
  results: [],
};

export const SearchContext = createContext(DEFAULT_SEARCH);

export function useSearchReducer() {
  const [search, sendSearch] = useReducer((state: SearchState, command: SearchCommand) => {
    if (command.id === "SET_RESULTS") {
      return {
        ...state,
        results: command.payload,
      };
    }
    if (command.id === "SET_SEARCH_STRING") {
      return {
        ...state,
        searchString: command.payload,
      };
    }
    if (command.id === "SET_SEARCH_MODE") {
      return {
        ...state,
        searchMode: command.payload,
      };
    }
    if (command.id === "OPEN") {
      return {
        ...state,
        isOpen: true,
      };
    }
    if (command.id === "CLOSE") {
      return {
        ...state,
        isOpen: false,
      };
    }
    return state;
  }, DEFAULT_SEARCH);

  const send = useMemo(() => {
    const commands = {
      setSearchMode: (mode: SearchModeType) =>
        sendSearch({
          id: "SET_SEARCH_MODE",
          payload: mode,
        }),
      toggle: () => {
        search.isOpen ? commands.close() : commands.open();
      },
      setResults: (results: Result[]) =>
        sendSearch({
          id: "SET_RESULTS",
          payload: results,
        }),
      open: () =>
        sendSearch({
          id: "OPEN",
        }),
      close: () =>
        sendSearch({
          id: "CLOSE",
        }),
      setSearchString: (searchString: string) =>
        sendSearch({
          id: "SET_SEARCH_STRING",
          payload: searchString,
        }),
    };

    return commands;
  }, [search, sendSearch]);

  const res = useMemo(
    () => ({
      state: search,
      send,
    }),
    [search, send],
  );
  return res;
}

type SearchProps = {
  pages: SubToPages_pages[];
  isOpen: boolean;
  close: () => void;
  setSearchMode: (mode: SearchModeType) => void;
  setResults: (results: Result[]) => void;
  searchMode: SearchModeType;
  bookId: string;
};

const getSearchModeLabel = (
  searchMode: SearchModeType,
  translation: SearchModeLabelTranslateProps,
) => {
  if (searchMode === "id") return translation.id;
  return translation.text;
};

export default function Search({
  isOpen,
  close,
  setSearchMode,
  searchMode,
  bookId,
  setResults,
}: SearchProps) {
  const [page, setPage] = useState(1);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [textQuery, setTextQuery] = useState("");
  const [debouncedInfos, setDebouncedInfos] = useState<[string, SearchModeType, string]>([
    textQuery,
    searchMode,
    bookId,
  ]);

  const updateDisplayedPageNumber = useUpdateDisplayedPageNumber();
  const getSearchModeLabelTranslate: SearchModeLabelTranslateProps = useI18nObjectHook(
    "project.search.getSearchModeLabel",
  );

  const nbResultTranslate: nbResultTranslateProps = useI18nObjectHook("project.search.nbResults");

  const [debouncedTextQuery, debouncedSearchMode, debouncedBookId] = debouncedInfos;

  useEffect(() => inputRef?.focus(), [inputRef]);

  const searchModeLabel = useMemo(
    () => getSearchModeLabel(searchMode, getSearchModeLabelTranslate),
    [searchMode, getSearchModeLabelTranslate],
  );

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(
        (textQuery, searchMode, bookId) => setDebouncedInfos([textQuery, searchMode, bookId]),
        300,
      ),
    [],
  );

  const debouncedUpdate = useCallback(debouncedChangeHandler, [debouncedChangeHandler]);

  useEffect(
    () => debouncedUpdate(textQuery, searchMode, bookId),
    [textQuery, searchMode, bookId, debouncedUpdate],
  );

  const { data, loading } = useSearchForRheses({
    query: debouncedTextQuery,
    mode: debouncedSearchMode === "id" ? RheseSearchMode.ID : RheseSearchMode.CONTENT,
    projectId: debouncedBookId,
  });

  const updateQuery = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setTextQuery(e.target.value),
    [setTextQuery],
  );

  const results = useMemo(() => {
    if (!data?.searchForRheses) {
      return [];
    }
    // Sort by pageNumber
    return [...data.searchForRheses.results].sort((a, b) => a.pageNumber - b.pageNumber);
  }, [data]);

  // results is part of data.searchForRheses
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalElements = useMemo(() => data?.searchForRheses.totalMatchNumber || 20, [results]);

  useEffect(() => setPage(1), [textQuery]);

  useEffect(() => {
    if (!isOpen) return;
    setResults(results);
    // setResults ends up with infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, isOpen]);

  const renderDropdownContent = useCallback(() => {
    const items: MenuItem[] = [
      getMenuItem({
        key: "1",
        label: getSearchModeLabel(SEARCH_MODES.STRING, getSearchModeLabelTranslate),
        onClick: () => setSearchMode("string"),
      }),
      getMenuItem({
        key: "2",
        label: getSearchModeLabel(SEARCH_MODES.ID, getSearchModeLabelTranslate),
        onClick: () => setSearchMode("id"),
      }),
    ];

    return <Menu className={style.dropDownMenu} items={items} />;
  }, [getSearchModeLabelTranslate, setSearchMode]);

  return (
    <Drawer
      title="Recherche"
      placement="right"
      bodyStyle={{ padding: 0, display: "flex", flexDirection: "column" }}
      className={style.root}
      open={isOpen}
      closable={true}
      onClose={() => {
        setResults([]);
        close();
      }}
      mask={false}
      width="35em">
      <List
        style={{ flex: 1, overflowY: "auto" }}
        size="large"
        dataSource={results.slice((page - 1) * 20, page * 20)}
        loading={loading}
        header={
          <Row className={style.search} justify={"center"} align={"middle"}>
            <Col style={{ textAlign: "center", fontSize: "1.2em" }} span={2}>
              <SearchOutlined />
            </Col>
            <Col span={16}>
              <input ref={setInputRef} value={textQuery} onChange={updateQuery} />
            </Col>
            <Col span={6}>
              <Dropdown dropdownRender={renderDropdownContent}>
                <Button>
                  {searchModeLabel} <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        }
        renderItem={(result) => {
          return (
            <List.Item
              onClick={() => {
                updateDisplayedPageNumber(result.pageNumber.toString());
                scrollToUrlPage();
              }}
              className={style.result}>
              <span>{result.pageNumber}</span>
              {result.content}
              <span>
                <ArrowUpOutlined className={style.arrow} />
              </span>
            </List.Item>
          );
        }}
      />
      <Pagination
        className={style.pagination}
        onChange={setPage}
        current={page}
        total={totalElements}
        pageSize={20}
        hideOnSinglePage
        responsive
        showSizeChanger={false}
        size="small"
      />
      <p className={style.nbResults}>
        {loading
          ? nbResultTranslate.loading
          : `${results.length} ${
              results.length > 1 ? nbResultTranslate.results : nbResultTranslate.result
            }`}
      </p>
    </Drawer>
  );
}
