import { gql, useMutation } from "@apollo/client";
import {
  CreateSyllableFromPosition,
  CreateSyllableFromPositionVariables,
} from "./__generated__/CreateSyllableFromPosition";
import {
  DeleteSyllableFromPosition,
  DeleteSyllableFromPositionVariables,
} from "./__generated__/DeleteSyllableFromPosition";

export const useCreateSyllableFromPosition = () =>
  useMutation<CreateSyllableFromPosition, CreateSyllableFromPositionVariables>(
    gql`
      mutation CreateSyllableFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        createSyllableFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
  );

export const useDeleteSyllableFromPosition = () =>
  useMutation<DeleteSyllableFromPosition, DeleteSyllableFromPositionVariables>(
    gql`
      mutation DeleteSyllableFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        deleteSyllableFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
  );
