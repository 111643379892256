.textContainer {
  width: 100%;
  margin-top: 2em;
  margin-bottom: 4em;
  height: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.textToRead {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
}

.paragraph {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  position: relative;
}

.unselect {
  position: sticky;
  font-size: 1em;
  top: 2em;
  transform: translateX(-100%);
}

.toggleRheseSilence {
  position: absolute;
  left: 0;
  transform: translateX(-110%);
  height: 4.6em;
}
