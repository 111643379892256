import React from "react";
import { SecureRoute } from "@okta/okta-react";
import { Redirect, RouteComponentProps, useHistory } from "react-router";
import Centered from "../../../../../../components/Centered/Centered";
import { Button, Result } from "antd";
import { useI18nObjectHook } from "../../../../../../i18n";
import { Spinner } from "../../..";

type Props = {
  failed?: boolean;
  notImportedYet?: boolean;
  loading?: boolean;
};

export default function SubRouteNotImported({ failed, notImportedYet, loading }: Props) {
  const history = useHistory();
  const t = useI18nObjectHook("project.importStatus");
  return (
    <>
      <SecureRoute
        exact
        path="/editor/books/edit/:bookId"
        render={(props: RouteComponentProps<{ bookId?: string }>) => (
          <Redirect to={`/editor/books/edit/${props.match.params.bookId}/overview`} />
        )}
      />
      {failed && (
        <Centered>
          <Result
            status="500"
            title={t.failed.title}
            subTitle={t.failed.message}
            extra={<Button onClick={() => history.push("/")}>{t.failed.action}</Button>}
          />
        </Centered>
      )}
      {notImportedYet && (
        <Centered>
          <Result
            icon={<Spinner />}
            title={t.inProgress.title}
            subTitle={t.inProgress.message}
            extra={<Button onClick={() => history.push("/")}>{t.inProgress.action}</Button>}
          />
        </Centered>
      )}
      {loading && (
        <>
          <Result
            style={{
              height: "100%",
              paddingTop: "25%",
            }}
            icon={<Spinner />}
            title={t.loading.title}
            subTitle={t.loading.message}
            extra={<Button onClick={() => history.push("/")}>{t.inProgress.action}</Button>}
          />
          <Spinner />
        </>
      )}
    </>
  );
}
