import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { UseAudioModeDataArgs } from "./useAudioModeData";
import { SubToPages_pages } from "../../../../editor/Project/api/SubToPages";

export default function useProofListeningNav(
  currentPageId: string | undefined,
  bookInfoFromUrl: UseAudioModeDataArgs["bookInfoFromUrl"],
  pages: SubToPages_pages[],
) {
  const history = useHistory();
  const goBack = useCallback(() => history.goBack(), [history]);

  const [previousPage, nextPage] = useMemo(() => {
    if (!pages?.length) return [];
    const idx = pages.findIndex((page) => page.id === currentPageId);
    return [pages[idx - 1]?.pageNumber, pages[idx + 1]?.pageNumber];
  }, [pages, currentPageId]);

  const nav = useMemo(() => {
    const navTo = (pageNumber?: number) => {
      if (typeof pageNumber !== "number") {
        return;
      }
      history.push(`/audio/library/edit/${bookInfoFromUrl.bookId}/${pageNumber}`);
    };

    return {
      prev: {
        canNavigate: previousPage !== undefined,
        navigate: () => navTo(previousPage),
      },
      next: {
        canNavigate: nextPage !== undefined,
        navigate: () => navTo(nextPage),
      },
    };
  }, [previousPage, nextPage, history, bookInfoFromUrl.bookId]);

  return {
    goBack,
    nav,
  };
}
