import { useCallback, useMemo, useState } from "react";
import { BookStatus, PreparationProgressStatus } from "../../../../../__generated__/globalTypes";
import {
  useUpdateProjectNarrationCompleteness,
  useUpdateProjectPreparationProgress,
  useSetProjectAsComplete,
  useSetProjectAsArchived,
  useRestoreProject,
} from "../api/api";
import { SubToProject_project } from "../api/__generated__/SubToProject";
import useProjectProgressStats from "./useProjectProgressStats";

export type ProjectType = SubToProject_project;

export const isProjectIsInResync = (project?: ProjectType) => project?.isDoingAudioResync;

const defaultProject = {
  id: null,
  status: "",
  preparationProgress: "",
  isNarrationComplete: false,
};

export default function useProjectProgress(project?: ProjectType) {
  const [setProjectAsComplete] = useSetProjectAsComplete();
  const [setProjectAsArchived] = useSetProjectAsArchived();
  const [setRestoreProject] = useRestoreProject();
  const [updateProjectPreparationProgress] = useUpdateProjectPreparationProgress();
  const [updateProjectNarrationCompleteness] = useUpdateProjectNarrationCompleteness();

  const { preparationProgress, status, isNarrationComplete } = project || defaultProject;

  const [isLoading, setIsLoading] = useState(false);

  /**
   * Methods to update "status" or "preparationProgress"
   */
  const markAsReadyForNarration = useCallback(async () => {
    try {
      setIsLoading(true);
      await updateProjectPreparationProgress({
        variables: {
          projectId: project?.id,
          preparationProgress: PreparationProgressStatus.READY_FOR_NARRATION,
        },
      });
    } catch (e) {
      console.error("markAsReadyForNarration error", e);
    } finally {
      setIsLoading(false);
    }
  }, [project?.id, updateProjectPreparationProgress]);

  const completePreparation = useCallback(async () => {
    try {
      setIsLoading(true);
      await updateProjectPreparationProgress({
        variables: {
          projectId: project?.id,
          preparationProgress: PreparationProgressStatus.COMPLETE,
        },
      });
    } catch (e) {
      console.error("completePreparation error", e);
    } finally {
      setIsLoading(false);
    }
  }, [project?.id, updateProjectPreparationProgress]);

  const completeNarration = useCallback(async () => {
    try {
      setIsLoading(true);
      await updateProjectNarrationCompleteness({
        variables: {
          projectId: project?.id,
          isNarrationComplete: true,
        },
      });
    } catch (e) {
      console.error("completeNarration error", e);
    } finally {
      setIsLoading(false);
    }
  }, [project?.id, updateProjectNarrationCompleteness]);

  const finalComplete = useCallback(async () => {
    try {
      setIsLoading(true);
      await setProjectAsComplete({
        variables: {
          projectId: project?.id,
        },
      });
    } catch (e) {
      console.error("finalComplete error", e);
    } finally {
      setIsLoading(false);
    }
  }, [project?.id, setProjectAsComplete]);

  const archiveProject = useCallback(async () => {
    try {
      setIsLoading(true);
      await setProjectAsArchived({
        variables: {
          projectId: project?.id,
        },
      });
    } catch (e) {
      console.error("archiveProject error", e);
    } finally {
      setIsLoading(false);
    }
  }, [project, setProjectAsArchived]);

  const unarchiveProject = useCallback(async () => {
    try {
      setIsLoading(true);
      await setRestoreProject({
        variables: {
          projectId: project?.id,
        },
      });
    } catch (e) {
      console.error("archiveProject error", e);
    } finally {
      setIsLoading(false);
    }
  }, [project, setRestoreProject]);

  /**
   * Get the label status
   */
  const bookStatusLabel: string = useMemo(() => {
    if (project && isProjectIsInResync(project)) {
      return "RESYNC";
    } else if (status === BookStatus.COMPLETE || status === BookStatus.ARCHIVED) {
      if (status === BookStatus.COMPLETE) {
        return "COMPLETE_BOOK";
      }
      return status;
    } else if (isNarrationComplete && preparationProgress !== PreparationProgressStatus.COMPLETE) {
      return "NARRATION_COMPLETE";
    } else if (
      !isNarrationComplete &&
      preparationProgress !== PreparationProgressStatus.NOT_READY
    ) {
      return "READY_FOR_NARRATION";
    } else if (
      preparationProgress === PreparationProgressStatus.NOT_READY ||
      preparationProgress === PreparationProgressStatus.READY_FOR_NARRATION ||
      preparationProgress === PreparationProgressStatus.COMPLETE
    ) {
      return preparationProgress;
    }

    return "";
  }, [status, preparationProgress, isNarrationComplete, project]);

  /**
   * Stats
   */
  const stats = useProjectProgressStats(project);

  return {
    project,
    markAsReadyForNarration,
    completePreparation,
    completeNarration,
    finalComplete,
    archiveProject,
    unarchiveProject,
    bookStatusLabel,
    isLoading,
    stats,
  };
}
