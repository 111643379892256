import React, { useEffect, useState } from "react";
import { SubToPages_pages } from "../../api/SubToPages";
import { SliderFontSizeProvider } from "../SliderFontSize/context";
import EditingRouter from "./EditingRouter";
import { useHistory } from "react-router";

type Props = {
  projectId: string;
  pages: SubToPages_pages[];
  loading: boolean;
};

const EditingRouterProvider = (props: Props) => {
  const history = useHistory();
  const [initialSearch] = useState(window.location.search);

  // Push search route to history using timeout: the search is erased automatically somewhere
  useEffect(() => {
    if (initialSearch) {
      setTimeout(() => {
        history.push(window.location.pathname + initialSearch);
      }, 100);
    }
  }, [initialSearch, history]);

  return (
    <SliderFontSizeProvider>
      <EditingRouter {...props} />
    </SliderFontSizeProvider>
  );
};

export default EditingRouterProvider;
