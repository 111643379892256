import React from "react";
import { motion } from "framer-motion";
import { Tooltip } from "antd";
import ImgIcon from "../../../../assets/img.svg";
import { LetterKind } from "../../../../api/formatters";

type PropTypes = {
  handleLettersKindAssignation: Function;
  sideMenuClassName: string;
  setIsDraggingImage: Function;
  isDraggingImage: boolean;
  handleImageCreation: Function;
  targetParagraph: string | null;
};

// eslint-disable-next-line react/display-name
const SideMenu = ({
  handleLettersKindAssignation,
  setIsDraggingImage,
  handleImageCreation,
  isDraggingImage,
  sideMenuClassName,
  targetParagraph,
}: PropTypes) => {
  return (
    <>
      <div className={sideMenuClassName}>
        <menu>
          <Tooltip placement="left" title="Gras">
            <div onClick={() => handleLettersKindAssignation(LetterKind.GRAS)}>
              <b>B</b>
            </div>
          </Tooltip>
          <Tooltip placement="left" title="Italique">
            <div onClick={() => handleLettersKindAssignation(LetterKind.ITALIQUE)}>
              <i>I</i>
            </div>
          </Tooltip>
          <Tooltip placement="left" title="Majuscule">
            <div onClick={() => handleLettersKindAssignation(LetterKind.MAJUSCULE)}>TT</div>
          </Tooltip>
          <Tooltip placement="left" title="Minuscule">
            <div onClick={() => handleLettersKindAssignation(LetterKind.MINUSCULE)}>tt</div>
          </Tooltip>
          <Tooltip placement="left" title="Exposant">
            <div onClick={() => handleLettersKindAssignation(LetterKind.EXPOSANT)}>
              T<sup>1</sup>
            </div>
          </Tooltip>
          <Tooltip placement="left" title="Indice">
            <div onClick={() => handleLettersKindAssignation(LetterKind.INDICE)}>
              T<sub>1</sub>
            </div>
          </Tooltip>
          <Tooltip placement="left" title="Citation">
            <div onClick={() => handleLettersKindAssignation(LetterKind.CITATION)}>❝❞</div>
          </Tooltip>
        </menu>
      </div>
      <div className={sideMenuClassName}>
        <menu>
          <Tooltip placement="left" title="Ajouter une image">
            <motion.div
              onDragStart={() => setIsDraggingImage(true)}
              onDragEnd={() => {
                setIsDraggingImage(false);
                handleImageCreation();
              }}
              animate={
                isDraggingImage
                  ? { opacity: targetParagraph !== null ? 1 : 0.3 }
                  : { x: 0, y: 0, opacity: 1 }
              }
              drag>
              <ImgIcon />
            </motion.div>
          </Tooltip>
        </menu>
      </div>
    </>
  );
};

export default SideMenu;
