import { Rhese } from "../rhese/Rhese";
import React, { useCallback } from "react";
import * as style from "./style.less";
import { groupBy } from "lodash";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

type TextToReadProps = {
  pageMetadata: SubscribeToExtendedPage_extendedPage;
  selectedRheses: SubscribeToExtendedPage_extendedPage_rhese[];
  addSelectedRhese: (rheseId?: string) => void;
  selectionStartId?: string;
  projectId: string;
  savingAudio: boolean;
};

const TextToRead = ({
  pageMetadata,
  selectedRheses,
  addSelectedRhese,
  selectionStartId,
  projectId,
  savingAudio,
}: TextToReadProps) => {
  const unselectAll = useCallback(addSelectedRhese, [addSelectedRhese]);

  const handleSelection = useCallback(
    (rheseId: string) => {
      const isSelected = selectedRheses.find((r) => r.data.id === rheseId);
      const rhese = pageMetadata.rhese.find((r) => r.data.id === rheseId)!;
      if (isSelected) {
        unselectAll();
      }
      addSelectedRhese(rhese.data.id);
    },
    [pageMetadata?.rhese, selectedRheses, addSelectedRhese, unselectAll],
  );

  if (!pageMetadata) return null;
  const groupedRheses = groupBy(pageMetadata.rhese, "paragraphId");
  return (
    <div className={style.textToRead}>
      <div className={style.textContainer}>
        {Object.entries(groupedRheses).map(([key, rheses]) => {
          return (
            <div key={key} className={style.paragraph}>
              {rheses
                .sort((a, b) => a.anchors[0].utf16Start - b.anchors[0].utf16Start)
                .map((rhese, index) => {
                  const isSelected = selectedRheses.includes(rhese);
                  const isSelectionStart = rhese.data.id === selectionStartId && !isSelected;

                  return (
                    <Rhese
                      key={rhese.data.id}
                      isSelectionStart={isSelectionStart}
                      onClick={handleSelection}
                      isSelected={isSelected}
                      rhese={rhese}
                      index={index}
                      projectId={projectId}
                      pageMetadata={pageMetadata}
                      inProgress={isSelected && savingAudio}
                    />
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TextToRead;
