import React, { ReactElement, useMemo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { ExportType } from "../../../__generated__/globalTypes";
import { SubscribeToExports_exports } from "../../app/editor/Project/api/__generated__/SubscribeToExports";
import * as style from "./style.less";
import useTable from "./useTable";
import { useTranslation } from "react-i18next";

type Props = {
  exports: SubscribeToExports_exports[];
  download: (projectId: string, exportId: string) => void;
  cancel: (projectId: string, exportId: string) => void;
  deleteExports: (exportIds: string[]) => void;
  deleteLoading: boolean;
  deletingExportIds: string[];
  hideTopLegend?: boolean;
};
interface DataType {
  key: React.Key;
  status: JSX.Element | null;
  type: ExportType;
  author: string;
  createdAt: string;
  completedAt: string;
  duration: string;
  includeAudio: JSX.Element | null;
  config: ReactElement<any, any>;
  action: JSX.Element | null;
}

export default function ExportsTable({
  exports,
  cancel,
  download,
  deleteExports: deleteExportsProps,
  deleteLoading,
  deletingExportIds,
  hideTopLegend,
}: Props) {
  const { data, rowSelection, hasSelected, deleteExports } = useTable({
    exports,
    cancel,
    download,
    deleteExportsProps,
    deletingExportIds,
  });
  const [t] = useTranslation();

  const columns: ColumnsType<DataType> = useMemo(() => {
    return [
      {
        title: t("tableExportsColumnStatus"),
        dataIndex: "status",
        width: 50,
      },
      {
        title: t("tableExportsColumnType"),
        dataIndex: "type",
        width: 30,
      },
      {
        title: t("tableExportsColumnProjectName"),
        dataIndex: "projectName",
        width: 180,
      },
      {
        title: t("tableExportsColumnAuthor"),
        dataIndex: "author",
        width: 160,
      },
      {
        title: t("tableExportsColumnCreatedAt"),
        dataIndex: "createdAt",
        width: 100,
      },
      {
        title: t("tableExportsColumnCompletedAt"),
        dataIndex: "completedAt",
        width: 100,
      },
      {
        title: t("tableExportsColumnDuration"),
        dataIndex: "duration",
        width: 60,
      },
      {
        title: t("tableExportsColumnIncludeAudio"),
        dataIndex: "includeAudio",
        width: 40,
      },
      {
        title: t("tableExportsColumnConfig"),
        dataIndex: "config",
        className: "cell-config",
        width: 40,
      },
      {
        title: t("tableExportsColumnAction"),
        dataIndex: "action",
        width: 40,
      },
    ];
  }, [t]);

  return (
    <div className={style.projectExports}>
      <div className={style.legendAndDelete}>
        {!!data.length && (
          <div className={style.deleteWrapper}>
            <Button
              type="primary"
              disabled={!hasSelected || deleteLoading}
              className={style.deleteBtn}
              onClick={deleteExports}
              loading={deleteLoading}>
              {!deleteLoading && <DeleteOutlined className={style.deleteIcon} />}
            </Button>
            <span style={{ marginLeft: 8 }}>
              {hasSelected ? `(${rowSelection.selectedRowKeys.length})` : ""}
            </span>
          </div>
        )}
        {!hideTopLegend && (
          <Alert
            className={style.legendAlert}
            message={t("tableExportsRetentionInfo")}
            type="info"
            showIcon
          />
        )}
      </div>
      <div className={style.projectExportsList}>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          pagination={{ position: ["topRight"] }}
        />
      </div>
    </div>
  );
}
