import { LetterKind } from "./Project/api/formatters";

export function checkIfPunctuation(str: string) {
  // eslint-disable-next-line
  return str.match(/[\\\.\(\)\[\]\{\}\"\'\`’\-\—\–,;:\/\!\?\¿\¡\$\*\«\»\£\$\µ\+\€\=\&\~]/);
}

export function checkIfSpace(str: string) {
  return str.match(/\s/);
}

export type Letter = {
  absoluteTextPosition: number;
  id: string;
  char: string;
  isMute: boolean;
  syllableId?: string;
  phoneme?: string | null;
  kind?: LetterKind[];
  customColoration?: string | null;
  cssClasses: string | null;
  position: number;
};

export type Rhese = {
  id: string;
  letters: Letter[];
  paragraphId?: string;
};
