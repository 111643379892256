import { gql, useMutation } from "@apollo/client";
import {
  SetScriptNotationFromPosition,
  SetScriptNotationFromPositionVariables,
} from "./__generated__/SetScriptNotationFromPosition";
import {
  UnsetScriptNotationFromPosition,
  UnsetScriptNotationFromPositionVariables,
} from "./__generated__/UnsetScriptNotationFromPosition";

export const useSetScriptNotationFromPosition = () =>
  useMutation<SetScriptNotationFromPosition, SetScriptNotationFromPositionVariables>(
    gql`
      mutation SetScriptNotationFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $scriptNotation: ExtendedTextScriptNotation!
        $stringVersion: Int!
      ) {
        setScriptNotationFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          scriptNotation: $scriptNotation
          stringVersion: $stringVersion
        )
      }
    `,
  );

export const useUnsetScriptNotationFromPosition = () =>
  useMutation<UnsetScriptNotationFromPosition, UnsetScriptNotationFromPositionVariables>(
    gql`
      mutation UnsetScriptNotationFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $scriptNotation: ExtendedTextScriptNotation!
        $stringVersion: Int!
      ) {
        unsetScriptNotationFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          scriptNotation: $scriptNotation
          stringVersion: $stringVersion
        )
      }
    `,
  );
