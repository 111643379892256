import { useCallback, useState } from "react";
import { FrogRoles, useSelectedRole } from "../../../../../identity";
import { SubToPages_pages } from "../../../../editor/Project/api/SubToPages";
import { FilterGroupOptions, FilterGroupState } from "./FilterGroup";
import { PageAudioInfo } from "./BookPanel";

const useFilterOnComments = (groupedRhesesAudioInfo: PageAudioInfo) => {
  const { state: selectedRole } = useSelectedRole();
  const [state, setState] = useState(FilterGroupState.off);

  const toggle = useCallback((option: FilterGroupOptions) => {
    setState((prevValue) => {
      if (option === FilterGroupOptions.with) {
        return prevValue === FilterGroupState.with ? FilterGroupState.off : FilterGroupState.with;
      }
      return prevValue === FilterGroupState.without
        ? FilterGroupState.off
        : FilterGroupState.without;
    });
  }, []);

  const applyField = useCallback(
    (pages: SubToPages_pages[]) => {
      return pages.map((page) => {
        const withInvalidReview = groupedRhesesAudioInfo[page.id]?.find(
          (group) =>
            group.withAudio === true && group.withReview === true && group.isReviewValid === false,
        );

        return {
          ...page,
          issuesCount: withInvalidReview?.count || 0,
        };
      });
    },
    [groupedRhesesAudioInfo],
  );

  const filter = useCallback(
    (pages: SubToPages_pages[]) => {
      if (state === FilterGroupState.off) {
        return pages;
      }
      return pages.filter((page) => {
        const withInvalidReview = groupedRhesesAudioInfo[page.id]?.find(
          (group) =>
            group.withAudio === true && group.withReview === true && group.isReviewValid === false,
        );

        if (state === FilterGroupState.with) {
          return (withInvalidReview?.count || 0) > 0;
        }
        return withInvalidReview?.count === 0;
      });
    },
    [groupedRhesesAudioInfo, state],
  );

  return {
    state,
    toggle,
    show: selectedRole !== FrogRoles.Editor,
    filter,
    applyField,
  };
};
export default useFilterOnComments;
