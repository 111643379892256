import { gql, useMutation } from "@apollo/client";
import {
  SetCitationFromPosition,
  SetCitationFromPositionVariables,
} from "./__generated__/SetCitationFromPosition";
import {
  UnsetCitationFromPosition,
  UnsetCitationFromPositionVariables,
} from "./__generated__/UnsetCitationFromPosition";

export const useSetCitationFromPosition = () =>
  useMutation<SetCitationFromPosition, SetCitationFromPositionVariables>(
    gql`
      mutation SetCitationFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        setCitationFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
  );

export const useUnsetCitationFromPosition = () =>
  useMutation<UnsetCitationFromPosition, UnsetCitationFromPositionVariables>(
    gql`
      mutation UnsetCitationFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        unsetCitationFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
  );
