import React, { MouseEventHandler, useCallback, useContext, useEffect, useState } from "react";
import getBlobDuration from "get-blob-duration";
import { Button, Switch, notification, Checkbox, Modal, Select, Slider } from "antd";
import {
  AudioOutlined,
  CloudUploadOutlined,
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { useMiscState, useMiscDispatch } from "../../Contexts/Misc";
import AudioAnalyzerContext from "../../Contexts/AudioAnalyzerContext";
import { isEmpty } from "../../Utils/common";
import * as style from "./style.less";
import RecorderButton from "../buttons/recorderButton/RecorderButton";
import { SendRecordedRheseToDatabaseButton } from "../buttons/sendRecordedRheseToDatabaseButton/SendDataToApiButton";
import { ClickableWrapper } from "../../../../components/clickableWrapper/ClickableWrapper";
import { Spinner } from "../../../editor/Project";
import { useMediaRecorder } from "./hooks";
import { useI18n } from "../../../../i18n";

const REGION_MIN_DURATION = 0.5;

type PageNavigation = {
  canNavigate: boolean;
  navigate: () => void;
};

export enum AudioRecorderState {
  idle = "idle",
  recording = "recording",
  playing = "playing",
  rhesesRecorder = "rhesesRecorder",
}

type AudioRecorderProps = {
  audioRecorderState: AudioRecorderState;
  setAudioRecorderState: (state: AudioRecorderState) => void;
  selectAllRheses: () => void;
  nextPage: PageNavigation;
  previousPage: PageNavigation;
};

const FILE_UPLOAD_MODE = "FILE_UPLOAD_MODE";

const modeStorage = {
  get: () => localStorage.getItem(FILE_UPLOAD_MODE) === "true",
  set: (fileUploadMode: boolean) =>
    localStorage.setItem(FILE_UPLOAD_MODE, fileUploadMode ? "true" : "false"),
};

const AudioRecorder = ({
  audioRecorderState,
  setAudioRecorderState,
  selectAllRheses,
  nextPage,
  previousPage,
}: AudioRecorderProps) => {
  const {
    errorMessage,
    setErrorMessage,
    selectedRheses,
    updateRecordedRheses,
    recordedRheses,
    clearRecordedRheses,
    uploadAudioFileToDatabase,
  } = useContext(AudioAnalyzerContext);
  const [t] = useI18n();
  const { settings } = useMiscState();
  const miscDispatch = useMiscDispatch();

  const { isRecording, stop, start, recorder, recordingDevices } = useMediaRecorder();

  const [uploadRef, setUploadRef] = useState<null | HTMLInputElement>(null);
  const [sendingToServer, setSendingToServer] = useState(false);
  const [recordPaths, setRecordPaths] = useState<string | undefined>(undefined);
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadMode, setFileUploadMode] = useState(modeStorage.get());

  useEffect(() => {
    if (!recorder) return;
    recorder.ondataavailable = async (e) => {
      const duration = await getBlobDuration(e.data);

      const trackMinDuration = selectedRheses.length * REGION_MIN_DURATION;

      if (duration < trackMinDuration) {
        setErrorMessage(
          `La piste enregistrée doit durer au minimum ${trackMinDuration} secondes, or votre enregistrement dure ${(
            duration - 0.01
          ).toFixed(2)} secondes.`,
        );
        return;
      }

      // Add the current record to the records' list.
      // globalThis is not defined
      // eslint-disable-next-line no-undef
      setRecordPaths(globalThis.URL.createObjectURL(e.data));
      // If there is any previous error message
      if (!isEmpty(errorMessage)) {
        // Let's delete it.
        setErrorMessage(undefined);
      }
      updateRecordedRheses();
    };
  });

  useEffect(() => {
    if (fileUploadMode) {
      selectAllRheses();
    }
  }, [fileUploadMode, selectAllRheses]);

  useEffect(() => modeStorage.set(fileUploadMode), [fileUploadMode]);

  const stopRecording = useCallback(() => {
    stop();
  }, [stop]);

  const onRecord = () => {
    if (isRecording) {
      return stopRecording();
    }
    if (isUploading) {
      return;
    }

    if (fileUploadMode.valueOf()) {
      return uploadRef?.click();
    }

    return start();
  };

  const playAudio: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!recordPaths) return;
    const audio = new Audio(recordPaths);
    audio.play();
  };

  const uploadFile = async (event: React.FormEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (!event.currentTarget.files?.[0]) {
      return;
    }
    var file = event.currentTarget.files[0];
    setIsUploading(true);
    try {
      await uploadAudioFileToDatabase(file, selectedRheses);
      notification.success({
        message: "le fichier audio a été traité avec succès.",
        placement: "topRight",
      });
    } catch (error: any) {
      notification.error({
        message: "Error while uploading audio file",
        description: error?.toString() || "",
        placement: "topRight",
      });
      console.error("Error while uploading audio file - " + error);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (isRecording) {
      setAudioRecorderState(AudioRecorderState.recording);
    } else if (recordedRheses.length > 0) {
      setAudioRecorderState(AudioRecorderState.rhesesRecorder);
    } else {
      setAudioRecorderState(AudioRecorderState.idle);
    }
    return () => {};
  }, [isRecording, recordedRheses, setAudioRecorderState]);

  const handleCancel = () => {
    clearRecordedRheses();
    setRecordPaths(undefined);
    setAudioRecorderState(AudioRecorderState.idle);
  };

  const onSettingsChange = useCallback(
    async (e) => {
      if (typeof e === "string") {
        miscDispatch({
          type: "settings",
          payload: { deviceId: e },
        });
        return;
      }

      switch (e?.target?.name) {
        case "open":
          miscDispatch({
            type: "settings",
            payload: { open: !settings?.open },
          });
          break;
        case "gainValue":
          miscDispatch({
            type: "settings",
            payload: { gainValue: parseFloat(e.target.value?.toString() || "1") / 10 },
          });
          break;
        case "echo":
          miscDispatch({
            type: "settings",
            payload: { echoCancellation: !settings?.echoCancellation },
          });
          break;
        case "noise":
          miscDispatch({
            type: "settings",
            payload: { noiseSuppression: !settings?.noiseSuppression },
          });
          break;
        case "gain":
          miscDispatch({
            type: "settings",
            payload: { autoGainControl: !settings?.autoGainControl },
          });
          break;
        default:
          console.log("Default: ", e);
          return;
      }
    },
    [miscDispatch, settings],
  );
  const handleSettingsModal = useCallback(() => {
    onSettingsChange({ target: { name: "open" } });
  }, [onSettingsChange]);
  const onGainControlChange = useCallback(
    (newValue: number) => {
      onSettingsChange({
        target: {
          value: newValue,
          name: "gainValue",
        },
      });
    },
    [onSettingsChange],
  );

  const isValidatingRecording =
    audioRecorderState === AudioRecorderState.rhesesRecorder && !sendingToServer;

  return (
    <>
      <Modal
        open={settings?.open}
        closable={false}
        title="Options audio"
        // onCancel={this.handleSettingsModal(false)}
        // cancelText="Annuler"
        footer={[
          <Button key="back" onClick={handleSettingsModal}>
            Fermer
          </Button>,
        ]}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: 200,
            justifyContent: "space-evenly",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}>
          <Checkbox name="echo" checked={settings?.echoCancellation} onClick={onSettingsChange}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Réduction de l'écho
          </Checkbox>
          <Checkbox
            name="noise"
            checked={settings?.noiseSuppression}
            onClick={onSettingsChange}
            style={{ marginLeft: 0 }}>
            Réduction du bruit
          </Checkbox>
          {/*<Checkbox*/}
          {/*  name="gain"*/}
          {/*  checked={autoGainControl}*/}
          {/*  onClick={onSettingsChange}*/}
          {/*  style={{ marginLeft: 0 }}*/}
          {/*>*/}
          {/*  Gain automatique*/}
          {/*</Checkbox>*/}
          <div style={{ fontStyle: "1.25rem" }}>
            <div style={{ marginBottom: "0.2rem", marginTop: "1rem" }}>Gain</div>
            <Slider
              min={1}
              max={10}
              value={(settings?.gainValue && settings?.gainValue * 10) || 10}
              onChange={onGainControlChange}
            />
          </div>
          <div style={{ fontStyle: "1.25rem" }}>
            <div style={{ marginBottom: "0.2rem", marginTop: "1rem" }}>Microphone</div>
            <Select
              defaultValue={settings?.deviceId}
              onChange={onSettingsChange}
              style={{ width: "100%" }}>
              {recordingDevices?.[0]?.label
                ? recordingDevices?.map((d: MediaDeviceInfo) => {
                    return <Select.Option key={d.label}>{d.label}</Select.Option>;
                  })
                : null}
            </Select>
          </div>
        </div>
      </Modal>

      <div className={style.wrapper}>
        <div className={style.recorderContainer}>
          <div className={style.recorderSwitchContainer}>
            <AudioOutlined className={style.recorderSwitchContainerIcon} />
            <Switch
              checked={fileUploadMode}
              onChange={(args) => setFileUploadMode(args.valueOf())}
              size="small" // TODO antd: only "small" allow the switch to display
            />
            <CloudUploadOutlined className={style.recorderSwitchContainerIcon} />
            <Button
              className={style.settingsButton}
              onClick={handleSettingsModal}
              icon={<SettingOutlined />}
            />
          </div>
          {!isValidatingRecording && (
            <Button
              disabled={!previousPage.canNavigate}
              onClick={previousPage.navigate}
              ghost
              type="primary"
              shape="circle"
              icon={<LeftOutlined />}
              className={style.rowOne}
            />
          )}
          <div className={style.recordingArea.concat(" ", style.rowOne)}>
            {sendingToServer && <Spinner />}
            {isValidatingRecording && (
              <div className={style.validationContainer}>
                <div className={style.buttons}>
                  <ClickableWrapper>
                    <div className={style.cancelButton} onClick={handleCancel}>
                      {t("cancel")}
                    </div>
                  </ClickableWrapper>
                  {recordPaths && (
                    <SendRecordedRheseToDatabaseButton
                      recordPaths={recordPaths}
                      onStart={() => setSendingToServer(true)}
                      onEnd={() => setSendingToServer(false)}
                    />
                  )}
                </div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="" onClick={playAudio}>
                  {t("listenToTheRecording")}
                </a>
              </div>
            )}

            {audioRecorderState !== AudioRecorderState.rhesesRecorder && (
              <RecorderButton
                disabled={isUploading || selectedRheses.length === 0}
                audioRecorderState={audioRecorderState}
                fileUploadMode={fileUploadMode}
                onClick={onRecord}
              />
            )}
          </div>
          {!isValidatingRecording && (
            <Button
              disabled={!nextPage.canNavigate}
              onClick={nextPage.navigate}
              ghost
              type="primary"
              shape="circle"
              icon={<RightOutlined />}
              className={style.rowOne}
            />
          )}
        </div>
        <input
          type="file"
          id="audioFile"
          ref={(ref) => setUploadRef(ref)}
          onChange={uploadFile}
          style={{ display: "none" }}
          accept="audio/*"
        />
      </div>
    </>
  );
};

export default AudioRecorder;
