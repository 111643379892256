import React from "react";
import { Typography, Button } from "antd";
import classNames from "classnames";
import { CheckCircleOutlined } from "@ant-design/icons";
import * as style from "../TabProgress.style.less";
import { useI18n } from "../../../../../../../../i18n";
import { PreparationProgressStatus } from "../../../../../../../../../__generated__/globalTypes";

const { Text } = Typography;

type Props = {
  preparationProgress: PreparationProgressStatus;
  markAsReadyForNarration?: () => void;
  completePreparation?: () => void;
};

export default function PreparingState({
  preparationProgress,
  markAsReadyForNarration,
  completePreparation,
}: Props) {
  const [t] = useI18n();

  return (
    <div>
      <div className={style.legendAndButton}>
        <Text className={style.legend} type="secondary">
          {preparationProgress === PreparationProgressStatus.READY_FOR_NARRATION ||
          preparationProgress === PreparationProgressStatus.COMPLETE
            ? t("project.actionBar.progress.prepared.legendTasksForNarrationComplete")
            : t("project.actionBar.progress.preparing.legendReadyToNarrate")}
        </Text>
        {preparationProgress === PreparationProgressStatus.NOT_READY ? (
          <Button className={style.button} onClick={markAsReadyForNarration} type="primary">
            {t("project.actionBar.progress.preparing.buttonReadyToNarrate")}
          </Button>
        ) : (
          <Button
            className={classNames(style.button, style.complete)}
            type="primary"
            icon={<CheckCircleOutlined />}>
            {t("project.actionBar.progress.prepared.buttonReadyToNarrate")}
          </Button>
        )}
      </div>
      <div className={style.legendAndButton}>
        <Text className={style.legend} type="secondary">
          {preparationProgress === PreparationProgressStatus.COMPLETE
            ? t("project.actionBar.progress.prepared.legendPreparationComplete")
            : t("project.actionBar.progress.preparing.legendPreparationComplete")}
        </Text>
        {preparationProgress === PreparationProgressStatus.COMPLETE ? (
          <Button
            type="primary"
            className={classNames(style.button, style.complete)}
            onClick={undefined}
            icon={<CheckCircleOutlined />}>
            {t("project.actionBar.progress.prepared.buttonPreparationComplete")}
          </Button>
        ) : (
          <Button
            type="primary"
            className={style.button}
            onClick={completePreparation}
            disabled={preparationProgress === PreparationProgressStatus.NOT_READY}>
            {t("project.actionBar.progress.preparing.buttonPreparationComplete")}
          </Button>
        )}
      </div>
    </div>
  );
}
