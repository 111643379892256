{
  "settings": {
    "open": false,
    "echoCancellation": true,
    "noiseSuppression": true,
    "autoGainControl": true,
    "gainValue": 1,
    "deviceId": "",
    "recordingDevices": []
  }
}
