import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, Form } from "antd";
import {
  ExtendedParagraphKind,
  ImagePosition,
  UpdateTextContent,
} from "../../../../../../../../../__generated__/globalTypes";
import { useI18n } from "../../../../../../../../i18n";
import {
  useCreatePageFromPosition,
  useCreateParagraphFromPosition,
  useRemoveImage,
  useUpdatePage,
  useUpdateTextContent,
} from "../../../../../api/api";
import { SubToPages_pages } from "../../../../../api/SubToPages";
import BookRenderer from "../../../../BookRenderer";
import { ImageType } from "./components/Image";
import ParagraphWrapper, { ParagraphWrapperProps } from "./components/ParagraphWrapper";
import * as style from "../../style.less";
import DialoguePicture from "./components/DialoguePicture";
import { SelectedDialogueSpeaker } from "../..";
import ModalEditImage from "./components/ModalEditImage";
import ActionMenu from "./components/ActionMenu";
import { SubToProject_project_speakers } from "../../../../../api/__generated__/SubToProject";
import { usePageData } from "../../../../../hooks/usePageData";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_paragraph_data_tag,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../../../api/__generated__/SubscribeToExtendedPage";
import { useUpdateDisplayedPageNumber } from "../../../../Page";

export type SelectedRheses = Array<SelectedRheseContent>;
export type SelectedRheseContent = {
  rheseId: string;
  lettersId: string[];
};

export type SelectedTextPosition = {
  start: number;
  size: number;
};

type OnTextSelectionChange = (
  selectedTextPosition: SelectedTextPosition,
  pageMetadata: SubscribeToExtendedPage_extendedPage,
) => void;

type PropTypes = {
  isDraggingImage: boolean;
  setLineBreakAfterRhese: (
    arg: SubscribeToExtendedPage_extendedPage_rhese,
    lineBreakAfterRhese: boolean,
  ) => void;
  assignRhesesToParagraph: (
    rheseIds: string[],
    paragraphKind: ExtendedParagraphKind,
    paragraphTagId?: string,
  ) => void;
  setTargetParagraph: (rheseId: string | null) => void;
  setPosition: (position: ImagePosition) => void;
  onTextSelectionChange: OnTextSelectionChange;
  projectId: string;
  lockPageStructure: boolean;
  speakers: SubToProject_project_speakers[];
  allPages: SubToPages_pages[];
};

export const FormattingComp = ({
  isDraggingImage,
  setLineBreakAfterRhese,
  setTargetParagraph,
  setPosition,
  onTextSelectionChange,
  projectId,
  speakers,
  allPages,
}: PropTypes) => {
  const [applyCreatePage] = useCreatePageFromPosition();
  const [applyUpdatePage] = useUpdatePage();
  const [applyCreateParagraphFromPosition] = useCreateParagraphFromPosition();
  const [applyUpdateTextContent] = useUpdateTextContent();
  const updateDisplayedPageNumber = useUpdateDisplayedPageNumber();

  const {
    page,
    pageMetadata,
  }: { page: SubToPages_pages; pageMetadata: SubscribeToExtendedPage_extendedPage } =
    usePageData(allPages);
  const rheses = pageMetadata?.rhese;
  const paragraphs = pageMetadata?.paragraph;

  const createPage = useCallback(
    async (lastRheseId: string) => {
      const rhese = pageMetadata.rhese.find(
        (r: SubscribeToExtendedPage_extendedPage_rhese) => r.data.id === lastRheseId,
      );
      if (!rhese) return;
      const currentPage = allPages.find((page) => page.id === pageMetadata.page.data.id);
      if (!currentPage) {
        throw new Error("Could not retrieve current page in pages list");
      }

      const start = rhese.anchors[0].utf16Start + rhese.anchors[0].utf16Size;
      const end = pageMetadata.page.anchors[0].utf16Start + pageMetadata.page.anchors[0].utf16Size;
      const size = end - start;

      const stringVersion = pageMetadata.page.anchors[0].stringVersion;
      // Creating a new page after the current one
      await applyCreatePage({
        variables: {
          projectId,
          stringVersion,
          start,
          size,
          partname: "",
          mutePagesAfter: currentPage.mutePagesAfter,
        },
      });
      // Update the current page to remove the mutePagesAfter (it has been moved to the new page)
      await applyUpdatePage({
        variables: {
          projectId,
          pageId: pageMetadata.page.data.id,
          mutePagesAfter: 0,
          partname: currentPage.partName || "",
        },
      });
    },
    [
      allPages,
      applyCreatePage,
      applyUpdatePage,
      pageMetadata?.page.anchors,
      pageMetadata?.page.data.id,
      pageMetadata?.rhese,
      projectId,
    ],
  );

  const [applyDeleteImage] = useRemoveImage();
  const [form] = Form.useForm();
  const [t] = useI18n();
  const ref = useRef<HTMLDivElement>(null);
  const [isEditingDialogueSpeaker, setIsEditingDialogueSpeaker] = useState<
    false | Partial<SelectedDialogueSpeaker>
  >(false);
  const [isEditingImage, setIsEditingImage] = useState<ImageType & { open: boolean }>({
    open: false,
  });
  const deleteImage = useCallback(
    (imageId: string) => applyDeleteImage({ variables: { imageId, projectId } }),
    [applyDeleteImage, projectId],
  );

  const contentEditableRef = useRef<HTMLDivElement>(null);
  const RenderBook = useMemo(() => BookRenderer<ParagraphWrapperProps>(), []);

  const [selectedRhesesContent, setSelectedRhesesContent] = useState<SelectedRheses>([]);
  const [selectedTextPosition, setSelectedTextPosition] = useState<{
    start: number;
    size: number;
  }>({ start: 0, size: 0 });

  const [isSegmentationHovered, setIsSegmentationHovered] = useState(false);

  const selectedRhesesIds = useMemo(
    () => selectedRhesesContent.map(({ rheseId }) => rheseId),
    [selectedRhesesContent],
  );

  useEffect(() => {
    onTextSelectionChange(selectedTextPosition, pageMetadata);
  }, [selectedTextPosition, onTextSelectionChange, pageMetadata]);

  useEffect(() => {
    const noop = (e: Event) => {
      e.preventDefault();
      return false;
    };

    const ref = contentEditableRef.current;

    ref?.addEventListener("cut", noop, false);
    ref?.addEventListener("paste", noop, false);
    ref?.addEventListener("keydown", noop, false);
    ref?.addEventListener("dragenter", noop, false);
    ref?.addEventListener("dragleave", noop, false);
    ref?.addEventListener("dragover", noop, false);
    ref?.addEventListener("drop", noop, false);
    return () => {
      ref?.removeEventListener("cut", noop);
      ref?.removeEventListener("paste", noop);
      ref?.removeEventListener("keydown", noop);
      ref?.removeEventListener("dragenter", noop);
      ref?.removeEventListener("dragleave", noop);
      ref?.removeEventListener("dragover", noop);
      ref?.removeEventListener("drop", noop);
    };
  }, [contentEditableRef]);

  const resetWindowSelection = useCallback(() => {
    window.getSelection()?.removeAllRanges();
    setSelectedTextPosition({ start: 0, size: 0 });
  }, [setSelectedTextPosition]);

  useEffect(() => {
    if (selectedRhesesIds.length > 1) {
      resetWindowSelection();
    }
  }, [resetWindowSelection, selectedRhesesIds]);

  const saveSelection = useCallback(
    (ev: React.SyntheticEvent<HTMLDivElement, Event>) => {
      // Replace url pageNumber to the one of the selection
      if (
        pageMetadata.page.data.pageNumber.pageNumber !==
        parseInt(window.location.search.split("=")[1])
      ) {
        updateDisplayedPageNumber(pageMetadata.page.data.pageNumber.pageNumber.toString());
      }

      if (ev.target instanceof HTMLElement && !ref.current?.contains(ev.target)) {
        return;
      }
      const isRightClick = ev.nativeEvent instanceof MouseEvent && ev.nativeEvent.button;
      if (isRightClick === 2) return;
      const selection = window.getSelection();
      if (!selection) return resetWindowSelection();

      let { focusNode } = selection;

      const endingRhese = focusNode?.parentElement?.closest(".rhese");

      // If the selection is made by a click on a rhese, select the rhese
      if (
        selection.getRangeAt(0).toString() === "" &&
        selection?.toString() !== "\xa0" &&
        endingRhese instanceof HTMLElement &&
        endingRhese.dataset.id
      ) {
        const rheseData = rheses.find(
          ({ data: { id } }: SubscribeToExtendedPage_extendedPage_rhese) =>
            id === endingRhese?.getAttribute("data-id"),
        );
        if (!rheseData) return;

        setSelectedTextPosition({
          start: rheseData?.anchors[0].utf16Start,
          size: rheseData?.anchors[0].utf16Size,
        });

        return setSelectedRhesesContent([{ rheseId: endingRhese.dataset.id, lettersId: [] }]);
      }

      if (selection.toString().length === 0) {
        setSelectedTextPosition({
          start: parseInt(focusNode?.parentElement?.getAttribute("data-absolute-position") || "0"),
          size: 0,
        });
        if (endingRhese instanceof HTMLElement && endingRhese.dataset.id) {
          setSelectedRhesesContent([{ rheseId: endingRhese.dataset.id, lettersId: [] }]);
        }
        return;
      }

      const pageLetters = focusNode?.parentElement
        // eslint-disable-next-line
        ?.closest('[id^="page-"]')
        ?.querySelectorAll(".letter,.ponctuation");

      if (!pageLetters) return resetWindowSelection();
      const selectedLetters = Array.from(pageLetters)
        .filter((letter) => {
          return selection?.containsNode(letter?.childNodes?.[0] || letter, false);
        })
        .map((letter) => {
          if (!(letter instanceof HTMLElement) || !letter.dataset.absolutePosition) {
            throw new Error("letter.dataset.absoluteTextPosition is undefined");
          }
          return parseInt(letter.dataset.absolutePosition);
        })
        .sort((a, b) => a - b);

      if (selectedLetters.length === 0) {
        setSelectedTextPosition({
          start: parseInt(focusNode?.parentElement?.getAttribute("data-absolute-position") || "0"),
          size: 0,
        });
        return;
      }

      const selectedPosition = {
        start: selectedLetters[0],
        size: selectedLetters[selectedLetters.length - 1] - selectedLetters[0] + 1,
      };

      setSelectedTextPosition(selectedPosition);
      setSelectedRhesesContent([]);
    },
    [
      pageMetadata?.page.data.pageNumber.pageNumber,
      resetWindowSelection,
      rheses,
      updateDisplayedPageNumber,
    ],
  );

  useEffect(() => form.resetFields(), [form, isEditingImage]);

  const [dropdownVisible, setDropdownVisibleChange] = useState(false);

  const createParagraph = useCallback(
    async (
      kind: ExtendedParagraphKind,
      tag?: SubscribeToExtendedPage_extendedPage_paragraph_data_tag,
    ) => {
      // Get the start of the rheses that include the selection start
      const startingRhese = rheses.find(
        ({ anchors }: SubscribeToExtendedPage_extendedPage_rhese) =>
          anchors[0].utf16Start <= selectedTextPosition.start &&
          anchors[0].utf16Start + anchors[0].utf16Size > selectedTextPosition.start,
      );
      if (!startingRhese) return;
      // Get the end of the rheses that include the selection end
      const endingRhese = rheses.find(
        ({ anchors }: SubscribeToExtendedPage_extendedPage_rhese) =>
          anchors[0].utf16Start < selectedTextPosition.start + selectedTextPosition.size &&
          anchors[0].utf16Start + anchors[0].utf16Size >=
            selectedTextPosition.start + selectedTextPosition.size,
      );
      if (!endingRhese) return;
      const fullSize =
        endingRhese.anchors[0].utf16Start +
        endingRhese.anchors[0].utf16Size -
        startingRhese.anchors[0].utf16Start;

      await applyCreateParagraphFromPosition({
        variables: {
          projectId,
          stringVersion: pageMetadata.page.anchors[0].stringVersion,
          start: startingRhese.anchors[0].utf16Start,
          size: fullSize,
          kind,
          ...(tag?.id ? { paragraphTagId: tag?.id } : {}),
        },
      });
    },
    [
      applyCreateParagraphFromPosition,
      pageMetadata?.page.anchors,
      projectId,
      rheses,
      selectedTextPosition.size,
      selectedTextPosition.start,
    ],
  );
  const handleNonBreakingSpace = useCallback(
    async (isNonBreakingSpace: boolean) => {
      const operations: UpdateTextContent[] = [
        {
          delete: {
            utf16Start: selectedTextPosition.start,
            utf16Size: 1,
          },
        },
        {
          insert: {
            utf16Start: selectedTextPosition.start,
            text: isNonBreakingSpace ? "\xa0" : " ",
          },
        },
      ];

      await applyUpdateTextContent({
        variables: {
          projectId,
          stringVersion: pageMetadata.page.anchors[0].stringVersion,
          operations,
        },
      });
    },
    [applyUpdateTextContent, pageMetadata?.page.anchors, projectId, selectedTextPosition.start],
  );

  const renderDropdownContent = useCallback(
    () => (
      <ActionMenu
        textContent={pageMetadata.textContent}
        projectId={projectId}
        page={page!}
        pageMetadata={pageMetadata}
        applySetNonbreakingSpace={handleNonBreakingSpace}
        selectedRhesesIds={selectedRhesesIds}
        selectedTextPosition={selectedTextPosition}
        createParagraph={createParagraph}
        addSeparator={createPage}
        setLineBreakAfterRhese={setLineBreakAfterRhese}
        setSelectedRhesesContent={setSelectedRhesesContent}
        setIsSegmentationHovered={setIsSegmentationHovered}
        setDropdownVisibleChange={setDropdownVisibleChange}
      />
    ),
    [
      pageMetadata,
      projectId,
      page,
      handleNonBreakingSpace,
      selectedRhesesIds,
      selectedTextPosition,
      createParagraph,
      createPage,
      setLineBreakAfterRhese,
    ],
  );

  if (!pageMetadata) return null;

  return (
    <Fragment>
      <div
        ref={ref}
        onSelect={saveSelection}
        className={style.root.concat(" ", isSegmentationHovered ? style.segmentationInfo : "")}>
        <ModalEditImage
          form={form}
          isEditingImage={isEditingImage}
          setIsEditingImage={setIsEditingImage}
          projectId={projectId}
        />
        <DialoguePicture
          projectId={projectId}
          isVisible={!!isEditingDialogueSpeaker}
          paragraphId={(isEditingDialogueSpeaker as SelectedDialogueSpeaker).paragraphId}
          speakerId={(isEditingDialogueSpeaker as SelectedDialogueSpeaker).speakerId}
          onExit={() => setIsEditingDialogueSpeaker(false)}
          speakers={speakers}
        />
        <div className={style.lettersCount}>
          {t("project.existing.editingMode.formatting.lettersCount.result", {
            lettersCount: selectedTextPosition.size,
          })}
        </div>
        <Dropdown
          trigger={["contextMenu"]}
          onOpenChange={setDropdownVisibleChange}
          open={dropdownVisible}
          dropdownRender={renderDropdownContent}>
          <div
            ref={contentEditableRef}
            contentEditable="true"
            suppressContentEditableWarning
            className="formating-container">
            <RenderBook
              paragraphWrapper={{
                component: ParagraphWrapper,
                props: {
                  isDraggingImage,
                  setTargetParagraph,
                  setPosition,
                  deleteImage,
                  editImage: (img: ImageType) => setIsEditingImage({ ...img, open: true }),
                  openDialogueSpeakerModal: setIsEditingDialogueSpeaker,
                  paragraphs,
                },
              }}
              enableParagraphTooltip={true}
              selectedRhesesIds={selectedRhesesIds}
              projectId={projectId}>
              {pageMetadata}
            </RenderBook>
          </div>
        </Dropdown>
      </div>
    </Fragment>
  );
};

export default FormattingComp;
