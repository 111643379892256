import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getMetadataContent } from "../../../../../utils/common";
import { Button, Dropdown, Input, Modal } from "antd";
import * as style from "../style.less";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../../api/__generated__/SubscribeToExtendedPage";
import { UpdateTextContent } from "../../../../../../../../__generated__/globalTypes";
import { diffStrings } from "../tools";
import {
  useCreateSyllableFromPosition,
  useDeleteSyllableFromPosition,
  useSetPhonemeFromPosition,
  useUnsetMuteFromPosition,
  useUnsetPhonemeFromPosition,
} from "../../../../api/api";
import { useSetMuteFromPosition } from "../../../../api/metadata/static/mute";
import { RenderRhese } from "../../../BookRenderer";
import {
  buildPhonemeMenu,
  BuildPhonemeMenuTranslationProps,
  buildSyllableMenu,
  BuildSyllableMenuTranslationProps,
} from "../../Syllable";
import { useTextPosition } from "../../../../hooks/useTextPosition";
import { useI18nObjectHook } from "../../../../../../../i18n";
import { convertPageContent } from "../../../../api/formatters";

type EditRheseProps = {
  open: boolean;
  rhese: SubscribeToExtendedPage_extendedPage_rhese;
  pageMetadata: SubscribeToExtendedPage_extendedPage;
  onCancel: () => void;
  editRhese: (to: UpdateTextContent[], newRheseSize: number) => void;
  onOk: () => void;
  projectId: string;
};

const EditRhese = ({
  rhese,
  onCancel,
  editRhese,
  onOk,
  pageMetadata,
  open,
  projectId,
}: EditRheseProps) => {
  const [step, setStep] = useState(1);
  const [applyDeleteSyllableFromPosition] = useDeleteSyllableFromPosition();
  const [applyCreateSyllableFromPosition] = useCreateSyllableFromPosition();
  const [applySetMuteFromPosition] = useSetMuteFromPosition();
  const [applyUnsetMuteFromPosition] = useUnsetMuteFromPosition();
  const [applySetPhonemeFromPosition] = useSetPhonemeFromPosition();
  const [applyUnsetPhonemeFromPosition] = useUnsetPhonemeFromPosition();

  const contentEditableRef = useRef<HTMLDivElement>(null);

  const convertedRhese = useMemo(() => {
    const convertedPageContent = convertPageContent("123", pageMetadata);
    return convertedPageContent.rheses.find((r) => r.id === rhese.data.id);
  }, [pageMetadata, rhese]);

  const title = useMemo(() => {
    if (step === 2) {
      return "Corriger les syllabes";
    }
    if (step === 3) {
      return "Corriger les phonèmes";
    }
    if (step === 4) {
      return "Attention !";
    }
    return "Éditer la rhese";
  }, [step]);

  const { selectedTextPosition, saveSelection } = useTextPosition();
  useEffect(() => {
    const noop = (e: Event) => {
      e.preventDefault();
      return false;
    };
    const contentEditableRefCurrent = contentEditableRef.current;
    contentEditableRefCurrent?.addEventListener("cut", noop, false);
    contentEditableRefCurrent?.addEventListener("paste", noop, false);
    contentEditableRefCurrent?.addEventListener("keydown", noop, false);
    contentEditableRefCurrent?.addEventListener("dragenter", noop, false);
    contentEditableRefCurrent?.addEventListener("dragleave", noop, false);
    contentEditableRefCurrent?.addEventListener("dragover", noop, false);
    contentEditableRefCurrent?.addEventListener("drop", noop, false);
    return () => {
      contentEditableRefCurrent?.removeEventListener("cut", noop);
      contentEditableRefCurrent?.removeEventListener("paste", noop);
      contentEditableRefCurrent?.removeEventListener("keydown", noop);
      contentEditableRefCurrent?.removeEventListener("dragenter", noop);
      contentEditableRefCurrent?.removeEventListener("dragleave", noop);
      contentEditableRefCurrent?.removeEventListener("dragover", noop);
      contentEditableRefCurrent?.removeEventListener("drop", noop);
    };
  }, [contentEditableRef]);

  /* STEP 1: EDIT RHESE */
  const [newRheseContent, setNewRheseContent] = useState<string>(
    getMetadataContent(rhese, pageMetadata) || "",
  );
  const handleChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    const newString = event.target instanceof HTMLInputElement && event.target.value;
    if (newString === false) return;
    setNewRheseContent(newString);
    return;
  }, []);
  const submit = useCallback(async () => {
    const prevText = getMetadataContent(rhese, pageMetadata);
    if (prevText === newRheseContent || !prevText) {
      onCancel();
      return;
    }

    await editRhese(
      diffStrings(prevText, newRheseContent, rhese.anchors[0].utf16Start),
      newRheseContent.length,
    );
    setStep(step + 1);
  }, [editRhese, newRheseContent, onCancel, pageMetadata, rhese, step]);

  /* Mute functions */
  const mute = useCallback(
    async (selectedTextPosition) => {
      applySetMuteFromPosition({
        variables: {
          projectId,
          stringVersion: pageMetadata.page.anchors[0].stringVersion,
          start: selectedTextPosition.start,
          size: selectedTextPosition.size,
        },
      });
    },
    [applySetMuteFromPosition, pageMetadata?.page.anchors, projectId],
  );
  const unmute = useCallback(
    async (selectedTextPosition) => {
      applyUnsetMuteFromPosition({
        variables: {
          projectId,
          stringVersion: pageMetadata.page.anchors[0].stringVersion,
          start: selectedTextPosition.start,
          size: selectedTextPosition.size,
        },
      });
    },
    [applyUnsetMuteFromPosition, pageMetadata?.page.anchors, projectId],
  );

  /* STEP 2: EDIT SYLLABLE */
  const buildSyllableMenuTranslation: BuildSyllableMenuTranslationProps = useI18nObjectHook(
    "project.existing.editingMode.syllable.buildSyllableMenu",
  );
  const renderDropdownContentSyllable = useCallback(
    () =>
      buildSyllableMenu(
        selectedTextPosition,
        (selectedTextPosition) => {
          applyDeleteSyllableFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata.page.anchors[0].stringVersion,
              start: selectedTextPosition.start,
              size: selectedTextPosition.size,
            },
          });
        },
        (selectedTextPosition) => {
          applyCreateSyllableFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata.page.anchors[0].stringVersion,
              start: selectedTextPosition.start,
              size: selectedTextPosition.size,
            },
          });
        },
        mute,
        unmute,
        buildSyllableMenuTranslation,
      ),
    [
      applyCreateSyllableFromPosition,
      applyDeleteSyllableFromPosition,
      buildSyllableMenuTranslation,
      mute,
      pageMetadata.page.anchors,
      projectId,
      selectedTextPosition,
      unmute,
    ],
  );

  /* STEP 3: EDIT PHONEME */
  const buildPhonemeMenuMenuTranslation: BuildPhonemeMenuTranslationProps = useI18nObjectHook(
    "project.existing.editingMode.syllable.buildPhonemeMenu",
  );
  const renderDropdownContentPhoneme = useCallback(
    () =>
      buildPhonemeMenu(
        selectedTextPosition,
        (selectedTextPosition) => {
          applyUnsetPhonemeFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata.page.anchors[0].stringVersion,
              start: selectedTextPosition.start,
              size: selectedTextPosition.size,
            },
          });
        },
        (selectedTextPosition, phoneme) => {
          applySetPhonemeFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata.page.anchors[0].stringVersion,
              start: selectedTextPosition.start,
              size: selectedTextPosition.size,
              kind: phoneme,
            },
          });
        },
        mute,
        unmute,
        buildPhonemeMenuMenuTranslation,
      ),
    [
      applySetPhonemeFromPosition,
      applyUnsetPhonemeFromPosition,
      buildPhonemeMenuMenuTranslation,
      mute,
      pageMetadata.page.anchors,
      projectId,
      selectedTextPosition,
      unmute,
    ],
  );

  return (
    <Modal className={style.editRhese} title={title} open={open} footer={null} onCancel={onCancel}>
      {step === 1 && (
        <>
          <Input onChange={handleChange} type="text" value={newRheseContent} />
          <div className={style.btnBar}>
            <Button type="ghost" shape="round" onClick={submit}>
              Continuer
            </Button>
          </div>
        </>
      )}
      {step === 2 && !!convertedRhese && (
        <>
          <Dropdown trigger={["contextMenu"]} dropdownRender={renderDropdownContentSyllable}>
            <div
              className={style.clonedRheseSyllable}
              onMouseUp={saveSelection}
              ref={contentEditableRef}>
              <div id={`page-${pageMetadata.page.data.pageNumber.pageNumber}`}>
                <RenderRhese
                  isSearchResult={false}
                  definitions={[]}
                  index={0}
                  activeStart={false}
                  activeEnd={false}
                  active={false}
                  spaceIfNeeded={null}
                  enableCustomCssTooltip={false}
                  highlightSpaces={false}>
                  {convertedRhese}
                </RenderRhese>
              </div>
            </div>
          </Dropdown>
          <div className={style.btnBar}>
            <Button shape="round" onClick={() => setStep(step + 1)}>
              Continuer
            </Button>
          </div>
        </>
      )}
      {step === 3 && !!convertedRhese && (
        <>
          <Dropdown trigger={["contextMenu"]} dropdownRender={renderDropdownContentPhoneme}>
            <div className={style.clonedRhesePhoneme} onMouseUp={saveSelection}>
              <div id={`page-${pageMetadata.page.data.pageNumber.pageNumber}`}>
                <RenderRhese
                  isSearchResult={false}
                  definitions={[]}
                  index={0}
                  activeStart={false}
                  activeEnd={false}
                  active={false}
                  spaceIfNeeded={null}
                  enableCustomCssTooltip={false}
                  highlightSpaces={false}>
                  {convertedRhese}
                </RenderRhese>
              </div>
            </div>
          </Dropdown>
          <div className={style.btnBar}>
            <Button type="ghost" htmlType="submit" shape="round" onClick={onOk}>
              Valider
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default EditRhese;
