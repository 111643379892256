@import "../../../../styles/colors.less";

.root {
  :global .paragraph:hover {
    cursor: pointer;
  }

  :global .paragraph:hover .rhese {
    background: fade(@blue, 30%);
  }

  :global .paragraph {
    .rhese.active,
    .rhese.activeStart,
    .rhese.activeEnd {
      background: @blue;
    }
  }

  :global *::selection {
    background: @yellow;
    color: inherit;
  }

  :global .separator {
    caret-color: transparent;
    font-size: 1.5em;
    line-height: 1em;
    color: @orange;
    padding: 0 0.2em;
    border: 1px solid transparent;

    &.selected {
      border: 1px solid @orange;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .lettersCount {
    display: none;
    background-color: @grey;
    color: @white;
    border-radius: 2px;
    text-align: center;
    position: absolute;
    padding: 0.5em 1em;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &.segmentationInfo .lettersCount {
    display: block;
  }

  :global {
    .rhese {
      .GRAS {
        font-weight: bold;
      }
      .ITALIQUE {
        font-style: italic;
      }
      .MAJUSCULE {
        text-transform: uppercase;
      }
      .MINUSCULE {
        text-transform: lowercase;
      }
      .EXPOSANT {
        font-variant-position: super;
      }
      .INDICE {
        font-variant-position: sub;
      }
      .CITATION {
        border-bottom: 1px solid @grey;
        border-top: 1px solid @grey;
      }
    }
  }
}

.side {
  --width: 58.3em;
  --margins: calc(100vw - var(--width));
  --margin: calc(var(--margins) / 2);

  position: absolute;
  transform: translateX(-50%);
  top: 2em;
  left: 0;
  font-size: 1em;
  pointer-events: none;
  row-gap: 1em;
  display: flex;
  flex-direction: column;
  left: calc(var(--margin) - 5em);
  .sideMenu {
    font-size: 1.2em;
    position: relative;
    display: flex;
    justify-content: end;

    menu {
      background-color: @white;
      width: 2.5em;
      pointer-events: auto;
      padding: 0;
      margin: 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      box-shadow: 5px 5px 15px #00000014;

      & > div {
        padding: 0.2em 0.3em;
        border-bottom: 1px solid @light-grey;
      }

      & > div:hover {
        cursor: pointer;
        background-color: @light-grey;
      }
    }
  }
}

.dropZone {
  padding: 0.5em;
  border: 2px dashed @blue;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 1em;
  z-index: 9999;
  position: relative;
}

.image {
  border: solid 2px @light-grey;
  background-color: @lightest-grey;
  border-radius: 3px;
  padding: 1em 2em;
  display: grid;
  column-gap: 0.5em;
  grid-template-columns: 1fr 2fr 1fr;

  &:hover {
    cursor: pointer;
  }

  .left {
    grid-column: 1;

    display: flex;
    text-transform: uppercase;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.5em;
  }

  .center {
    grid-column: 2;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .right {
    grid-column: 3;
    align-self: center;
    justify-self: end;
  }
}

.delete {
  transition: all 0.3s;
  &:hover {
    font-size: 1.1em;
    color: @red;
  }
}

.dialogueWrapper {
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.dialogue {
  z-index: 10;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 0 1em;
  opacity: 1;
  height: 100%;
  display: flex;
  align-items: center;
  transform: translateX(-90%);
}

.dialogueButton {
  justify-content: center;
  align-items: center;
  display: flex;
}
