import { gql, useMutation } from "@apollo/client";
import { SetMuteFromPosition, SetMuteFromPositionVariables } from "./__generated__/SetMuteFromPosition";
import { UnsetMuteFromPosition, UnsetMuteFromPositionVariables } from "./__generated__/UnsetMuteFromPosition";

export const useSetMuteFromPosition = () =>
  useMutation<SetMuteFromPosition, SetMuteFromPositionVariables>(
    gql`
      mutation SetMuteFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        setMuteFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
  );

export const useUnsetMuteFromPosition = () =>
  useMutation<UnsetMuteFromPosition, UnsetMuteFromPositionVariables>(
    gql`
      mutation UnsetMuteFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        unsetMuteFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
  );
