import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { getRecordingPresignedUrls } from "../../../../../Queries/queries";
import { SubscribeToExtendedPage_extendedPage_rhese_data_audioRecording } from "../../../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

export default function useAudioBuffer(
  audioContext: AudioContext,
  recording: SubscribeToExtendedPage_extendedPage_rhese_data_audioRecording | null,
) {
  const [buffer, setBuffer] = useState<null | AudioBuffer>(null);

  const { data } = useQuery(getRecordingPresignedUrls, {
    variables: { filename: recording?.fileUuid },
    fetchPolicy: "network-only",
    skip: !recording,
  });

  useEffect(() => {
    if (data && data.audioRecording.presignedUrl.getUrl) {
      fetch(data.audioRecording.presignedUrl.getUrl, { mode: "cors" })
        .then((res) => res.arrayBuffer())
        .then((audioData) => audioContext.decodeAudioData(audioData))
        .then((buffer) => {
          setBuffer(buffer);
        });
    }
  }, [audioContext, data]);

  return buffer;
}

export const getAudioSource = (audioContext: AudioContext, buffer: AudioBuffer | null) => {
  const audioSource = audioContext.createBufferSource();
  audioSource.buffer = buffer;
  audioSource.connect(audioContext.destination);
  return audioSource;
};
