import { useOktaAuth } from "@okta/okta-react";
import {
  BaseSubscriptionOptions,
  gql,
  SubscriptionHookOptions,
  SubscriptionResult,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { EditProject, EditProjectVariables } from "./__generated__/EditProject";

import {
  AssignToNewParagraph,
  AssignToNewParagraphVariables,
} from "./__generated__/AssignToNewParagraph";
import { DeleteProject, DeleteProjectVariables } from "./__generated__/DeleteProject";
import { SearchForRheses, SearchForRhesesVariables } from "./__generated__/SearchForRheses";
import {
  GiveAccesToProject,
  GiveAccesToProjectVariables,
} from "./__generated__/GiveAccesToProject";
import { AddImage, AddImageVariables } from "./__generated__/AddImage";
import { RemoveImage, RemoveImageVariables } from "./__generated__/RemoveImage";
import { UpdateImage, UpdateImageVariables } from "./__generated__/UpdateImage";
import {
  UpdateCustomColorationConfig,
  UpdateCustomColorationConfigVariables,
} from "./__generated__/UpdateCustomColorationConfig";
import {
  LinkDialogueToSpeaker,
  LinkDialogueToSpeakerVariables,
} from "./__generated__/LinkDialogueToSpeaker";
import {
  UnlinkDialogueAndSpeaker,
  UnlinkDialogueAndSpeakerVariables,
} from "./__generated__/UnlinkDialogueAndSpeaker";
import {
  UpsertParagraphTag,
  UpsertParagraphTagVariables,
} from "./__generated__/UpsertParagraphTag";
import {
  DeleteParagraphTag,
  DeleteParagraphTagVariables,
} from "./__generated__/DeleteParagraphTag";
import { UpdateAudioRhese, UpdateAudioRheseVariables } from "./__generated__/UpdateAudioRhese";
import { SubToProject, SubToProjectVariables } from "./__generated__/SubToProject";
import {
  UpdateProjectNarrationCompleteness,
  UpdateProjectNarrationCompletenessVariables,
} from "./__generated__/UpdateProjectNarrationCompleteness";
import { SubscribeToExports } from "./__generated__/SubscribeToExports";
import { ExportProject, ExportProjectVariables } from "./__generated__/ExportProject";
import { ExportDownloadUrl, ExportDownloadUrlVariables } from "./__generated__/ExportDownloadUrl";
import { DeleteExport, DeleteExportVariables } from "./__generated__/DeleteExport";
import {
  SetProjectAsComplete,
  SetProjectAsCompleteVariables,
} from "./__generated__/SetProjectAsComplete";
import {
  SetProjectAsArchived,
  SetProjectAsArchivedVariables,
} from "./__generated__/SetProjectAsArchived";
import { RestoreProject, RestoreProjectVariables } from "./__generated__/RestoreProject";
import {
  LaunchProjectReSync,
  LaunchProjectReSyncVariables,
} from "./__generated__/LaunchProjectReSync";
import { Meta } from "./__generated__/Meta";
import {
  RevokeAccessToProject,
  RevokeAccessToProjectVariables,
} from "./__generated__/RevokeAccessToProject";
import {
  SubToPagesExtended,
  SubToPagesExtendedVariables,
} from "./__generated__/SubToPagesExtended";
import {
  SubscribeToExtendedPage,
  SubscribeToExtendedPageVariables,
} from "./__generated__/SubscribeToExtendedPage";
import {
  UpdateProjectPreparationProgress,
  UpdateProjectPreparationProgressVariables,
} from "./__generated__/UpdateProjectPreparationProgress";

// Static
import { convertExtendedPagesToPages } from "./formatters";
import { useSetBoldFromPosition, useUnsetBoldFromPosition } from "./metadata/static/weight";
import { useSetCaseFromPosition, useUnsetCaseFromPosition } from "./metadata/static/case";
import {
  useSetCitationFromPosition,
  useUnsetCitationFromPosition,
} from "./metadata/static/citation";
import {
  useSetItalicizationFromPosition,
  useUnsetItalicizationFromPosition,
} from "./metadata/static/italicization";
import { useSetMuteFromPosition, useUnsetMuteFromPosition } from "./metadata/static/mute";
import {
  useSetScriptNotationFromPosition,
  useUnsetScriptNotationFromPosition,
} from "./metadata/static/scriptNotation";
import { useSetPhonemeFromPosition, useUnsetPhonemeFromPosition } from "./metadata/static/phoneme";

// Dynamic
import {
  useCreatePageFromPosition,
  useUpdatePagesPartname,
  useUpdatePage,
} from "./metadata/dynamic/page";
import {
  useCreateDefinitionFromPosition,
  useDeleteDefinition,
  useUpdateDefinition,
} from "./metadata/dynamic/definition";
import { useCreateRheseFromPosition, useUpdateRhese } from "./metadata/dynamic/rhese";
import { useCreateParagraphFromPosition } from "./metadata/dynamic/paragraph";
import { useCreateCssFromPosition, useDeleteCssFromPosition } from "./metadata/dynamic/customCss";
import {
  useCreateColorationFromPosition,
  useDeleteColorationFromPosition,
} from "./metadata/dynamic/customColoration";
import {
  useCreateSyllableFromPosition,
  useDeleteSyllableFromPosition,
} from "./metadata/dynamic/syllable";
import { UpdateTextContent, UpdateTextContentVariables } from "./__generated__/UpdateTextContent";
import {
  RemoveProjectFromCache,
  RemoveProjectFromCacheVariables,
} from "./__generated__/RemoveProjectFromCache";

export {
  // Static
  useSetBoldFromPosition,
  useUnsetBoldFromPosition,
  useSetCaseFromPosition,
  useUnsetCaseFromPosition,
  useSetCitationFromPosition,
  useUnsetCitationFromPosition,
  useSetItalicizationFromPosition as setItalicizationFromPosition,
  useUnsetItalicizationFromPosition as unsetItalicizationFromPosition,
  useSetMuteFromPosition as setMuteFromPosition,
  useUnsetMuteFromPosition,
  useSetScriptNotationFromPosition,
  useUnsetScriptNotationFromPosition,
  useSetPhonemeFromPosition,
  useUnsetPhonemeFromPosition,
  // Dynamic
  useCreatePageFromPosition,
  useUpdatePage,
  useUpdatePagesPartname,
  useCreateDefinitionFromPosition,
  useUpdateDefinition,
  useDeleteDefinition,
  useCreateRheseFromPosition,
  useUpdateRhese,
  useCreateParagraphFromPosition,
  useCreateCssFromPosition,
  useDeleteCssFromPosition,
  useCreateColorationFromPosition,
  useDeleteColorationFromPosition,
  useCreateSyllableFromPosition,
  useDeleteSyllableFromPosition,
};

export enum ImportTypes {
  JSON_IMPORT = "JSON_IMPORT",
  RHESED_TEXT = "RHESED_TEXT",
  TEXT = "TEXT",
  HTML = "HTML",
}

export const useNewJsonProject = () => {
  const { oktaAuth } = useOktaAuth();
  return (projectContent: Blob, locale: string, colorationOnComplexWordsOnly: boolean) => {
    const json = JSON.stringify({
      locale,
    });
    const jsonBlob = new Blob([json], {
      type: "application/json",
    });
    const data = new FormData();
    data.append("jsonPayload", jsonBlob);
    data.append("file", projectContent);

    return fetch(
      process.env.IMPORT_EXPORT_API_ENDPOINT +
        `/import?type=JSON_IMPORT&colorationOnComplexWordsOnly=${colorationOnComplexWordsOnly}`,
      {
        method: "POST",
        body: data,
        headers: {
          Authorization: "bearer " + oktaAuth.getAccessToken(),
          Accept: "application/json",
        },
      },
    );
  };
};

export const newProject = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { oktaAuth } = useOktaAuth();
  return (
    name: string,
    author: string,
    EAN: string,
    projectContent: Blob,
    importType: ImportTypes,
    locale: string,
    colorationOnComplexWordsOnly: boolean,
  ) => {
    const json = JSON.stringify({
      name,
      author,
      EAN,
      locale,
    });
    const jsonBlob = new Blob([json], {
      type: "application/json",
    });
    const data = new FormData();
    data.append("jsonPayload", jsonBlob);
    data.append("file", projectContent);

    return fetch(
      process.env.IMPORT_EXPORT_API_ENDPOINT +
        `/import?type=${importType}&colorationOnComplexWordsOnly=${colorationOnComplexWordsOnly}`,
      {
        method: "POST",
        body: data,
        headers: {
          Authorization: "bearer " + oktaAuth.getAccessToken(),
          Accept: "application/json",
        },
      },
    );
  };
};

export const useRemoveProjectFromCache = () =>
  useMutation<RemoveProjectFromCache, RemoveProjectFromCacheVariables>(gql`
    mutation RemoveProjectFromCache($projectId: UUID!) {
      removeProjectFromCache(projectId: $projectId)
    }
  `);
export const useUpdateTextContent = () =>
  useMutation<UpdateTextContent, UpdateTextContentVariables>(gql`
    mutation UpdateTextContent(
      $projectId: UUID!
      $stringVersion: Int!
      $operations: [UpdateTextContent!]!
    ) {
      updateTextContent(
        projectId: $projectId
        stringVersion: $stringVersion
        operations: $operations
      )
    }
  `);

export const useEditProject = () =>
  useMutation<EditProject, EditProjectVariables>(gql`
    mutation EditProject(
      $id: UUID!
      $name: String!
      $author: String!
      $EAN: String!
      $locale: String
    ) {
      updateProject(projectId: $id, name: $name, author: $author, ean: $EAN, locale: $locale)
    }
  `);

export const useUpdateCustomColorationConfig = () =>
  useMutation<UpdateCustomColorationConfig, UpdateCustomColorationConfigVariables>(gql`
    mutation UpdateCustomColorationConfig($id: UUID!, $customColorationConfig: JSONObject!) {
      updateProject(projectId: $id, customColorationConfig: $customColorationConfig)
    }
  `);

export const useDeleteProject = () =>
  useMutation<DeleteProject, DeleteProjectVariables>(gql`
    mutation DeleteProject($id: UUID!) {
      deleteProject(projectId: $id)
    }
  `);

export const useSearchForRheses = (variables: SearchForRhesesVariables) => {
  return useQuery<SearchForRheses, SearchForRhesesVariables>(
    gql`
      query SearchForRheses($projectId: UUID!, $query: String!, $mode: RheseSearchMode!) {
        searchForRheses(projectId: $projectId, query: $query, mode: $mode) {
          totalMatchNumber
          results {
            rhese {
              data {
                id
              }
              anchors {
                utf16Start
                utf16Size
                stringVersion
              }
            }
            pageNumber
            content
          }
        }
      }
    `,
    {
      variables,
    },
  );
};

export const useSubToProject = (
  variables: SubToProjectVariables,
  options?: Omit<BaseSubscriptionOptions<any, any>, "variables">,
) =>
  useSubscription<SubToProject, SubToProjectVariables>(
    gql`
      subscription SubToProject($id: UUID!, $withInfo: Boolean!, $withAudioInfo: Boolean!) {
        project(projectId: $id) {
          id
          createdAt
          stringVersion
          author
          name
          EAN: ean
          userBasedAccess
          importStatus
          kind
          config {
            id
            narrationWordsCountLimit
            locale
          }
          status
          preparationProgress
          isNarrationComplete
          isDoingAudioResync
          rhesesAudioInfo @include(if: $withAudioInfo) {
            count
            withReview
            withAudio
            isReviewValid
            isReviewReNarrated
            pageId
          }
          definitionsAudioInfo @include(if: $withAudioInfo) {
            count
            withAudio
            withReview
            isReviewValid
            isReviewReNarrated
            pageId
          }
          totalPageNumber
          speakers {
            id
            name
          }
          paragraphTags {
            id
            name
            createdAt
          }
          info @include(if: $withInfo) {
            pageNumber
            rhesesNumber
            wordsNumber
            charsNumber
            defsNumber
            imagesNumber
            usedParagraphsKinds
            usedCustomCssClasses
          }
          roles {
            id
            userRole
            userTask
            userId
            projectId
          }
          customColorationConfig
          isAudiosyncComplete
        }
      }
    `,
    {
      variables,
      ...options,
    },
  );

export const useSubscribeToExtendedPage = (
  variables: SubscribeToExtendedPageVariables,
): SubscriptionResult<SubscribeToExtendedPage> => {
  const { loading, data, error } = useSubscription<
    SubscribeToExtendedPage,
    SubscribeToExtendedPageVariables
  >(
    gql`
      subscription SubscribeToExtendedPage(
        $projectId: UUID!
        $pageId: UUID!
        $pageSizeFactor: Int
      ) {
        extendedPage(projectId: $projectId, pageId: $pageId, pageSizeFactor: $pageSizeFactor) {
          textContent
          nbOfRheses
          page {
            data {
              id
              pageNumber(projectId: $projectId) {
                pageNumber
                lastPageNumber
              }
            }
            anchors {
              utf16Start
              utf16Size
              stringVersion
            }
          }
          textItalicization {
            anchors {
              utf16Start
              utf16Size
            }
          }
          textCase {
            data {
              textCase
            }
            anchors {
              utf16Start
              utf16Size
            }
          }
          textMuted {
            anchors {
              utf16Start
              utf16Size
            }
          }
          textWeight {
            anchors {
              utf16Start
              utf16Size
            }
          }
          textCitation {
            anchors {
              utf16Start
              utf16Size
            }
          }
          textScriptNotation {
            data {
              textScriptNotation
            }
            anchors {
              utf16Start
              utf16Size
            }
          }
          phoneme {
            data {
              kind
            }
            anchors {
              utf16Start
              utf16Size
            }
          }
          syllable {
            data {
              id
            }
            anchors {
              utf16Start
              utf16Size
            }
          }
          paragraph {
            data {
              id
              kind
              tag {
                id
                name
              }
              speaker {
                id
                name
              }
              image {
                id
                kind
                position
                fileName
                description
                figcaption
              }
            }
            anchors {
              externalId
              insertOrder
              utf16Start
              utf16Size
            }
          }
          rhese {
            data {
              id
              lineBreakAfter
              ignoreTextForAudioSync
              audioInfo {
                id
                reviewId
                start
                end
                confidence
                speechConfidence
                humanStart
                humanEnd
                status
                comment
              }
              audioRecording {
                fileUuid
                syncStatus
              }
            }
            anchors {
              externalId
              insertOrder
              utf16Start
              utf16Size
            }
          }
          definition {
            data {
              id
              kind
              description
              imageName
              audioFile
              audioReviewStatus
              audioReviewComment
            }
            anchors {
              externalId
              utf16Start
              utf16Size
              insertOrder
            }
          }
          customColoration {
            data {
              id
              customColorationKey
            }
            anchors {
              utf16Start
              utf16Size
            }
          }
          customCss {
            data {
              id
              customCssClasses
            }
            anchors {
              utf16Start
              utf16Size
            }
          }
        }
      }
    `,
    {
      variables,
    },
  );

  // Sort all metadata per utf16Start
  const sortedData = data?.extendedPage;
  if (sortedData) {
    sortedData.rhese.sort((a, b) => a.anchors[0].utf16Start - b.anchors[0].utf16Start);
    sortedData.definition.sort((a, b) => a.anchors[0].utf16Start - b.anchors[0].utf16Start);
    sortedData.paragraph.sort((a, b) => a.anchors[0].utf16Start - b.anchors[0].utf16Start);
  }

  return {
    loading,
    data: sortedData ? { extendedPage: sortedData } : undefined,
    error,
  };
};

export const useDeleteParagraphTag = () =>
  useMutation<DeleteParagraphTag, DeleteParagraphTagVariables>(
    gql`
      mutation DeleteParagraphTag($tagId: UUID!) {
        deleteParagraphTag(tagId: $tagId)
      }
    `,
  );

export const useUpdateAudioRhese = () =>
  useMutation<UpdateAudioRhese, UpdateAudioRheseVariables>(
    gql`
      mutation UpdateAudioRhese(
        $humanStart: Float
        $humanEnd: Float
        $audioRheseId: UUID!
        $projectId: UUID!
      ) {
        updateAudioRhese(
          humanStart: $humanStart
          humanEnd: $humanEnd
          audioRheseId: $audioRheseId
          projectId: $projectId
        )
      }
    `,
  );

export const useGiveAccessToProject = () =>
  useMutation<GiveAccesToProject, GiveAccesToProjectVariables>(
    gql`
      mutation GiveAccesToProject($projectId: UUID!, $userId: String!) {
        giveAccessToProject(projectId: $projectId, userId: $userId)
      }
    `,
  );

export const useRevokeAccessToProject = () =>
  useMutation<RevokeAccessToProject, RevokeAccessToProjectVariables>(
    gql`
      mutation RevokeAccessToProject($projectId: UUID!, $userId: String!) {
        revokeAccessToProject(projectId: $projectId, userId: $userId)
      }
    `,
  );

export const useSubscribeToPagesExtended = ({
  variables,
  skip,
}: SubscriptionHookOptions<unknown, SubToPagesExtendedVariables>) => {
  const { loading, data, error } = useSubscription<SubToPagesExtended, SubToPagesExtendedVariables>(
    gql`
      subscription SubToPagesExtended($projectId: UUID!) {
        extendedPages(projectId: $projectId) {
          computedMetadata {
            id
            externalId
            insertOrder
            family
            utf16Start
            utf16Size
            stringVersion
          }
          extendedPages {
            id
            mutePagesAfter
            partname
            updatedAt
            pageNumber(projectId: $projectId) {
              pageNumber
              lastPageNumber
            }
          }
        }
      }
    `,
    {
      variables,
      skip,
    },
  );

  const convertedPages = data?.extendedPages
    ? convertExtendedPagesToPages(data?.extendedPages)
    : [];

  return {
    loading,
    data: { pages: convertedPages },
    error,
  };
};

export const useSetProjectAsComplete = () =>
  useMutation<SetProjectAsComplete, SetProjectAsCompleteVariables>(gql`
    mutation SetProjectAsComplete($projectId: UUID!) {
      setProjectAsComplete(projectId: $projectId)
    }
  `);

export const useSetProjectAsArchived = () =>
  useMutation<SetProjectAsArchived, SetProjectAsArchivedVariables>(gql`
    mutation SetProjectAsArchived($projectId: UUID!) {
      setProjectAsArchived(projectId: $projectId)
    }
  `);

export const useRestoreProject = () =>
  useMutation<RestoreProject, RestoreProjectVariables>(gql`
    mutation RestoreProject($projectId: UUID!) {
      restoreProject(projectId: $projectId)
    }
  `);

export const useUpdateProjectPreparationProgress = () =>
  useMutation<UpdateProjectPreparationProgress, UpdateProjectPreparationProgressVariables>(gql`
    mutation UpdateProjectPreparationProgress(
      $projectId: UUID!
      $preparationProgress: PreparationProgressStatus!
    ) {
      updateProjectPreparationProgress(
        projectId: $projectId
        preparationProgress: $preparationProgress
      )
    }
  `);

export const useUpdateProjectNarrationCompleteness = () =>
  useMutation<UpdateProjectNarrationCompleteness, UpdateProjectNarrationCompletenessVariables>(gql`
    mutation UpdateProjectNarrationCompleteness($projectId: UUID!, $isNarrationComplete: Boolean!) {
      updateProjectNarrationCompleteness(
        projectId: $projectId
        isNarrationComplete: $isNarrationComplete
      )
    }
  `);

export const useUpsertParagraphTag = () =>
  useMutation<UpsertParagraphTag, UpsertParagraphTagVariables>(gql`
    mutation UpsertParagraphTag($tagName: String!, $tagId: UUID, $projectId: UUID!) {
      upsertParagraphTag(tagName: $tagName, tagId: $tagId, projectId: $projectId)
    }
  `);

export const useAddImage = () =>
  useMutation<AddImage, AddImageVariables>(gql`
    mutation AddImage(
      $kind: ImageKind!
      $position: ImagePosition!
      $fileName: String!
      $description: String!
      $figcaption: String
      $paragraphId: UUID!
      $projectId: UUID!
    ) {
      addImage(
        kind: $kind
        position: $position
        fileName: $fileName
        description: $description
        paragraphId: $paragraphId
        figcaption: $figcaption
        projectId: $projectId
      )
    }
  `);

export const useLinkDialogueToSpeaker = () =>
  useMutation<LinkDialogueToSpeaker, LinkDialogueToSpeakerVariables>(gql`
    mutation LinkDialogueToSpeaker(
      $speakerId: UUID
      $speakerName: String
      $paragraphId: UUID!
      $projectId: UUID!
    ) {
      linkDialogueToSpeaker(
        speakerId: $speakerId
        speakerName: $speakerName
        paragraphId: $paragraphId
        projectId: $projectId
      )
    }
  `);

export const useUnlinkDialogueAndSpeaker = () =>
  useMutation<UnlinkDialogueAndSpeaker, UnlinkDialogueAndSpeakerVariables>(gql`
    mutation UnlinkDialogueAndSpeaker($paragraphId: UUID!, $projectId: UUID!) {
      unlinkDialogueAndSpeaker(paragraphId: $paragraphId, projectId: $projectId)
    }
  `);

export const useUpdateImage = () =>
  useMutation<UpdateImage, UpdateImageVariables>(gql`
    mutation UpdateImage(
      $imageId: UUID!
      $kind: ImageKind!
      $position: ImagePosition!
      $fileName: String!
      $description: String!
      $figcaption: String
      $projectId: UUID!
    ) {
      updateImage(
        imageId: $imageId
        kind: $kind
        position: $position
        fileName: $fileName
        description: $description
        figcaption: $figcaption
        projectId: $projectId
      )
    }
  `);

export const useRemoveImage = () =>
  useMutation<RemoveImage, RemoveImageVariables>(gql`
    mutation RemoveImage($imageId: UUID!, $projectId: UUID!) {
      removeImage(imageId: $imageId, projectId: $projectId)
    }
  `);

export const useAssignToNewParagraph = () =>
  useMutation<AssignToNewParagraph, AssignToNewParagraphVariables>(gql`
    mutation AssignToNewParagraph(
      $rheses: [UUID!]!
      $paragraphKind: ExtendedParagraphKind!
      $projectId: UUID!
      $paragraphTagId: UUID
    ) {
      createParagraphForRheses(
        projectId: $projectId
        paragraphKind: $paragraphKind
        paragraphTagId: $paragraphTagId
        rheses: $rheses
      )
    }
  `);

/**
 * Export logic
 */

export const useSubToExports = () =>
  useSubscription<SubscribeToExports>(
    gql`
      subscription SubscribeToExports {
        exports {
          id
          createdAt
          completedAt
          projectId
          author
          exportType
          exportStatus
          comment
          config
          filename
          projectAuthor
          projectName
        }
      }
    `,
  );

export const useExportProject = () =>
  useMutation<ExportProject, ExportProjectVariables>(gql`
    mutation ExportProject(
      $projectId: UUID!
      $exportType: ExportType!
      $exportConfig: ExportConfig!
    ) {
      exportProject(projectId: $projectId, exportType: $exportType, exportConfig: $exportConfig)
    }
  `);

export const useDeleteExport = () =>
  useMutation<DeleteExport, DeleteExportVariables>(gql`
    mutation DeleteExport($exports: [ExportRequest!]!) {
      deleteExport(exports: $exports)
    }
  `);

export const useGetExportDownloadLink = () =>
  useLazyQuery<ExportDownloadUrl, ExportDownloadUrlVariables>(
    gql`
      query ExportDownloadUrl($projectId: UUID!, $exportId: UUID!) {
        exportDownloadUrl(projectId: $projectId, exportId: $exportId)
      }
    `,
  );

/**
 * Re-sync a book
 */

export const useLaunchProjectReSync = () =>
  useMutation<LaunchProjectReSync, LaunchProjectReSyncVariables>(gql`
    mutation LaunchProjectReSync($projectId: UUID!) {
      launchProjectReSync(projectId: $projectId)
    }
  `);

/**
 * Server meta information
 */

export const useMeta = () =>
  useQuery<Meta>(
    gql`
      query Meta {
        meta {
          serverVersion
        }
      }
    `,
  );
