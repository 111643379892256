import React, { useCallback } from "react";
import { InView } from "react-intersection-observer";
import A4Page from "../../A4Page/A4Page";

type LazyProps = {
  pageId: string;
  scrollBoxSelector: string;
  displayedTotalPageNumber: number;
  displayedPageNumber: number;
  loadMore: () => void;
  hasMoreToLoad: boolean;
  loadingMore: boolean;
  children: JSX.Element | JSX.Element[];
};

export default function Lazy(props: LazyProps) {
  const render = useCallback(
    ({ inView, ref }) => {
      return (
        <A4Page
          innerRef={ref}
          pageId={props.pageId}
          displayedPageNumber={props.displayedPageNumber}
          displayedTotalPageNumber={props.displayedTotalPageNumber}
          loadMore={props.loadMore}
          hasMoreToLoad={props.hasMoreToLoad}
          loadingMore={props.loadingMore}>
          {inView ? props.children : []}
        </A4Page>
      );
    },
    [
      props.children,
      props.displayedPageNumber,
      props.displayedTotalPageNumber,
      props.hasMoreToLoad,
      props.loadMore,
      props.loadingMore,
      props.pageId,
    ],
  );

  return <InView>{render}</InView>;
}
