import { gql } from "@apollo/client";

export const getRecordingPresignedUrls = gql`
  query audioRecordingQuery($filename: UUID) {
    audioRecording(filename: $filename) {
      presignedUrl {
        filename
        getUrl
        putUrl
      }
    }
  }
`;

// IMPORTANT BUG NOTE
// DO NOT USE useLazyQuery in parallel, it's buggy: see : https://github.com/apollographql/apollo-client/issues/9755
// export const useGetRecordingPresignedUrls = () => {
//   return useLazyQuery<AudioRecording, AudioRecordingVariables>(
//     gql`
//       query audioRecording($filename: UUID) {
//         audioRecording(filename: $filename) {
//           presignedUrl {
//             filename
//             getUrl
//             putUrl
//           }
//         }
//       }
//     `,
//   );
// };
