@import "../../../styles/colors.less";

.panelWrapper {
  position: absolute;
  z-index: 900;
  width: 100%;
  height: 100vh;
  transform: translateY(-100%);

  .transparentBackground {
    height: 100%;
    width: 100%;
    background-color: fade(@grey, 50%);
  }
}

.projectPanel {
  position: absolute;
  height: 40em;
  width: 100vw;
  background-color: white;
  bottom: 0;
  overflow: hidden;
  display: flex;

  h4 {
    font-size: 3em;
  }

  .cover {
    width: 34%;
    max-width: 40em;
    min-width: 24em;
    background-color: @grey;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3 {
      color: white;
    }
    .name {
      font-size: 2.5em;
    }
    .author {
      font-size: 1.5em;
    }
  }

  .tabs {
    width: 66%;
    overflow-x: auto;
  }
  .tabs :global(.ant-tabs-nav .ant-tabs-nav-list) {
    padding: 1em 0;
    width: 100%;
  }

  :global(.ant-tabs-nav-wrap) {
    justify-content: center;
    align-items: center;
    width: 15em;

    :global(.ant-tabs-tab-btn) {
      font-size: 1.5em;
    }
  }

  :global(.ant-tabs-content-holder) {
    overflow-y: auto;
  }

  :global(.ant-tabs-content) {
    min-height: 100%;
  }

  :global(.ant-tabs-tabpane) {
    min-height: 100%;
  }
}

// Adapt slightly the panel below medium screens
@media (max-width: 1200px) {
  .projectPanel {
    .cover {
      width: 25%;
      min-width: 16em;
      .name {
        font-size: 2em;
      }
      .author {
        font-size: 1em;
      }
    }
    .tabs {
      flex: 1;
      width: unset;
    }
    .tabs :global(.ant-tabs-nav-wrap) {
      width: 12em;
      :global(.ant-tabs-tab) {
        font-size: 90%;
        margin-left: -4px;
      }
    }
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999;
  pointer-events: none;
}

.step {
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  row-gap: 1em;

  > label {
    font-size: 1.25em;
  }
}

.reloadButton {
  margin-top: 2em;
}

.projectStatus {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;

  :global(.ant-radio-group.ant-radio-group-outline) {
    display: flex;
  }

  :global(.ant-radio-button-wrapper) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
  }
}

.infos,
.config,
.checklist,
.userAccess,
.progress {
  padding: 2em;
  font-size: 1.2em;
  position: relative;
  min-height: 100%;

  & > *:not(h4) {
    font-size: 1.2em;
  }
  ul {
    padding: 1em 0;
    text-decoration: none;
    list-style: none;

    li {
      font-weight: normal;
    }
  }

  a {
    font-size: 1.2em;
  }
}

.radio {
  justify-content: start;
}

.customColorConfig {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1em;
  width: max-content;
  margin-bottom: 0.5em;
}

.add {
  margin: 0.5em 0;
}

.config h3 {
  padding-bottom: 1em;
}

.divider {
  margin: 2em 0;
}

/*
Badge
*/

.badgeStatus {
  margin-top: 1em;
}

/*
Spinner container
*/

.spinnerContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(255 255 255 / 80%);
  display: flex;
  justify-content: center;
  align-items: center;
}
