import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Slider } from "antd";
import { preciseFloat } from "../../../../../Utils/common";
import { SliderMarks } from "antd/lib/slider";

const AUDIO_SLIDER_STEP = 0.01; // in seconds

type Props = {
  defaultStart: number;
  defaultEnd: number;
  originalStart: number;
  originalEnd: number;
  additionalTimeBefore: number;
  additionalTimeAfter: number;
  setStartOffset: (value: number) => void;
  setEndOffset: (value: number) => void;
  lastInputStartOffset?: number;
  lastInputEndOffset?: number;
};

export default function SynchroSlider({
  originalStart,
  originalEnd,
  defaultStart,
  defaultEnd,
  setStartOffset,
  setEndOffset,
  additionalTimeBefore,
  additionalTimeAfter,
  lastInputStartOffset,
  lastInputEndOffset,
}: Props) {
  const [initialStartHandled, setInitialStartHandled] = useState(false);
  const [initialEndHandled, setInitialEndHandled] = useState(false);
  // const [prevStartOffset, setPrevStartOffset] = useState(startOffset);

  /**
   * Slider values
   */
  const [start, setStart] = useState(defaultStart);
  const [end, setEnd] = useState(defaultEnd);

  const [newMin, setNewMin] = useState(originalStart); // TODO
  const [newMax, setNewMax] = useState(originalEnd);

  /**
   * Slider handlers
   */
  const onChange = useCallback(
    (value: [number, number]) => {
      const _start = preciseFloat(value[0], 2);
      const _end = preciseFloat(value[1], 2);

      // Internal state only
      setStart(_start);
      setEnd(_end);

      // Update value
      const _startOffset = preciseFloat(_start - originalStart, 2);
      const _endOffset = preciseFloat(_end - originalEnd, 2);
      setStartOffset(_startOffset);
      setEndOffset(_endOffset);
    },
    [originalEnd, originalStart, setEndOffset, setStartOffset],
  );

  /**
   * newMin logic
   */

  // Effect: update "newMin" when "additionalTimeBefore" changes
  useEffect(() => {
    const _newMin = preciseFloat(originalStart - additionalTimeBefore, 2);
    setNewMin(_newMin);
  }, [additionalTimeBefore, originalStart]);

  // Effect: "newMin" has changed, and is higher than the start value
  useEffect(() => {
    if (start < newMin) {
      if (!initialStartHandled && defaultStart !== originalStart) {
        // Don't update values in this case. Required to not overide initial values
        setInitialStartHandled(true);
      } else {
        setStart(newMin);
        setStartOffset(additionalTimeBefore);
      }
    }
  }, [
    additionalTimeBefore,
    setStartOffset,
    start,
    newMin,
    initialStartHandled,
    defaultStart,
    originalStart,
  ]);

  /**
   * newMax logic
   */

  // Effect: update "newMax" when "additionalTimeAfter" changes
  useEffect(() => {
    const _newMax = preciseFloat(originalEnd + additionalTimeAfter, 2);
    setNewMax(_newMax);
  }, [additionalTimeAfter, originalEnd]);

  // Effect: "newMax" has changed, and is lower than the end value
  useEffect(() => {
    if (end > newMax) {
      if (!initialEndHandled && defaultEnd !== originalEnd) {
        // Don't update values in this case. Required to not overide initial values
        setInitialEndHandled(true);
      } else {
        setEnd(newMax);
        setEndOffset(additionalTimeAfter);
      }
    }
  }, [end, newMax, additionalTimeAfter, setEndOffset, initialEndHandled, defaultEnd, originalEnd]);

  /**
   * Slider marks
   */
  const marks: SliderMarks = useMemo(() => {
    const _marks = {
      [originalStart]: "Start",
      [originalEnd]: "End",
    };
    if (newMin < originalStart) {
      _marks[newMin] = `-${preciseFloat(originalStart - newMin, 2)}s`;
    }
    if (newMax > originalEnd) {
      _marks[newMax] = `+${preciseFloat(newMax - originalEnd, 2)}s`;
    }
    return _marks;
  }, [originalStart, originalEnd, newMin, newMax]);

  /**
   * Stay sync if inputs change
   */
  const [prevLastInputStartOffset, setPrevLastInputStartOffset] = useState(lastInputStartOffset);
  const [prevLastInputEndOffset, setPrevLastInputEndOffset] = useState(lastInputEndOffset);
  useEffect(() => {
    // Start
    if (lastInputStartOffset !== undefined) {
      if (lastInputStartOffset !== prevLastInputStartOffset) {
        const _start = preciseFloat(originalStart + lastInputStartOffset, 2);
        setStart(_start);
      }
      setPrevLastInputStartOffset(lastInputStartOffset);
    }
    // End
    if (lastInputEndOffset !== undefined) {
      if (lastInputEndOffset !== prevLastInputEndOffset) {
        const _end = preciseFloat(originalEnd - lastInputEndOffset, 2);
        setEnd(_end);
      }
      setPrevLastInputEndOffset(lastInputEndOffset);
    }
  }, [
    lastInputStartOffset,
    prevLastInputStartOffset,
    originalStart,
    originalEnd,
    lastInputEndOffset,
    prevLastInputEndOffset,
    defaultEnd,
  ]);

  /**
   * Effect to fix a tooltip bug
   * Bug: is tooltip's "open" option is set to true from start, the tooltip will have a bad position, preventing from grabbing the dots easily
   */
  const [showTooltip, setShowTooltip] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowTooltip(true);
    }, 350);
  }, []);

  return (
    <Slider
      // NOTE: "key" is required to handle re-render logic to fix tooltip issues when "newMax" changes
      key={`${newMin}-${newMax}`}
      min={newMin}
      max={newMax}
      value={[start, end]}
      step={AUDIO_SLIDER_STEP}
      onChange={onChange}
      range={{ draggableTrack: true }}
      tooltip={{
        formatter: (value) => value?.toFixed(2),
        open: showTooltip,
      }}
      marks={marks}
    />
  );
}
