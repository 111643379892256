import React, { createContext, useContext } from "react";
import { usePersistedReducer } from "../../../../persistence/usePersistedReducer";
import { STORAGE_KEY } from "./config";
import reducer, { initialState } from "./reducer";

const SliderFontSizeContext = createContext<
  | {
      state: typeof initialState;
      dispatch: React.Dispatch<any>;
    }
  | undefined
>(undefined);

export const SliderFontSizeProvider = ({ children }: { children: JSX.Element }) => {
  const store = usePersistedReducer(reducer, initialState, STORAGE_KEY);

  return <SliderFontSizeContext.Provider value={store}>{children}</SliderFontSizeContext.Provider>;
};

const useSliderFontSizeStoreContext = () => {
  const context = useContext(SliderFontSizeContext);
  if (!context)
    throw new Error("SliderFontSizeContext must be used within a SliderFontSizeProvider");
  return context;
};

export const useSliderFontSizeStore = () => {
  const {
    state: { value },
    dispatch,
  } = useSliderFontSizeStoreContext();
  return {
    value,
    onChange: (val: number) => dispatch({ type: "FONT_SIZE_CHANGED", value: val }),
  };
};
