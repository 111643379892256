import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import { Row } from "antd";
import * as style from "../../style.less";
import { Users } from "../../../UserAccess";
import BadgeStatus from "../../../Project/components/BadgeStatus/BadgeStatus";
import { useI18n } from "../../../../../i18n";
import DropdownMenu from "./DropdownMenu";
import { ProjectMethods } from "../hooks/useProjectMethods";
import classNames from "classnames";
import { SubToProjectForHomepage_projects } from "../../__generated__/SubToProjectForHomepage";
import { useProjectStatusLabel } from "../../../Project/hooks/useProjectStatusLabel";

type Props = {
  project: SubToProjectForHomepage_projects;
  projectMethods: ProjectMethods;
};

const i18nKey = "library.index";

const getCountyCode = (str: string) => str.split("_")?.[1] || "";

export default function ProjectCard({ project, projectMethods }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useI18n();
  const projectCardI18nKey = `${i18nKey}.projectCard`;
  const countryCode = getCountyCode(project.config.locale);
  const bookStatusLabel = useProjectStatusLabel(project);

  const ref = useRef<HTMLDivElement>(null);

  const titleI18nKey = `${projectCardI18nKey}.button`;
  const textProps = {
    initial: { opacity: 1, y: "0" },
    animate: { opacity: isOpen ? 0 : 1, y: isOpen ? "20px" : "0" },
  };

  const menuProps = {
    animate: { y: isOpen ? "0px" : "-20px" },
    initial: { y: "-20px" },
  };

  const isInReSync = project.isDoingAudioResync;

  return (
    <motion.div
      style={{ position: "relative" }}
      ref={ref}
      className={classNames(style.projectCard, { [style.isInReSync]: isInReSync })}
      onHoverEnd={isInReSync ? undefined : () => setIsOpen(false)}
      onHoverStart={isInReSync ? undefined : () => setIsOpen(true)}>
      <BadgeStatus className={style.badge} bookStatusLabel={bookStatusLabel} small />
      <motion.div {...textProps}>
        <Row justify="center" className={style.breakText}>
          {project.name}
        </Row>
      </motion.div>
      <motion.div {...textProps}>
        <Row justify="center" className={style.breakText}>
          {project.author}
        </Row>
      </motion.div>
      <motion.div {...textProps}>
        <Row justify="center" className={style.countryCode}>
          {countryCode}
        </Row>
      </motion.div>
      {!isInReSync && (
        <>
          <motion.div
            {...textProps}
            style={{
              position: "absolute",
              bottom: "1em",
              fontSize: "0.833em",
              paddingTop: "0.5em",
              textAlign: "center",
              width: "100%",
            }}>
            {new Date(project.createdAt).toLocaleDateString().replace(/\//g, ".")}
          </motion.div>
          <motion.div
            className={style.projectHover}
            animate={{ opacity: isOpen ? 1 : 0 }}
            initial={{ opacity: 0 }}>
            <motion.div {...menuProps}>
              <div className={style.buttonWrapper}>
                <motion.button
                  initial={{
                    backgroundColor: "rgba(0,0,0,0)",
                    color: "rgb(255,255,255)",
                  }}
                  whileHover={{
                    backgroundColor: "rgba(255,255,255,1)",
                    color: "rgb(0,0,0)",
                  }}
                  onClick={() => projectMethods.open(String(project.id))}>
                  {t(`${titleI18nKey}.title`)}
                </motion.button>
              </div>
              <motion.div style={{ x: "-0.5em" }} className={style.users}>
                <Users userBasedAccess={project.userBasedAccess} />
              </motion.div>
            </motion.div>
            <DropdownMenu
              menuProps={menuProps}
              project={project}
              projectMethods={projectMethods}
              refProp={ref}
            />
          </motion.div>
        </>
      )}
    </motion.div>
  );
}
