import React, { CSSProperties } from "react";
import { Spin } from "antd";

export const Spinner = ({
  className,
  style,
  grow,
  notFlex,
  small,
}: {
  className?: string;
  style?: CSSProperties;
  grow?: boolean;
  notFlex?: boolean;
  small?: boolean;
}) => (
  <div
    className={className}
    style={{
      display: notFlex ? "" : "flex",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: grow ? 1 : "unset",
      ...style,
    }}>
    <Spin size={small ? "small" : undefined} />
  </div>
);
