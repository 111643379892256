{
    "authentication": {
        "SignInWidget": {
            "text": "Anslut med Google"
        }
    },
    "library": {
        "index": {
            "newProject": {
                "title": "Lägg till en bok"
            },
            "projectCard": {
                "menu": {
                    "modifies": "Redigera",
                    "export": "Exportera projektet",
                    "exportNarration": "Exportera projektet för berättelse",
                    "invite": "Bjuda in en kollega",
                    "delete": "Radera",
                    "exportEPub": "Exportera projektet (epub)",
                    "viewExports": "Voir les exports",
                    "resync": "Lancer une re-synchro audio"
                },
                "button": {
                    "date": "Todo Date Format",
                    "title": "Tillgång till texten"
                }
            },
            "menu": {
                "signOut": "Koppla bort"
            },
            "default": {
                "export": {
                    "message": "Export av det aktuella projektet {{name}}",
                    "serverErrorMessage": "Servern returnerade ett fel",
                    "successNotification": "Ladda ner {{name}}",
                    "type": "octet/stream",
                    "errorNotification": "Ett fel inträffade under exporten",
                    "colorationOptions": "Färgningsalternativ",
                    "audioOption": "Ljudfiler",
                    "audioOptionCheckbox": "Inkludera ljud"
                },
                "exportNarration": {
                    "message": "Export (narration) du projet {{name}} en cours",
                    "serverErrorMessage": "Servern returnerade ett fel",
                    "successNotification": "Ladda ner {{name}}",
                    "type": "octet/stream",
                    "errorNotification": "Ett fel inträffade under exporten",
                    "exportsTabLabel": "Exports"
                },
                "project": {
                    "errorMessage": "Importen av böcker misslyckades",
                    "successMessage": "Boken importeras"
                },
                "navBar": {
                    "title": "Bibliotek",
                    "tabTitle": "Alla",
                    "errorMessage": "Ett fel har inträffat, det är omöjligt att hitta listan över projekt för tillfället."
                }
            }
        },
        "inviteModal": {
            "title": "Bjuda in en kollega",
            "email": "Den anställdes e-postadress.",
            "invite": "Bjuda in"
        }
    },
    "project": {
        "a4Page": {
            "placeholder": "Namnge din sektion",
            "showMore": "Visa mer"
        },
        "actionBar": {
            "stepComp": {
                "raw": "Brutto",
                "inProgress": "Under förberedelse",
                "toReview": "som ska korrigeras",
                "done": "Slutförd"
            },
            "getStepLabel": {
                "formatting": "Segmentering",
                "definition": "Definitioner",
                "syllables": "Stavelsättning",
                "rhese": "Uppdelning i ordgrupper med betydelse",
                "dialogues": "Dialoger",
                "narration": "Berättelse"
            },
            "projectInfo": {
                "title": "Information",
                "description": "Information",
                "charsNumber": "Antal tecken",
                "defsNumber": "Antal definitioner",
                "imagesNumber": "Antal bilder",
                "pageNumber": "Antal sidor",
                "rhesesNumber": "Antal ordgrupper med en betydelse",
                "wordsNumber": "Antal ord",
                "listTitle": "Typer av stycken",
                "export": "Exportera till CSV"
            },
            "checklist": {
                "title": "Checklist"
            },
            "userAccess": {
                "title": "Personal"
            },
            "default": {
                "tabPaneTitle": {
                    "preview": "Allmänt",
                    "rhese": "Uppdelning i ordgrupper med betydelse ",
                    "formatting": "Layout",
                    "definitions": "Definitionsläge",
                    "dialogues": "Dialogsläge",
                    "syllables": "Stavelseläge",
                    "narration": "Berättande läge"
                }
            },
            "projectTabs": {
                "info": "Information",
                "checklist": "Checklist",
                "access": "Personal",
                "config": "Konfigurering",
                "progress": "Avancemang"
            },
            "config": {
                "title": "Configuration des clés",
                "customColoration": {
                    "title": "Skapa ett personligt färgsättningssystem",
                    "add": "Lägg till en ny färgskillnad"
                },
                "customParagraphTag": {
                    "title": "Konfigurera etiketter för stycken",
                    "add": "Lägga till en ny etikett"
                }
            },
            "progress": {
                "title_state_preparation": "Under förberedelse",
                "title_state_narration": "Under berättandet",
                "title_state_narrationComplete": "Inspelningen av berättelsen är avslutad",
                "title_state_narrationAndFixComplete": "Narration et correction complètes",
                "title_state_bookCompleted": "Boken är markerad som färdig",
                "preparing": {
                    "title": "Under förberedelse",
                    "legendReadyToNarrate": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium\n",
                    "buttonReadyToNarrate": "Valider pour narration",
                    "legendPreparationComplete": "Les tâches complémentaires sont en cours de préparation",
                    "buttonPreparationComplete": "Valider la préparation"
                },
                "prepared": {
                    "title": "Préparé",
                    "legendTasksForNarrationComplete": "Les tâches nécessaires pour la narration sont terminées",
                    "buttonReadyToNarrate": "Redo att berätta",
                    "legendPreparationComplete": "Toutes les tâches de préparation sont terminées",
                    "buttonPreparationComplete": "Förberedelse slutförd"
                },
                "narration": {
                    "title": "Under berättandet",
                    "narratedPages": "Berättade sidor",
                    "narratedDefinitions": "Berättade definitioner",
                    "proofListening": "Kontrollera ljudinspelningen",
                    "remainingCorrections": "Återstående korrigeringar",
                    "buttonDone": "Markera boken som avslutad",
                    "markNarrationDone": "Valider la narration",
                    "narrationDone": "Narration validée"
                },
                "preparationAndNarration": {
                    "markBookComplete": "Marquer le livre comme étant terminé"
                },
                "bookCompleted": {
                    "legend": "Un livre archivé est supprimé à l'affichage dahs la bibliothèque des différents acteurs.",
                    "legendBis": "Pour le désarchiver, contacter le développeur.",
                    "button": "Arkiv"
                },
                "bookStateStatus": {
                    "not_ready": "Under förberedelse",
                    "ready_for_narration": "Under berättandet",
                    "narration_complete": "Fullständig ljudinspelning",
                    "complete": "Redo för validering",
                    "complete_book": "Slutfört",
                    "archived": "Arkiverad",
                    "resync": "En re-synchro"
                },
                "archived": {
                    "title": "Archivé",
                    "buttonUnarchive": "Désarchiver"
                }
            }
        },
        "existing": {
            "bookRenderer": {
                "paraghapLabel": {
                    "citation": "Citat",
                    "dialogue": "Dialog",
                    "didascalie": "Didascalia",
                    "email": "E-post",
                    "intro": "Introduktion",
                    "normal": "Normal",
                    "note": "Obs",
                    "resume": "Sammanfattning",
                    "vers": "Vers",
                    "center": "Centrum",
                    "mail": "Post",
                    "right": "Höger",
                    "space": "Mellanslag",
                    "bold": "Fett",
                    "text": "Meddelande",
                    "title1": "Titel1",
                    "title2": "Titel2",
                    "title3": "Titel3",
                    "title4": "Titel4",
                    "title5": "Titel5",
                    "title6": "Titel6",
                    "listUnordered": "Oordnad lista",
                    "listOrdered": "Ordnad lista",
                    "listDefinition": "Lista över definitioner"
                }
            },
            "index": {
                "syllableComponent": {
                    "syllables": "Stavelser",
                    "phonemes": "Fonemes",
                    "custom": "Personlig"
                },
                "pageBar": {
                    "mutePage": "Osynlig(a) sida(n)"
                },
                "goToPage": {
                    "nav": "Gå till",
                    "validation": "OK"
                }
            },
            "editingMode": {
                "definition": {
                    "buildDefinitionMenu": {
                        "associates": "Länk till definitionen",
                        "modifies": "Ändra definitionen",
                        "add": "Lägg till en definition",
                        "dissociate": "Inte längre kopplad till definitionen"
                    },
                    "content": {
                        "all": "Alla definitioner",
                        "explicit": "Tydliga definitioner",
                        "implicit": "Implicita definitioner",
                        "foot_note": "Fotnoter",
                        "image": "With image"
                    },
                    "definitionModal": {
                        "message": {
                            "assign": "Tilldela en definition",
                            "update": "Spara ändringar",
                            "create": "Skapa en definition"
                        },
                        "radioButton": {
                            "explicit": "Tydliga definitioner",
                            "implicit": "Implicita definitioner",
                            "foot_note": "Fotnoter",
                            "image": "With image"
                        },
                        "textArea": {
                            "placeholder": "Att skriva en definition"
                        },
                        "imageName": {
                            "placeholder": "Image name"
                        }
                    }
                },
                "formatting": {
                    "dropZone": "Lägg till en bild",
                    "menu": {
                        "titre_1": "Nivå 1 titel",
                        "titre_2": "Nivå 2 titel",
                        "titre_3": "Nivå 3 titel",
                        "titre_4": "Nivå 4 titel",
                        "normal": "Normal",
                        "dialogue": "Dialog",
                        "intro": "Introduktion",
                        "resume": "Sammanfattning",
                        "email": "E-post",
                        "citation": "Citat",
                        "vers": "Vers",
                        "didascalie": "Didascalia",
                        "note": "Obs",
                        "subMenuTitleTransformsTitle": "Växla till en titel",
                        "subMenuTitleAlign": "Justera...",
                        "space": "Mellanslag",
                        "center": "I centrum",
                        "right": "Till höger",
                        "subMenuTitleTransformsParagraph": "Växla till et stycke",
                        "subMenuTitleException": "Undantag",
                        "bold": "Fett",
                        "text": "Meddelande",
                        "mail": "Post",
                        "rheseAction": "Sidbrytning efter ordgruppen",
                        "spaceAction": {
                            "normal": "Förvandla till mellanslag",
                            "unbreakable": "Omvandla till hårt mellanslag"
                        },
                        "lineSuppression": {
                            "normal": "Radera radbrytningen",
                            "afterRhese": "Radbrytning efter ordgruppen"
                        },
                        "titre_5": "nivå 5 titel",
                        "titre_6": "nivå 6 titel",
                        "subMenuList": "Lista",
                        "listUnordered": "oordnad",
                        "listOrdered": "ordnad",
                        "listDefinition": "Definitioner",
                        "list": "Lista",
                        "addClassesAction": "Lägga till anpassade css-klasser",
                        "removeClassesAction": "Ta bort anpassade css-klasser"
                    },
                    "lettersCount": {
                        "form": {
                            "name": "grundläggande",
                            "item": {
                                "imageNaming": {
                                    "label": "Bildens namn",
                                    "name": "Filnamm"
                                },
                                "validation": "Validera",
                                "imageDescription": {
                                    "label": "Beskrivning av bilden",
                                    "name": "Beskrivning"
                                },
                                "radioButton": {
                                    "normal": "Normal",
                                    "popup": "Popup"
                                }
                            }
                        },
                        "result": "{{lettersCount}} tecken"
                    },
                    "default": {
                        "modal": {
                            "title": "Lägg till en bild"
                        },
                        "form": {
                            "imageNaming": {
                                "label": "Bildens namn",
                                "name": "Namm"
                            },
                            "validation": "Validera",
                            "imageDescription": {
                                "label": "Beskrivning av bilden",
                                "name": "Författare"
                            },
                            "radioButton": {
                                "normal": "Normal",
                                "popup": "Popup"
                            },
                            "figcaption": {
                                "label": "Bildtext",
                                "name": "legende"
                            }
                        },
                        "tooltip": {
                            "title": "Lägg till en bild"
                        }
                    },
                    "associateToSpeaker": {
                        "title": "Associera med en karaktär",
                        "name": "Karaktärens namn",
                        "alphaNumError": "Det angivna värdet måste vara alfanumeriskt",
                        "dissociate": "Ta bort talarens namn"
                    },
                    "modalCustomCss": {
                        "title": "anpassade css-klasser",
                        "modalOkLabel": "uppdatering",
                        "ovverideWarning": "De valda objekten har inte samma klass. Om du validerar en ny post kommer de gamla posterna att gå förlorade.",
                        "inputPlaceholder": "min-klass1, min-klass2"
                    }
                },
                "narration": {
                    "section": {
                        "minutes": "minuter"
                    },
                    "default": {
                        "title": "Ljudinslagen"
                    }
                },
                "rhese": {
                    "index": {
                        "rhesesRenderer": {
                            "menu": {
                                "merge": "Slå samman ordgrupper med betydelse ",
                                "creat": "Skapa en ordgrupp med betydelse ",
                                "split": "Dela upp ordgruppen med betydelse "
                            },
                            "edit": "Redigera"
                        }
                    }
                },
                "syllable": {
                    "buildSyllableMenu": {
                        "creat": "Skapa en stavelse",
                        "transformMute": "Förvandlas till tysta bokstäver",
                        "transformNoMute": "Förvandlas till icke-tysta bokstäver",
                        "ignore": "Ignorera stavelsen"
                    },
                    "buildPhonemeMenu": {
                        "title": "Tilldelning av en fonem",
                        "vowel": "Vokal",
                        "consonant": "Konsonant",
                        "complex": "Komplex",
                        "transformMute": "Förvandlas till tysta bokstäver",
                        "transformNoMute": "Förvandlas till icke-tysta bokstäver",
                        "ignore": "Ignorera fonem"
                    },
                    "buildCustomColorsMenu": {
                        "assignCustomColor": "Tilldela som",
                        "removeCustomColor": "Ta bort färger"
                    }
                }
            }
        },
        "search": {
            "getSearchModeLabel": {
                "id": "Identifierare",
                "text": "Text"
            },
            "nbResults": {
                "loading": "Laddas",
                "result": "resultat",
                "results": "resultaten"
            }
        },
        "edit": {
            "modalTitle": "Redigera en bok",
            "form": {
                "bookName": "Bokens namn",
                "bookAuthor": "Författarens namn",
                "locale": "Language",
                "modifies": "Redigera"
            }
        },
        "new": {
            "stepOne": {
                "title": "Bokens text",
                "importType": "Importtyp",
                "successMessage": "filen har importerats",
                "errorMessage": ": misslyckande med att importera filen...",
                "uploadOutlined": "Sök på din dator...",
                "import": "Importera",
                "rhesedText": "Text uppdelad i ordgrupper med betydelse",
                "text": "Text",
                "htmlBook": "HTML",
                "locale": "Bokens språk",
                "jsonImport": "JSON",
                "disableColorationOnSimpleWords": "Inaktivera färgning av enskilda ord"
            },
            "stepTwo": {
                "title": "Titel",
                "titlePlaceholder": "t.ex. Nils Holgerssons underbara resa genom Sverige",
                "author": "Författare",
                "authorPlaceholder": "t.ex.Selma Lagerlöf",
                "ean": "EAN",
                "eanPlaceholder": "9782070360420",
                "create": "Skapa"
            },
            "modalTitle": "Lägg till bok"
        },
        "importStatus": {
            "failed": {
                "title": "Projekt otillgängligt",
                "message": "Projektimport misslyckades",
                "action": "Återgå till listan över projekt"
            },
            "inProgress": {
                "title": "Projekt otillgängligt",
                "message": "Importen av projektet pågår fortfarande",
                "action": "Återgå till listan över projekt"
            },
            "loading": {
                "title": "Laddar vänligen vänta",
                "message": "Tack för ditt tålamod"
            }
        }
    },
    "userAccess": {
        "userTable": {
            "title": "Roll",
            "preparer": "Förberedare",
            "correction": "Rättelse"
        },
        "tasksLabels": {
            "definition": "Definition",
            "dialogues": "Dialoger",
            "formatting": "Layout",
            "narration": "Berättelse",
            "rhese": "Uppdelning i ordgrupper med betydelse",
            "syllables": "Stavelsättning"
        }
    },
    "locale": {
        "fr_FR": "Franska",
        "en_GB": "Engelska",
        "it_IT": "Italienska",
        "sv_SE": "Svenska"
    },
    "page": "Sidan",
    "paragraphe": "Stycken",
    "on": "på",
    "tagTheParagraph": "koppla en etikett",
    "removeTagFromParagraph": "Ta bort en etikett",
    "selectADefinition": "Välj en definition",
    "associateToDefinition": "Länk till definition",
    "editor": "Beredare",
    "narrator": "Berättare",
    "prooflistener": "Ljudkontroller",
    "audioLibrarty": "Ljudbibliotek",
    "GQLError.cannotUpdateRheseContent.message": "Det är omöjligt att ändra ordgruppen",
    "GQLError.cannotUpdateRheseContent.description": "Ordgruppen är redan associerad med en ljudfil och kan inte ändras",
    "select_recorgind_issue_type": "Välj typ av anmärkning",
    "audioReview.bad_speech": "Uttryck",
    "audioReview.bad_text": "Felaktig text",
    "audioReview.recording": "Problem med registrering",
    "audioReview.valid": "OK",
    "audioReview.comment": "Kommentar",
    "audioReview.typeYourComment": "Lägg till din kommentar",
    "audioReview.addAReviewToSelectedRheses": "Lägg till en anmärkning till de valda ordgruppen",
    "cancel": "Avbryt",
    "apply": "Ansök på",
    "reset": "Återställ",
    "validate": "Valider",
    "confirm": "Confirmer",
    "warning": "Attention",
    "startOffset": "flytta starttiden",
    "endOffset": "flytta sluttiden",
    "audioOffset": "flytta ljudet",
    "listen": "Lyssna på",
    "offsetConfigIsInvalid": "Inställningen är ogiltig",
    "listenToTheRecording": "Lyssna på inspelningen",
    "proofListening.pageNotFullyRecordedTitle": "Den här sidan i boken har ännu inte berättats.",
    "proofListening.pageNotFullyRecordedButtonLabel": "Tillbaka",
    "definitions": "Definitioner",
    "rheses": "Ordgrupper med betydelse",
    "loadingError": "Ett fel inträffade under laddningen",
    "customCssClassesUsed": "Anpassade CSS-klasser som används",
    "audioReview.bad_text_and_narration": "Problem med text och berättelser",
    "audioReview.commentName": "Kommentar",
    "audioReview.resetStatusBtn": "Radera recensioner",
    "audioReview.resetStatusModalTitle": "Är du säker?",
    "audioReview.resetStatusModalText": "Observera att denna åtgärd tar bort statusen för de valda ordgrupperna, samt deras kommentarer",
    "audioReview.resetStatusModalBtn": "Ta bort",
    "definitionRecordedAudioUploadSuccess": "Filen har sparats framgångsrikt",
    "definitionRecordedAudioUploadError": "Fel, filen har inte sparats",
    "definitionAudioUploadSuccess": "Filen har sparats framgångsrikt",
    "definitionAudioUploadError": "Fel, filen har inte sparats",
    "definitionAudioUploadErrorSize": "Filen får inte överstiga",
    "definitionAudioUploadErrorExtension": "Tillägget stöds inte. Använd följande tillägg",
    "filterWithIssues": "Med problem",
    "filterAudioComplete": "Med fullständigt ljud",
    "filterRenarrated": "Ominspelad",
    "audioReview.renarrated": "Ominspelad",
    "rheseIgnoreSyncroMenuItemLabel": "Ignorera denna ordgrupp vid synkronisering",
    "rheseUseSyncroMenuItemLabel": "OK för att synkronisera",
    "rheseUseSyncroDialogTitle": "Bekräftar du denna åtgärd?",
    "rheseUseSyncroDialogOk": "Bekräfta",
    "rheseUseSyncroDialogCancel": "Avbryt",
    "rheseIconSyncroTooltip": "Osynkroniserad ordgruppen",
    "forceChange": "Att tvinga fram förändringar",
    "adminCanForceChange": "Du kan tvinga fram en förändring",
    "forcedUpdateWillDeleteAssociatedAudio": "Om du tvingar fram uppdateringen raderas de tillhörande ljudfilerna",
    "serverHasSentError": "Servern returnerade ett felmeddelande",
    "errorHasBeenSentToTechStaff": "Det vidarebefordrades automatiskt till det tekniska teamet",
    "downloadErrorReport": "Ladda ner felrapporten",
    "tabArchivesLabel": "Archives",
    "exportType_DEFINITIONS": "Définitions",
    "exportType_FULL": "Complet",
    "exportType_HTML": "HTML",
    "exportType_INTO_IMPORTED_EPUB": "ePub",
    "exportType_NARRATION": "Simplifié",
    "exportType_SIMPLE": "Simplifié",

    "tableExportsColumnStatus": "Statut",
    "tableExportsColumnType": "Type",
    "tableExportsColumnProjectName": "Livre",
    "tableExportsColumnAuthor": "Utilisateur",
    "tableExportsColumnCreatedAt": "Démarré à",
    "tableExportsColumnCompletedAt": "Terminé à",
    "tableExportsColumnDuration": "Durée",
    "tableExportsColumnIncludeAudio": "Audio inclus",
    "tableExportsColumnConfig": "Config",
    "tableExportsColumnAction": "Action",
    "tableExportsRetentionInfo": "Les exports réussis restent affichés pour une période de 24h",

    "modalConfirmReSync": "La re-synchronisation audio d'un projet entraîne son blocage durant tout le traitement.\nConfirmez-vous l'action ?",
    "modalConfirmDelete": "Est-ce que vous confirmez la suppression de ce projet ?",
    "overlayReSync": "Projet en cours de re-synchronisation",

    "tabArchivesNoResult": "Pas de livres archivés",

    "proofListeningDataChanged": "Réinitialisation du lecteur audio, dû à un changement de fichier(s) audios",

    "revokeUserAccess": "Révoquer l'accès d'un utilisateur",
    "userEmail": "Email de l'utilisateur",
    "revokeUser": "Révoquer l'utilisateur",
    "filterLabelWith": "Avec",
    "filterLabelWithout": "Sans",
    "proofListening.pageOnlyAccessibleToAdmin": "Cette page n'est accessible qu'aux narrateurs et aux administrateurs car la narration n'est pas terminée.",
    "project.existing.bookRenderer.paraghapLabel.résumé": "Résumé",
    "reload.project": "Reload projet"
}
