import React from "react";
import { ClickableWrapper } from "../../modules/components/clickableWrapper/ClickableWrapper";

type MagnifyingGlassProps = {
  onClick?: () => void;
  className?: string;
};

const MagnifyingGlass = ({ onClick, className = "" }: MagnifyingGlassProps) => {
  return (
    <ClickableWrapper>
      <div className={className} onClick={onClick}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M15.7805 14.9013L12.6622 12.2006C13.9691 10.9176 14.7046 9.1831 14.7091 7.37377C14.7484 5.94572 14.3504 4.53867 13.5662 3.33258C12.782 2.12648 11.6471 1.17615 10.3066 0.603089C8.96617 0.0300312 7.48107 -0.139692 6.04127 0.115609C4.60148 0.37091 3.27241 1.03964 2.22401 2.03628C1.17562 3.03292 0.455552 4.31218 0.15589 5.71047C-0.143772 7.10876 -0.00942868 8.56253 0.541782 9.88587C1.09299 11.2092 2.03601 12.342 3.25022 13.1393C4.46443 13.9367 5.89466 14.3624 7.35801 14.3619C8.89694 14.3645 10.3988 13.9009 11.6564 13.035L14.8914 15.8356C15.0223 15.9484 15.1929 16.007 15.3673 15.9993C15.5417 15.9916 15.7061 15.9181 15.826 15.7942C15.885 15.7336 15.9308 15.662 15.9605 15.5836C15.9903 15.5052 16.0035 15.4217 15.9992 15.3382C15.995 15.2547 15.9734 15.173 15.9358 15.0978C15.8982 15.0227 15.8453 14.9559 15.7805 14.9013V14.9013ZM1.3294 7.37298C1.29759 6.20243 1.6241 5.04921 2.26718 4.06076C2.91026 3.07231 3.84069 2.29352 4.93953 1.82399C6.03836 1.35447 7.25567 1.21554 8.43582 1.42493C9.61597 1.63433 10.7053 2.18256 11.5647 2.99952C12.424 3.81649 13.0142 4.86506 13.2599 6.0112C13.5055 7.15735 13.3955 8.34897 12.9438 9.43374C12.4921 10.5185 11.7192 11.4471 10.7241 12.1009C9.72894 12.7546 8.55669 13.1038 7.35721 13.1037C5.78003 13.1221 4.25978 12.5291 3.1298 11.4548C1.99983 10.3805 1.35236 8.9126 1.3294 7.37298V7.37298Z"
            fill="#353A52"
          />
        </svg>
      </div>
    </ClickableWrapper>
  );
};

export default MagnifyingGlass;
