import { usePageContext } from "../components/EditingRouter/components/PageContentLoader";
import { useMemo } from "react";
import { SubToPages_pages } from "../api/SubToPages";

export const usePageData = (allPages: SubToPages_pages[]) => {
  const { extendedPage } = usePageContext();
  const page = useMemo(() => {
    const foundPage = allPages.find((p) => p.id === extendedPage?.page.data.id);
    if (!foundPage) {
      // This is not a blocking error in some cases
      console.error("Page not found");
      // throw new Error("Page not found");
    }
    return foundPage;
  }, [extendedPage, allPages]);
  return { page, pageMetadata: extendedPage };
};
