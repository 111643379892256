import { cloneDeep } from "lodash";
import { ExportStatus } from "../../../../../../../__generated__/globalTypes";
import { SubscribeToExports_exports } from "../../../../Project/api/__generated__/SubscribeToExports";
import { isMoreThan24h } from "../../../../utils/common";

export const sortByRecent = (exports: SubscribeToExports_exports[]) => {
  const copy = cloneDeep(exports);
  copy.sort((a, b) => (b.createdAt < a.createdAt ? -1 : 1));
  return copy;
};

// Filter less than 24h if DONE (only in global view)
export const retentionFilter = (
  exports: SubscribeToExports_exports[],
  shouldFilterDoneIfOld: boolean,
) => {
  const copy = cloneDeep(exports);
  return !shouldFilterDoneIfOld
    ? copy
    : copy.filter((exp) =>
        exp.exportStatus === ExportStatus.DONE ? !isMoreThan24h(exp.completedAt) : true,
      );
};

export const filteredByProjectId = (exports: SubscribeToExports_exports[], projectId?: string) => {
  const copy = cloneDeep(exports);
  return !projectId ? [...copy] : copy.filter((p) => p.projectId === projectId);
};

export const filterOnSearch = (exports: SubscribeToExports_exports[], search = "") => {
  const copy = cloneDeep(exports);
  return copy.filter((p) => {
    const s = search.toLowerCase().trim();
    if (!s) return true;
    return (
      p.projectName.toLowerCase().includes(s) ||
      p.projectAuthor.toLowerCase().includes(s) ||
      p.exportType.toLowerCase().includes(s) ||
      p.author.toLowerCase().includes(s) ||
      p.filename?.toLowerCase()?.includes(s)
    );
  });
};
