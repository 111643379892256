import { DeleteOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuid4 } from "uuid";
import { useI18n } from "../../../../../../i18n";
import {
  useDeleteParagraphTag,
  useUpdateCustomColorationConfig,
  useUpsertParagraphTag,
} from "../../../api/api";
import { SubToProject_project } from "../../../api/__generated__/SubToProject";
import * as style from "../style.less";

export default function TabProjectConfiguration({ project }: { project: SubToProject_project }) {
  const [t] = useI18n();
  const { customColorationConfig, paragraphTags } = project;

  const [customColors, setCustomColors] = useState(
    Object.entries(customColorationConfig).map(([key, value]) => ({
      key,
      ...(JSON.parse(value as string) as { label: string; color: string }),
    })),
  );

  const [applyUpdateCustomColorationConfig] = useUpdateCustomColorationConfig();
  const debouncedUpdateCustomColorationConfig = useMemo(
    () => debounce(applyUpdateCustomColorationConfig, 500),
    [applyUpdateCustomColorationConfig],
  );

  const [applyUpsertParagraphTag] = useUpsertParagraphTag();
  const [applyDeleteParagraphTag] = useDeleteParagraphTag();

  const [localTags, setLocalTags] = useState(paragraphTags);

  useEffect(() => {
    setLocalTags(paragraphTags.sort((a, b) => a.createdAt - b.createdAt));
  }, [paragraphTags]);
  useEffect(() => {
    debouncedUpdateCustomColorationConfig({
      variables: {
        id: project.id,
        customColorationConfig: customColors.reduce((acc: any, { key, color, label }) => {
          if (color === "" || label === "") return acc;
          acc[key] = { color, label };
          return acc;
        }, {}),
      },
    });
  }, [customColors, debouncedUpdateCustomColorationConfig, project.id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateTag = useCallback(
    debounce((tagId: string, tagName: string) => {
      applyUpsertParagraphTag({
        variables: { tagId, tagName, projectId: project.id },
      });
    }, 1000),
    [project.id],
  );

  const createTag = useCallback(async () => {
    await applyUpsertParagraphTag({
      variables: { tagName: "", projectId: project.id },
    });
  }, [applyUpsertParagraphTag, project.id]);
  const updateTag = useCallback(
    (tagId: string) =>
      ({ target: { value: tagName } }: React.ChangeEvent<HTMLInputElement>) => {
        debouncedUpdateTag(tagId, tagName);
        setLocalTags((tags) =>
          tags.map((tag) => {
            if (tag.id === tagId) return { ...tag, name: tagName };
            return tag;
          }),
        );
      },
    [debouncedUpdateTag],
  );
  const deleteTag = useCallback(
    (tagId: string) => () => {
      applyDeleteParagraphTag({ variables: { tagId } });
    },
    [applyDeleteParagraphTag],
  );

  return (
    <div className={style.config}>
      <h4>{t("project.actionBar.config.title")}</h4>

      {/* CUSTOM COLOR */}

      <h3>{t("project.actionBar.config.customColoration.title")}</h3>
      {customColors.map(({ key, color, label }) => (
        <div className={style.customColorConfig} key={key}>
          <Button
            onClick={() =>
              setCustomColors(
                customColors
                  .map((c) => (c.key === key ? null : c))
                  .filter(
                    (
                      o,
                    ): o is {
                      label: string;
                      color: string;
                      key: string;
                    } => !!o,
                  ),
              )
            }>
            <DeleteOutlined />
          </Button>
          <input
            value={label}
            onChange={(e) => {
              const { value: label } = e.target;
              setCustomColors(
                customColors.map((color) => {
                  if (key === color.key) {
                    return { ...color, label };
                  }
                  return color;
                }),
              );
            }}
          />
          <input
            type="color"
            value={color}
            onChange={(e) => {
              const { value } = e.target;
              setCustomColors(
                customColors.map((color) => {
                  if (key === color.key) {
                    return { ...color, color: value };
                  }
                  return color;
                }),
              );
            }}
          />
        </div>
      ))}
      <Button
        className={style.add}
        onClick={() =>
          setCustomColors(customColors.concat({ key: uuid4(), label: "", color: "#000000" }))
        }>
        {t("project.actionBar.config.customColoration.add")}
      </Button>
      <Divider className={style.divider} />

      {/* PARAGRAPH TAG */}

      <h3>{t("project.actionBar.config.customParagraphTag.title")}</h3>
      {localTags.map((tag) => (
        <div className={style.customColorConfig} key={tag.id}>
          <Button onClick={deleteTag(tag.id)}>
            <DeleteOutlined />
          </Button>
          <input value={tag.name} onChange={updateTag(tag.id)} />
        </div>
      ))}
      <Button className={style.add} onClick={createTag}>
        {t("project.actionBar.config.customParagraphTag.add")}
      </Button>
    </div>
  );
}
