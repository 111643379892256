import { saveToLocal } from "../Tools";
import { KEY_MISC } from "./context";
import { SettingsActionType, SettingsType, StateType } from "../../Types/settings";

export default (state: StateType, { type, payload, log = false }: SettingsActionType) => {
  switch (type) {
    case "settings": {
      const finalState = { ...state };
      finalState.settings = { ...finalState.settings, ...payload } as SettingsType;
      saveToLocal(finalState, KEY_MISC);
      if (log) console.log("New state: ", finalState);
      return finalState;
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
