import React, { ReactElement, useCallback } from "react";
import { Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

const { Dragger } = Upload;

type Props = {
  children: ReactElement | ReactElement[];
  onFileSelected: (file: any) => void;
};

export default function DropZone({ onFileSelected, children }: Props) {
  const onFileDroppedOrSelected = useCallback(
    (e: UploadChangeParam<UploadFile<any>>) => {
      const { file } = e;
      if (file) {
        onFileSelected(file);
      }
    },
    [onFileSelected],
  );

  return (
    <Dragger
      className="dragger"
      name="file"
      multiple={false}
      onChange={onFileDroppedOrSelected}
      // fileList=[] will prevent from displaying the files dropped
      fileList={[]}
      customRequest={() => null}>
      {children}
    </Dragger>
  );
}
