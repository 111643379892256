import React, { useMemo, useEffect } from "react";
import { AutoComplete, Button, Form, Modal, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useI18n } from "../../../../../../../../../i18n";
import { useLinkDialogueToSpeaker, useUnlinkDialogueAndSpeaker } from "../../../../../../api/api";
import _ from "lodash";
import { SubToProject_project_speakers } from "../../../../../../api/__generated__/SubToProject";

type DialoguePictureProps = {
  projectId: string;
  isVisible: boolean;
  paragraphId: string;
  speakerId: string;
  onExit: () => void;
  speakers: SubToProject_project_speakers[];
};

const DialoguePicture = ({
  isVisible,
  projectId,
  paragraphId,
  speakerId,
  onExit,
  speakers,
}: DialoguePictureProps) => {
  const options = speakers.map((s) => ({ value: s.name, key: s.id }));
  const defaultSpeaker = useMemo(
    () => speakers.find((s) => s.id === speakerId),
    [speakers, speakerId],
  );

  const i18nKeyForm = "project.existing.editingMode.formatting.associateToSpeaker";
  const [t] = useI18n();
  const [applyLinkDialogueToSpeaker] = useLinkDialogueToSpeaker();
  const [applyUnlinkDialogueAndSpeaker] = useUnlinkDialogueAndSpeaker();
  const [form] = useForm();
  const key = paragraphId + (defaultSpeaker?.name || "");
  useEffect(() => form.resetFields(), [form, isVisible]);
  const hasError =
    form
      .getFieldsError()
      .map((e) => e.errors)
      .flat().length !== 0;

  return (
    <Modal
      title={t(`${i18nKeyForm}.title`)}
      open={isVisible}
      onCancel={() => {
        onExit();
      }}
      footer={false}>
      {isVisible && (
        <Form
          key={key}
          initialValues={{
            name: defaultSpeaker?.name,
          }}
          form={form}
          onFinish={({ name }: any) => {
            const existingSpeaker = speakers.find((s) => s.name === name);
            if (existingSpeaker) {
              applyLinkDialogueToSpeaker({
                variables: {
                  speakerId: existingSpeaker.id,
                  paragraphId: paragraphId,
                  projectId,
                },
              });
            } else {
              applyLinkDialogueToSpeaker({
                variables: {
                  speakerName: name,
                  paragraphId: paragraphId,
                  projectId,
                },
              });
            }
            onExit();
          }}
          layout="vertical">
          <Form.Item
            style={{ padding: "0 7em" }}
            label={t(`${i18nKeyForm}.name`)}
            normalize={(v) => _.startCase(v.toLowerCase()).replace(/\s/, "")}
            rules={[
              () => ({
                validator: async (_, value) => {
                  if (value.toLowerCase().match(/^\w+$/)) {
                    return;
                  }
                  throw new Error(t(`${i18nKeyForm}.alphaNumError`));
                },
              }),
            ]}
            name="name">
            <AutoComplete options={options} />
          </Form.Item>
          <Form.Item style={{ margin: 0, marginTop: "2em" }}>
            <Row justify="center">
              <Button disabled={hasError} type="ghost" htmlType="submit" shape="round">
                {t("project.existing.editingMode.formatting.default.form.validation")}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      )}
      {defaultSpeaker && (
        <Row justify="center" style={{ paddingTop: "2em" }}>
          <Button
            onClick={() => {
              applyUnlinkDialogueAndSpeaker({ variables: { paragraphId, projectId } });
              onExit();
            }}
            shape="round"
            size="small"
            danger>
            {t(`${i18nKeyForm}.dissociate`)}
          </Button>
        </Row>
      )}
    </Modal>
  );
};

export default DialoguePicture;
