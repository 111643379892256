import React from "react";
import ExportsTable from "../../../../../../components/ExportsTable/ExportsTable";
import useProjectExports from "../useProjectExports/useProjectExports";

type Props = {
  useProjectExportsHook: ReturnType<typeof useProjectExports>;
};

export default function PanelAllExports({ useProjectExportsHook }: Props) {
  return (
    <ExportsTable
      exports={useProjectExportsHook.exports}
      cancel={useProjectExportsHook.cancelExport}
      download={useProjectExportsHook.downloadExport}
      deleteExports={useProjectExportsHook.deleteExports}
      deleteLoading={useProjectExportsHook.deleteLoading}
      deletingExportIds={useProjectExportsHook.deletingExportIds}
    />
  );
}
