import { Dispatch, SetStateAction } from "react";
import { SubToPages, SubToPages_pages } from "../../editor/Project/api/SubToPages";

export const initProjectPage = (
  setProjectPages: Dispatch<SetStateAction<SubToPages | undefined>>,
  projectPages: SubToPages | undefined,
  setCurrentProjectPage: Dispatch<SetStateAction<SubToPages_pages | undefined>>,
  currentProjectPage: SubToPages_pages | undefined,
  dataPages?: SubToPages_pages[],
): void => {
  if (dataPages) {
    if (!currentProjectPage && !projectPages) {
      const pages = dataPages.sort((a, b) => a.pageNumber - b.pageNumber);
      setProjectPages({ pages });
      setCurrentProjectPage(pages[0]);
    }
  } else {
    throw new Error("Ce livre n'a aucune page.");
  }
};
