@import "../../../../../../editor/styles/colors.less";

.content {
  display: flex;
  flex-direction: column;
  row-gap: 1em;

  .sliderContainer {
    padding: 2em 0 2em 0;
  }
  .inputRow {
    margin-bottom: 1em;
    .input {
      width: auto;
      flex: 1;
      &:first-of-type {
        border-radius: 8px 0 0 8px;
      }
      &:last-of-type {
        border-radius: 0 8px 8px 0;
      }
    }
    .durationAndPlayBtn {
      text-align: center;
      .duration {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 5em;
        text-align: center;
        margin: 0.3em 1em 0 1em;
      }
      .listenButton {
        border-radius: 100px;
        border: none;
        padding: 0;
        min-width: auto;
        height: auto;
        width: 1.4em;
        height: 1.4em;
        font-size: 1.8em;
        margin-top: 2px;
        svg {
          margin-top: 4px;
          path {
            fill: @dark-grey;
          }
        }
      }
    }
  }
  .actionBar {
    display: flex;
    justify-content: space-between;
    margin-top: 3em;
  }

  h3 {
    text-align: center;
  }

  .buttonReset {
    margin-left: 0.4em;
    margin-right: 0.4em;
    border-color: @orange;
    color: @orange;
    &:hover {
      opacity: 0.8;
      background-color: @orange;
      color: @white;
    }
  }
}

.extender {
  flex-grow: 1;
}
