import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next, useTranslation } from "react-i18next";
import fr from "./ref/fr.json";
import en from "./translation/en.json";
import sv from "./translation/sv.json";
import it from "./translation/it.json";

export const i18n = i18next.createInstance();
import { useMemo } from "react";

const namespace = "translation";
const fallbackLng = "fr";

export const translate = i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: namespace,
    fallbackLng,
    returnEmptyString: false,
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      sv: { translation: sv },
      it: { translation: it },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export const useI18n = () => {
  const [t] = useTranslation();
  return [t];
};

export const useI18nObjectHook = (path: string) => {
  const [t] = useTranslation();

  const result: any = useMemo(() => t(path, { returnObjects: true }), [path, t]);
  return result;
};

export default i18n;
