@import "../styles/colors.less";

.existingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pageBar {
  button {
    margin: 0 0.4em 0 0.4em;
    background: @white;
    border: 1px solid @light-grey;
    border-radius: 5em;
    padding: 0;
    height: 3.33em;
    width: 3.33em;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 1em;
    }
  }

  button:hover {
    cursor: pointer;
  }

  button:disabled {
    opacity: 0.3;
    &:hover {
      cursor: not-allowed;
    }
  }

  .addingMute {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.3em 5.4em;
    background-color: white;
    width: 58.3em;
    justify-content: space-between;
    line-height: 2.8em;
    & > div > span {
      font-family: "Roboto Slab";
      color: @grey;
      font-weight: 300;
      padding-left: 1em;
    }
  }
}

.fontSize {
  z-index: 800;
  position: absolute;
  right: 8em;
  bottom: 12em;

  span {
    font-size: 1.5em;
  }
}

.goToPage {
  // from A4Page style
  --width: 58.3em;
  --margins: calc(100vw - var(--width));
  --margin: calc(var(--margins) / 2);

  font-size: 1em;
  position: absolute;
  top: 2em;
  right: calc(var(--margin) - 5em);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  padding: 0.8em;
}

.bookIcon {
  background-color: @dark-grey;

  &:hover {
    background-color: @orange;
  }
  &:global(.ant-popover-open) {
    background-color: @orange;
  }
}

.popoverGoTo {
  padding: 0.5em 1em;
  display: flex;
  column-gap: 1em;
  justify-content: center;
  align-items: center;

  @fontSize: 0.9em;

  .popoverGoToSpanCount {
    display: flex;
    flex-shrink: 0;
    margin-left: -7px;
    font-size: @fontSize;
  }

  :global(.ant-input-number) {
    width: 4em;
    border-radius: 2px;
  }
  :global(.ant-input-number-input) {
    height: 1.9em;
    width: 4em;
    font-size: @fontSize;
  }

  :global(.ant-btn) {
    height: 2.1em;
    padding-left: 0.6em;
    padding-right: 0.6em;
    font-size: @fontSize;

    &:hover {
      svg path {
        fill: @orange;
      }
    }
    svg {
      path {
        transition: all 0.5s;
      }
      margin-left: 0.5em;
      font-size: 0.75em;
    }
  }
}
