import React from "react";
import { Redirect } from "react-router-dom";
import OktaSignInWidget from "./SignInWidget";
import { useOktaAuth } from "@okta/okta-react";

type LoginProps = {
  config: { authDomain: string };
};

const Login = ({ config }: LoginProps) => {
  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens: any) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (_err: any) => {
    console.error(_err);
  };

  return authState?.isAuthenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <OktaSignInWidget baseUrl={config.authDomain} onSuccess={onSuccess} onError={onError} />
  );
};
export default Login;
