import React, { useCallback, useState } from "react";
import { Button, Modal } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useI18n } from "../../i18n";
import style from "./ModalComment.style.less";
import classNames from "classnames";

type Props = {
  value?: string | null | undefined;
  readOnly?: boolean;
  modalProps?: {
    onCancel?: () => void;
    onOk?: () => void;
    okText?: string;
  };
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  isModalsOpen?: boolean;
  showModal?: () => void;
  hideModal?: () => void;
  canConfirm?: boolean;
};

export default function ModalComment({
  value,
  readOnly,
  modalProps = {},
  onChange,
  placeholder,
  isModalsOpen,
  showModal,
  hideModal,
  canConfirm,
}: Props) {
  const [t] = useI18n();

  const isControlled = typeof isModalsOpen === "boolean";

  const [_isOpen, setIsOpen] = useState(false);

  const _showModal = useCallback(() => {
    if (isControlled && showModal) {
      showModal();
    } else {
      setIsOpen(true), [];
    }
  }, [isControlled, showModal]);

  const _hideModal = useCallback(() => {
    if (isControlled && hideModal) {
      hideModal();
    } else {
      setIsOpen(false), [];
    }
  }, [hideModal, isControlled]);

  if (!value && readOnly) return null;

  return (
    <>
      <Button shape="circle" type="ghost" onClick={_showModal}>
        <CommentOutlined />
      </Button>
      <Modal
        title={t("audioReview.commentName")}
        open={isControlled ? isModalsOpen : _isOpen}
        footer={
          readOnly ? (
            false
          ) : (
            <>
              <Button onClick={modalProps.onCancel || hideModal} shape="round">
                {t("cancel")}
              </Button>
              <Button onClick={modalProps.onOk} shape="round" type="primary" disabled={!canConfirm}>
                {t("audioReview.comment")}
              </Button>
            </>
          )
        }
        onCancel={_hideModal}
        okButtonProps={{
          autoFocus: false,
        }}
        className={classNames(style.root, { [style.readOnly]: !!readOnly })}
        destroyOnClose
        {...modalProps}>
        <TextArea
          value={value as string}
          placeholder={placeholder}
          onChange={onChange}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!readOnly}
          readOnly={readOnly}
          onFocus={(e) => {
            // Hack that allows to be at the end of the content when auto-focusing the input
            const { value } = e.target;
            e.target.value = "";
            e.target.value = value;
          }}
          autoSize={readOnly}
        />
      </Modal>
    </>
  );
}
