import { gql, useSubscription } from "@apollo/client";
import {
  SubToProjectForHomepage,
  SubToProjectForHomepageVariables,
} from "./__generated__/SubToProjectForHomepage";

export const useSubToLibrary = (variables: SubToProjectForHomepageVariables) =>
  useSubscription<SubToProjectForHomepage, SubToProjectForHomepageVariables>(
    gql`
      subscription SubToProjectForHomepage($archivedProjects: Boolean) {
        projects(archivedProjects: $archivedProjects) {
          id
          name
          author
          kind
          createdAt
          EAN: ean
          userBasedAccess
          importStatus
          kind
          config {
            id
            narrationWordsCountLimit
            locale
          }
          status
          preparationProgress
          isNarrationComplete
          isDoingAudioResync
          customColorationConfig
          roles {
            id
            userRole
            userTask
            userId
            projectId
          }
        }
      }
    `,
    {
      variables,
    },
  );
