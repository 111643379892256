import React from "react";

const LogoFrogEditor = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="117.954"
      height="11.364"
      viewBox="0 0 117.954 11.364"
    >
      <defs>{/* <style>.a{isolation:isolate;}.b{fill:#fff;}</style> */}</defs>
      <g transform="translate(0 0)">
        <g className="a" transform="translate(17.718 0.004)">
          <g className="a">
            <path
              className="b"
              d="M-260.8,427.094V425.85l1.191-.228v-8.092l-1.191-.228v-1.251h8.69v2.942H-253.7l-.114-1.349h-3.731v3.208h3.905v1.592h-3.905v3.178l1.191.228v1.243Z"
              transform="translate(260.799 -415.893)"
            />
            <path
              className="b"
              d="M-249.176,427.094V425.85l1.191-.228v-8.092l-1.191-.228v-1.251H-244a5.434,5.434,0,0,1,2.15.387,3,3,0,0,1,1.372,1.115,3.124,3.124,0,0,1,.475,1.745,3.137,3.137,0,0,1-.47,1.714,3.21,3.21,0,0,1-1.316,1.165,4.431,4.431,0,0,1-1.96.428l-2.169.008v3.01l1.191.228v1.243Zm3.253-6.074h1.851a2.3,2.3,0,0,0,1.517-.42,1.526,1.526,0,0,0,.493-1.225,1.671,1.671,0,0,0-.486-1.259,2.017,2.017,0,0,0-1.456-.47h-1.919Zm3.891,6.074-1.987-4.929,1.9-.387,1.7,3.86.9.2v1.259Z"
              transform="translate(259.205 -415.893)"
            />
            <path
              className="b"
              d="M-231.373,427.228a4.762,4.762,0,0,1-2.01-.414,4.457,4.457,0,0,1-1.551-1.167,5.334,5.334,0,0,1-1-1.775,6.869,6.869,0,0,1-.349-2.241v-.167a6.859,6.859,0,0,1,.349-2.234,5.368,5.368,0,0,1,1-1.775,4.535,4.535,0,0,1,1.547-1.171,4.709,4.709,0,0,1,2.005-.417,4.818,4.818,0,0,1,2.036.417,4.552,4.552,0,0,1,1.558,1.171,5.264,5.264,0,0,1,1,1.775,6.93,6.93,0,0,1,.345,2.234v.167a6.939,6.939,0,0,1-.345,2.241,5.266,5.266,0,0,1-.993,1.775,4.439,4.439,0,0,1-1.558,1.167A4.853,4.853,0,0,1-231.373,427.228Zm0-1.623a2.543,2.543,0,0,0,1.608-.5,2.959,2.959,0,0,0,.952-1.393,6.286,6.286,0,0,0,.314-2.085v-.182a6.1,6.1,0,0,0-.318-2.062,2.991,2.991,0,0,0-.959-1.388,2.529,2.529,0,0,0-1.6-.5,2.459,2.459,0,0,0-1.57.5,2.978,2.978,0,0,0-.944,1.384,6.193,6.193,0,0,0-.315,2.07v.182a6.3,6.3,0,0,0,.315,2.082,2.967,2.967,0,0,0,.947,1.4A2.478,2.478,0,0,0-231.373,425.605Z"
              transform="translate(257.436 -415.868)"
            />
            <path
              className="b"
              d="M-217.927,427.228a5.31,5.31,0,0,1-2.776-.69,4.583,4.583,0,0,1-1.767-1.911,6.237,6.237,0,0,1-.613-2.836v-.334a6.563,6.563,0,0,1,.613-2.916,4.766,4.766,0,0,1,1.726-1.965,4.757,4.757,0,0,1,2.613-.708,7.389,7.389,0,0,1,2.225.318,5.143,5.143,0,0,1,1.764.94v2.4H-215.7l-.28-1.509a2.34,2.34,0,0,0-.819-.4,4.266,4.266,0,0,0-1.176-.148,2.7,2.7,0,0,0-1.615.5,3.19,3.19,0,0,0-1.066,1.392,5.344,5.344,0,0,0-.375,2.079v.349a5.124,5.124,0,0,0,.368,2.029,2.966,2.966,0,0,0,1.069,1.335,2.991,2.991,0,0,0,1.718.474,5.021,5.021,0,0,0,1.118-.114,3.719,3.719,0,0,0,.83-.28v-2.025l-1.691-.113v-1.464h3.762v4.429a5.9,5.9,0,0,1-1.688.839A7.7,7.7,0,0,1-217.927,427.228Z"
              transform="translate(255.626 -415.868)"
            />
          </g>
          <g className="a" transform="translate(43.588)">
            <path
              className="b"
              d="M-210.282,427.094v-.66l1.236-.1v-9.517l-1.236-.1v-.668h7.933v2.419h-.774l-.076-1.669h-4.936v4.179h4.451v.758h-4.451v4.619h5.172l.076-1.684h.765v2.419Z"
              transform="translate(210.282 -415.893)"
            />
            <path
              className="b"
              d="M-198.448,427.094v-.66l1.236-.1v-9.517l-1.236-.1v-.668h4.269a4.424,4.424,0,0,1,2.48.7,4.836,4.836,0,0,1,1.68,1.926,6.21,6.21,0,0,1,.61,2.8v.182a6.272,6.272,0,0,1-.6,2.794,4.772,4.772,0,0,1-1.664,1.931,4.368,4.368,0,0,1-2.469.7Zm2.146-.758h2.123a3.37,3.37,0,0,0,2.017-.619,4.148,4.148,0,0,0,1.354-1.675,5.6,5.6,0,0,0,.489-2.378v-.2a5.581,5.581,0,0,0-.489-2.385,4.076,4.076,0,0,0-1.354-1.658,3.421,3.421,0,0,0-2.017-.607H-196.3Z"
              transform="translate(208.659 -415.893)"
            />
            <path
              className="b"
              d="M-185.868,427.094v-.66l1.236-.1v-9.517l-1.236-.1v-.668h3.382v.668l-1.236.1v9.517l1.236.1v.66Z"
              transform="translate(206.933 -415.893)"
            />
            <path
              className="b"
              d="M-177.294,427.094v-.66l1.236-.1V416.8h-3.1l-.1,1.683H-180v-2.427h8.79v2.427h-.736l-.106-1.683h-3.094v9.539l1.236.1v.66Z"
              transform="translate(206.129 -415.893)"
            />
            <path
              className="b"
              d="M-163.233,427.228a4.383,4.383,0,0,1-1.9-.4,4.155,4.155,0,0,1-1.456-1.145,5.3,5.3,0,0,1-.933-1.771,7.538,7.538,0,0,1-.326-2.286v-.144a7.618,7.618,0,0,1,.322-2.286,5.285,5.285,0,0,1,.929-1.774,4.138,4.138,0,0,1,1.456-1.15,4.387,4.387,0,0,1,1.9-.4,4.463,4.463,0,0,1,1.922.4,4.092,4.092,0,0,1,1.456,1.15,5.219,5.219,0,0,1,.918,1.774,7.792,7.792,0,0,1,.314,2.286v.144a7.8,7.8,0,0,1-.314,2.286,5.218,5.218,0,0,1-.914,1.771,4.082,4.082,0,0,1-1.452,1.145A4.467,4.467,0,0,1-163.233,427.228Zm0-.8a3.348,3.348,0,0,0,2.022-.6,3.683,3.683,0,0,0,1.248-1.684,6.9,6.9,0,0,0,.425-2.525v-.16a6.787,6.787,0,0,0-.429-2.518,3.716,3.716,0,0,0-1.255-1.679,3.324,3.324,0,0,0-2.017-.6,3.246,3.246,0,0,0-1.972.6,3.808,3.808,0,0,0-1.271,1.683,6.593,6.593,0,0,0-.443,2.513v.16a6.647,6.647,0,0,0,.443,2.525,3.779,3.779,0,0,0,1.274,1.684A3.292,3.292,0,0,0-163.233,426.432Z"
              transform="translate(204.461 -415.868)"
            />
            <path
              className="b"
              d="M-155.025,427.1v-.668l1.236-.1v-9.517l-1.236-.1v-.66h4.656a5.113,5.113,0,0,1,2,.349,2.682,2.682,0,0,1,1.248,1.016,2.986,2.986,0,0,1,.429,1.645,2.784,2.784,0,0,1-.444,1.6,2.753,2.753,0,0,1-1.244.99,4.891,4.891,0,0,1-1.877.345l-2.624.022v4.307l1.236.1v.668Zm2.146-5.832h2.419a3.228,3.228,0,0,0,2.168-.607,2.028,2.028,0,0,0,.69-1.6,2.06,2.06,0,0,0-.69-1.658,3.14,3.14,0,0,0-2.078-.588h-2.51Zm5.278,5.832-2.374-5.483.91-.007,2.108,4.754.9.061v.675Z"
              transform="translate(202.703 -415.894)"
            />
          </g>
        </g>
        <path
          className="b"
          d="M-276.249,417.946a2.543,2.543,0,0,1-.077-1.313,1.231,1.231,0,0,1,1.589-.684,1.32,1.32,0,0,1,.806,1.553c-.232.761-.563,1.5-.739,2.274a3.37,3.37,0,0,0,.086,1.4.944.944,0,0,0,1.072.8l.01,0a1.43,1.43,0,0,0,1.181-1.192,6.522,6.522,0,0,1,.242-1.324,1.189,1.189,0,0,1,1.368-.751,1.327,1.327,0,0,1,1.071,1.035,1.282,1.282,0,0,1-.6,1.313,1.536,1.536,0,0,1-.486.242c-.972.188-1.28.928-1.512,1.744a25.713,25.713,0,0,1-.863,2.761c-.574,1.4-1.391,1.666-2.748.993a12.131,12.131,0,0,1-1.336-.751,1.772,1.772,0,0,0-1.953-.143,1.475,1.475,0,0,1-1.467.122,1.338,1.338,0,0,1-.6-1.765,1.252,1.252,0,0,1,1.672-.582l.016.008c.353.173.64.486.993.673a1.076,1.076,0,0,0,.783.187.963.963,0,0,0,.386-.707,2.21,2.21,0,0,0-1.8-2.45c-.585-.188-1.136-.419-1.3-1.115a1.271,1.271,0,0,1,.875-1.569,1.271,1.271,0,0,1,1.455.6c.166.3.21.662.353.971a6.828,6.828,0,0,0,.717,1.381.938.938,0,0,0,.806.276c.2-.055.375-.419.442-.673a5.34,5.34,0,0,0,.078-1.035A11.527,11.527,0,0,0-276.249,417.946Z"
          transform="translate(281.333 -415.864)"
        />
      </g>
    </svg>
  );
};

export default LogoFrogEditor;
