import React, { useMemo, useState } from "react";
import { Modal, notification } from "antd";
import { RouteComponentProps } from "react-router";
import { useDeleteProject, useExportProject, useLaunchProjectReSync } from "../../../Project/api/api";
import { ExportType } from "../../../../../../__generated__/globalTypes";
import { buildColorationConfigFromArrayOfString } from "../../../utils/common";
import { useI18n } from "../../../../../i18n";
import { ExclamationCircleOutlined } from "@ant-design/icons";

type Params = {
  history: RouteComponentProps["history"];
  setModalExport: (params: { projectId: string; name: string; isEPubExport: boolean }) => void;
  setModalEdit: (id: string) => void;
  exportNarrationI18nKey: string;
};

export type ProjectMethods = ReturnType<typeof useProjectMethods>["projectMethods"];

export default function useProjectMethods({
  history,
  setModalExport,
  setModalEdit,
  exportNarrationI18nKey,
}: Params) {
  const [t] = useI18n();
  const [applyDeleteProject] = useDeleteProject();
  const [exportProject] = useExportProject();
  const [launchProjectReSync] = useLaunchProjectReSync();

  const [isInvitingToProject, setIsInvitingToProject] = useState("");
  const [isRevokingToProject, setIsRevokingToProject] = useState("");
  const [exportsProjectId, setExportsProjectId] = useState("");

  const projectMethods = useMemo(
    () => ({
      open: (projectId: { toString: () => string }) =>
        history.push("/editor/books/edit/" + projectId),
      delete: async (projectId: string) => {
        Modal.confirm({
          title: t("warning"),
          icon: <ExclamationCircleOutlined />,
          content: t("modalConfirmDelete"),
          okText: t("validate"),
          cancelText: t("cancel"),
          onOk: () => applyDeleteProject({ variables: { id: projectId } }),
          okButtonProps: {
            danger: true,
          },
        });
      },
      invite: setIsInvitingToProject,
      revoke: setIsRevokingToProject,
      edit: setModalEdit,
      export: (projectId: string, name: string, isEPubExport = false) =>
        setModalExport({ projectId, name, isEPubExport }),
      exportNarration: async (projectId: string) => {
        try {
          notification.info({
            message: t(`${exportNarrationI18nKey}.message`),
          });

          await exportProject({
            variables: {
              projectId,
              exportType: ExportType.NARRATION,
              exportConfig: {
                coloration: buildColorationConfigFromArrayOfString([]),
                includeAudio: true,
              },
            },
          });
        } catch (e) {
          notification.error({
            message: t(`${exportNarrationI18nKey}.errorNotification`),
          });
        }
      },
      viewExports: (projectId: string) => {
        setExportsProjectId(projectId);
      },
      resync: (projectId: string) => {
        Modal.confirm({
          title: t("warning"),
          icon: <ExclamationCircleOutlined />,
          content: t("modalConfirmReSync"),
          okText: t("validate"),
          cancelText: t("cancel"),
          onOk: () =>
            launchProjectReSync({
              variables: {
                projectId,
              },
            }),
          okButtonProps: {
            danger: true,
          },
        });
      },
    }),
    [
      setModalEdit,
      history,
      applyDeleteProject,
      setModalExport,
      t,
      exportNarrationI18nKey,
      exportProject,
      launchProjectReSync,
    ],
  );

  return {
    projectMethods,
    isInvitingToProject,
    isRevokingToProject,
    setIsRevokingToProject,
    setIsInvitingToProject,
    exportsProjectId,
    setExportsProjectId,
  };
}
