@import "../../../../app/editor/styles/colors.less";

.buttonContainer {
  display: flex;
  padding: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  box-shadow: inset 0px -1px 0px rgba(91, 96, 121, 0.12);
}

.buttonPrimary {
  color: @darkest-grey;
  &:hover {
    color: @orange;
  }
}

.buttonAlert {
  color: @red;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
