import { StateType } from "../Types/settings";

export const getFromLocalOrDefault = (state: StateType, key: string) => {
  const item = localStorage.getItem(key);
  // console.log('Getting from local: ', state, key, item);
  return item ? JSON.parse(item) : state;
};
export const saveToLocal = (state: StateType, key: string) => {
  // console.log('Saving to local: ', state, key);
  localStorage.setItem(key, JSON.stringify(state));
};
