@import "../../../../styles/colors.less";

.root :global {
  #preview *::selection {
    background: @blue;
    color: @white;
  }

  .rhese:hover {
    background: fade(@blue, 30%);
  }
}

.root.syllables :global {
  .rhese {
    padding: 0.12em 0;
    .letter {
      position: relative;
    }
    .pair {
      color: @syllable-blue;
    }
    .impair {
      color: @red;
    }
    .muet {
      color: @grey;
    }
  }
}

.root.phonemes :global {
  .rhese {
    padding: 0.12em 0;
    .letter {
      position: relative;
    }
    .consonant {
      color: @phoneme-consonant;
    }
    .vowel {
      color: @phoneme-vowel;
    }
    .complicated {
      color: @phoneme-complicated;
    }
    .muet {
      color: @grey;
    }
  }
}

.mode {
  position: fixed;
  bottom: 10em;
  left: 10em;
}

:global(.clorationMode) {
  span {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
