import { INITIAL_SPEED } from "./config";

export const initialState = {
  speed: INITIAL_SPEED,
};

type SpeedState = typeof initialState;

export default function reducer(_state: SpeedState, action: any): SpeedState {
  switch (action.type) {
    case "SPEED_CHANGED":
      return {
        speed: action.value,
      };
    default:
      throw new Error(`Action ${action.type} not handled`);
  }
}
