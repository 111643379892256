import { gql, useMutation } from "@apollo/client";
import {
  SetCaseFromPosition,
  SetCaseFromPositionVariables,
} from "./__generated__/SetCaseFromPosition";
import {
  UnsetCaseFromPosition,
  UnsetCaseFromPositionVariables,
} from "./__generated__/UnsetCaseFromPosition";

export const useSetCaseFromPosition = () =>
  useMutation<SetCaseFromPosition, SetCaseFromPositionVariables>(
    gql`
      mutation SetCaseFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
        $kind: ExtendedTextCase!
      ) {
        setCaseFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
          kind: $kind
        )
      }
    `,
  );

export const useUnsetCaseFromPosition = () =>
  useMutation<UnsetCaseFromPosition, UnsetCaseFromPositionVariables>(
    gql`
      mutation UnsetCaseFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
        $kind: ExtendedTextCase!
      ) {
        unsetCaseFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
          kind: $kind
        )
      }
    `,
  );
