import { gql, useMutation } from "@apollo/client";
import {
  CreateRheseFromPosition,
  CreateRheseFromPositionVariables,
} from "./__generated__/CreateRheseFromPosition";
import {
  UpdateExtendedRhese,
  UpdateExtendedRheseVariables,
} from "./__generated__/UpdateExtendedRhese";

export const useCreateRheseFromPosition = () =>
  useMutation<CreateRheseFromPosition, CreateRheseFromPositionVariables>(
    gql`
      mutation CreateRheseFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
        $lineBreakAfter: Boolean!
        $ignoreTextForAudioSync: Boolean!
      ) {
        createRheseFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
          lineBreakAfter: $lineBreakAfter
          ignoreTextForAudioSync: $ignoreTextForAudioSync
        )
      }
    `,
  );

export const useUpdateRhese = () =>
  useMutation<UpdateExtendedRhese, UpdateExtendedRheseVariables>(
    gql`
      mutation UpdateExtendedRhese(
        $projectId: UUID!
        $rheseId: UUID!
        $lineBreakAfter: Boolean!
        $ignoreTextForAudioSync: Boolean!
      ) {
        updateExtendedRhese(
          projectId: $projectId
          rheseId: $rheseId
          lineBreakAfter: $lineBreakAfter
          ignoreTextForAudioSync: $ignoreTextForAudioSync
        )
      }
    `,
  );
