import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import deepEqual from "fast-deep-equal/es6";
// import { usePrevious } from "./usePrevious"

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function usePersistedReducer<S, A>(
  reducer: (state: S, action: A) => S,
  initialState: S,
  storageKey: string,
) {
  const init = useCallback(() => {
    const stringState = localStorage.getItem(storageKey);
    if (stringState) {
      try {
        return JSON.parse(stringState);
      } catch (error) {
        return initialState;
      }
    } else {
      return initialState;
    }
  }, [initialState, storageKey]);

  const [state, dispatch] = useReducer(reducer, initialState, init);
  const prevState = usePrevious(state);

  useEffect(() => {
    const stateEqual = deepEqual(prevState, state);
    if (!stateEqual) {
      const stringifiedState = JSON.stringify(state);
      localStorage.setItem(storageKey, stringifiedState);
    }
  }, [prevState, state, storageKey]);

  const store = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return store;
}
