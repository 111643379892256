@import "../styles/colors.less";
@import "../styles/fonts.less";

.user {
  position: relative;
  border-radius: 50%;
  height: 3em;
  width: 3em;
  display: flex;
  justify-content: center;
  align-items: center;

  .email {
    position: absolute;
    background-color: @light-grey;
    padding: 0.5em;
    border-radius: 4px;
    bottom: 0em;
    color: @darkest-grey;
  }
}

.userTable {
  padding-bottom: 1em;
  row-gap: 1em;
  display: flex;
  flex-direction: column;
  width: max-content;

  table {
    border-collapse: separate;
    border-spacing: 0 0.5em;

    th,
    td {
      padding: 0 1em 0 0;
    }
  }
}
