import { useCallback, useState } from "react";
import { FrogRoles, useSelectedRole } from "../../../../../identity";
import { SubToPages_pages } from "../../../../editor/Project/api/SubToPages";
import { FilterGroupOptions, FilterGroupState } from "./FilterGroup";
import { PageAudioInfo } from "./BookPanel";

// Each filter can have 3 states: off, with, without
// off: no filter
// with: only pages with audio
// without: only pages without audio

export default function useFilterOnAudio(groupedRhesesAudioInfo: PageAudioInfo) {
  const { state: selectedRole } = useSelectedRole();
  const [state, setState] = useState(FilterGroupState.off);

  const toggle = useCallback((option: FilterGroupOptions) => {
    setState((prevValue) => {
      // If click on the with option
      if (option === FilterGroupOptions.with) {
        return prevValue === FilterGroupState.with ? FilterGroupState.off : FilterGroupState.with;
      }
      // If click on the without option
      return prevValue === FilterGroupState.without
        ? FilterGroupState.off
        : FilterGroupState.without;
    });
  }, []);

  const applyField = useCallback(
    (pages: SubToPages_pages[]) => {
      return pages.map((page) => {
        const audioIncomplete = groupedRhesesAudioInfo[page.id]?.find(
          (group) => group.withAudio === false,
        );

        return {
          ...page,
          fullyRecorded: !((audioIncomplete?.count || 0) > 0),
        };
      });
    },
    [groupedRhesesAudioInfo],
  );

  const filter = useCallback(
    (pages: SubToPages_pages[]) => {
      if (state === FilterGroupState.off) {
        return pages;
      }
      return pages.filter((page) => {
        const audioIncomplete = groupedRhesesAudioInfo[page.id]?.find(
          (group) => group.withAudio === false,
        );

        if (state === FilterGroupState.without) {
          return (audioIncomplete?.count || 0) > 0;
        }
        if (state === FilterGroupState.with) {
          return audioIncomplete?.count === 0;
        }
        return true;
      });
    },
    [groupedRhesesAudioInfo, state],
  );

  return {
    state,
    toggle,
    show: selectedRole !== FrogRoles.Editor,
    filter,
    applyField,
  };
}
