import React from "react";
import { ClickableWrapper } from "../../../components/clickableWrapper/ClickableWrapper";

type BackArrowProps = {
  onClick: <T>(value: React.SetStateAction<T>) => void;
};

const BackArrow = ({ onClick }: BackArrowProps) => {
  return (
    <ClickableWrapper>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => onClick(undefined)}>
        <path
          d="M20.0156 11.0156V12.9844H8.01562L13.5 18.5156L12.0938 19.9219L4.17188 12L12.0938 4.07812L13.5 5.48438L8.01562 11.0156H20.0156Z"
          fill="white"
        />
      </svg>
    </ClickableWrapper>
  );
};

export default BackArrow;
