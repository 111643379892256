import React, { useState, useRef, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Layout, Tabs, Spin, notification, Popover, Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import NewProjectModal from "../../Project/components/New";
import EditProjectModal from "../../Project/components/Edit";
import * as style from "../style.less";
import { useIsContentAdministrator } from "../../../../identity";
import Invite from "../Invite";
import { useI18n } from "../../../../i18n";
import ExportModal from "./exports/ExportModal/ExportModal";
import { useUserPanel, UserPanel } from "../../../../identity/User/Panel";
import ProjectCard from "./ProjectCard/ProjectCard";
import SearchBar from "./SearchBar";
import NewProject from "./ProjectCard/NewProjectCard";
import PanelAllExports from "./exports/PanelAllExports/PanelAllExports";
import PanelProjectExports from "./exports/PanelProjectExports/PanelProjectExports";
import useProjectExports from "./exports/useProjectExports/useProjectExports";
import ExportsPopover from "./exports/ExportsPopover/ExportsPopover";
import useProjectsData from "./hooks/useProjectsData";
import useProjectMethods from "./hooks/useProjectMethods";
import Header from "../../../../components/Header/Header";
import { getTabItem, TabItems } from "../../styles/antDesignTheme";
import Revoke from "../Revoke";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type Props = {} & RouteComponentProps;

const i18nKey = "library.index";

const ProjectsPage = ({ history }: Props) => {
  // i18n
  const [t] = useI18n();

  const defaultI18nKey = `${i18nKey}.default`;
  const exportNarrationI18nKey = `${defaultI18nKey}.exportNarration`;
  const projectI18nKey = `${defaultI18nKey}.project`;
  const navBarI18nKey = `${defaultI18nKey}.navBar`;
  const errorMessage = t(`${navBarI18nKey}.errorMessage`);

  // Misc
  const [filterString, setFilterString] = useState("");
  const { isUserPanelOpened, openPanel, closePanel } = useUserPanel();
  const ref = useRef<HTMLDivElement>(null);

  // Modal logic
  const [modalNew, setModalNew] = useState(false);
  const [modalEdit, setModalEdit] = useState("");
  const [modalExport, setModalExport] = useState<null | {
    projectId: string;
    name: string;
    isEPubExport: boolean;
  }>(null);

  // Data hook
  const { projects, loading, error } = useProjectsData({ filterString });
  const {
    projects: archivedProjects,
    loading: archivedLoading,
    error: archivedError,
  } = useProjectsData({ filterString, archivedProjects: true });

  // Project methods hook
  const {
    projectMethods,
    isInvitingToProject,
    setIsInvitingToProject,
    setIsRevokingToProject,
    isRevokingToProject,
    exportsProjectId,
    setExportsProjectId,
  } = useProjectMethods({
    history,
    setModalExport,
    setModalEdit,
    exportNarrationI18nKey,
  });

  // Project exports hook
  const useProjectExportsHook = useProjectExports(undefined, filterString);
  const { processing: exportsProcessing } = useProjectExportsHook;

  // Role hook
  const isProjectAdministrator = useIsContentAdministrator();

  const tabItems: TabItems = useMemo(() => {
    return [
      getTabItem({
        key: "1",
        label: t(`${navBarI18nKey}.tabTitle`),
        children: (
          <>
            {loading ? (
              <Spin indicator={antIcon} />
            ) : error ? (
              errorMessage
            ) : (
              <div className={style.projects}>
                {(isProjectAdministrator
                  ? [<NewProject key={"new project"} i18nKey={i18nKey} onClick={setModalNew} />]
                  : []
                ).concat(
                  projects.map((p) => (
                    <ProjectCard key={p.id} project={p} projectMethods={projectMethods} />
                  )),
                )}
              </div>
            )}
          </>
        ),
      }),
      ...(isProjectAdministrator
        ? [
            getTabItem({
              key: "2",
              label: (
                <span>
                  <span>{t(`${exportNarrationI18nKey}.exportsTabLabel`)}</span>
                  {!!exportsProcessing.count && (
                    <Popover
                      destroyTooltipOnHide
                      placement="bottom"
                      content={
                        <>
                          {exportsProcessing.exports.map((exp) => (
                            <ExportsPopover key={exp.id} _export={exp} />
                          ))}
                        </>
                      }>
                      <span className={style.exportCountInPopover}>
                        {" "}
                        ({exportsProcessing.count})
                      </span>
                    </Popover>
                  )}
                </span>
              ),
              children: loading ? (
                <Spin indicator={antIcon} />
              ) : error ? (
                errorMessage
              ) : (
                <PanelAllExports useProjectExportsHook={useProjectExportsHook} />
              ),
            }),
            getTabItem({
              key: "3",
              label: t("tabArchivesLabel"),
              children: archivedLoading ? (
                <Spin indicator={antIcon} />
              ) : error ? (
                archivedError?.message
              ) : (
                <>
                  {!archivedProjects?.length && <Empty description={t("tabArchivesNoResult")} />}
                  <div className={style.projects}>
                    {archivedProjects.map((p) => (
                      <ProjectCard key={p.id} project={p} projectMethods={projectMethods} />
                    ))}
                  </div>
                </>
              ),
            }),
          ]
        : []),
    ];
  }, [
    archivedError?.message,
    archivedLoading,
    archivedProjects,
    error,
    errorMessage,
    exportNarrationI18nKey,
    exportsProcessing.count,
    exportsProcessing.exports,
    isProjectAdministrator,
    loading,
    navBarI18nKey,
    projectMethods,
    projects,
    t,
    useProjectExportsHook,
  ]);

  return (
    <>
      <Invite projectId={isInvitingToProject} close={() => setIsInvitingToProject("")} />
      <Revoke projectId={isRevokingToProject} close={() => setIsRevokingToProject("")} />
      <ExportModal
        name={modalExport?.name}
        id={modalExport?.projectId}
        isEPubExport={modalExport?.isEPubExport}
        onExit={() => setModalExport(null)}
      />
      {modalNew ? (
        <NewProjectModal
          onSuccess={() => {
            notification.info({
              message: t(`${projectI18nKey}.successMessage`),
            });
            setModalNew(false);
          }}
          onError={(val: unknown) => {
            if (val instanceof Error) {
              const jsonBlob = new Blob([val.message], {
                type: "application/json",
              });

              const url = URL.createObjectURL(jsonBlob);

              notification.error({
                message: t(`${projectI18nKey}.errorMessage`),
                description: <a href={url}>{t("downloadErrorReport")}</a>,
              });
            }

            setModalNew(false);
          }}
          container={ref.current || undefined}
          setIsOpen={setModalNew}
        />
      ) : (
        <></>
      )}
      {modalEdit !== "" && (
        <EditProjectModal
          projectId={modalEdit}
          validate={() => setModalEdit("")}
          cancel={() => setModalEdit("")}
          reference={ref}
        />
      )}
      <Layout className={style.root}>
        <Header openPanel={openPanel} />
        <Layout.Content className={style.content}>
          <Tabs
            tabBarExtraContent={{
              left: <h1 className={style.libraryTitle}>{t(`${navBarI18nKey}.title`)}</h1>,
              right: <SearchBar onChange={setFilterString} />,
            }}
            className={style.tabs}
            defaultActiveKey="1"
            onChange={() => {}}
            items={tabItems}
          />
        </Layout.Content>
      </Layout>
      <UserPanel closePanel={closePanel} isUserPanelOpened={isUserPanelOpened} />
      <PanelProjectExports
        projectId={exportsProjectId}
        open={!!exportsProjectId}
        onDismiss={() => setExportsProjectId("")}
      />
    </>
  );
};
export default ProjectsPage;
