import React, { useCallback, useMemo } from "react";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Spinner } from "../../../../../../components/Spinner";
import PlayIcon from "../assets/PlayIcon";
import PauseIcon from "../assets/PauseIcon";
import * as style from "../style.less";
import useAudioPlayerMachine from "./audioPlayerMachine/useAudioPlayerMachine";
import { useSpeedControlsStore } from "../SpeedControls/context";
import useSpeed from "./useSpeed";
import SpeedControls from "../SpeedControls/SpeedControls";

type PageNavigation = {
  canNavigate: boolean;
  navigate: () => void;
};

type AudioPlayerProps = {
  previousPage: PageNavigation;
  nextPage: PageNavigation;
  audioElement: HTMLAudioElement;
};

export default function AudioPlayer({ previousPage, nextPage, audioElement }: AudioPlayerProps) {
  const audioPlayerMachine = useAudioPlayerMachine();
  const {
    send,
    togglePlay,
    isLoadingRessources: isLoading,
    isPaused: isMachinePaused,
    isInitializing,
  } = audioPlayerMachine;

  // Speed
  const {
    state: { speed },
  } = useSpeedControlsStore();

  const handleMachineRateChange = useCallback(() => {
    send({ type: "Handle Playback Rate Change" });
  }, [send]);

  useSpeed(audioElement, speed, handleMachineRateChange);

  // Button comp
  const buttonContent = useMemo(() => {
    if (isLoading) {
      return <Spinner />;
    }
    return isMachinePaused === false ? <PauseIcon /> : <PlayIcon />;
  }, [isLoading, isMachinePaused]);

  if (isInitializing) {
    return null;
  }

  return (
    <div className={style.audioPlayer}>
      <div className={style.speedControlsContainer}>
        <SpeedControls className={style.speedControls} />
      </div>
      <div className={style.buttonsRow}>
        <Button
          disabled={!previousPage.canNavigate}
          onClick={previousPage.navigate}
          type="primary"
          shape="circle"
          icon={<LeftOutlined />}
          className={style.pageNav}
        />
        <Button disabled={isLoading} shape="round" className={style.playPause} onClick={togglePlay}>
          {buttonContent}
        </Button>
        <Button
          disabled={!nextPage.canNavigate}
          onClick={nextPage.navigate}
          type="primary"
          shape="circle"
          icon={<RightOutlined />}
          className={style.pageNav}
        />
      </div>
    </div>
  );
}
