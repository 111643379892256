import React from "react";
import classNames from "classnames";
import * as style from "./BadgeStatus.style.less";
import { useI18n } from "../../../../../i18n";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  bookStatusLabel: string;
  className?: string;
  small?: boolean;
};

export default function BadgeStatus({ className, bookStatusLabel, small }: Props) {
  const [t] = useI18n();
  return (
    <div
      className={classNames(style.badgeStatus, className, style[`${bookStatusLabel}`], {
        [style.small]: small,
      })}>
      {bookStatusLabel === "RESYNC" ? (
        <LoadingOutlined />
      ) : (
        <div className={classNames(style.indicator, style[`${bookStatusLabel}`])} />
      )}
      <div className={style.text}>
        {t("project.actionBar.progress.bookStateStatus." + `${bookStatusLabel.toLowerCase()}`)}
      </div>
    </div>
  );
}
