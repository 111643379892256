@import "../../style/colors.less";

.headerHomeContainer {
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  background-color: @darkest-grey;
  align-items: center;
  height: 80px;
  color: @white;
}

.headerBookContainer {
  display: flex;
  justify-content: space-between;
  background-color: @darkest-grey;
  flex-direction: column;
  width: 100%;
  color: @white;
  box-shadow: 0px 5px 15px #00000040;
  border: 1px solid #5b607913;
}

.headerTitleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 16px 24px;
}

.userMenu {
  display: flex;
  align-items: center;
  background: @medium-grey;
  border-radius: 250px;
  height: 40px;
  width: 40px;
  justify-content: center;
}

.userFirstLetter {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.emailInHeader {
  display: inline;
  margin: 10px;
  font-size: 15px;
  font-family: Roboto;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: 800;
}

.logoutButton {
  margin: 20px;
  cursor: pointer;
  text-align: center;
  letter-spacing: 0px;
  opacity: 1;
  padding: 10px;
  background: #e66525 0% 0% no-repeat padding-box;
  border: 1px solid #e66525;
  border-radius: 80px;
  font: normal normal medium 14px/16px Roboto;
  color: #ffffff;
}

.bookInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin: auto;
}

.headerTitle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: @white;
  margin: 0px;
}

.headerAuthor {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: @white;
  margin: 0px;
}

.paginationBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.24);
}

.pagination {
  display: flex;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.halfHidden {
  opacity: 0.3;
  pointer-events: none;
}

.headerSpinner {
  position: absolute;
  margin: auto;
  top: 5px;
  bottom: 0;
  left: 0;
  right: 0;
}
