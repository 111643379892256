import React, { ReactElement } from "react";
import { SubToPages_pages } from "../../../../editor/Project/api/SubToPages";
import { FrogRoles } from "../../../../../identity";
import { SubscribeToExtendedPage_extendedPage } from "../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";
import { usePageContext } from "../../../../editor/Project/components/EditingRouter/components/PageContentLoader";
import { Definitions } from "../Definitions/DefinitionsList";
import { MiscProvider } from "../../../Contexts/Misc";
import { RhesesAndAudioRecorder } from "../../../Components/RhesesAndAudioRecorder/RhesesAndAudioRecorder";

type NarratorContentProps = {
  currentPageId: string;
  bookId: string;
  bookInfoFromUrl: {
    bookId: string;
    currentPage: string;
  };
  scrollRef: React.RefObject<HTMLDivElement>;
  pages: SubToPages_pages[];
  selectedRole: FrogRoles;
  title: ReactElement;
};

const NarratorContent = ({
  currentPageId,
  bookId,
  bookInfoFromUrl,
  scrollRef,
  pages,
  selectedRole,
  title,
}: NarratorContentProps) => {
  // @ts-ignore
  const { extendedPage: pageMetadata }: { extendedPage: SubscribeToExtendedPage_extendedPage } =
    usePageContext();

  return (
    <div>
      <Definitions
        pageId={currentPageId}
        projectId={bookId}
        selectedRole={selectedRole}
        pageMetadata={pageMetadata}
      />
      {title}
      <MiscProvider>
        <RhesesAndAudioRecorder
          key={bookInfoFromUrl.currentPage}
          bookInfoFromUrl={bookInfoFromUrl}
          currentPageId={currentPageId}
          scrollRef={scrollRef}
          pages={pages}
          pageMetadata={pageMetadata}
        />
      </MiscProvider>
    </div>
  );
};

export default NarratorContent;
