import React from "react";
import { Button } from "antd";
import * as style from "../TabProgress.style.less";
import { useI18n } from "../../../../../../../../i18n";
import ProgressBar from "./ProgressBar";
import classNames from "classnames";
import { PreparationProgressStatus } from "../../../../../../../../../__generated__/globalTypes";
import { preciseFloat } from "../../../../../../../narration/Utils/common";
import { ProgressStats } from "../../../../../hooks/useProjectProgressStats";
import { CheckCircleOutlined } from "@ant-design/icons";

type Props = {
  stats: ProgressStats;
  preparationProgress: PreparationProgressStatus;
  isNarrationComplete: boolean;
  completeNarration: () => void;
  finalComplete: () => void;
};

export default function NarrationState({
  stats,
  preparationProgress,
  isNarrationComplete,
  completeNarration,
  finalComplete,
}: Props) {
  const [t] = useI18n();

  const { pages, definitions, proofListening, fixes, canValidate } = stats;

  const canMarkAsCompleted =
    isNarrationComplete && preparationProgress === PreparationProgressStatus.COMPLETE;

  return (
    <div className={style.narrationState}>
      <ProgressBar
        title={t("project.actionBar.progress.narration.narratedPages")}
        legend={`${pages.narrated}/${pages.total}`}
        percent={pages.progress}
      />
      <ProgressBar
        title={t("project.actionBar.progress.narration.narratedDefinitions")}
        legend={`${definitions.narrated}/${definitions.total}`}
        percent={definitions.progress}
      />
      <ProgressBar
        title={t("project.actionBar.progress.narration.proofListening")}
        legend={`${preciseFloat(proofListening.progress, 0)}%`}
        percent={proofListening.progress}
      />
      <ProgressBar
        title={t("project.actionBar.progress.narration.remainingCorrections")}
        legend={fixes.remaining.toString()}
        percent={fixes.remaining ? 0 : 100}
      />
      {!isNarrationComplete ? (
        <Button
          className={classNames(style.button, style.buttonMarkBookAsDone)}
          type="primary"
          disabled={!canValidate}
          onClick={completeNarration}>
          {t("project.actionBar.progress.narration.markNarrationDone")}
        </Button>
      ) : (
        <Button
          className={classNames(style.button, style.buttonMarkBookAsDone, style.complete)}
          type="primary"
          disabled={!canValidate}
          onClick={undefined}
          icon={<CheckCircleOutlined />}>
          {t("project.actionBar.progress.narration.narrationDone")}
        </Button>
      )}

      <Button
        className={classNames(style.button, style.buttonMarkBookAsDone)}
        type="primary"
        disabled={!canMarkAsCompleted}
        onClick={finalComplete}>
        {t("project.actionBar.progress.preparationAndNarration.markBookComplete")}
      </Button>
    </div>
  );
}
