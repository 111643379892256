import React from "react";
import Formatting from "../../EditingModes/Formatting";
import { SubToPages_pages } from "../../../api/SubToPages";
import { SecureRoute } from "@okta/okta-react";
import { subroutesPath } from "./subroutesPath";
import { SubToProject_project_speakers } from "../../../api/__generated__/SubToProject";

type Props = {
  allPages: SubToPages_pages[];
  projectId: string;
  lockPageStructure: boolean;
  speakers: SubToProject_project_speakers[];
};

export default function SubRouteFormat({
  projectId,
  lockPageStructure,
  allPages,
  speakers,
}: Props) {
  return (
    <SecureRoute
      path={subroutesPath.format}
      render={() => (
        <Formatting
          projectId={projectId}
          lockPageStructure={lockPageStructure}
          allPages={allPages}
          speakers={speakers}
        />
      )}
    />
  );
}
