import { OktaAuth } from "@okta/okta-auth-js";
import cleanLocalStorage from "../authentication/cleanLocalStorage";

export const config = {
  authDomain: "https://dev-753646.okta.com",
};

export const clientId = process.env.OKTA_CLIENT_ID;
export const issuer = "https://dev-753646.okta.com/oauth2/default";

cleanLocalStorage();


const oktaAuth = new OktaAuth({
  issuer: issuer,
  clientId: clientId,
  redirectUri: window.location.origin + "/login/callback",
  tokenManager: {
    autoRemove: true,
  },
});

oktaAuth.start();

export default oktaAuth;
