export const EDITOR_TABS = {
  overview: "overview",
  rhese: "rhese",
  format: "format",
  definition: "definition",
  dialogue: "dialogue",
  syllable: "syllable",
  narration: "narration",
};

export const EDITOR_TABS_MAP: { [key: string]: string } = {
  [EDITOR_TABS.overview]: "1",
  [EDITOR_TABS.rhese]: "2",
  [EDITOR_TABS.format]: "3",
  [EDITOR_TABS.definition]: "4",
  [EDITOR_TABS.dialogue]: "5",
  [EDITOR_TABS.syllable]: "6",
  [EDITOR_TABS.narration]: "7",
};
