import { gql, useMutation } from "@apollo/client";
import { UnsetItalicizationFromPosition, UnsetItalicizationFromPositionVariables } from "./__generated__/UnsetItalicizationFromPosition";
import { SetItalicizationFromPosition, SetItalicizationFromPositionVariables } from "./__generated__/SetItalicizationFromPosition";

export const useSetItalicizationFromPosition = () => useMutation<SetItalicizationFromPosition, SetItalicizationFromPositionVariables>(
  gql`
      mutation SetItalicizationFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        setItalicizationFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
);

export const useUnsetItalicizationFromPosition = () => useMutation<UnsetItalicizationFromPosition, UnsetItalicizationFromPositionVariables>(
  gql`
      mutation UnsetItalicizationFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        unsetItalicizationFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
);
