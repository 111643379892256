import { useApolloClient } from "@apollo/client";
import React, {
  Fragment,
  Reducer,
  RefObject,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useHistory } from "react-router";
import InfiniteScroll from "react-infinite-scroller";

import { Spinner } from "../../../../components/Spinner";

import { SubToPages, SubToPages_pages } from "../../../editor/Project/api/SubToPages";
import AudioAnalyzerContext from "../../Contexts/AudioAnalyzerContext";
import { ADD_AUDIO_RECORDING } from "../../Mutations/mutations";
import { getRecordingPresignedUrls } from "../../Queries/queries";
import { audioRecording } from "../../Queries/__generated__/audioRecording";
import { initProjectPage } from "../../Services/progressFollowerServices";

import { errorMessageType } from "../../Types/errorMessage";
import { recordType } from "../../Types/record";

import AudioRecorder, { AudioRecorderState } from "../audioRecorder/AudioRecorder";
import TextToRead from "../textToRead/TextToRead";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

type RhesesAndAudioRecorderProps = {
  bookInfoFromUrl: {
    bookId: string;
    currentPage: string;
  };
  currentPageId: string;
  scrollRef: RefObject<HTMLElement>;
  pages: SubToPages_pages[];
  pageMetadata: SubscribeToExtendedPage_extendedPage;
};

type Selection = [string | undefined, string | undefined];

export const useRhesesSelector = (
  rheses: SubscribeToExtendedPage_extendedPage_rhese[] | null,
): [
  SubscribeToExtendedPage_extendedPage_rhese[],
  React.Dispatch<string | undefined>,
  string | undefined,
] => {
  const [selection, select] = useReducer<Reducer<Selection, string | undefined>>(
    (selection: Selection, rheseId?: string): Selection => {
      if (!rheseId) return [undefined, undefined];
      const [first, second] = selection;
      if (!first) return [rheseId, undefined];
      if (first && second) return [rheseId, undefined];
      return [first, rheseId];
    },
    [undefined, undefined],
  );
  const [startIndex, endIndex] = useMemo(() => {
    if (!rheses) return [undefined, undefined];
    const [start, end] = selection;
    const startIndex = rheses.findIndex((r) => r.data.id === start);
    const endIndex = rheses.findIndex((r) => r.data.id === end);
    return endIndex > startIndex ? [startIndex, endIndex] : [endIndex, startIndex];
  }, [selection, rheses]);

  const selectedRheses = useMemo(() => {
    if (startIndex === undefined || rheses === null) return [];
    if (endIndex === undefined) return [rheses[startIndex]];
    return rheses
      .slice(startIndex, endIndex + 1)
      .sort((a, b) => a.anchors[0].utf16Start - b.anchors[0].utf16Start);
  }, [startIndex, endIndex, rheses]);

  return [selectedRheses, select, selection[0]];
};

export const RhesesAndAudioRecorder = ({
  bookInfoFromUrl,
  currentPageId,
  scrollRef,
  pages,
  pageMetadata,
}: RhesesAndAudioRecorderProps) => {
  const history = useHistory();
  const client = useApolloClient();

  const [previousPage, nextPage] = useMemo(() => {
    if (!pages.length) return [];
    const idx = pages.findIndex((page) => page.id === currentPageId);
    return [pages[idx - 1]?.pageNumber, pages[idx + 1]?.pageNumber];
  }, [pages, currentPageId]);

  const initErrorMessageValue: errorMessageType = useMemo(() => undefined, []);
  const [errorMessage, setErrorMessage] = useState<errorMessageType>(initErrorMessageValue);

  const [recordedRheses, setRecordedRheses] = useState<
    SubscribeToExtendedPage_extendedPage_rhese[]
  >([]);
  const [currentProjectPage, setCurrentProjectPage] = useState<SubToPages_pages>();
  const [projectPages, setProjectPages] = useState<SubToPages>();
  const [audioRecorderState, setAudioRecorderState] = useState(AudioRecorderState.idle);

  const [selectedRheses, select, selectionAnchor] = useRhesesSelector(pageMetadata.rhese);

  const [savingAudio, setSavingAudio] = useState(false);

  if (pages) {
    initProjectPage(
      setProjectPages,
      projectPages,
      setCurrentProjectPage,
      currentProjectPage,
      pages,
    );
  }

  const selectAllRheses = useCallback(() => {
    // Deselect all rheses then select from first to last
    const first: string = pageMetadata.rhese?.[0]?.data.id;
    const last: string = pageMetadata.rhese?.[pageMetadata.rhese?.length - 1]?.data.id;
    select(undefined);
    select(first);
    select(last);
  }, [select, pageMetadata.rhese]);

  const clearRecordedRheses = () => {
    setRecordedRheses([]);
  };

  const updateRecordedRheses = () => {
    if (!selectedRheses) return;
    if (recordedRheses) {
      const newRecordedRheses = [...recordedRheses, ...selectedRheses];
      setRecordedRheses(
        newRecordedRheses.sort((a, b) => a.anchors[0].utf16Start - b.anchors[0].utf16Start),
      );
    } else {
      setRecordedRheses(
        selectedRheses.sort((a, b) => a.anchors[0].utf16Start - b.anchors[0].utf16Start),
      );
    }
  };

  const getStorageURLData = async () => {
    try {
      const { data: storageOrDownloadUrl }: { data: audioRecording } = await client.query({
        query: getRecordingPresignedUrls,
        fetchPolicy: "network-only",
      });

      return storageOrDownloadUrl;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Impossible to get the storage or download url");
      }
    }
  };

  const getDownloadURLData = async (filename: string) => {
    try {
      const { data: storageOrDownloadUrl }: { data: audioRecording } = await client.query({
        query: getRecordingPresignedUrls,
        fetchPolicy: "network-only",
        variables: {
          filename: filename,
        },
      });

      return storageOrDownloadUrl;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Impossible to get the storage or download url");
      }
    }
  };

  const sendRecordedRhesesToDatabase = async (blob: recordType) => {
    try {
      setSavingAudio(true);

      const storageUrlData = await getStorageURLData();

      const response = await fetch(storageUrlData.audioRecording.presignedUrl.putUrl, {
        method: "PUT",
        body: blob,
      });
      if (response.ok) {
        const downloadURLData = await getDownloadURLData(
          storageUrlData.audioRecording.presignedUrl.filename,
        );

        await client.mutate({
          mutation: ADD_AUDIO_RECORDING,
          fetchPolicy: "network-only",
          variables: {
            addRecording: {
              projectId: bookInfoFromUrl.bookId,
              fileUuid: downloadURLData.audioRecording.presignedUrl.filename,
              audioRheses: recordedRheses.map((rhese) => ({
                rheseId: rhese.data.id,
              })),
            },
          },
        });
        select(undefined);
        clearRecordedRheses();
        setSavingAudio(false);
      }
    } catch (error) {
      setSavingAudio(false);
      throw new Error(
        "L'enregistrement audio n'a pas été enregistré pour cause de problème technique (communication serveur).",
      );
    }
  };

  const uploadAudioFileToDatabase = async (
    file: File,
    rheses: SubscribeToExtendedPage_extendedPage_rhese[],
  ) => {
    const storageUrlData = await getStorageURLData();
    await new Promise((r: any) => setTimeout(() => r(), 2000));

    const response = await fetch(storageUrlData.audioRecording.presignedUrl.putUrl, {
      method: "PUT",
      body: file,
    });
    if (response.ok) {
      const downloadURLData = await getDownloadURLData(
        storageUrlData.audioRecording.presignedUrl.filename,
      );

      await client.mutate({
        mutation: ADD_AUDIO_RECORDING,
        fetchPolicy: "network-only",
        variables: {
          addRecording: {
            projectId: bookInfoFromUrl.bookId,
            fileUuid: downloadURLData.audioRecording.presignedUrl.filename,
            audioRheses: rheses.map((rhese) => ({
              rheseId: rhese.data.id,
            })),
          },
        },
      });
    }
  };

  if (!pageMetadata || !selectedRheses) return <Spinner grow />;

  return (
    <AudioAnalyzerContext.Provider
      value={{
        errorMessage,
        setErrorMessage,
        selectedRheses,
        recordedRheses,
        updateRecordedRheses,
        clearRecordedRheses,
        sendRecordedRhesesToDatabase,
        uploadAudioFileToDatabase,
      }}>
      <InfiniteScroll
        useWindow={false}
        hasMore={false}
        loadMore={() => {}}
        getScrollParent={() => scrollRef?.current}
        pageStart={0}
        threshold={10}
        loader={
          <Fragment key={0}>
            <Spinner small />
            <br />
            <br />
          </Fragment>
        }>
        <TextToRead
          selectedRheses={selectedRheses}
          selectionStartId={selectionAnchor}
          addSelectedRhese={select}
          projectId={bookInfoFromUrl.bookId}
          pageMetadata={pageMetadata}
          savingAudio={savingAudio}
        />
      </InfiniteScroll>
      <AudioRecorder
        audioRecorderState={audioRecorderState}
        setAudioRecorderState={setAudioRecorderState}
        selectAllRheses={selectAllRheses}
        previousPage={{
          canNavigate: previousPage !== undefined,
          navigate: () =>
            typeof previousPage === "number" &&
            history.push(`/audio/library/edit/${bookInfoFromUrl.bookId}/${previousPage}`),
        }}
        nextPage={{
          canNavigate: nextPage !== undefined,
          navigate: () =>
            typeof nextPage === "number" &&
            history.push(`/audio/library/edit/${bookInfoFromUrl.bookId}/${nextPage}`),
        }}
      />
    </AudioAnalyzerContext.Provider>
  );
};
