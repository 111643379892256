import React from "react";
import { motion } from "framer-motion";
import { DeleteOutlined } from "@ant-design/icons";
import { ImageKind, ImagePosition } from "../../../../../../../../../../__generated__/globalTypes";
import { useI18n } from "../../../../../../../../../i18n";
import ImgIcon from "../../../../../../assets/img.svg";
import * as style from "../../../style.less";

const imageKindToString = (kind: ImageKind) => {
  if (kind === ImageKind.NORMAL)
    return "project.existing.editingMode.formatting.default.form.radioButton.normal";
  return "project.existing.editingMode.formatting.default.form.radioButton.popup";
};

export type ImageType = {
  id: string;
  fileName: string;
  description: string;
  figcaption?: string | null;
  kind: ImageKind;
  position: ImagePosition;
};

type ImageProps = ImageType & {
  deleteImage: (imageId: string) => void;
  editImage: (image: ImageType) => void;
};

const Image = ({
  id,
  fileName,
  description,
  kind,
  position,
  figcaption,
  editImage,
  deleteImage,
}: ImageProps) => {
  const [t] = useI18n();

  return (
    <motion.div
      onClick={() => editImage({ id, fileName, description, kind, position, figcaption })}
      whileHover="hover"
      variants={{
        hover: {
          scale: 1.05,
        },
      }}
      id={id}
      className={style.image}>
      <div className={style.left}>
        <ImgIcon />
        <div>{t(`${imageKindToString(kind)}`)}</div>
      </div>
      <div className={style.center}>
        <div>{fileName}</div>
        <div>{description}</div>
        <div>{figcaption}</div>
      </div>
      <div className={style.right}>
        <DeleteOutlined
          className={style.delete}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            deleteImage(id);
          }}
        />
      </div>
    </motion.div>
  );
};

export default Image;
