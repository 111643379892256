@import "../../../app/editor/styles/colors.less";

.userPanelContainer {
  width: 250px;
  height: 100%;
  z-index: 4;
}

.userFirstLetterContainer {
  display: flex;
  align-items: center;
  background: @grey;
  border-radius: 250px;
  height: 40px;
  width: 40px;
  justify-content: center;
}

.userFirstLetter {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: @white;
}

.userInformationContainer {
  padding: 48px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  box-shadow: inset 0px -1px 0px rgba(91, 96, 121, 0.12);
}

.nameAndEmailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.roleSwitcher {
  text-align: center;
  padding: 2em 1em;
  font-size: 1em;

  .roleSelector {
    width: 100%;
    :global(.ant-select-selector) {
      height: 32px;
    }
    :global(.ant-select-selection-item) {
      font-weight: 500;
    }
  }
}
