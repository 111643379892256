import { useEffect } from "react";

const useSpeed = (
  audioElement: HTMLAudioElement,
  speed: number,
  onChange: (speed: number) => void = () => {},
) => {
  const currentRheseId = audioElement.dataset.rheseId;
  useEffect(() => {
    audioElement.playbackRate = speed;
    onChange(speed);
  }, [audioElement, currentRheseId, onChange, speed]);
};

export default useSpeed;
