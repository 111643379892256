import { gql, useMutation } from "@apollo/client";
import {
  SetBoldFromPosition,
  SetBoldFromPositionVariables,
} from "./__generated__/SetBoldFromPosition";
import {
  UnsetBoldFromPosition,
  UnsetBoldFromPositionVariables,
} from "./__generated__/UnsetBoldFromPosition";

export const useSetBoldFromPosition = () =>
  useMutation<SetBoldFromPosition, SetBoldFromPositionVariables>(
    gql`
      mutation SetBoldFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        setBoldFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
  );

export const useUnsetBoldFromPosition = () =>
  useMutation<UnsetBoldFromPosition, UnsetBoldFromPositionVariables>(
    gql`
      mutation UnsetBoldFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        unsetBoldFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
  );
