import React from "react";
import { ClickableWrapper } from "../../../../components/clickableWrapper/ClickableWrapper";
import * as style from "./style.less";

type UserPanelButtonProps = {
  className?: string;
  text: string;
  onClick?: () => void;
};

export enum UserPanelButtonType {
  primary = "primary",
  alert = "alert",
}

const getClassName = (className: string | undefined) => {
  if (className === UserPanelButtonType.alert) {
    return style.buttonAlert;
  } else {
    return style.buttonPrimary;
  }
};

const UserPanelButton = ({ className, text, onClick }: UserPanelButtonProps) => {
  const selectedClassName = getClassName(className);
  return (
    <ClickableWrapper>
      <div className={style.buttonContainer} onClick={onClick}>
        <div className={selectedClassName}>{text}</div>
      </div>
    </ClickableWrapper>
  );
};

export default UserPanelButton;
