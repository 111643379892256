import React, { ReactElement, useMemo, useCallback, useEffect, useState } from "react";
import { Input, Layout } from "antd";
import { InView } from "react-intersection-observer";

import * as styles from "./styles.less";
import { useProjectContext, useUpdateDisplayedPageNumber } from "../Page";
import { useUpdatePage } from "../../api/api";
import debounce from "lodash/debounce";
import { useI18n } from "../../../../../i18n";
import { ProjectKind } from "../../../../../../__generated__/globalTypes";
import { SubToPages_pages } from "../../api/SubToPages";
import { Spinner } from "../../../../../components/Spinner";
import { usePrevious } from "../../../utils/common";

export type A4Props = {
  pageId: string;
  children: ReactElement | ReactElement[];
  displayedTotalPageNumber: number;
  displayedPageNumber: number;
  loadMore: () => void;
  hasMoreToLoad: boolean;
  loadingMore: boolean;
  innerRef: React.Ref<any>;
};

const i18nKey = "project.a4Page";

const TopBar = ({
  projectId,
  pageId,
  displayedPageNumber,
  displayedTotalPageNumber,
  pages,
  readOnly = false,
}: {
  projectId: string;
  pageId: string;
  displayedPageNumber: number;
  displayedTotalPageNumber: number;
  pages: SubToPages_pages[];
  readOnly: boolean;
}) => {
  const [applyUpdatePage] = useUpdatePage();
  const [t] = useI18n();
  const page = pages.find((page) => page?.id === pageId);

  let partName: string | null | undefined = page?.partName;
  const previousPartName = usePrevious(partName);

  const [currentInputValue, setCurrentInputValue] = useState<string | null | undefined>(
    partName || "",
  );

  if (!partName) {
    const pageIndex = pages.findIndex((p) => p?.id === pageId);
    if (pageIndex > -1) {
      partName = pages
        .slice(0, pageIndex)
        .reduce(
          (lastPartName: string | null | undefined, p) => p?.partName || lastPartName,
          undefined,
        );
    }
  }

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((partName: string | null) => {
        if (!page) return;
        applyUpdatePage({
          variables: {
            partname: partName || "",
            pageId,
            projectId,
            mutePagesAfter: page?.mutePagesAfter || 0,
          },
        });
      }, 1000),
    [applyUpdatePage, page, pageId, projectId],
  );

  const updatePartName = useCallback(debouncedChangeHandler, [debouncedChangeHandler]);

  useEffect(() => {
    if (!partName || partName !== previousPartName) {
      setCurrentInputValue(partName || "");
    }
  }, [setCurrentInputValue, partName, previousPartName]);

  return (
    <div className={styles.topBar} id={pageId}>
      <Input
        readOnly={readOnly}
        style={{ width: "83%" }}
        className={styles.chapterName}
        placeholder={t(`${i18nKey}.placeholder`)}
        value={currentInputValue || undefined}
        onChange={(e) => {
          const partName = e.target.value === "" ? null : e.target.value;
          setCurrentInputValue(partName);
          updatePartName(partName);
        }}
      />
      <span style={{ width: "9%" }} className={styles.totalPageNumber}>
        {displayedPageNumber}/{displayedTotalPageNumber}
      </span>
    </div>
  );
};

const Comp = ({
  children,
  displayedTotalPageNumber,
  displayedPageNumber,
  loadMore,
  hasMoreToLoad,
  loadingMore,
  pageId,
  innerRef,
}: A4Props) => {
  const { project, pages } = useProjectContext();
  const [t] = useI18n();

  const updateDisplayedPageNumber = useUpdateDisplayedPageNumber();

  const updatePageNumberUrl = useCallback(
    (isVisible) => {
      if (isVisible) {
        updateDisplayedPageNumber(displayedPageNumber.toString());
      }
    },
    [displayedPageNumber, updateDisplayedPageNumber],
  );

  return (
    <Layout className={styles.root} ref={innerRef}>
      <Layout.Content
        id={"page-" + displayedPageNumber}
        className={styles.contentWrapper + " pageContent"}
        style={{ display: "flex" }}
        lang={project.config.locale}>
        <TopBar
          pageId={pageId}
          pages={pages}
          displayedPageNumber={displayedPageNumber}
          displayedTotalPageNumber={displayedTotalPageNumber}
          projectId={project.id}
          readOnly={project?.kind === ProjectKind.HTML_TEMPLATE}
        />
        <InView onChange={updatePageNumberUrl} threshold={0.3}>
          <div
            style={{ height: "100%", minHeight: "72em", paddingBottom: "3em" }}
            className={styles.a4Wrapper}>
            <div className={styles.content}>{children}</div>
          </div>
        </InView>
        {loadingMore ? (
          <Spinner />
        ) : hasMoreToLoad ? (
          <div className={styles.showMore} onClick={loadMore}>
            {t(`${i18nKey}.showMore`)}
          </div>
        ) : null}
      </Layout.Content>
    </Layout>
  );
};
export default React.memo(Comp);
