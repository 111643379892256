@import "../../../../editor/styles/colors.less";

.definitions {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
  row-gap: 1em;
  &.definitionsProofListener {
    padding-top: 0.2em;
    margin-bottom: 2em;
  }
}

.definitionWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.definition {
  padding: 1em 2em;
  width: 100%;
  border-radius: 8px;
  background-color: @white;
  border: 1px solid #e0e0e0;
}

.definitionLabel {
  flex-grow: 1;
}

.recorder {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
}

.recordButton,
.playButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stopIcon {
  width: 13px;
  * {
    fill: @orange;
  }
}

.recordSpinner {
  margin-top: 6px;
}

.definitionActions {
  display: grid;
  grid-template-columns: 3em 3em;
  grid-auto-flow: column;

  > * {
    width: min-content;
  }
}

.audioDropInput {
  margin-left: 8px;
  :global(.ant-upload-drag) {
    width: 55px;
    height: 64px;
    border-radius: 7px;
    :global(.ant-upload-btn) {
      padding: 0;
    }
  }
  :global(.ant-upload-drag-hover) {
    background-color: @light-grey;
    :global(.ant-upload-btn) {
    }
  }
  .uploadIcon {
    font-size: 16px;
  }

  &.audioDropInputActive {
    :global(.ant-upload-drag) {
      // background-color: red !important;
    }
  }
}
