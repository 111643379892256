import { useCallback, useState } from "react";
import { notification } from "antd";
import { useI18n } from "../../../../../../i18n";

type Options = {
  setDefinitionAudioFile: (definitionId: string, filename: string) => void;
  microphone: {
    start: () => void;
    stop: () => Promise<Blob>;
  } | null;
};

export default function useUploadRecordedFile({ setDefinitionAudioFile, microphone }: Options) {
  const [t] = useI18n();
  const [isUploading, setIsUploading] = useState(false);
  const uploadRecordedFile = useCallback(
    async (definitionId: string, putUrl: string, filename: string) => {
      try {
        setIsUploading(true);
        if (!microphone) return;
        microphone.stop().then(async (blob) => {
          await fetch(putUrl, {
            method: "PUT",
            body: blob,
          });

          await setDefinitionAudioFile(definitionId, filename);
        });

        notification.success({ message: t("definitionRecordedAudioUploadSuccess") });
      } catch (e: any) {
        console.log("useUploadRecordedFile error", e);
        notification.error({
          message: `${t("definitionRecordedAudioUploadError")}: ${e?.message || ""}`,
        });
      } finally {
        setIsUploading(false);
      }
    },
    [microphone, setDefinitionAudioFile, t],
  );
  return {
    uploadRecordedFile,
    isUploading,
  };
}
