import { BookStatus, PreparationProgressStatus } from "../../../../../__generated__/globalTypes";
import { SubToProjectForHomepage_projects } from "../../Library/__generated__/SubToProjectForHomepage";

export const useProjectStatusLabel = (project: SubToProjectForHomepage_projects) => {
  if (project?.isDoingAudioResync) {
    return "RESYNC";
  } else if (project.status === BookStatus.COMPLETE || project.status === BookStatus.ARCHIVED) {
    if (project.status === BookStatus.COMPLETE) {
      return "COMPLETE_BOOK";
    }
    return project.status;
  } else if (
    project.isNarrationComplete &&
    project.preparationProgress !== PreparationProgressStatus.COMPLETE
  ) {
    return "NARRATION_COMPLETE";
  } else if (
    !project.isNarrationComplete &&
    project.preparationProgress !== PreparationProgressStatus.NOT_READY
  ) {
    return "READY_FOR_NARRATION";
  } else if (
    project.preparationProgress === PreparationProgressStatus.NOT_READY ||
    project.preparationProgress === PreparationProgressStatus.READY_FOR_NARRATION ||
    project.preparationProgress === PreparationProgressStatus.COMPLETE
  ) {
    return project.preparationProgress;
  }

  return "";
};
