import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { Button, Popover, Slider } from "antd";
import style from "./style.less";
import { FieldTimeOutlined } from "@ant-design/icons";
import { useSpeedControlsStore } from "./context";
import { SPEED_MARKS, SPEED_RANGE, SPEED_STEP } from "./config";

const markStyle = {
  marginTop: "4px",
};

const SpeedControls = ({ className }: { className: string }) => {
  const {
    state: { speed },
    dispatch,
  } = useSpeedControlsStore();

  const onChange = useCallback(
    (value: number) => {
      dispatch({
        type: "SPEED_CHANGED",
        value,
      });
    },
    [dispatch],
  );

  const marks = useMemo(() => {
    const _marks: {
      [value: number]: {
        style: Object;
        label: JSX.Element | string;
      };
    } = {};
    SPEED_MARKS.forEach((mark) => {
      _marks[mark] = {
        style: markStyle,
        label: <strong>{mark}</strong>,
      };
    });
    return _marks;
  }, []);

  const Content = (
    <div className={style.content}>
      <Slider
        value={(speed * 100) / 100}
        onChange={onChange}
        min={SPEED_RANGE[0]}
        max={SPEED_RANGE[1]}
        step={SPEED_STEP}
        tooltip={{
          open: false,
        }}
        marks={marks}
        included={false}
        trackStyle={{
          backgroundColor: "transparent",
        }}
      />
    </div>
  );

  return (
    <Popover content={Content} trigger="hover" className={style.popover}>
      <Button className={classNames(style.popoverButton, className)}>
        <FieldTimeOutlined className={style.speedIcon} />
        {speed !== 1 && ` ${speed}`}
      </Button>
    </Popover>
  );
};

export default SpeedControls;
