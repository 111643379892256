import React, { useState } from "react";
import { Radio } from "antd";
import SyllableEdit, { Props as SyllableEditProps } from "../../EditingModes/Syllable";
import { SubToPages_pages } from "../../../api/SubToPages";
import { useI18n } from "../../../../../../i18n";
import { SplitContent } from "./SplitContent";

const RadioButton = Radio.Button;

type PartialSyllableProps = Omit<
  SyllableEditProps,
  "rheses" | "mode" | "paragraphs" | "pageMetadata"
>;

type Props = {
  secureRootProps: any;
  componentProps: PartialSyllableProps;
  pages: SubToPages_pages[];
};

export default function SyllableComponent({ secureRootProps, componentProps, pages }: Props) {
  const [t] = useI18n();
  const i18nKey = "project.existing.index.syllableComponent";
  const [mode, setMode] = useState<"syllables" | "phonemes" | "custom">("syllables");
  return (
    <>
      <div
        style={{
          position: "absolute",
          bottom: "12em",
          left: "8em",
          zIndex: 999,
        }}>
        <Radio.Group
          className="clorationMode"
          value={mode}
          onChange={(e) => setMode(e.target.value)}>
          <RadioButton value="syllables">{t(`${i18nKey}.syllables`)}</RadioButton>
          <RadioButton value="phonemes">{t(`${i18nKey}.phonemes`)}</RadioButton>
          <RadioButton value="custom">{t(`${i18nKey}.custom`)}</RadioButton>
        </Radio.Group>
      </div>

      <SplitContent
        component={SyllableEdit}
        props={{
          ...secureRootProps,
          ...componentProps,
          mode,
          projectId: secureRootProps.match.params.bookId,
        }}>
        {pages}
      </SplitContent>
    </>
  );
}
