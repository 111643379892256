import React, { useState, useCallback, useMemo, SetStateAction, useEffect } from "react";
import {
  Upload,
  message,
  Button,
  Row,
  Form,
  Input,
  Modal,
  Progress,
  Radio,
  Switch,
  Select,
} from "antd";
import { ImportTypes, useNewJsonProject, newProject } from "../../api/api";
import { UploadOutlined } from "@ant-design/icons";
import * as style from "./style.less";
import { useI18nObjectHook } from "../../../../../i18n";
import { TAGS } from "../../../../../i18n/tags";
import { Spinner } from "../../../../../components/Spinner";

const { Option } = Select;
const RadioButton = Radio.Button;

type Props = {
  container?: HTMLElement;
  setIsOpen: (v: boolean) => void;
  onSuccess: () => void;
  onError: (val: any) => void;
};

type StepOneTranslateProps = {
  locale: string;
  title: string;
  importType: string;
  successMessage: string;
  errorMessage: string;
  uploadOutlined: string;
  import: string;
  rhesedText: string;
  text: string;
  htmlBook: string;
  localse: string;
  disableColorationOnSimpleWords: string;
  jsonImport: string;
};

type StepTwoTranslateProps = {
  title: string;
  titlePlaceholder: string;
  author: string;
  authorPlaceholder: string;
  ean: string;
  eanPlaceholder: string;
  placeholder: string;
  create: string;
};

function StepOne({
  onFinish,
  setFile,
  isWaitingUpload,
  setImportType: updateImportType,
  setLocale: updateLocale,
  setColorationOnComplexWordsOnly,
}: {
  onFinish: () => void;
  setFile: (f: Blob) => void;
  setImportType: (importType: ImportTypes) => void;
  setLocale: (locale: string) => void;
  setColorationOnComplexWordsOnly: (value: boolean) => void;
  isWaitingUpload: boolean;
}) {
  const getFileExtension = (importType: ImportTypes) => {
    if (importType === ImportTypes.JSON_IMPORT) {
      return ".json";
    }

    if (importType === ImportTypes.HTML) {
      return ".zip";
    }

    return ".txt";
  };

  const translation: StepOneTranslateProps = useI18nObjectHook("project.new.stepOne");

  const [importType, setImportType] = useState(ImportTypes.RHESED_TEXT);
  const [locale, _setLocale] = useState("fr_FR");
  const setLocale = useCallback(
    (locale: SetStateAction<string>) => _setLocale(locale.toString().replace(/-/g, "_")),
    [_setLocale],
  );
  useEffect(() => updateImportType(importType), [updateImportType, importType]);
  useEffect(() => updateLocale(locale), [updateLocale, locale]);
  const languageNames = useMemo(
    () => new Intl.DisplayNames([locale.replace(/_/g, "-")], { type: "language" }),
    [locale],
  );

  return (
    <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} layout="vertical">
      <Form.Item
        style={{ padding: "0 7em" }}
        label={translation.importType}
        className={style.importType}>
        <Radio.Group
          value={importType}
          onChange={(e) => {
            setImportType(e.target?.value);
          }}>
          <RadioButton value={ImportTypes.RHESED_TEXT}>{translation.rhesedText}</RadioButton>
          <RadioButton value={ImportTypes.TEXT}>{translation.text}</RadioButton>
          <RadioButton value={ImportTypes.HTML}>{translation.htmlBook}</RadioButton>
          <RadioButton value={ImportTypes.JSON_IMPORT}>{translation.jsonImport}</RadioButton>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        style={{ padding: "0 7em" }}
        label={translation.disableColorationOnSimpleWords}
        className={style.disableColorationOnSimpleWords}>
        <Switch
          defaultChecked={false}
          onChange={(args) => setColorationOnComplexWordsOnly(args.valueOf())}
          size="small" // TODO antd: only "small" allow the switch to display
        />
      </Form.Item>
      <Form.Item style={{ padding: "0 7em" }} label={translation.locale}>
        <Select
          showSearch
          value={locale.replace(/_/g, "-")}
          style={{ width: "100%" }}
          onChange={setLocale}>
          {Object.keys(TAGS).map((tag) => (
            <Option key={tag} value={tag}>
              {languageNames.of(tag.replace(/_/g, "-"))}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        style={{ padding: "0 7em" }}
        label={translation.title}
        name="text"
        valuePropName="text">
        <Upload
          accept={getFileExtension(importType)}
          customRequest={({ file, onSuccess }) => {
            // @ts-ignore
            setFile(file);
            // @ts-ignore
            onSuccess({}, file);
          }}
          onChange={(info) => {
            if (info.file.status === "done") {
              message.success(`${info.file.name} ${translation.successMessage}`);
            } else if (info.file.status === "error") {
              message.error(`${info.file.name} ${translation.errorMessage}`);
            }
          }}>
          <Button style={{ width: "100%" }}>
            <UploadOutlined />
            {translation.uploadOutlined}
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item style={{ margin: 0 }}>
        <Row justify="center">
          <Button disabled={isWaitingUpload} htmlType="submit" shape="round" type="ghost">
            {translation.import}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
}

function StepTwo({ onFinish }: { onFinish: (title: string, author: string, EAN: string) => void }) {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [EAN, setEAN] = useState("");

  const createBook = useCallback(
    () => onFinish(title, author, EAN),
    [title, author, EAN, onFinish],
  );
  const translation: StepTwoTranslateProps = useI18nObjectHook("project.new.stepTwo");

  return (
    <Form name="basic" initialValues={{ remember: true }} onFinish={createBook} layout="vertical">
      <Form.Item style={{ padding: "0 7em" }} label={translation.title} name="title">
        <Input
          placeholder={translation.titlePlaceholder}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Form.Item>
      <Form.Item style={{ padding: "0 7em" }} label={translation.author} name="author">
        <Input
          placeholder={translation.authorPlaceholder}
          onChange={(e) => setAuthor(e.target.value)}
        />
      </Form.Item>
      <Form.Item style={{ padding: "0 7em" }} label={translation.ean} name="ean">
        <Input placeholder={translation.eanPlaceholder} onChange={(e) => setEAN(e.target.value)} />
      </Form.Item>
      <Form.Item style={{ margin: 0 }}>
        <Row justify="center">
          <Button
            disabled={title.length === 0 || author.length === 0}
            type="ghost"
            htmlType="submit"
            shape="round">
            {translation.create}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
}

function StepThree({ progress }: { progress: number }) {
  return (
    <Form name="basic" layout="vertical">
      <Form.Item>
        <Row justify="center">
          <Progress type="circle" percent={Math.round(progress * 100)} />
        </Row>
      </Form.Item>
    </Form>
  );
}
// eslint-disable-next-line react/display-name
export default ({ container, setIsOpen, onSuccess, onError }: Props) => {
  const [file, setFile] = useState<Blob>();
  const [isImporting, setIsImporting] = useState(false);
  const [colorationOnComplexWordsOnly, setColorationOnComplexWordsOnly] = useState<boolean>(false);
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [importProgress] = useState(0);
  const [importType, setImportType] = useState(ImportTypes.RHESED_TEXT);
  const [locale, setLocale] = useState("fr_FR");
  const createJsonProject = useNewJsonProject();

  const { ok, cancel } = useMemo(
    () => ({
      ok: () => setIsOpen(false),
      cancel: () => setIsOpen(false),
    }),
    [setIsOpen],
  );

  const handleFirstStepEnd = useCallback(() => {
    if (!file) return;
    if (importType === ImportTypes.JSON_IMPORT) {
      try {
        createJsonProject(file, locale, colorationOnComplexWordsOnly);
        onSuccess();
      } catch (e) {
        onError(e);
      }
    } else {
      setStep(2);
    }
  }, [
    file,
    locale,
    colorationOnComplexWordsOnly,
    createJsonProject,
    setStep,
    onSuccess,
    onError,
    importType,
  ]);

  const createProject = newProject();

  const create = useCallback(
    async (title: string, author: string, EAN: string) => {
      if (!file) {
        throw new Error("File should be set before creating project");
      }

      try {
        const query = createProject(
          title,
          author,
          EAN,
          file,
          importType,
          locale,
          colorationOnComplexWordsOnly,
        );
        setIsImporting(true);
        const res = await query;
        if (res.ok) {
          onSuccess();
        } else {
          throw new Error(await res.text());
        }
      } catch (e) {
        onError(e);
      } finally {
        setIsImporting(false);
      }
    },
    [
      file,
      locale,
      colorationOnComplexWordsOnly,
      onSuccess,
      onError,
      importType,
      createProject,
      setIsImporting,
    ],
  );

  let stepComp;

  const translation: string = useI18nObjectHook("project.new.modalTitle");

  if (step === 1) {
    stepComp = (
      <StepOne
        setColorationOnComplexWordsOnly={setColorationOnComplexWordsOnly}
        setImportType={setImportType}
        setLocale={setLocale}
        onFinish={handleFirstStepEnd}
        isWaitingUpload={!file}
        setFile={setFile}
      />
    );
  } else if (step === 2) {
    stepComp = <StepTwo onFinish={create} />;
  } else {
    stepComp = <StepThree progress={importProgress} />;
  }

  if (isImporting) {
    stepComp = <Spinner />;
  }
  return (
    <Modal
      centered={true}
      width={"56.66em"}
      title={translation}
      className={style.modal}
      open={true}
      onOk={ok}
      onCancel={cancel}
      footer={null}
      getContainer={() => container || document.body}>
      {stepComp}
    </Modal>
  );
};
