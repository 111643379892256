import "./editor/styles/index.less";
import "../i18n/index";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import React, { useMemo } from "react";
import { ConfigProvider, Layout } from "antd";
import { ApolloProvider } from "@apollo/client";
import Library from "./editor/Library/ProjectsPage/ProjectsPage";
import { render } from "react-dom";
import Book from "./editor/Project";
import { toRelativeUrl } from "@okta/okta-auth-js";
import "antd/dist/reset.css";
import apolloClient from "../apollo";
import { NarrationHome } from "./narration/pages/narrationHome/NarrationHome";
import { MemoizedBook } from "./narration/pages/narrationBookPage/NarrationBookPage";
import history from "../routing/history";
import oktaAuth, { config } from "../okta";
import Login from "../authentication/Component";
import {
  SelectedRoleProvider,
  useSelectedRoleReducer,
  FrogRoles,
  useSelectedRole,
  useRedirectOnRoleChange,
} from "../identity";
import EnvBanner from "../components/EnvBanner/EnvBanner";
import { antDesignTheme } from "./editor/styles/antDesignTheme";

Sentry.init({
  dsn: "https://bd65d1f5d8154efaa4b1ce880c7c2e4b@o576930.ingest.sentry.io/5731119",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
});

const restoreOriginalUri = async (_oktaAuth: any, originalUri: string | null) => {
  history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
};
const customAuthHandler = () => history.push("/login");
const onAuthResume = customAuthHandler;
export const client = apolloClient(oktaAuth);

const RoutesWithRole = () => {
  const roleReducer = useSelectedRoleReducer();
  return (
    <SelectedRoleProvider value={roleReducer}>
      <ProtectedRoutes />
    </SelectedRoleProvider>
  );
};

const ProtectedRoutes = () => {
  const { state: selectedRole } = useSelectedRole();

  useRedirectOnRoleChange(selectedRole);

  const redirect = useMemo(() => {
    if (selectedRole === FrogRoles.Narrator || selectedRole === FrogRoles.ProofListener) {
      return <Redirect push to="/audio/library" />;
    }
    if (selectedRole === FrogRoles.Editor) {
      return <Redirect push to="/editor/library" />;
    }
  }, [selectedRole]);

  return (
    <Switch>
      <SecureRoute path="/editor/books/edit/:bookId" component={Book} />
      <SecureRoute exact path="/editor/library" component={Library} />
      <SecureRoute exact path="/audio/library" component={NarrationHome} />
      <SecureRoute path="/audio/library/edit/:bookId/:page" component={MemoizedBook} />
      {redirect}
    </Switch>
  );
};

const EntryPoint = () => {
  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <ConfigProvider theme={antDesignTheme}>
          <EnvBanner />
          <Layout style={{ height: "100vh" }}>
            <Security
              oktaAuth={oktaAuth}
              restoreOriginalUri={restoreOriginalUri}
              onAuthRequired={customAuthHandler}>
              <Switch>
                <Route exact path="/login" render={() => <Login config={config} />} />
                <Route
                  exact
                  path="/login/callback"
                  render={(props: any) => <LoginCallback {...props} onAuthResume={onAuthResume} />}
                />
                <SecureRoute path="/" component={RoutesWithRole} />
              </Switch>
            </Security>
          </Layout>
        </ConfigProvider>
      </Router>
    </ApolloProvider>
  );
};

window.addEventListener("load", () => render(<EntryPoint />, document.getElementById("app")));
