import { StringOpAnchor, UpdateTextContent } from "../../../../../../../__generated__/globalTypes";
import * as jsdiff from "diff";
import { SubscribeToExtendedPage_extendedPage } from "../../../api/__generated__/SubscribeToExtendedPage";
import { SelectedTextPosition } from "../Formatting/components/FormattingComp/FormattingComp";

export const diffStrings = (
  prevText: string,
  nextText: string,
  startOffset: number,
): UpdateTextContent[] => {
  const diff = jsdiff.diffChars(prevText, nextText);

  let operations: UpdateTextContent[] = [];
  let position = 0;

  diff.forEach((part) => {
    if (part.added) {
      operations.push({
        insert: {
          text: part.value,
          utf16Start: startOffset + position,
          anchor: position === 0 ? StringOpAnchor.AFTER_INDEX : StringOpAnchor.BEFORE_INDEX,
        },
      });
    } else if (part.removed) {
      operations.push({
        delete: {
          utf16Start: startOffset + position,
          utf16Size: part.count!,
        },
      });
    }
    if (!part.removed) position += part.count!;
  });
  return operations;
};

export const hasCloseSpace = (
  pageMetadata: SubscribeToExtendedPage_extendedPage,
  selectedPosition: SelectedTextPosition,
) => {
  if (selectedPosition.size !== 0) return false;

  const previousChar = pageMetadata.textContent.substring(
    selectedPosition.start - (pageMetadata.page.anchors[0]?.utf16Start || 0),
    selectedPosition.start - (pageMetadata.page.anchors[0]?.utf16Start || 0) + 1,
  );
  const nextChar = pageMetadata.textContent.substring(
    selectedPosition.start -
      (pageMetadata.page.anchors[0]?.utf16Start || 0) +
      selectedPosition.size +
      1,
    selectedPosition.start -
      (pageMetadata.page.anchors[0]?.utf16Start || 0) +
      selectedPosition.size +
      2,
  );

  return previousChar === " " || previousChar === "\xa0" || nextChar === " " || nextChar === "\xa0";
};
