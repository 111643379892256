import { gql, useMutation } from "@apollo/client";
import {
  CreateCssFromPosition,
  CreateCssFromPositionVariables,
} from "./__generated__/CreateCssFromPosition";
import {
  DeleteCssFromPosition,
  DeleteCssFromPositionVariables,
} from "./__generated__/DeleteCssFromPosition";

export const useCreateCssFromPosition = () =>
  useMutation<CreateCssFromPosition, CreateCssFromPositionVariables>(
    gql`
      mutation CreateCssFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
        $newCss: AddExtendedMetadataCustomCSS!
      ) {
        createCssFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
          newCss: $newCss
        )
      }
    `,
  );

export const useDeleteCssFromPosition = () =>
  useMutation<DeleteCssFromPosition, DeleteCssFromPositionVariables>(
    gql`
      mutation DeleteCssFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        deleteCssFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
  );
