import { useCallback } from "react";
import { LetterKind } from "../../../../api/formatters";
import {
  useSetBoldFromPosition,
  useSetCaseFromPosition,
  useSetCitationFromPosition,
  setItalicizationFromPosition,
  setMuteFromPosition,
  useSetScriptNotationFromPosition,
  useUnsetBoldFromPosition,
  useUnsetCaseFromPosition,
  useUnsetCitationFromPosition,
  unsetItalicizationFromPosition,
  useUnsetScriptNotationFromPosition,
  useUnsetMuteFromPosition,
} from "../../../../api/api";
import { SelectedTextPosition } from "../components/FormattingComp/FormattingComp";
import {
  ExtendedTextCase,
  ExtendedTextScriptNotation,
} from "../../../../../../../../__generated__/globalTypes";
import { SubscribeToExtendedPage_extendedPage } from "../../../../api/__generated__/SubscribeToExtendedPage";

function getHasOneWithoutKindInSelection(
  metadata: { anchors: { utf16Start: number; utf16Size: number }[] }[],
  position: SelectedTextPosition,
) {
  let hasOneWithoutKindInSelection = false;

  // Calculate if all letters currently selected have the kind
  if (!metadata.length) {
    hasOneWithoutKindInSelection = true;
  } else {
    for (let i = position.start; i < position.start + position.size; i++) {
      for (let j = 0; j < metadata[0].anchors.length; j++) {
        if (
          metadata[0].anchors[j].utf16Start <= i &&
          i <= metadata[0].anchors[j].utf16Start + metadata[0].anchors[j].utf16Size
        ) {
          break;
        }
        if (j === metadata[0].anchors.length - 1) {
          hasOneWithoutKindInSelection = true;
        }
      }
    }
  }

  return hasOneWithoutKindInSelection;
}

const useHandleLettersKindAssignation = (
  position: SelectedTextPosition,
  projectId: string,
  pageMetadata: SubscribeToExtendedPage_extendedPage | null,
) => {
  const [applySetBoldFromPosition] = useSetBoldFromPosition();
  const [applyUnsetBoldFromPosition] = useUnsetBoldFromPosition();
  const [applySetCaseFromPosition] = useSetCaseFromPosition();
  const [applyUnsetCaseFromPosition] = useUnsetCaseFromPosition();
  const [applySetMuteFromPosition] = setMuteFromPosition();
  const [applyUnsetMuteFromPosition] = useUnsetMuteFromPosition();
  const [applySetItalicizationFromPosition] = setItalicizationFromPosition();
  const [applyUnsetItalicizationFromPosition] = unsetItalicizationFromPosition();
  const [applySetCitationFromPosition] = useSetCitationFromPosition();
  const [applyUnsetCitationFromPosition] = useUnsetCitationFromPosition();
  const [applySetScriptNotationFromPosition] = useSetScriptNotationFromPosition();
  const [applyUnsetScriptNotationFromPosition] = useUnsetScriptNotationFromPosition();

  const handleLettersKindAssignation = useCallback(
    (kind: LetterKind) => {
      if (position.size === 0 || !pageMetadata) {
        return;
      }

      let hasOneWithoutKindInSelection = false;

      // Calculate if all letters currently selected have the kind
      switch (kind) {
        case LetterKind.GRAS: {
          hasOneWithoutKindInSelection = getHasOneWithoutKindInSelection(
            pageMetadata.textWeight,
            position,
          );
          break;
        }
        case LetterKind.ITALIQUE: {
          hasOneWithoutKindInSelection = getHasOneWithoutKindInSelection(
            pageMetadata.textItalicization,
            position,
          );
          break;
        }
        case LetterKind.CITATION: {
          hasOneWithoutKindInSelection = getHasOneWithoutKindInSelection(
            pageMetadata.textCitation,
            position,
          );
          break;
        }
        case LetterKind.MAJUSCULE: {
          hasOneWithoutKindInSelection = getHasOneWithoutKindInSelection(
            pageMetadata.textCase.filter((m) => m.data.textCase === ExtendedTextCase.UPPER),
            position,
          );
          break;
        }
        case LetterKind.MINUSCULE: {
          hasOneWithoutKindInSelection = getHasOneWithoutKindInSelection(
            pageMetadata.textCase.filter((m) => m.data.textCase === ExtendedTextCase.LOWER),
            position,
          );
          break;
        }
        case LetterKind.MUTE: {
          hasOneWithoutKindInSelection = getHasOneWithoutKindInSelection(
            pageMetadata.textMuted,
            position,
          );
          break;
        }
        case LetterKind.INDICE: {
          hasOneWithoutKindInSelection = getHasOneWithoutKindInSelection(
            pageMetadata.textScriptNotation.filter(
              (m) => m.data.textScriptNotation === ExtendedTextScriptNotation.SUBSCRIPT,
            ),
            position,
          );
          break;
        }
        case LetterKind.EXPOSANT: {
          hasOneWithoutKindInSelection = getHasOneWithoutKindInSelection(
            pageMetadata.textScriptNotation.filter(
              (m) => m.data.textScriptNotation === ExtendedTextScriptNotation.SUPERSCRIPT,
            ),
            position,
          );
          break;
        }
      }

      switch (kind) {
        case LetterKind.GRAS: {
          if (hasOneWithoutKindInSelection) {
            // Set kind
            return applySetBoldFromPosition({
              variables: {
                projectId,
                stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
                start: position.start,
                size: position.size,
              },
            });
          }
          // Remove kind
          return applyUnsetBoldFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
              start: position.start,
              size: position.size,
            },
          });
        }
        case LetterKind.ITALIQUE: {
          if (hasOneWithoutKindInSelection) {
            // Set kind
            return applySetItalicizationFromPosition({
              variables: {
                projectId,
                stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
                start: position.start,
                size: position.size,
              },
            });
          }
          // Remove kind
          return applyUnsetItalicizationFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
              start: position.start,
              size: position.size,
            },
          });
        }
        case LetterKind.CITATION: {
          if (hasOneWithoutKindInSelection) {
            // Set kind
            return applySetCitationFromPosition({
              variables: {
                projectId,
                stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
                start: position.start,
                size: position.size,
              },
            });
          }
          // Remove kind
          return applyUnsetCitationFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
              start: position.start,
              size: position.size,
            },
          });
        }
        case LetterKind.MAJUSCULE: {
          if (hasOneWithoutKindInSelection) {
            // Set kind
            return applySetCaseFromPosition({
              variables: {
                projectId,
                stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
                start: position.start,
                size: position.size,
                kind: ExtendedTextCase.UPPER,
              },
            });
          }
          // Remove kind
          return applyUnsetCaseFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
              start: position.start,
              size: position.size,
              kind: ExtendedTextCase.UPPER,
            },
          });
        }
        case LetterKind.MINUSCULE: {
          if (hasOneWithoutKindInSelection) {
            // Set kind
            return applySetCaseFromPosition({
              variables: {
                projectId,
                stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
                start: position.start,
                size: position.size,
                kind: ExtendedTextCase.LOWER,
              },
            });
          }
          // Remove kind
          return applyUnsetCaseFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
              start: position.start,
              size: position.size,
              kind: ExtendedTextCase.LOWER,
            },
          });
        }
        case LetterKind.EXPOSANT: {
          if (hasOneWithoutKindInSelection) {
            // Set kind
            return applySetScriptNotationFromPosition({
              variables: {
                projectId,
                stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
                start: position.start,
                size: position.size,
                scriptNotation: ExtendedTextScriptNotation.SUPERSCRIPT,
              },
            });
          }
          // Remove kind
          return applyUnsetScriptNotationFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
              start: position.start,
              size: position.size,
              scriptNotation: ExtendedTextScriptNotation.SUPERSCRIPT,
            },
          });
        }
        case LetterKind.INDICE: {
          if (hasOneWithoutKindInSelection) {
            // Set kind
            return applySetScriptNotationFromPosition({
              variables: {
                projectId,
                stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
                start: position.start,
                size: position.size,
                scriptNotation: ExtendedTextScriptNotation.SUBSCRIPT,
              },
            });
          }
          // Remove kind
          return applyUnsetScriptNotationFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
              start: position.start,
              size: position.size,
              scriptNotation: ExtendedTextScriptNotation.SUBSCRIPT,
            },
          });
        }
        case LetterKind.MUTE: {
          if (hasOneWithoutKindInSelection) {
            // Set kind
            return applySetMuteFromPosition({
              variables: {
                projectId,
                stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
                start: position.start,
                size: position.size,
              },
            });
          }
          // Remove kind
          return applyUnsetMuteFromPosition({
            variables: {
              projectId,
              stringVersion: pageMetadata?.page?.anchors[0].stringVersion,
              start: position.start,
              size: position.size,
            },
          });
        }
        case LetterKind.DEFAULT:
        default:
          return;
      }
    },
    [
      position,
      pageMetadata,
      applyUnsetBoldFromPosition,
      projectId,
      applySetBoldFromPosition,
      applyUnsetItalicizationFromPosition,
      applySetItalicizationFromPosition,
      applyUnsetCitationFromPosition,
      applySetCitationFromPosition,
      applyUnsetCaseFromPosition,
      applySetCaseFromPosition,
      applyUnsetScriptNotationFromPosition,
      applySetScriptNotationFromPosition,
      applyUnsetMuteFromPosition,
      applySetMuteFromPosition,
    ],
  );
  return {
    handleLettersKindAssignation,
  };
};

export default useHandleLettersKindAssignation;
