import React from "react";
import { motion } from "framer-motion";
import classNames from "classnames";
import * as style from "./ProjectCard.style.less";
import { ClickableWrapper } from "../../../../components/clickableWrapper/ClickableWrapper";
import BadgeStatus from "../../../editor/Project/components/BadgeStatus/BadgeStatus";
import { useProjectStatusLabel } from "../../../editor/Project/hooks/useProjectStatusLabel";
import { SubToProjectForHomepage_projects } from "../../../editor/Library/__generated__/SubToProjectForHomepage";

type ProjectCardProps = {
  openProject: (projectId: string) => void;
  project: SubToProjectForHomepage_projects;
  key: number;
};

export default function ProjectCard({ project, openProject }: ProjectCardProps) {
  const bookStatusLabel = useProjectStatusLabel(project);

  return (
    <ClickableWrapper disabled={project.isDoingAudioResync}>
      <motion.div
        onClick={project.isDoingAudioResync ? undefined : () => openProject(project.id)}
        initial={{ backgroundColor: "rgba(91, 96, 121, 0.7)" }}
        whileHover={{ backgroundColor: "rgba(91, 96, 121, 1)" }}
        className={classNames(style.project, { [style.isInReSync]: project.isDoingAudioResync })}>
        {project.name}
        <BadgeStatus className={style.badge} bookStatusLabel={bookStatusLabel} small />
      </motion.div>
    </ClickableWrapper>
  );
}
