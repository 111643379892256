import React, { useMemo, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import * as style from "./style.less";
import { useSubToLibrary } from "../../../editor/Library/api";
import { Layout, Spin, Tabs } from "antd";
import { useI18n } from "../../../../i18n";
import { UserPanel, useUserPanel } from "../../../../identity/User/Panel";
import SearchBar from "../../../editor/Library/ProjectsPage/SearchBar";
import ProjectCard from "../ProjectCard/ProjectCard";
import Header from "../../../../components/Header/Header";
import { getTabItem, TabItems } from "../../../editor/styles/antDesignTheme";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type BookListProps = {
  openProject: (projectId: string) => void;
};

const i18nKey = "library.index";
const defaultI18nKey = `${i18nKey}.default`;
const navBarI18nKey = `${defaultI18nKey}.navBar`;

const BookList = ({ openProject }: BookListProps) => {
  const { loading, error, data } = useSubToLibrary({ archivedProjects: false });
  const [filterString, setFilterString] = useState("");

  const projects = useMemo(() => {
    const projects = data?.projects || [];
    const isSubStr = (reference: string, subString: string) =>
      reference.toUpperCase().indexOf(subString.toUpperCase()) > -1;
    return projects.filter(
      (p) => isSubStr(p.name, filterString) || isSubStr(p.author, filterString),
    );
  }, [data, filterString]);
  const { isUserPanelOpened, openPanel, closePanel } = useUserPanel();

  const [t] = useI18n();
  const errorMessage = t(`${navBarI18nKey}.errorMessage`);

  const tabItems: TabItems = useMemo(
    () => [
      getTabItem({
        key: "1",
        label: t(`${navBarI18nKey}.tabTitle`),
        children: loading ? (
          <Spin indicator={antIcon} />
        ) : error ? (
          errorMessage
        ) : (
          <div className={style.projects}>
            {projects.map((p) => (
              <ProjectCard key={p.id} project={p} openProject={openProject} />
            ))}
          </div>
        ),
      }),
    ],
    [error, errorMessage, loading, openProject, projects, t],
  );

  return (
    <>
      <Layout className={style.root}>
        <Header openPanel={openPanel} />
        <Layout.Content className={style.content}>
          <Tabs
            className={style.tabs}
            tabBarExtraContent={{
              left: <h1 className={style.libraryTitle}>{t("audioLibrarty")}</h1>,
              right: <SearchBar onChange={setFilterString} />,
            }}
            items={tabItems}
          />
        </Layout.Content>
      </Layout>
      <UserPanel closePanel={closePanel} isUserPanelOpened={isUserPanelOpened} />
    </>
  );
};
export default BookList;
