import { useEffect, useMemo, useState } from "react";

import { useMiscState } from "../../Contexts/Misc";

const modifyGain = (stream: MediaStream, gainValue: number): MediaStream => {
  const ctx = new AudioContext();
  const src = ctx.createMediaStreamSource(stream);
  const dst = ctx.createMediaStreamDestination();
  const gainNode = ctx.createGain();
  gainNode.gain.value = gainValue;
  // @ts-ignore
  [src, gainNode, dst].reduce((a, b) => a && a.connect(b));
  return dst.stream;
};

declare var MediaRecorder: any;

export const useMediaRecorder = () => {
  const { settings } = useMiscState();
  const [isRecording, setIsRecording] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [error, setError] = useState<any>();
  const [recordingDevices, setRecordingDevices] = useState<Array<MediaDeviceInfo>>([]);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();

  useEffect(() => {
    (async function () {
      try {
        const s = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: settings?.echoCancellation || false,
            noiseSuppression: settings?.noiseSuppression || false,
            autoGainControl: settings?.autoGainControl || false,
            deviceId: settings?.deviceId || undefined,
          },
        });
        const devicesList = await navigator.mediaDevices.enumerateDevices();
        setRecordingDevices(devicesList);
        setStream(s);
      } catch (error) {
        setError(error);
      }
    })();
  }, [settings]);

  useEffect(() => {
    if (stream) {
      setMediaRecorder(new MediaRecorder(modifyGain(stream, settings?.gainValue || 1)));
    }
  }, [stream, settings]);

  useEffect(() => {
    if (!mediaRecorder) return;
    mediaRecorder.onstop = () => setIsRecording(false);

    return () => {
      mediaRecorder?.stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    };
  }, [mediaRecorder]);

  const { start, stop } = useMemo(
    () => ({
      start: () => {
        setIsRecording(true);
        mediaRecorder?.start();
      },
      stop: () => {
        setIsRecording(false);
        mediaRecorder?.stop();
      },
    }),
    [mediaRecorder],
  );

  return {
    start,
    stop,
    error,
    recorder: mediaRecorder,
    recordingDevices,
    isRecording,
  };
};
