import React from "react";

export const ValidationCheckSvg = () => {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1.65625L4 9.65625L0.34375 6L1.28125 5.0625L4 7.78125L11.0625 0.71875L12 1.65625Z"
        fill="#61C374"
      />
    </svg>
  );
};
