export enum TAGS {
  "af-ZA" = "af-ZA",
  "am-ET" = "am-ET",
  "ar-AE" = "ar-AE",
  "ar-BH" = "ar-BH",
  "ar-DZ" = "ar-DZ",
  "ar-EG" = "ar-EG",
  "ar-IQ" = "ar-IQ",
  "ar-JO" = "ar-JO",
  "ar-KW" = "ar-KW",
  "ar-LB" = "ar-LB",
  "ar-LY" = "ar-LY",
  "ar-MA" = "ar-MA",
  "arn-CL" = "arn-CL",
  "ar-OM" = "ar-OM",
  "ar-QA" = "ar-QA",
  "ar-SA" = "ar-SA",
  "ar-SD" = "ar-SD",
  "ar-SY" = "ar-SY",
  "ar-TN" = "ar-TN",
  "ar-YE" = "ar-YE",
  "as-IN" = "as-IN",
  "az-az" = "az-az",
  "az-Cyrl-AZ" = "az-Cyrl-AZ",
  "az-Latn-AZ" = "az-Latn-AZ",
  "ba-RU" = "ba-RU",
  "be-BY" = "be-BY",
  "bg-BG" = "bg-BG",
  "bn-BD" = "bn-BD",
  "bn-IN" = "bn-IN",
  "bo-CN" = "bo-CN",
  "br-FR" = "br-FR",
  "bs-Cyrl-BA" = "bs-Cyrl-BA",
  "bs-Latn-BA" = "bs-Latn-BA",
  "ca-ES" = "ca-ES",
  "co-FR" = "co-FR",
  "cs-CZ" = "cs-CZ",
  "cy-GB" = "cy-GB",
  "da-DK" = "da-DK",
  "de-AT" = "de-AT",
  "de-CH" = "de-CH",
  "de-DE" = "de-DE",
  "de-LI" = "de-LI",
  "de-LU" = "de-LU",
  "dsb-DE" = "dsb-DE",
  "dv-MV" = "dv-MV",
  "el-CY" = "el-CY",
  "el-GR" = "el-GR",
  "en-029" = "en-029",
  "en-AU" = "en-AU",
  "en-BZ" = "en-BZ",
  "en-CA" = "en-CA",
  "en-cb" = "en-cb",
  "en-GB" = "en-GB",
  "en-IE" = "en-IE",
  "en-IN" = "en-IN",
  "en-JM" = "en-JM",
  "en-MT" = "en-MT",
  "en-MY" = "en-MY",
  "en-NZ" = "en-NZ",
  "en-PH" = "en-PH",
  "en-SG" = "en-SG",
  "en-TT" = "en-TT",
  "en-US" = "en-US",
  "en-ZA" = "en-ZA",
  "en-ZW" = "en-ZW",
  "es-AR" = "es-AR",
  "es-BO" = "es-BO",
  "es-CL" = "es-CL",
  "es-CO" = "es-CO",
  "es-CR" = "es-CR",
  "es-DO" = "es-DO",
  "es-EC" = "es-EC",
  "es-ES" = "es-ES",
  "es-GT" = "es-GT",
  "es-HN" = "es-HN",
  "es-MX" = "es-MX",
  "es-NI" = "es-NI",
  "es-PA" = "es-PA",
  "es-PE" = "es-PE",
  "es-PR" = "es-PR",
  "es-PY" = "es-PY",
  "es-SV" = "es-SV",
  "es-US" = "es-US",
  "es-UY" = "es-UY",
  "es-VE" = "es-VE",
  "et-EE" = "et-EE",
  "eu-ES" = "eu-ES",
  "fa-IR" = "fa-IR",
  "fi-FI" = "fi-FI",
  "fil-PH" = "fil-PH",
  "fo-FO" = "fo-FO",
  "fr-BE" = "fr-BE",
  "fr-CA" = "fr-CA",
  "fr-CH" = "fr-CH",
  "fr-FR" = "fr-FR",
  "fr-LU" = "fr-LU",
  "fr-MC" = "fr-MC",
  "fy-NL" = "fy-NL",
  "ga-IE" = "ga-IE",
  "gd-GB" = "gd-GB",
  "gd-ie" = "gd-ie",
  "gl-ES" = "gl-ES",
  "gsw-FR" = "gsw-FR",
  "gu-IN" = "gu-IN",
  "ha-Latn-NG" = "ha-Latn-NG",
  "he-IL" = "he-IL",
  "hi-IN" = "hi-IN",
  "hr-BA" = "hr-BA",
  "hr-HR" = "hr-HR",
  "hsb-DE" = "hsb-DE",
  "hu-HU" = "hu-HU",
  "hy-AM" = "hy-AM",
  "id-ID" = "id-ID",
  "ig-NG" = "ig-NG",
  "ii-CN" = "ii-CN",
  "in-ID" = "in-ID",
  "is-IS" = "is-IS",
  "it-CH" = "it-CH",
  "it-IT" = "it-IT",
  "iu-Cans-CA" = "iu-Cans-CA",
  "iu-Latn-CA" = "iu-Latn-CA",
  "iw-IL" = "iw-IL",
  "ja-JP" = "ja-JP",
  "ka-GE" = "ka-GE",
  "kk-KZ" = "kk-KZ",
  "kl-GL" = "kl-GL",
  "km-KH" = "km-KH",
  "kn-IN" = "kn-IN",
  "kok-IN" = "kok-IN",
  "ko-KR" = "ko-KR",
  "ky-KG" = "ky-KG",
  "lb-LU" = "lb-LU",
  "lo-LA" = "lo-LA",
  "lt-LT" = "lt-LT",
  "lv-LV" = "lv-LV",
  "mi-NZ" = "mi-NZ",
  "mk-MK" = "mk-MK",
  "ml-IN" = "ml-IN",
  "mn-MN" = "mn-MN",
  "mn-Mong-CN" = "mn-Mong-CN",
  "moh-CA" = "moh-CA",
  "mr-IN" = "mr-IN",
  "ms-BN" = "ms-BN",
  "ms-MY" = "ms-MY",
  "mt-MT" = "mt-MT",
  "nb-NO" = "nb-NO",
  "ne-NP" = "ne-NP",
  "nl-BE" = "nl-BE",
  "nl-NL" = "nl-NL",
  "nn-NO" = "nn-NO",
  "no-no" = "no-no",
  "nso-ZA" = "nso-ZA",
  "oc-FR" = "oc-FR",
  "or-IN" = "or-IN",
  "pa-IN" = "pa-IN",
  "pl-PL" = "pl-PL",
  "prs-AF" = "prs-AF",
  "ps-AF" = "ps-AF",
  "pt-BR" = "pt-BR",
  "pt-PT" = "pt-PT",
  "qut-GT" = "qut-GT",
  "quz-BO" = "quz-BO",
  "quz-EC" = "quz-EC",
  "quz-PE" = "quz-PE",
  "rm-CH" = "rm-CH",
  "ro-mo" = "ro-mo",
  "ro-RO" = "ro-RO",
  "ru-mo" = "ru-mo",
  "ru-RU" = "ru-RU",
  "rw-RW" = "rw-RW",
  "sah-RU" = "sah-RU",
  "sa-IN" = "sa-IN",
  "se-FI" = "se-FI",
  "se-NO" = "se-NO",
  "se-SE" = "se-SE",
  "si-LK" = "si-LK",
  "sk-SK" = "sk-SK",
  "sl-SI" = "sl-SI",
  "sma-NO" = "sma-NO",
  "sma-SE" = "sma-SE",
  "smj-NO" = "smj-NO",
  "smj-SE" = "smj-SE",
  "smn-FI" = "smn-FI",
  "sms-FI" = "sms-FI",
  "sq-AL" = "sq-AL",
  "sr-BA" = "sr-BA",
  "sr-CS" = "sr-CS",
  "sr-Cyrl-BA" = "sr-Cyrl-BA",
  "sr-Cyrl-CS" = "sr-Cyrl-CS",
  "sr-Cyrl-ME" = "sr-Cyrl-ME",
  "sr-Cyrl-RS" = "sr-Cyrl-RS",
  "sr-Latn-BA" = "sr-Latn-BA",
  "sr-Latn-CS" = "sr-Latn-CS",
  "sr-Latn-ME" = "sr-Latn-ME",
  "sr-Latn-RS" = "sr-Latn-RS",
  "sr-ME" = "sr-ME",
  "sr-RS" = "sr-RS",
  "sr-sp" = "sr-sp",
  "sv-FI" = "sv-FI",
  "sv-SE" = "sv-SE",
  "sw-KE" = "sw-KE",
  "syr-SY" = "syr-SY",
  "ta-IN" = "ta-IN",
  "te-IN" = "te-IN",
  "tg-Cyrl-TJ" = "tg-Cyrl-TJ",
  "th-TH" = "th-TH",
  "tk-TM" = "tk-TM",
  "tlh-QS" = "tlh-QS",
  "tn-ZA" = "tn-ZA",
  "tr-TR" = "tr-TR",
  "tt-RU" = "tt-RU",
  "tzm-Latn-DZ" = "tzm-Latn-DZ",
  "ug-CN" = "ug-CN",
  "uk-UA" = "uk-UA",
  "ur-PK" = "ur-PK",
  "uz-Cyrl-UZ" = "uz-Cyrl-UZ",
  "uz-Latn-UZ" = "uz-Latn-UZ",
  "uz-uz" = "uz-uz",
  "vi-VN" = "vi-VN",
  "wo-SN" = "wo-SN",
  "xh-ZA" = "xh-ZA",
  "yo-NG" = "yo-NG",
  "zh-CN" = "zh-CN",
  "zh-HK" = "zh-HK",
  "zh-MO" = "zh-MO",
  "zh-SG" = "zh-SG",
  "zh-TW" = "zh-TW",
  "zu-ZA" = "zu-ZA",
}
