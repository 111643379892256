import { useCallback, useEffect, useState } from "react";
import { SelectedTextPosition } from "../components/EditingModes/Formatting/components/FormattingComp/FormattingComp";
import { useUpdateDisplayedPageNumber } from "../components/Page";

const CONTROL_KEY = "Control";

export const useTextPosition = () => {
  const updateDisplayedPageNumber = useUpdateDisplayedPageNumber();
  const [selectedTextPosition, setSelectedTextPosition] = useState<SelectedTextPosition>({
    start: 0,
    size: 0,
  });
  const [pressingControl, setPressingControl] = useState(false);

  const reset = useCallback(() => {
    setSelectedTextPosition({ start: 0, size: 0 });
    window.getSelection()?.removeAllRanges();
  }, []);

  const saveSelection = useCallback(() => {
    const selection = window.getSelection();
    if (!selection) return reset();

    let { focusNode } = selection;

    // Replace url pageNumber to the one of the selection
    // Get the first parent with pageContent class
    const pageContent = focusNode?.parentElement?.closest(".pageContent");

    // Get page number
    const pageId = pageContent?.getAttribute?.("id")?.split("-")[1];
    if (pageId && pageId !== window.location.search.split("=")[1]) {
      updateDisplayedPageNumber(pageId);
    }

    if (selection.toString().length === 0) {
      setSelectedTextPosition({
        start:
          parseInt(focusNode?.parentElement?.getAttribute("data-absolute-position") || "0") -
          (1 - selection?.anchorOffset),
        size: 0,
      });
      return;
    }

    const pageLetters = focusNode?.parentElement
      // eslint-disable-next-line
      ?.closest('[id^="page-"]')
      ?.querySelectorAll(".letter,.ponctuation");
    if (!pageLetters) return reset();

    const selectedLetters = Array.from(pageLetters)
      .filter((letter) => {
        return selection?.containsNode(letter?.childNodes?.[0] || letter, false);
      })
      .map((letter) => {
        if (!(letter instanceof HTMLElement) || !letter.dataset.absolutePosition) {
          throw new Error("letter.dataset.absoluteTextPosition is undefined");
        }
        return parseInt(letter.dataset.absolutePosition);
      })
      .sort((a, b) => a - b);

    if (selectedLetters.length === 0) {
      setSelectedTextPosition({
        start: parseInt(focusNode?.parentElement?.getAttribute("data-absolute-position") || "0"),
        size: 0,
      });
      return;
    }

    const selectedPosition = {
      start: selectedLetters[0],
      size: selectedLetters[selectedLetters.length - 1] - selectedLetters[0] + 1,
    };

    setSelectedTextPosition(selectedPosition);
  }, [reset, updateDisplayedPageNumber]);

  // Save control pressed state
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === CONTROL_KEY) {
        setPressingControl(true);
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === CONTROL_KEY) {
        setPressingControl(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  });

  return {
    selectedTextPosition,
    saveSelection,
    reset,
    pressingControl,
  };
};
