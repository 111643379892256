import React from "react";
import { useI18n } from "../../../../../../i18n";
import { SubToProject_project } from "../../../api/__generated__/SubToProject";
import * as style from "../style.less";

export default function ProjectInfo({ project }: { project: SubToProject_project }) {
  const { info } = project;
  const [t] = useI18n();
  const i18nKey = "project.actionBar.projectInfo";
  const paragraphKinds = [...new Set(info.usedParagraphsKinds)];

  return (
    <div className={style.infos}>
      <h4>{t(`${i18nKey}.title`)}</h4>
      <div>
        {t(`${i18nKey}.description`)}
        <ul>
          <li>
            {t(`${i18nKey}.charsNumber`)} {info.charsNumber}
          </li>
          <li>
            {t(`${i18nKey}.defsNumber`)} {info.defsNumber}
          </li>
          <li>
            {t(`${i18nKey}.imagesNumber`)} {info.imagesNumber}
          </li>
          <li>
            {t(`${i18nKey}.pageNumber`)} {info.pageNumber}
          </li>
          <li>
            {t(`${i18nKey}.rhesesNumber`)} {info.rhesesNumber}
          </li>
          <li>
            {t(`${i18nKey}.wordsNumber`)} {info.wordsNumber}
          </li>
        </ul>
      </div>
      <div>
        {t(`${i18nKey}.listTitle`)}
        <ul>
          {paragraphKinds.map((kind) => (
            <li key={kind}>{kind}</li>
          ))}
        </ul>
      </div>
      <div>
        {t("customCssClassesUsed")}
        <ul>
          {(info.usedCustomCssClasses || []).map((className, i) => (
            <li key={i}>{className}</li>
          ))}
        </ul>
      </div>
      <a
        download={`${project.name}_KPI.csv`}
        href={`data:application/octet-stream;charset=utf-16le;base64,${btoa(`
              Nombre de caractères        | ${info.charsNumber}
              Nombre de définitions       | ${info.defsNumber}
              Nombre d'images             | ${info.imagesNumber}
              Nombre de pages             | ${info.pageNumber}
              Nombre de rhèses            | ${info.rhesesNumber}
              Nombre de mots              | ${info.wordsNumber}
              Types de paragraphes        | ${info.usedParagraphsKinds.join(",")}
              Classes CSS personnalisées  | ${(info.usedCustomCssClasses || []).join(",")}
            `)}`}>
        {t(`${i18nKey}.export`)}
      </a>
    </div>
  );
}
