import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { Button } from "antd";
import { motion } from "framer-motion";
import { getRecordingPresignedUrls } from "../../../../Queries/queries";
import PlayIcon from "../../assets/play";
// import * as style from "../style.less";
import * as style from "../../ProofListening/style.less";
import { AudioReviewStatus } from "../../../../../../../__generated__/globalTypes";
import StatusSelector from "../../ProofListening/Rheses/StatusSelector";
import CommentButtonAndModal from "../../ProofListening/Rheses/CommentButtonAndModal";
import StopIconSvg from "../../../../../../../assets/svg/StopIconSvg";
import { SubscribeToExtendedPage_extendedPage_definition } from "../../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

/**
 * Note: this component uses the styles comming
 * from "../../ProofListening/ to look like a rhese.
 */

const DefinitionItemForProofListening = ({
  children,
  definition,
  filename,
  onPlayEnded,
  updateComment,
  updateStatus,
}: {
  children: string;
  definition: SubscribeToExtendedPage_extendedPage_definition;
  filename: string | null;
  onPlayEnded: (definition: SubscribeToExtendedPage_extendedPage_definition) => void;
  updateComment: ({
    definition,
    status,
    comment,
  }: {
    definition: SubscribeToExtendedPage_extendedPage_definition;
    status?: AudioReviewStatus;
    comment?: string;
  }) => void;
  updateStatus: ({
    definition,
    status,
    comment,
  }: {
    definition: SubscribeToExtendedPage_extendedPage_definition;
    status?: AudioReviewStatus;
    comment?: string;
  }) => void;
}) => {
  const { audioReviewStatus, audioReviewComment } = definition.data;

  // Get the audio url
  const { data } = useQuery(getRecordingPresignedUrls, {
    variables: { filename: filename || undefined },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });
  const audioUrl = data?.audioRecording?.presignedUrl?.getUrl;

  // Instanciate the audio element
  const audio = useMemo(() => new Audio(), []);
  const [isPlaying, setIsPlaying] = useState(false);

  /**
   * Handlers
   */
  const play = useCallback(
    (e: React.MouseEvent<any, MouseEvent>) => {
      e.stopPropagation();
      if (!audioUrl) {
        return;
      }
      audio.src = audioUrl;
      audio.play();
      setIsPlaying(true);
      audio.onended = () => {
        onPlayEnded(definition);
        setIsPlaying(false);
      };
    },
    [audio, definition, audioUrl, onPlayEnded],
  );
  const stop = useCallback(
    (e: React.MouseEvent<any, MouseEvent>) => {
      e.stopPropagation();
      if (!audioUrl) {
        return;
      }
      e.stopPropagation();
      audio.pause();
      setIsPlaying(false);
    },
    [audio, audioUrl],
  );

  const _updateComment = useCallback(
    (comment: string) => updateComment({ definition, comment }),
    [updateComment, definition],
  );

  const _updateStatus = useCallback(
    (status) => updateStatus({ definition, status }),
    [updateStatus, definition],
  );

  return (
    <motion.div
      className={style.rheseContainer}
      whileHover="hover"
      initial={{ opacity: 0.65 }}
      variants={{ hover: { opacity: 1 } }}>
      <div className={`${style.rhese}`}>
        <div className={style.content}>{children}</div>

        <div onClick={(e) => e.stopPropagation()} className={style.audioReview}>
          <motion.div
            initial={{ opacity: audioReviewComment ? 1 : 0.3 }}
            animate={{ opacity: audioReviewComment ? 1 : 0.3 }}
            variants={{ hover: { opacity: 1 } }}>
            <CommentButtonAndModal defaultValue={audioReviewComment} onValidate={_updateComment} />
          </motion.div>
          <StatusSelector status={audioReviewStatus} onChange={_updateStatus} />
        </div>
      </div>
      <motion.div
        className={style.playRhese}
        initial={{ opacity: 0 }}
        variants={{ hover: { opacity: 1 } }}>
        {!isPlaying ? (
          <Button disabled={!filename} shape="round" onClick={play}>
            <PlayIcon />
          </Button>
        ) : (
          <Button disabled={!filename} shape="round" onClick={stop}>
            <StopIconSvg className={style.stopIcon} />
          </Button>
        )}
      </motion.div>
    </motion.div>
  );
};

export default DefinitionItemForProofListening;
