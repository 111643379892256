import React, { useCallback, useEffect, useState } from "react";
import { useI18nObjectHook } from "../../../../../../../../../../i18n";
import { getCommonClass, hasDifferentClasses } from "./utils";
import { SelectedTextPosition } from "../../FormattingComp";
import { SubscribeToExtendedPage_extendedPage_customCss } from "../../../../../../../api/__generated__/SubscribeToExtendedPage";
import { useCreateCssFromPosition } from "../../../../../../../api/metadata/dynamic/customCss";

type Args = {
  setVisibility: (value: boolean) => void;
  selectedTextPosition?: SelectedTextPosition;
  classesMetadata: SubscribeToExtendedPage_extendedPage_customCss[];
  projectId: string;
  stringVersion: number;
};

export const useModal = ({
  setVisibility,
  selectedTextPosition = { start: 0, size: 0 },
  classesMetadata,
  projectId,
  stringVersion,
}: Args) => {
  /**
   * State
   */
  const [value, setValue] = useState("");
  const [isOverrideWarning, setIsOverrideWarning] = useState(false);

  const strings = useI18nObjectHook("project.existing.editingMode.formatting.modalCustomCss");

  /**
   * Methods
   */
  const close = useCallback(() => setVisibility(false), [setVisibility]);

  /**
   * Requests
   */
  const [applyCreateCssFromPosition] = useCreateCssFromPosition();
  const [createCssLoading, setCreateCssLoading] = useState(false);

  /**
   * Handlers
   */
  const onChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    // @ts-ignore // TODO LOIC CHECK
    setValue(event.target.value);
  }, []);

  const onCancel = useCallback(() => {
    close();
    setValue("");
  }, [close]);

  const onOk = useCallback(async () => {
    if (createCssLoading) {
      return;
    }
    setCreateCssLoading(true);
    await applyCreateCssFromPosition({
      variables: {
        projectId: projectId,
        start: selectedTextPosition.start,
        size: selectedTextPosition.size,
        stringVersion: stringVersion,
        newCss: {
          customCssClasses: value?.trim(),
        },
      },
    });
    setCreateCssLoading(false);
    onCancel();
  }, [
    createCssLoading,
    applyCreateCssFromPosition,
    projectId,
    selectedTextPosition.start,
    selectedTextPosition.size,
    stringVersion,
    value,
    onCancel,
  ]);

  /**
   * Effects
   */
  // Effect needed to keep a trace of the selected letters (because when the modal opens and the dropdown closes, selectedLetters received from parents is reset)
  // useEffect(() => {
  //   if (argsSelectedLetters?.length) {
  //     setSelectedLetters(argsSelectedLetters);
  //   }
  // }, [argsSelectedLetters]);

  // Show warning if there is different classes to avoid unwanted overide
  useEffect(() => {
    const showWarning = hasDifferentClasses(selectedTextPosition, classesMetadata);
    setIsOverrideWarning(showWarning);
  }, [classesMetadata, selectedTextPosition]);

  // Initial input value effect
  useEffect(() => {
    const commonClass = getCommonClass(selectedTextPosition);
    if (commonClass) {
      setValue(commonClass);
    } else {
      setValue("");
    }
  }, [selectedTextPosition]);

  return {
    inputValue: value,
    onInputChange: onChange,
    onOk,
    onCancel,
    strings,
    isOverrideWarning,
    loading: createCssLoading,
  };
};
