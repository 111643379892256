@import "../../../../styles/colors.less";

.filter:hover {
  cursor: pointer;
}

.filter {
  transition: all 0.3s;
  width: 90%;
  padding: 1em 2em;
  border-bottom: 1px solid @light-grey;

  span {
    display: inline-block;
  }
  &:nth-child(1) {
    color: @grey;
  }
  &:nth-child(2) {
    color: @eastern-blue;
  }
  &:nth-child(3) {
    color: @electric-violet;
  }
  &:nth-child(4) {
    color: @purple;
  }
  &:nth-child(5) {
    color: @indigo;
  }
}

.selectedFilter {
  width: 100%;
}

.modal :global .ant-modal-body {
  display: flex;
  flex-direction: column;
  row-gap: 3em;
  padding: 4em 8em;
}

.root :global {
  #preview *::selection {
    background: @blue;
    color: @white;
  }

  .rhese:hover {
    background: fade(@blue, 30%);
  }

  .definition {
    border-left: 0.3em solid transparent;
    padding: 0 0.2em;
  }

  .all,
  .explicit {
    .definition.explicit {
      background-color: @light-grey;
      border-left: 0.3em solid @eastern-blue;
    }
  }

  .all,
  .implicit {
    .definition.implicit {
      background-color: @light-grey;
      border-left: 0.3em solid @electric-violet;
    }
  }

  .all,
  .ndbp {
    .definition.ndbp {
      background-color: @light-grey;
      border-left: 0.3em solid @purple;
    }
  }
}

.definitionActions {
  .action:hover {
    color: white !important;
    background-color: #61c374 !important;
  }

  :nth-child(1) .action {
    color: @grey;
  }

  :nth-child(2) .action {
    color: @green;
  }
}
