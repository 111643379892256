import React, { useEffect, useRef } from "react";
// @ts-ignore
import OktaSignIn from "@okta/okta-signin-widget";
import { clientId, issuer } from "../../okta";

import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { useI18n } from "../../i18n";

type Props = {
  baseUrl: string;
  onSuccess: (arg: any) => void;
  onError: (arg: any) => void;
};

const OktaSignInWidget = ({ baseUrl, onSuccess, onError }: Props) => {
  const widgetRef = useRef(null);

  const [t] = useI18n();
  const i18nKey = "authentication.SignInWidget";
  useEffect(() => {
    if (!widgetRef.current) return;

    const widget = new OktaSignIn({
      clientId,
      baseUrl: baseUrl,
      redirectUri: window.location.origin + "/login/callback",
      authParams: {
        issuer,
        display: "page",
      },
      features: {
        idpDiscovery: true,
      },
      idpDiscovery: {
        requestContext: window.location.origin + "/login/callback",
      },
      idps: [
        {
          type: "IdentityProviderName",
          id: "0oamq747xSO8Hp33P4x6",
          text: t(`${i18nKey}.text`),
        },
      ],
      idpDisplay: "SECONDARY",
    });

    widget.showSignInToGetTokens({ el: widgetRef.current }).then(onSuccess).catch(onError);

    return () => {
      widget.remove();
    };
  }, [baseUrl, onSuccess, onError, t]);

  return <div id="oktasigninanchor" ref={widgetRef} />;
};
export default OktaSignInWidget;
