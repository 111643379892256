import React, { useMemo, useEffect, useRef } from "react";
import bookRenderer from "../../BookRenderer";
import * as style from "./style.less";
import { SubscribeToExtendedPage_extendedPage } from "../../../api/__generated__/SubscribeToExtendedPage";
import { usePageData } from "../../../hooks/usePageData";
import { SubToPages_pages } from "../../../api/SubToPages";

type Props = {
  pageMetadata: SubscribeToExtendedPage_extendedPage;
  projectId: string;
  allPages: SubToPages_pages[];
};

export default function Overview({ projectId, allPages }: Props) {
  const { pageMetadata } = usePageData(allPages);
  const contentEditableRef = useRef<HTMLDivElement>(null);
  const RenderBook = useMemo(() => bookRenderer(), []);
  useEffect(() => {
    const noop = (e: Event) => {
      e.preventDefault();
      return false;
    };
    const contentEditableRefCurrent = contentEditableRef.current;
    contentEditableRefCurrent?.addEventListener("cut", noop, false);
    contentEditableRefCurrent?.addEventListener("paste", noop, false);
    contentEditableRefCurrent?.addEventListener("keydown", noop, false);
    contentEditableRefCurrent?.addEventListener("dragenter", noop, false);
    contentEditableRefCurrent?.addEventListener("dragleave", noop, false);
    contentEditableRefCurrent?.addEventListener("dragover", noop, false);
    contentEditableRefCurrent?.addEventListener("drop", noop, false);
    return () => {
      contentEditableRefCurrent?.removeEventListener("cut", noop);
      contentEditableRefCurrent?.removeEventListener("paste", noop);
      contentEditableRefCurrent?.removeEventListener("keydown", noop);
      contentEditableRefCurrent?.removeEventListener("dragenter", noop);
      contentEditableRefCurrent?.removeEventListener("dragleave", noop);
      contentEditableRefCurrent?.removeEventListener("dragover", noop);
      contentEditableRefCurrent?.removeEventListener("drop", noop);
    };
  }, [contentEditableRef]);

  return (
    <div className={style.root}>
      <RenderBook projectId={projectId}>{pageMetadata}</RenderBook>
    </div>
  );
}
