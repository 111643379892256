import React from "react";
import { Spinner } from "../../../../../components/Spinner";
import { useI18n } from "../../../../../i18n";
import { FrogRoles } from "../../../../../identity";
import DefinitionsListening from "./listening/DefinitionsListening";
import DefinitionsRecording from "./narrator/DefinitionsRecording";
import { ExtendedDefinitionKind } from "../../../../../../__generated__/globalTypes";
import { SubscribeToExtendedPage_extendedPage } from "../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

type Props = {
  projectId: string;
  pageId: string;
  selectedRole: FrogRoles;
  pageMetadata: SubscribeToExtendedPage_extendedPage;
};

export const Definitions = ({ selectedRole, projectId, pageMetadata }: Props) => {
  const [t] = useI18n();

  if (!pageMetadata) {
    return <Spinner />;
  }

  const definitions = pageMetadata?.definition;
  const definitionsExceptImplicit = definitions.filter(
    (d) => d.data.kind !== ExtendedDefinitionKind.IMPLICIT,
  );

  if (definitionsExceptImplicit.length === 0) {
    return null;
  }

  const Component =
    selectedRole === FrogRoles.Narrator ? DefinitionsRecording : DefinitionsListening;

  return (
    <>
      <h1>{t("definitions")}</h1>
      <Component
        definitions={definitionsExceptImplicit}
        projectId={projectId}
        pageMetadata={pageMetadata}
      />
    </>
  );
};
