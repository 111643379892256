import React from "react";
import { Typography, Progress } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import * as style from "../TabProgress.style.less";
import { colors } from "../../../../../../../narration/Constants/colors";

const { Text } = Typography;

type Props = {
  title: string;
  legend: string;
  percent: number;
};

export default function ProgressBar({ title, legend, percent }: Props) {
  return (
    <div className={style.progressContainer}>
      <div className={style.textContainer}>
        <Text className={style.progressTitle}>{title}</Text>
        <div>
          {percent === 100 && <CheckCircleOutlined className={style.iconChecked} />}
          <Text className={style.progressBarLegend} type="secondary">
            {legend}
          </Text>
        </div>
      </div>
      <Progress percent={percent} strokeColor={colors.BLUE_GREY} showInfo={false} strokeWidth={3} />
    </div>
  );
}
