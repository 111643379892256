import React, { useCallback, useMemo, useState } from "react";
import { AudioOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button } from "antd";
import { motion } from "framer-motion";
import { getRecordingPresignedUrls } from "../../../../Queries/queries";
import PlayIcon from "../../assets/play";
import * as style from "../style.less";
import { Spinner } from "../../../../../../components/Spinner";
import StopIconSvg from "../../../../../../../assets/svg/StopIconSvg";
import ReviewCommentAndStatus from "../../../../Components/reviewCommentAndStatus/ReviewCommentAndStatus";
import { AudioReviewStatus } from "../../../../../../../__generated__/globalTypes";

const DefinitionItem = ({
  children,
  onPressStart,
  onPressEnd,
  definitionId,
  filename,
  loading: loading,
  audioReviewComment,
  audioReviewStatus,
}: {
  onPressStart: () => void;
  onPressEnd: (definitionId: string, putUrl: string, filename: string) => void;
  children: string;
  definitionId: string;
  filename: string | null;
  loading: boolean;
  audioReviewStatus: AudioReviewStatus | null;
  audioReviewComment?: string | null;
}) => {
  const [pressed, setPressed] = useState(false);
  const { data } = useQuery(getRecordingPresignedUrls, {
    variables: { filename: filename || undefined },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const {
    getUrl,
    putUrl,
    filename: generatedFilename,
  } = useMemo(() => {
    const res = data?.audioRecording?.presignedUrl;
    if (res) {
      return res;
    }
    return { getUrl: null, putUrl: null, filename: null };
  }, [data]);

  const handleOnPressStart = useCallback(
    (
      e:
        | React.PointerEvent<HTMLButtonElement>
        | React.PointerEvent<HTMLButtonElement | HTMLAnchorElement>,
    ) => {
      e.preventDefault();
      e.stopPropagation();
      if (loading || pressed) return;
      setPressed(true);
      onPressStart();
    },
    [onPressStart, loading, pressed],
  );

  const handleOnPressEnd = useCallback(() => {
    if (!pressed) {
      return;
    }
    setPressed(false);
    onPressEnd(definitionId, putUrl, filename || generatedFilename);
  }, [pressed, onPressEnd, definitionId, putUrl, generatedFilename, filename]);

  const audio = useMemo(() => new Audio(), []);

  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <motion.div className={style.recorder} onMouseLeave={handleOnPressEnd} whileHover="hover">
      <span className={style.definitionLabel}>{children}</span>
      <ReviewCommentAndStatus comment={audioReviewComment} audioReviewStatus={audioReviewStatus} />
      <motion.div className={style.definitionActions}>
        <Button
          onPointerDown={handleOnPressStart}
          onPointerUp={handleOnPressEnd}
          onPointerLeave={handleOnPressEnd}
          className={style.recordButton}
          shape="circle"
          disabled={loading || isPlaying}>
          {pressed ? (
            <motion.div
              animate={{
                scale: [1, 0.25, 1],
              }}
              style={{
                background: "red",
                width: "1.5em",
                height: "1.5em",
                borderRadius: "100%",
              }}
              transition={{
                duration: 1,
                ease: "easeInOut",
                repeat: Infinity,
                times: [0, 0.2, 0.5, 0.8, 1],
              }}
            />
          ) : (
            <>{loading ? <Spinner className={style.recordSpinner} small /> : <AudioOutlined />}</>
          )}
        </Button>
        {!isPlaying ? (
          <Button
            disabled={!filename}
            onMouseDown={async (e) => {
              e.stopPropagation();
              if (!getUrl) {
                return;
              }

              audio.src = `${getUrl}&cache-fix=${Math.random()}`;
              audio.play();
              setIsPlaying(true);

              audio.onended = function () {
                setIsPlaying(false);
              };
            }}
            className={style.recordButton}
            shape="circle">
            <PlayIcon />
          </Button>
        ) : (
          <Button
            disabled={!filename}
            onMouseDown={async (e) => {
              e.stopPropagation();
              audio.pause();
              setIsPlaying(false);
            }}
            className={style.recordButton}
            shape="circle">
            <StopIconSvg className={style.stopIcon} />
          </Button>
        )}
      </motion.div>
    </motion.div>
  );
};

export default DefinitionItem;
