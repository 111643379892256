import React from "react";
import { Button } from "antd";
import * as style from "../TabProgress.style.less";
import { useI18n } from "../../../../../../../../i18n";

type Props = {
  unarchiveProject: () => void;
};

export default function StepArchived({ unarchiveProject }: Props) {
  const [t] = useI18n();

  return (
    <div className={style.step}>
      <h4>{t("project.actionBar.progress.archived.title")}</h4>
      <div className={style.container}>
        <Button className={style.button} onClick={unarchiveProject} type="primary">
          {t("project.actionBar.progress.archived.buttonUnarchive")}
        </Button>
      </div>
    </div>
  );
}
