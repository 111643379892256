import { Input, Row, Button, Form, Modal, Select } from "antd";
import React, { RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TAGS } from "../../../../../i18n/tags";
import { useEditProject, useSubToProject } from "../../api/api";

type Props = {
  cancel: () => void;
  validate: (name: string, author: string) => void;
  projectId: string;
  reference?: RefObject<HTMLElement>;
};

export default function EditProject({ cancel, validate, projectId, reference }: Props) {
  const [name, setName] = useState("");
  const [author, setAuthor] = useState("");
  const [EAN, setEAN] = useState("");
  const [locale, setLocale] = useState("fr_FR");
  const [editProject] = useEditProject();

  const onApply = useCallback(async () => {
    await editProject({ variables: { id: projectId, name, author, EAN, locale } });
    validate(name, author);
  }, [editProject, projectId, name, author, EAN, locale, validate]);

  const { data, loading } = useSubToProject({
    id: projectId,
    withInfo: false,
    withAudioInfo: false,
  });

  const project = useMemo(() => {
    return data?.project[0];
  }, [data]);

  useEffect(() => {
    if (project) {
      setName(project.name);
      setEAN(project.EAN || "");
      setAuthor(project.author);
      setLocale(project.config.locale);
    }
  }, [project]);

  const [t] = useTranslation();

  const languageNames = useMemo(
    () => new Intl.DisplayNames([locale.replace(/_/g, "-")], { type: "language" }),
    [locale],
  );

  return (
    <Modal
      key={project?.id}
      centered={true}
      width={"56.66em"}
      title={t("project.edit.modalTitle")}
      open={!loading && projectId !== ""}
      onCancel={cancel}
      footer={null}
      getContainer={reference?.current as HTMLElement}>
      {!loading && projectId !== "" && (
        <Form name="basic" initialValues={{ remember: true }} onFinish={onApply} layout="vertical">
          <Form.Item
            style={{ padding: "0 7em" }}
            label={t("project.edit.form.bookName")}
            name="name">
            <Input defaultValue={project?.name || ""} onChange={(e) => setName(e.target.value)} />
          </Form.Item>
          <Form.Item
            style={{ padding: "0 7em" }}
            label={t("project.edit.form.bookAuthor")}
            name="author">
            <Input
              defaultValue={project?.author || ""}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </Form.Item>
          <Form.Item style={{ padding: "0 7em" }} label="EAN" name="ean">
            <Input defaultValue={project?.EAN || ""} onChange={(e) => setEAN(e.target.value)} />
          </Form.Item>
          <Form.Item style={{ padding: "0 7em" }} label={t("project.edit.form.locale")}>
            <Select
              showSearch
              value={locale.replace(/_/g, "-")}
              style={{ width: "100%" }}
              onChange={setLocale}>
              {Object.keys(TAGS).map((tag) => (
                <Select.Option key={tag} value={tag}>
                  {languageNames.of(tag.replace(/_/g, "-"))}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Row justify="center">
              <Button disabled={name.length === 0} type="ghost" htmlType="submit" shape="round">
                {t("project.edit.form.modifies")}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
