{
    "authentication": {
        "SignInWidget": {
            "text": "Connect with Google"
        }
    },
    "library": {
        "index": {
            "newProject": {
                "title": "Add a book"
            },
            "projectCard": {
                "menu": {
                    "modifies": "Edit",
                    "export": "Export the project",
                    "exportNarration": "Export the project for Narration",
                    "invite": "Invite a collaborator",
                    "delete": "Delete",
                    "exportEPub": "Export (epub)",
                    "viewExports": "Voir les exports",
                    "resync": "Lancer une re-synchro audio"
                },
                "button": {
                    "date": "Todo Date Format",
                    "title": "Go the text"
                }
            },
            "menu": {
                "signOut": "Logout"
            },
            "default": {
                "export": {
                    "message": "Project {{name}} export on-going",
                    "serverErrorMessage": "Internal server error",
                    "successNotification": "Download {{name}}",
                    "type": "octet/stream",
                    "errorNotification": "An error occurred in the export",
                    "colorationOptions": "Coloring options",
                    "audioOption": "Audio",
                    "audioOptionCheckbox": "Include audio"
                },
                "exportNarration": {
                    "message": "Export (narrative) of the current {{name}} project",
                    "serverErrorMessage": "The server returned an error",
                    "successNotification": "Download {{name}}",
                    "type": "octet/stream",
                    "errorNotification": "An error occurred in the export",
                    "exportsTabLabel": "Exports"
                },
                "project": {
                    "errorMessage": "The book import failed",
                    "successMessage": "The book is being imported"
                },
                "navBar": {
                    "title": "Shelf",
                    "tabTitle": "All",
                    "errorMessage": "An error has occurred, it is impossible to find the list of projects for the moment"
                }
            }
        },
        "inviteModal": {
            "title": "Invite a colleague",
            "email": "Colleague's email",
            "invite": "Invite"
        }
    },
    "project": {
        "a4Page": {
            "placeholder": "Name the chapter",
            "showMore": "View more"
        },
        "actionBar": {
            "stepComp": {
                "raw": "Raw",
                "inProgress": "In preparation",
                "toReview": "To be corrected",
                "done": "Completed"
            },
            "getStepLabel": {
                "formatting": "Segmentation",
                "definition": "Definitions",
                "syllables": "Syllabification",
                "rhese": "Division into units of meaning",
                "dialogues": "Dialogs",
                "narration": "Narration"
            },
            "projectInfo": {
                "title": "Informations",
                "description": "Figures and parameters",
                "charsNumber": "Number of signs:",
                "defsNumber": "Number of definitions:",
                "imagesNumber": "Number of pictures:",
                "pageNumber": "Number of pages:",
                "rhesesNumber": "Number of units of meaning:",
                "wordsNumber": "Number of words:",
                "listTitle": "Paragraph types",
                "export": "CSV export"
            },
            "checklist": {
                "title": "Checklist"
            },
            "userAccess": {
                "title": "Colleagues"
            },
            "default": {
                "tabPaneTitle": {
                    "preview": "General view",
                    "rhese": "Division in meaning units",
                    "formatting": "Page layout",
                    "definitions": "Definitions mode",
                    "dialogues": "Dialog mode",
                    "syllables": "Syllable mode",
                    "narration": "Narration mode"
                }
            },
            "projectTabs": {
                "info": "Info",
                "checklist": "Checklist",
                "access": "Colleagues",
                "config": "Setup",
                "progress": "Progress"
            },
            "config": {
                "title": "Configuration des clés",
                "customColoration": {
                    "title": "Custom coloring setup",
                    "add": "Add a new color distinction"
                },
                "customParagraphTag": {
                    "title": "Custom the paragraph tags",
                    "add": "Add a new paragraph tag"
                }
            },
            "progress": {
                "title_state_preparation": "in preparation",
                "title_state_narration": "In narration",
                "title_state_narrationComplete": "Narration completed",
                "title_state_narrationAndFixComplete": "Narration et correction complètes",
                "title_state_bookCompleted": "Book marked as completed",
                "preparing": {
                    "title": "In preparation",
                    "legendReadyToNarrate": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium\n",
                    "buttonReadyToNarrate": "Valider pour narration",
                    "legendPreparationComplete": "Les tâches complémentaires sont en cours de préparation",
                    "buttonPreparationComplete": "Valider la préparation"
                },
                "prepared": {
                    "title": "Préparé",
                    "legendTasksForNarrationComplete": "Les tâches nécessaires pour la narration sont terminées",
                    "buttonReadyToNarrate": "Ready to be narrated",
                    "legendPreparationComplete": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium",
                    "buttonPreparationComplete": "Preparation completed"
                },
                "narration": {
                    "title": "In narration",
                    "narratedPages": "Narrated pages",
                    "narratedDefinitions": "Narrated definitions",
                    "proofListening": "Proof Listening",
                    "remainingCorrections": "Remaining corrections",
                    "buttonDone": "Mark the book as completed",
                    "markNarrationDone": "Valider la narration",
                    "narrationDone": "Narration validée"
                },
                "preparationAndNarration": {
                    "markBookComplete": "Marquer le livre comme étant terminé"
                },
                "bookCompleted": {
                    "legend": "Un livre archivé est supprimé à l'affichage dahs la bibliothèque des différents acteurs.",
                    "legendBis": "Pour le désarchiver, contacter le développeur.",
                    "button": "Archive"
                },
                "bookStateStatus": {
                    "not_ready": "In preparation",
                    "ready_for_narration": "In narration",
                    "narration_complete": "Narration completed",
                    "complete": "Ready for validation",
                    "complete_book": "Complete",
                    "archived": "Archived",
                    "resync": "En re-synchro"
                },
                "archived": {
                    "title": "Archivé",
                    "buttonUnarchive": "Désarchiver"
                }
            }
        },
        "existing": {
            "bookRenderer": {
                "paraghapLabel": {
                    "citation": "Quotes",
                    "dialogue": "Dialog",
                    "didascalie": "Didascalia",
                    "email": "Email",
                    "intro": "Intro",
                    "normal": "Regular",
                    "note": "Note",
                    "resume": "Summary",
                    "vers": "Verses",
                    "center": "Center",
                    "mail": "Letter",
                    "right": "Right",
                    "space": "Space",
                    "bold": "Bold",
                    "text": "Short message",
                    "title1": "Titel 1",
                    "title2": "Titel 2",
                    "title3": "Titel 3",
                    "title4": "Titel 4",
                    "title5": "Title5",
                    "title6": "Title6",
                    "listUnordered": "Unordered list",
                    "listOrdered": "Ordered list",
                    "listDefinition": "Definition list"
                }
            },
            "index": {
                "syllableComponent": {
                    "syllables": "Syllables",
                    "phonemes": "Phonems",
                    "custom": "Customized"
                },
                "pageBar": {
                    "mutePage": "Dummy page(s)"
                },
                "goToPage": {
                    "nav": "Go to...",
                    "validation": "OK"
                }
            },
            "editingMode": {
                "definition": {
                    "buildDefinitionMenu": {
                        "associates": "Link to the definition",
                        "modifies": "Edit the definition",
                        "add": "Insert a definition",
                        "dissociate": "Suppress the definition"
                    },
                    "content": {
                        "all": "All defintions",
                        "explicit": "Explicit definitions",
                        "implicit": "Implicit definitions",
                        "foot_note": "Footnotes",
                        "image": "With image"
                    },
                    "definitionModal": {
                        "message": {
                            "assign": "Insert a definition",
                            "update": "Save",
                            "create": "Insert a definition"
                        },
                        "radioButton": {
                            "explicit": "E",
                            "implicit": "Implicit definitions",
                            "foot_note": "Footnotes",
                            "image": "With image"
                        },
                        "textArea": {
                            "placeholder": "Write the definition..."
                        },
                        "imageName": {
                            "placeholder": "Image name"
                        }
                    }
                },
                "formatting": {
                    "dropZone": "Insert a picture",
                    "menu": {
                        "titre_1": "Titel 1",
                        "titre_2": "Titel 2",
                        "titre_3": "Titel 3",
                        "titre_4": "Titel 4",
                        "normal": "Regular",
                        "dialogue": "Dialog",
                        "intro": "Introduction",
                        "resume": "Summary",
                        "email": "Email",
                        "citation": "Quotes",
                        "vers": "Verses",
                        "didascalie": "Didascalia",
                        "note": "Note",
                        "subMenuTitleTransformsTitle": "Turn into a titel...",
                        "subMenuTitleAlign": "Paragraph align...",
                        "space": "Space",
                        "center": "Center",
                        "right": "Right",
                        "subMenuTitleTransformsParagraph": "Turn into a paragraph",
                        "subMenuTitleException": "Exceptions",
                        "bold": "Bold",
                        "text": "Short Message",
                        "mail": "Letter",
                        "rheseAction": "Insert a pagebreak after this unit of meaning",
                        "spaceAction": {
                            "normal": "Turn into space",
                            "unbreakable": "Turn into an unbreakable space"
                        },
                        "lineSuppression": {
                            "normal": "Remove the line break",
                            "afterRhese": "Line break after this unit of meaning"
                        },
                        "titre_5": "Title level 5",
                        "titre_6": "Title level 6",
                        "subMenuList": "Lists...",
                        "listUnordered": "unordered",
                        "listOrdered": "ordered",
                        "listDefinition": "definitions",
                        "list": "List",
                        "addClassesAction": "Add custom css classes",
                        "removeClassesAction": "Delete custom css classes"
                    },
                    "lettersCount": {
                        "form": {
                            "name": "basic",
                            "item": {
                                "imageNaming": {
                                    "label": "Picture name",
                                    "name": "File name"
                                },
                                "validation": "Save",
                                "imageDescription": {
                                    "label": "Picture details",
                                    "name": "Details"
                                },
                                "radioButton": {
                                    "normal": "Regular",
                                    "popup": "Popup"
                                }
                            }
                        },
                        "result": "{{lettersCount}} signs"
                    },
                    "default": {
                        "modal": {
                            "title": "Insert a picture"
                        },
                        "form": {
                            "imageNaming": {
                                "label": "Picture name",
                                "name": "Name"
                            },
                            "validation": "Save",
                            "imageDescription": {
                                "label": "Picture details",
                                "name": "author"
                            },
                            "radioButton": {
                                "normal": "Regular",
                                "popup": "Popup"
                            },
                            "figcaption": {
                                "label": "Picture legend",
                                "name": "caption"
                            }
                        },
                        "tooltip": {
                            "title": "Add a picture"
                        }
                    },
                    "associateToSpeaker": {
                        "title": "Link to a speaker mark",
                        "name": "Speaker name",
                        "alphaNumError": "The value must be alphanumeric",
                        "dissociate": "Remove the speaker"
                    },
                    "modalCustomCss": {
                        "title": "Custom css classes",
                        "modalOkLabel": "Update",
                        "ovverideWarning": "The selected elements do not share the same classes. Validating a new entry will cause the old ones to be lost.",
                        "inputPlaceholder": "my-class1, my-class2"
                    }
                },
                "narration": {
                    "section": {
                        "minutes": "minutes"
                    },
                    "default": {
                        "title": "audio fragments"
                    }
                },
                "rhese": {
                    "index": {
                        "rhesesRenderer": {
                            "menu": {
                                "merge": "Merge the units of meaning",
                                "creat": "create a unit of meaning",
                                "split": "Split the unit of meaning"
                            },
                            "edit": "Edit"
                        }
                    }
                },
                "syllable": {
                    "buildSyllableMenu": {
                        "creat": "Define a syllable",
                        "transformMute": "Turn into mute letter(s)",
                        "transformNoMute": "Turn into a non-mute letter(s)",
                        "ignore": "Ignore syllable"
                    },
                    "buildPhonemeMenu": {
                        "title": "Define as a phonem",
                        "vowel": "Voyel",
                        "consonant": "Consonant",
                        "complex": "Complex",
                        "transformMute": "Turn into mute letter(s)",
                        "transformNoMute": "Turn into a non-mute letter(s)",
                        "ignore": "Remove the phonemic coloration"
                    },
                    "buildCustomColorsMenu": {
                        "assignCustomColor": "Define as",
                        "removeCustomColor": "Remove coloration"
                    }
                }
            }
        },
        "search": {
            "getSearchModeLabel": {
                "id": "Id",
                "text": "Text"
            },
            "nbResults": {
                "loading": "Loading...",
                "result": "Result",
                "results": "Results"
            }
        },
        "edit": {
            "modalTitle": "Edit a book",
            "form": {
                "bookName": "Book name",
                "bookAuthor": "Author's name",
                "locale": "Language",
                "modifies": "Edit"
            }
        },
        "new": {
            "stepOne": {
                "title": "Book text",
                "importType": "Load type",
                "successMessage": ": file successfully imported.",
                "errorMessage": ": failed to import the file.",
                "uploadOutlined": "Search on your computer...",
                "import": "Import",
                "rhesedText": "Text devided in units of meaning",
                "text": "Text",
                "htmlBook": "HTML",
                "locale": "Book language",
                "jsonImport": "JSON",
                "disableColorationOnSimpleWords": "Disable coloring on simple words"
            },
            "stepTwo": {
                "title": "Titel",
                "titlePlaceholder": "eg. To be, or not to be",
                "author": "Author",
                "authorPlaceholder": "eg. William Shakespeare",
                "ean": "EAN",
                "eanPlaceholder": "9782070360420",
                "create": "Create"
            },
            "modalTitle": "Add a book"
        },
        "importStatus": {
            "failed": {
                "title": "Inaccessible project",
                "message": "Project import failed",
                "action": "Return to the list of projects"
            },
            "inProgress": {
                "title": "Inaccessible project ",
                "message": "The import of the project is still in progress",
                "action": "Back to the list of projects"
            },
            "loading": {
                "title": "Loading...",
                "message": "Please wait..."
            }
        }
    },
    "userAccess": {
        "userTable": {
            "title": "Role",
            "preparer": "Preparator",
            "correction": "Corrector"
        },
        "tasksLabels": {
            "definition": "Definition",
            "dialogues": "Dialogs",
            "formatting": "Page layout ",
            "narration": "Narration",
            "rhese": "Division in units of meaning",
            "syllables": "Syllable coloration"
        }
    },
    "locale": {
        "fr_FR": "French",
        "en_GB": "English",
        "it_IT": "Italian",
        "sv_SE": "Swedish"
    },
    "page": "Page",
    "paragraphe": "Paragraph",
    "on": "on",
    "tagTheParagraph": "Tag the paragraph",
    "removeTagFromParagraph": "Remove the tag",
    "selectADefinition": "Select a definition",
    "associateToDefinition": "Associate to a definition",
    "editor": "Editor",
    "narrator": "Narrator",
    "prooflistener": "Audio controller",
    "audioLibrarty": "Audio library",
    "GQLError.cannotUpdateRheseContent.message": "impossible to modify the rhesis",
    "GQLError.cannotUpdateRheseContent.description": "The text is already associated with an audio file and cannot be modified",
    "select_recorgind_issue_type": "Select the type of remark",
    "audioReview.bad_speech": "Prononciation",
    "audioReview.bad_text": "Wrong text",
    "audioReview.recording": "Recording issue",
    "audioReview.valid": "Ok",
    "audioReview.comment": "Comment",
    "audioReview.typeYourComment": "Add your comment",
    "audioReview.addAReviewToSelectedRheses": "Add a remark to the selected rhesis",
    "cancel": "Cancel",
    "apply": "Apply",
    "reset": "Reset",
    "validate": "Valider",
    "confirm": "Confirmer",
    "warning": "Attention",
    "startOffset": "Start time shift",
    "endOffset": "End time shift",
    "audioOffset": "Shift audio",
    "listen": "Listen",
    "offsetConfigIsInvalid": "Setting is invalid",
    "listenToTheRecording": "Listen to the recording",
    "proofListening.pageNotFullyRecordedTitle": "This page of the book has not yet been narrated.",
    "proofListening.pageNotFullyRecordedButtonLabel": "Back",
    "definitions": "Definitions",
    "rheses": "Rhesis",
    "loadingError": "Loading error",
    "customCssClassesUsed": "Custom css classes used",
    "audioReview.bad_text_and_narration": "Problem text+narration",
    "audioReview.commentName": "Comments",
    "audioReview.resetStatusBtn": "Delete comments",
    "audioReview.resetStatusModalTitle": "Are you sure ?",
    "audioReview.resetStatusModalText": "Please note that this action will delete the statuses of the selected rheses, but also their comments.",
    "audioReview.resetStatusModalBtn": "Delete",
    "definitionRecordedAudioUploadSuccess": "The file has been successfully saved",
    "definitionRecordedAudioUploadError": "Error, the file has not been saved",
    "definitionAudioUploadSuccess": "The file has been successfully saved",
    "definitionAudioUploadError": "Error, the file has not been saved",
    "definitionAudioUploadErrorSize": "The file must not exceed",
    "definitionAudioUploadErrorExtension": "Extension not supported. Please use the following extensions",
    "filterWithIssues": "With issues",
    "filterAudioComplete": "With full audio",
    "filterRenarrated": "Reviewed",
    "audioReview.renarrated": "Reviewed",
    "rheseIgnoreSyncroMenuItemLabel": "Ignore this rhesis in synchronisation",
    "rheseUseSyncroMenuItemLabel": "OK to synchronise",
    "rheseUseSyncroDialogTitle": "Do you validate this operation?",
    "rheseUseSyncroDialogOk": "Validate",
    "rheseUseSyncroDialogCancel": "Cancel",
    "rheseIconSyncroTooltip": "Non-synchronised rhesis",
    "forceChange": "Force change",
    "adminCanForceChange": "You can force the change",
    "forcedUpdateWillDeleteAssociatedAudio": "Forcing the update will delete the associated audio files",
    "serverHasSentError": "The server returned an error",
    "errorHasBeenSentToTechStaff": "It was automatically forwarded to the technical team",
    "downloadErrorReport": "Download the error report",
    "tabArchivesLabel": "Archives",
    "exportType_DEFINITIONS": "Définitions",
    "exportType_FULL": "Complet",
    "exportType_HTML": "HTML",
    "exportType_INTO_IMPORTED_EPUB": "ePub",
    "exportType_NARRATION": "Simplifié",
    "exportType_SIMPLE": "Simplifié",

    "tableExportsColumnStatus": "Statut",
    "tableExportsColumnType": "Type",
    "tableExportsColumnProjectName": "Livre",
    "tableExportsColumnAuthor": "Utilisateur",
    "tableExportsColumnCreatedAt": "Démarré à",
    "tableExportsColumnCompletedAt": "Terminé à",
    "tableExportsColumnDuration": "Durée",
    "tableExportsColumnIncludeAudio": "Audio inclus",
    "tableExportsColumnConfig": "Config",
    "tableExportsColumnAction": "Action",
    "tableExportsRetentionInfo": "Les exports réussis restent affichés pour une période de 24h",

    "modalConfirmReSync": "La re-synchronisation audio d'un projet entraîne son blocage durant tout le traitement.\nConfirmez-vous l'action ?",
    "modalConfirmDelete": "Est-ce que vous confirmez la suppression de ce projet ?",
    "overlayReSync": "Projet en cours de re-synchronisation",

    "tabArchivesNoResult": "Pas de livres archivés",

    "proofListeningDataChanged": "Réinitialisation du lecteur audio, dû à un changement de fichier(s) audios",

    "revokeUserAccess": "Révoquer l'accès d'un utilisateur",
    "userEmail": "Email de l'utilisateur",
    "revokeUser": "Révoquer l'utilisateur",
    "filterLabelWith": "Avec",
    "filterLabelWithout": "Sans",
    "proofListening.pageOnlyAccessibleToAdmin": "Cette page n'est accessible qu'aux narrateurs et aux administrateurs car la narration n'est pas terminée.",
    "project.existing.bookRenderer.paraghapLabel.résumé": "Résumé",
    "reload.project": "Reload projet"
}
