.root {
  &.readOnly {
    :global(.ant-modal-body) {
      textarea {
        border: none;
      }
    }
  }
  &:not(.readOnly) {
    :global(.ant-modal-body) {
      textarea {
        min-height: 20em;
      }
    }
  }
}
