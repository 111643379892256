import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Redirect, RouteComponentProps, Switch } from "react-router";
import { SecureRoute } from "@okta/okta-react";
import OverviewRoute from "../EditingModes/Overview/route";
import * as style from "../../style.less";
import { useSubToProject } from "../../api/api";
import { ProjectProvider } from "../Page";
import { ImportStatus, ProjectKind } from "../../../../../../__generated__/globalTypes";
import SliderFontSize from "../SliderFontSize/SliderFontSize";
import { useSliderFontSizeStore } from "../SliderFontSize/context";
import SubRouteNotImported from "./subroutes/SubRouteNotImported";
import SubRouteFormat from "./subroutes/SubRouteFormat";
import SubRouteRhese from "./subroutes/SubRouteRhese";
import SubRouteSyllable from "./subroutes/SubRouteSyllable";
// import SubRouteNarration from "./subroutes/SubRouteNarration";
import SubRouteDefinition from "./subroutes/SubRouteDefinition";
import { subroutesPath } from "./subroutes/subroutesPath";
import { SubToPages_pages } from "../../api/SubToPages";
import GoToPage from "../GoToPage/GoToPage";

type Props = {
  projectId: string;
  pages: SubToPages_pages[];
  loading: boolean;
};

const EditingRouter = ({ projectId, loading, pages }: Props) => {
  const history = useHistory();
  const shouldDisplayFontResizer = !history.location.pathname.match(/narration/);

  const { loading: projectsLoading, data: projectsData } = useSubToProject({
    id: projectId,
    withInfo: false,
    withAudioInfo: false,
  });

  const project = useMemo(() => {
    if (projectsData?.project) {
      return projectsData?.project?.find((p) => p.id === projectId);
    }
  }, [projectId, projectsData?.project]);

  const { value: fontSize } = useSliderFontSizeStore();

  if (project?.importStatus === ImportStatus.FAILED) {
    return <SubRouteNotImported failed />;
  }

  if (
    project?.importStatus === ImportStatus.WAITING_FOR_CONTENT ||
    project?.importStatus === ImportStatus.IMPORTING_CONTENT
  ) {
    return <SubRouteNotImported notImportedYet />;
  }

  if (!project || loading || projectsLoading) {
    return <SubRouteNotImported loading />;
  }

  const lockPageStructure = project?.kind === ProjectKind.HTML_TEMPLATE;

  return (
    <ProjectProvider
      value={{
        project,
        pages,
      }}>
      <div
        style={{ fontSize: `${fontSize}em` }}
        className={style.existingContainer}
        spellCheck={false}>
        <SubRouteFormat
          allPages={pages}
          projectId={projectId}
          lockPageStructure={lockPageStructure}
          speakers={project?.speakers || []}
        />
        <SubRouteRhese allPages={pages} projectId={projectId} />
        <SubRouteSyllable allPages={pages} project={project} />
        <SubRouteDefinition allPages={pages} projectId={projectId} />
        <OverviewRoute allPages={pages} />
        {/*<SubRouteNarration pages={pages} project={project} />*/}
        <SecureRoute
          exact
          path={subroutesPath.bookBase}
          render={(props: RouteComponentProps<{ bookId?: string }>) => (
            <Redirect to={subroutesPath.overview(props.match.params.bookId)} />
          )}
        />
      </div>
      <SecureRoute path={subroutesPath.base}>
        <Switch>
          <SecureRoute path={subroutesPath.narration}></SecureRoute>
          <SecureRoute>
            <GoToPage totalPageNumber={pages?.[0]?.lastPageNumber} />
          </SecureRoute>
        </Switch>
      </SecureRoute>
      {shouldDisplayFontResizer && <SliderFontSize style={style.fontSize} />}
    </ProjectProvider>
  );
};

export default React.memo(EditingRouter);
