{
    "authentication": {
        "SignInWidget": {
            "text": "Collegati con Google"
        }
    },
    "library": {
        "index": {
            "newProject": {
                "title": "Aggiungi un libro"
            },
            "projectCard": {
                "menu": {
                    "modifies": "Modifica",
                    "export": "Esporta il progetto",
                    "exportNarration": "Esporta il projetto por fare la narrazione",
                    "invite": "Invita un collega",
                    "delete": "Cancella",
                    "exportEPub": "Esportazione (epub)",
                    "viewExports": "Voir les exports",
                    "resync": "Lancer une re-synchro audio"
                },
                "button": {
                    "date": "Di fare Date Format",
                    "title": "Accedi al testo"
                }
            },
            "menu": {
                "signOut": "Scollega"
            },
            "default": {
                "export": {
                    "message": "Esportazione del projetto {{name}} in corso",
                    "serverErrorMessage": "Il server ha restituito un errore",
                    "successNotification": "Scarica {{name}}",
                    "type": "octet/stream",
                    "errorNotification": "Errore durante l'esportazione",
                    "colorationOptions": "Opzioni di colorazione",
                    "audioOption": "Audios",
                    "audioOptionCheckbox": "Includete l'audio"
                },
                "exportNarration": {
                    "message": "Esportazione (narrazione) del progetto {{name}} in corso",
                    "serverErrorMessage": "Il server ha restituito un errore",
                    "successNotification": "Scarica {{name}}",
                    "type": "octet/stream",
                    "errorNotification": "Errore durante l'esportazione",
                    "exportsTabLabel": "Exports"
                },
                "project": {
                    "errorMessage": "Importazione del libro non riuscita",
                    "successMessage": "Il libro è in fase di importazione"
                },
                "navBar": {
                    "title": "Biblioteca",
                    "tabTitle": "Tutti",
                    "errorMessage": "Errore. Non è possibile trovare la lista dei progetti al momento"
                }
            }
        },
        "inviteModal": {
            "title": "Invita un collega",
            "email": "Email del collega",
            "invite": "Invita"
        }
    },
    "project": {
        "a4Page": {
            "placeholder": "Dai un nome al tuo capitolo",
            "showMore": "Vedere di più"
        },
        "actionBar": {
            "stepComp": {
                "raw": "Crudo",
                "inProgress": "In preparazione",
                "toReview": "Da correggere",
                "done": "Completato"
            },
            "getStepLabel": {
                "formatting": "Segmentazione",
                "definition": "Definizioni",
                "syllables": "Sillabazione",
                "rhese": "Ripartizione in unità significative",
                "dialogues": "Dialoghi",
                "narration": "Narrazione"
            },
            "projectInfo": {
                "title": "Informazioni",
                "description": "Cifre",
                "charsNumber": "Numero di caraterri",
                "defsNumber": "Numero di definizioni",
                "imagesNumber": "Numero di immagine",
                "pageNumber": "Numero di pagine",
                "rhesesNumber": "Numero di unita significative",
                "wordsNumber": "Numero di parole",
                "listTitle": "Tipo di paragrafi",
                "export": "Esporta nel formato CSV"
            },
            "checklist": {
                "title": "Checklist"
            },
            "userAccess": {
                "title": "Collaboratori"
            },
            "default": {
                "tabPaneTitle": {
                    "preview": "Vista generale",
                    "rhese": "Ripartizione in unità significative",
                    "formatting": "Layout",
                    "definitions": "Modo Definizioni",
                    "dialogues": "Modo Dialoghi",
                    "syllables": "Modo Sillabazione",
                    "narration": "Narrazione preparation"
                }
            },
            "projectTabs": {
                "info": "Informazioni",
                "checklist": "Checklist",
                "access": "Collaboratori",
                "config": "Configurazione",
                "progress": "Avanzamento"
            },
            "config": {
                "title": "Configuration des clés",
                "customColoration": {
                    "title": "Configurazione della colorazione personalizzata",
                    "add": "Aggiungi una nuova chiave di colorazione"
                },
                "customParagraphTag": {
                    "title": "Impostare le etichette dei paragrafi",
                    "add": "Aggiungere una etichetta"
                }
            },
            "progress": {
                "title_state_preparation": "In preparazione",
                "title_state_narration": "In narrazione",
                "title_state_narrationComplete": "Narrazione completa",
                "title_state_narrationAndFixComplete": "Narration et correction complètes",
                "title_state_bookCompleted": "Libro segnato come finito",
                "preparing": {
                    "title": "In preparazione",
                    "legendReadyToNarrate": "Les tâches nécessaires pour la narration sont en cours de préparation",
                    "buttonReadyToNarrate": "Valider pour narration",
                    "legendPreparationComplete": "Les tâches complémentaires sont en cours de préparation",
                    "buttonPreparationComplete": "Valider la préparation"
                },
                "prepared": {
                    "title": "Préparé",
                    "legendTasksForNarrationComplete": "Les tâches nécessaires pour la narration sont terminées",
                    "buttonReadyToNarrate": "Pronto per la narrazione",
                    "legendPreparationComplete": "Toutes les tâches de préparation sont terminées",
                    "buttonPreparationComplete": "Preparazione completata"
                },
                "narration": {
                    "title": "In narrazione",
                    "narratedPages": "Pagine narrate",
                    "narratedDefinitions": "Definizioni narrate",
                    "proofListening": "Controllo della qualità audio",
                    "remainingCorrections": "Correzioni rimanenti",
                    "buttonDone": "Segna il libro come finito",
                    "markNarrationDone": "Valider la narration",
                    "narrationDone": "Narration validée"
                },
                "preparationAndNarration": {
                    "markBookComplete": "Marquer le livre comme étant terminé"
                },
                "bookCompleted": {
                    "legend": "Un livre archivé est supprimé à l'affichage dahs la bibliothèque des différents acteurs.",
                    "legendBis": "Pour le désarchiver, contacter le développeur.",
                    "button": "Archiviare il progetto"
                },
                "bookStateStatus": {
                    "not_ready": "In preparazione",
                    "ready_for_narration": "In narrazione",
                    "narration_complete": "Narrazione completa",
                    "complete": "Pronto per la validazione",
                    "complete_book": "Completato",
                    "archived": "Archiviato",
                    "resync": "En re-synchro"
                },
                "archived": {
                    "title": "Archivé",
                    "buttonUnarchive": "Désarchiver"
                }
            }
        },
        "existing": {
            "bookRenderer": {
                "paraghapLabel": {
                    "citation": "Citazione",
                    "dialogue": "dialogo",
                    "didascalie": "Didascalia",
                    "email": "Email",
                    "intro": "Intro",
                    "normal": "Normale",
                    "note": "Nota",
                    "resume": "Riassunto",
                    "vers": "Verso",
                    "center": "Centro",
                    "mail": "Lettera",
                    "right": "Destra",
                    "space": "Spazio",
                    "bold": "Grassetto",
                    "text": "Messagio",
                    "title1": "Tittolo1",
                    "title2": "Tittolo2",
                    "title3": "Tittolo3",
                    "title4": "Tittolo4",
                    "title5": "Titolo 5",
                    "title6": "Titolo 6",
                    "listUnordered": "Lista non ordinata",
                    "listOrdered": "Lista ordinata",
                    "listDefinition": "Lista di definizioni"
                }
            },
            "index": {
                "syllableComponent": {
                    "syllables": "Sillabe",
                    "phonemes": "Fonema",
                    "custom": "Personalizzato"
                },
                "pageBar": {
                    "mutePage": "Pagina invisibile"
                },
                "goToPage": {
                    "nav": "Vai a...",
                    "validation": "OK"
                }
            },
            "editingMode": {
                "definition": {
                    "buildDefinitionMenu": {
                        "associates": "Associa una definizione",
                        "modifies": "Modifica la definizione",
                        "add": "Aggiungi una definizione",
                        "dissociate": "Elimina la definizione"
                    },
                    "content": {
                        "all": "Tutte le definizioni",
                        "explicit": "Definizioni esplicite",
                        "implicit": "Definizioni implicite",
                        "foot_note": "Note a piè di pagina",
                        "image": "With image"
                    },
                    "definitionModal": {
                        "message": {
                            "assign": "Assegna alla definizione",
                            "update": "Salva i cambiamenti",
                            "create": "Crea una definizione"
                        },
                        "radioButton": {
                            "explicit": "Definizioni esplicite",
                            "implicit": "Definizioni implicite",
                            "foot_note": "Note a piè di pagina",
                            "image": "With image"
                        },
                        "textArea": {
                            "placeholder": "Scrivere la definizione"
                        },
                        "imageName": {
                            "placeholder": "Image name"
                        }
                    }
                },
                "formatting": {
                    "dropZone": "Aggiungi un'immagine",
                    "menu": {
                        "titre_1": "Titolo livello1",
                        "titre_2": "Titolo livello2",
                        "titre_3": "Titolo livello3",
                        "titre_4": "Titolo livello 4",
                        "normal": "Normale",
                        "dialogue": "Dialogo",
                        "intro": "Introduzione",
                        "resume": "Riassunto",
                        "email": "Email",
                        "citation": "Citazione",
                        "vers": "Verso",
                        "didascalie": "Legenda",
                        "note": "Nota",
                        "subMenuTitleTransformsTitle": "Trasforma in titolo",
                        "subMenuTitleAlign": "Allineare...",
                        "space": "Spazio",
                        "center": "In centro",
                        "right": "A destra",
                        "subMenuTitleTransformsParagraph": "Trasforma in paragrofo...",
                        "subMenuTitleException": "Eccezioni...",
                        "bold": "Grassetto",
                        "text": "Messagio",
                        "mail": "Lettera",
                        "rheseAction": "Segmentare dopo unità significative",
                        "spaceAction": {
                            "normal": "Trasforma in spazio",
                            "unbreakable": "Trasforma in spazio non secatibile"
                        },
                        "lineSuppression": {
                            "normal": "Rimuovere il ritorno a capo",
                            "afterRhese": "Ritorna alla riga dopo l'unità significative"
                        },
                        "titre_5": "Titolo livello 5",
                        "titre_6": "Titolo livello 6",
                        "subMenuList": "Liste",
                        "listUnordered": "Non ordinata",
                        "listOrdered": "ordinata",
                        "listDefinition": "definizioni",
                        "list": "Lista",
                        "addClassesAction": "Aggiunta di classi css personalizzate",
                        "removeClassesAction": "Eliminare le classi css personalizzate"
                    },
                    "lettersCount": {
                        "form": {
                            "name": "di base",
                            "item": {
                                "imageNaming": {
                                    "label": "Nome dell'immagine",
                                    "name": "Nome del file"
                                },
                                "validation": "Convalidare",
                                "imageDescription": {
                                    "label": "Descrizione dell'immagine",
                                    "name": "descrizione"
                                },
                                "radioButton": {
                                    "normal": "Normale",
                                    "popup": "Popup"
                                }
                            }
                        },
                        "result": "{{lettersCount}} caratteri"
                    },
                    "default": {
                        "modal": {
                            "title": "Agiungia un'immagine"
                        },
                        "form": {
                            "imageNaming": {
                                "label": "Nome dell'immagine",
                                "name": "Nome"
                            },
                            "validation": "Convalidare",
                            "imageDescription": {
                                "label": "Descrizione dell'immagine",
                                "name": "Autore"
                            },
                            "radioButton": {
                                "normal": "Normale",
                                "popup": "Popup"
                            },
                            "figcaption": {
                                "label": "Didascalia immagine",
                                "name": "didascalia"
                            }
                        },
                        "tooltip": {
                            "title": "Aggiungi un'immagine"
                        }
                    },
                    "associateToSpeaker": {
                        "title": "Link a un personnagio",
                        "name": "Nome del personaggio",
                        "alphaNumError": "Il valore inserito deve essere alfanumerico",
                        "dissociate": "Dissociarsi da chi parla"
                    },
                    "modalCustomCss": {
                        "title": "Classi css personalizzate",
                        "modalOkLabel": "Aggiornamento",
                        "ovverideWarning": "Gli elementi selezionati non condividono le stesse classi. La convalida di una nuova voce comporta la perdita di quelle precedenti.",
                        "inputPlaceholder": "la-mia-classe-1, la-mia-classe-2"
                    }
                },
                "narration": {
                    "section": {
                        "minutes": "minuti"
                    },
                    "default": {
                        "title": "Segmenti audio"
                    }
                },
                "rhese": {
                    "index": {
                        "rhesesRenderer": {
                            "menu": {
                                "merge": "Unisci unità significative",
                                "creat": "Crea una unità significative",
                                "split": "Dividere l'unità significative"
                            },
                            "edit": "Modificare"
                        }
                    }
                },
                "syllable": {
                    "buildSyllableMenu": {
                        "creat": "Creare una sillaba",
                        "transformMute": "Converti in lettere silenziose",
                        "transformNoMute": "Converti in lettere non silenziose",
                        "ignore": "Ignora una sillaba"
                    },
                    "buildPhonemeMenu": {
                        "title": "Assegna a un fonema",
                        "vowel": "Vocale",
                        "consonant": "Consonante",
                        "complex": "Fonema complesso",
                        "transformMute": "Converti in lettere silenziose",
                        "transformNoMute": "Converti in lettere non silenziose",
                        "ignore": "Ignora la fonema"
                    },
                    "buildCustomColorsMenu": {
                        "assignCustomColor": "Assegna come",
                        "removeCustomColor": "Rimuovere le macchie"
                    }
                }
            }
        },
        "search": {
            "getSearchModeLabel": {
                "id": "Identificativo",
                "text": "Testo"
            },
            "nbResults": {
                "loading": "Caricamento in corso",
                "result": "risultato",
                "results": "risultati"
            }
        },
        "edit": {
            "modalTitle": "Modificare un libro",
            "form": {
                "bookName": "Nome del libro",
                "bookAuthor": "Nome dell'autore",
                "locale": "Language",
                "modifies": "Modifica"
            }
        },
        "new": {
            "stepOne": {
                "title": "Testo del libro",
                "importType": "Tipo d'importazione",
                "successMessage": ": file importato con successo",
                "errorMessage": ": mancata importazione del file...",
                "uploadOutlined": "Cerca sul tuo computer...",
                "import": "Importa",
                "rhesedText": "Testo scomposto in unità significative",
                "text": "Testo",
                "htmlBook": "HTML",
                "locale": "Lingua del libro",
                "jsonImport": "JSON",
                "disableColorationOnSimpleWords": "Disattivare la colorazione delle semplici parole"
            },
            "stepTwo": {
                "title": "Tittolo",
                "titlePlaceholder": "esempio : Pinocchio",
                "author": "Autore",
                "authorPlaceholder": "esempio : Carlo Collodi",
                "ean": "EAN",
                "eanPlaceholder": "9782070360420",
                "create": "Crea"
            },
            "modalTitle": "Aggiungi un libro"
        },
        "importStatus": {
            "failed": {
                "title": "Il progetto non è accessibile",
                "message": "Importazione del progetto fallita",
                "action": "Torna all'elenco dei progetti"
            },
            "inProgress": {
                "title": "Progetto inaccessibile",
                "message": "L'importazione del progetto è ancora in corso",
                "action": "Torna all'elenco dei progetti"
            },
            "loading": {
                "title": "Caricamento in corso",
                "message": "Per favore aspetta qualche istante"
            }
        }
    },
    "userAccess": {
        "userTable": {
            "title": "Ruolo",
            "preparer": "Preparatore",
            "correction": "Correttore"
        },
        "tasksLabels": {
            "definition": "Definizione",
            "dialogues": "Dialoghi",
            "formatting": "Layout",
            "narration": "Narrazione",
            "rhese": "Ripartizione in unità significative",
            "syllables": "Sillabazione"
        }
    },
    "locale": {
        "fr_FR": "Francese",
        "en_GB": "Inglese",
        "it_IT": "Italian",
        "sv_SE": "Svedese"
    },
    "page": "Pagina",
    "paragraphe": "Paragrafo",
    "on": "sul",
    "tagTheParagraph": "Etichetta",
    "removeTagFromParagraph": "rimuovi l'etichetta",
    "selectADefinition": "Scegli una definizione",
    "associateToDefinition": "link a una definizione",
    "editor": "Editore",
    "narrator": "Narratore",
    "prooflistener": "Controllore audio",
    "audioLibrarty": "Biblioteca audio",
    "GQLError.cannotUpdateRheseContent.message": "Impossibile cambiare l'unità di significato",
    "GQLError.cannotUpdateRheseContent.description": "L'unità di significato è già associata a un file audio e non può essere modificata.",
    "select_recorgind_issue_type": "Selezionare il tipo di osservazione",
    "audioReview.bad_speech": "Pronuncia",
    "audioReview.bad_text": "Testo sbagliato",
    "audioReview.recording": "Problema di registrazione",
    "audioReview.valid": "OK",
    "audioReview.comment": "Commento",
    "audioReview.typeYourComment": "Aggiungi il tuo commento",
    "audioReview.addAReviewToSelectedRheses": "Aggiungere un'osservazione alle unità di significato selezionate",
    "cancel": "Annullamento",
    "apply": "Applicare",
    "reset": "Reset",
    "validate": "Valider",
    "confirm": "Confirmer",
    "warning": "Attention",
    "startOffset": "Spostare l'ora di inizio",
    "endOffset": "Spostare l'ora della fine",
    "audioOffset": "Audio del turno",
    "listen": "Spostare l'audio",
    "offsetConfigIsInvalid": "L'impostazione non è valida",
    "listenToTheRecording": "Ascolta la registrazione",
    "proofListening.pageNotFullyRecordedTitle": "Questa pagina del libro non è ancora stata narrata",
    "proofListening.pageNotFullyRecordedButtonLabel": "Indietro",
    "definitions": "Definizioni",
    "rheses": "Unità di significato",
    "loadingError": "Si è verificato un errore durante il caricamento",
    "customCssClassesUsed": "Classi CSS personalizzate utilizzate",
    "audioReview.bad_text_and_narration": "Problema testo+narrazione",
    "audioReview.commentName": "Commento",
    "audioReview.resetStatusBtn": "Cancella le osservazioni",
    "audioReview.resetStatusModalTitle": "Certo ?",
    "audioReview.resetStatusModalText": "Si noti che questa azione cancellerà gli stati delle unità di significato selezionate, ma anche i loro commenti.",
    "audioReview.resetStatusModalBtn": "Cancella",
    "definitionRecordedAudioUploadSuccess": "Il file è stato salvato con successo",
    "definitionRecordedAudioUploadError": "Errore, il file non è stato salvato",
    "definitionAudioUploadSuccess": "Il file è stato salvato con successo",
    "definitionAudioUploadError": "Errore, il file non è stato salvato",
    "definitionAudioUploadErrorSize": "Il file non deve superare",
    "definitionAudioUploadErrorExtension": "Estensione non supportata. Utilizzare le seguenti estensioni",
    "filterWithIssues": "Con problemi",
    "filterAudioComplete": "Con audio completo",
    "filterRenarrated": "Registrato di nuovo",
    "audioReview.renarrated": "Registrato di nuovo",
    "rheseIgnoreSyncroMenuItemLabel": "Ignorare questa unità di significato nella sincronizzazione",
    "rheseUseSyncroMenuItemLabel": "OK per sincronizzare",
    "rheseUseSyncroDialogTitle": "Convalidate questa operazione?",
    "rheseUseSyncroDialogOk": "Confermare",
    "rheseUseSyncroDialogCancel": "Annullare",
    "rheseIconSyncroTooltip": "Unità di significato non sincronizzata",
    "forceChange": "Costringere questa modifica",
    "adminCanForceChange": "È possibile forzare la modifica",
    "forcedUpdateWillDeleteAssociatedAudio": "La forzatura dell'aggiornamento comporta l'eliminazione dei file audio associati",
    "serverHasSentError": "Il server ha restituito un errore",
    "errorHasBeenSentToTechStaff": "È stata inoltrata automaticamente al team tecnico",
    "downloadErrorReport": "Scarica il rapporto sugli errori",
    "tabArchivesLabel": "Archives",
    "exportType_DEFINITIONS": "Définitions",
    "exportType_FULL": "Complet",
    "exportType_HTML": "HTML",
    "exportType_INTO_IMPORTED_EPUB": "ePub",
    "exportType_NARRATION": "Simplifié",
    "exportType_SIMPLE": "Simplifié",

    "tableExportsColumnStatus": "Statut",
    "tableExportsColumnType": "Type",
    "tableExportsColumnProjectName": "Livre",
    "tableExportsColumnAuthor": "Utilisateur",
    "tableExportsColumnCreatedAt": "Démarré à",
    "tableExportsColumnCompletedAt": "Terminé à",
    "tableExportsColumnDuration": "Durée",
    "tableExportsColumnIncludeAudio": "Audio inclus",
    "tableExportsColumnConfig": "Config",
    "tableExportsColumnAction": "Action",
    "tableExportsRetentionInfo": "Les exports réussis restent affichés pour une période de 24h",

    "modalConfirmReSync": "La re-synchronisation audio d'un projet entraîne son blocage durant tout le traitement.\nConfirmez-vous l'action ?",
    "modalConfirmDelete": "Est-ce que vous confirmez la suppression de ce projet ?",
    "overlayReSync": "Projet en cours de re-synchronisation",

    "tabArchivesNoResult": "Pas de livres archivés",

    "proofListeningDataChanged": "Réinitialisation du lecteur audio, dû à un changement de fichier(s) audios",

    "revokeUserAccess": "Révoquer l'accès d'un utilisateur",
    "userEmail": "Email de l'utilisateur",
    "revokeUser": "Révoquer l'utilisateur",
    "filterLabelWith": "Avec",
    "filterLabelWithout": "Sans",
    "proofListening.pageOnlyAccessibleToAdmin": "Cette page n'est accessible qu'aux narrateurs et aux administrateurs car la narration n'est pas terminée.",
    "project.existing.bookRenderer.paraghapLabel.résumé": "Résumé",
    "reload.project": "Reload projet"
}
