import { Checkbox } from "antd";
import { motion } from "framer-motion";
import uniq from "lodash/uniq";
import React from "react";
import { UserRole, UserTask } from "../../../../__generated__/globalTypes";
import { useI18nObjectHook } from "../../../i18n";
import { SubToProject_project_roles } from "../Project/api/__generated__/SubToProject";
import { useAddProjectRole, useDeleteProjectRole } from "./api";
import * as style from "./style.less";

type userTableTranslationProps = {
  title: string;
  preparer: string;
  correction: string;
};

type TaskLabelsTranslationProps = {
  definition: string;
  dialogues: string;
  formatting: string;
  narration: string;
  rhese: string;
  syllables: string;
};

function toColor(str: string): string {
  let hash = 0;
  if (str.length === 0) throw new Error("String shouldn't be empty");
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
}

function contrastedTextColor(hex: string) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }

  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
}

export const Users = ({ userBasedAccess = [] }: { userBasedAccess: string[] | null }) => {
  const users = uniq(userBasedAccess || []).map((user) => {
    const [first, second] = user.split("@")[0];
    return {
      email: user,
      short: (first + second).toUpperCase(),
      color: toColor(user),
    };
  });

  return (
    <>
      {users.map(({ short, email, color }) => (
        <motion.div
          className={style.user}
          key={email}
          style={{
            backgroundColor: color,
            color: contrastedTextColor(color),
          }}
          initial="initial"
          whileHover="hover">
          {short}
          <motion.div
            className={style.email}
            variants={{
              initial: { opacity: 0, y: "130%" },
              hover: { opacity: 1, y: "115%" },
            }}>
            {email}
          </motion.div>
        </motion.div>
      ))}
    </>
  );
};

const UserTable = ({
  projectId,
  userId,
  roles,
}: {
  projectId: string;
  userId: string;
  roles: SubToProject_project_roles[];
}) => {
  const [applyDeleteProjectRole] = useDeleteProjectRole();
  const [applyAddProjectRole] = useAddProjectRole();

  const translation: userTableTranslationProps = useI18nObjectHook("userAccess.userTable");

  const TaskLabelsTranslation: TaskLabelsTranslationProps =
    useI18nObjectHook("userAccess.tasksLabels");

  const TaskLabels: {
    [key: string]: string;
  } = {
    DEFINITION: TaskLabelsTranslation.definition,
    DIALOGUES: TaskLabelsTranslation.dialogues,
    FORMATTING: TaskLabelsTranslation.formatting,
    NARRATION: TaskLabelsTranslation.narration,
    RHESE: TaskLabelsTranslation.rhese,
    SYLLABLES: TaskLabelsTranslation.syllables,
  };

  const tasksColumns = Object.keys(UserTask).map((key) => ({
    key,
    dataIndex: key,
    title: TaskLabels[key],
  }));

  const columns = [
    {
      key: "ROLE",
      dataIndex: "ROLE",
      title: translation.title,
    },
  ].concat(tasksColumns);

  const preparerRoles = roles.filter((role) => role.userRole === UserRole.PREPARER);
  const correctorRoles = roles.filter((role) => role.userRole === UserRole.CORRECTOR);

  const preparerColumns = tasksColumns.map((task) =>
    preparerRoles.find((role) => role.userTask === task.key),
  );
  const correctorColumns = tasksColumns.map((task) =>
    correctorRoles.find((role) => role.userTask === task.key),
  );

  return (
    <div className={style.userTable}>
      <span>{userId}</span>
      <table>
        <thead>
          <tr>
            {columns.map((column, i) => (
              <th key={`${column?.key} ${i}`}>{column.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{translation.preparer}</td>
            {preparerColumns.map((column, i) => (
              <td key={`${column?.id} ${i}`}>
                <Checkbox
                  checked={!!column}
                  onChange={(e) =>
                    e.target.checked
                      ? applyAddProjectRole({
                          variables: {
                            userId,
                            projectId,
                            userRole: UserRole.PREPARER,
                            userTask: tasksColumns[i].key as UserTask,
                          },
                        })
                      : applyDeleteProjectRole({
                          variables: {
                            roleId: column?.id,
                          },
                        })
                  }
                />
              </td>
            ))}
          </tr>
          <tr>
            <td>{translation.correction}</td>
            {correctorColumns.map((column, i) => (
              <td key={`${column?.id} ${i}`}>
                <Checkbox
                  checked={!!column}
                  onChange={(e) => {
                    e.target.checked
                      ? applyAddProjectRole({
                          variables: {
                            userId,
                            projectId,
                            userRole: UserRole.CORRECTOR,
                            userTask: tasksColumns[i].key as UserTask,
                          },
                        })
                      : applyDeleteProjectRole({
                          variables: {
                            roleId: column?.id,
                          },
                        });
                  }}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

type UserRolesProps = {
  roles: SubToProject_project_roles[];
  userBasedAccess: string[] | null;
  projectId: string;
};

export const UserRoles = ({ roles, userBasedAccess = [], projectId }: UserRolesProps) => {
  const users = userBasedAccess || [];

  return (
    <>
      {users.map((user, i) => (
        <UserTable
          projectId={projectId}
          key={user + i}
          userId={user}
          roles={roles.filter((role) => role.userId === user)}
        />
      ))}
    </>
  );
};
