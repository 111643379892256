@import "../../../styles/colors.less";

.badgeStatus {
  color: @white;
  padding: 0.5em 1em;
  display: flex;
  display: inline-flex;
  align-items: center;
  width: auto;
  border-radius: 50px;
  background-color: @darkest-grey;

  .indicator {
    width: 0.6em;
    height: 0.6em;
    border-radius: 50px;

    &.NOT_READY {
      background-color: @bookstatus-not-ready;
    }
    &.READY_FOR_NARRATION {
      background-color: @bookstatus-ready-for-narration;
    }
    &.NARRATION_COMPLETE {
      background-color: @bookstatus-narration-complete;
    }
    &.COMPLETE {
      background-color: @bookstatus-complete;
    }
    &.COMPLETE_BOOK {
      background-color: @bookstatus-complete-book;
    }
    &.ARCHIVED {
      background-color: @bookstatus-archived;
    }
  }
  .text {
    color: @white;
    font-size: 1em;
    padding-left: 0.75em;
    font-weight: normal;
  }

  &.RESYNC {
    background-color: @bookstatus-resync;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    :global(.anticon) {
      color: black;
      font-size: 16px;
    }
    .text {
      color: black;
    }
  }

  &.small {
    top: 0.75em;
    right: 0.75em;
    padding: 0.15em 0.5em;

    .indicator {
      width: 0.5em;
      height: 0.5em;
    }
    .text {
      font-size: 0.6em;
      opacity: 0.85;
      padding-left: 0.65em;
    }
  }
}
