import React, { useCallback, useMemo } from "react";
import { AudioPlayerByRhese } from "../audioPlayerByRhese/AudioPlayerByRhese";
import * as style from "./style.less";
import { AudioRecordingSyncStatus } from "../../../../../__generated__/globalTypes";
import { ApiOutlined, CheckOutlined, WarningOutlined } from "@ant-design/icons";
import ReviewCommentAndStatus from "../reviewCommentAndStatus/ReviewCommentAndStatus";
import { Dropdown, Tooltip } from "antd";
import useSkipSynchro from "../../Hooks/useSkipSynchro";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../components/Spinner";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

type RheseProps = {
  rhese: SubscribeToExtendedPage_extendedPage_rhese;
  index: number;
  onClick: (id: string) => void;
  isSelected: boolean;
  isSelectionStart: boolean;
  inProgress: boolean;
  projectId: string;
  pageMetadata: SubscribeToExtendedPage_extendedPage;
};

const getRheseStyle = (index: number) => {
  if (index % 2 === 0) {
    return style.rheseWhite;
  } else {
    return style.rheseGrey;
  }
};

const Rhese = ({
  rhese,
  onClick,
  index,
  isSelected,
  isSelectionStart,
  projectId,
  pageMetadata,
  inProgress,
}: RheseProps) => {
  const [t] = useTranslation();

  const rhesesStyle = getRheseStyle(index);
  const syncStatus = rhese.data.audioRecording?.syncStatus;
  const hasFailed = syncStatus === AudioRecordingSyncStatus.FAILED;
  const hasSucceeded = syncStatus === AudioRecordingSyncStatus.SUCCEEDED;
  const isWaiting = syncStatus === AudioRecordingSyncStatus.TO_DO;

  const {
    contextMenuItems,
    onItemMenuSelected,
    loading: loadingIgnoreOrUse,
  } = useSkipSynchro(projectId, rhese.data);

  const _onClick = useCallback(() => onClick(rhese.data.id), [onClick, rhese.data.id]);

  const onMenuItemClick = useCallback((data) => onItemMenuSelected(data.key), [onItemMenuSelected]);

  const menuProps = useMemo(
    () => ({ items: contextMenuItems, onClick: onMenuItemClick }),
    [contextMenuItems, onMenuItemClick],
  );

  const text = pageMetadata.textContent.substring(
    rhese.anchors[0].utf16Start - pageMetadata.page.anchors[0].utf16Start,
    rhese.anchors[0].utf16Start +
      rhese.anchors[0].utf16Size -
      pageMetadata.page.anchors[0].utf16Start,
  );

  return (
    <Dropdown key={index} menu={menuProps} trigger={["contextMenu"]}>
      <div className={style.rheseRoot} onClick={_onClick}>
        {isSelectionStart && <div className={style.selectionStart}></div>}
        {isSelected && <CheckOutlined className={style.selectedIcon} />}
        <div
          className={`${style.rheseContainer} ${rhesesStyle}`}
          aria-selected={isSelected}
          data-rhese-id={rhese.data.id}>
          {rhese.data.ignoreTextForAudioSync &&
            (loadingIgnoreOrUse ? (
              <Spinner small style={{ marginRight: "1.2em" }} />
            ) : (
              <Tooltip title={t("rheseIconSyncroTooltip")}>
                <ApiOutlined className={style.iconIgnoredFromSynchro} onClick={() => null} />
              </Tooltip>
            ))}
          <p key={rhese.data.id} className={style.rhese}>
            {text}
          </p>
          <ReviewCommentAndStatus
            comment={rhese.data.audioInfo?.comment}
            audioReviewStatus={rhese.data.audioInfo?.status}
          />
          {rhese.data.audioInfo?.id && hasSucceeded && (
            <div>
              <AudioPlayerByRhese rhese={rhese} />
            </div>
          )}
          {(isWaiting || inProgress) && <Spinner style={{ marginLeft: "1em" }} />}
          {hasFailed && <WarningOutlined />}
        </div>
      </div>
    </Dropdown>
  );
};

export { Rhese };
