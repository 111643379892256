.root {
  width: 95vw !important;
  :global(.ant-modal-content) {
    height: 95vh;
    display: flex;
    flex-direction: column;
    :global(.ant-modal-body) {
      flex-grow: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }
  }
}
