import { AudioOutlined, ExclamationCircleOutlined, RedoOutlined } from "@ant-design/icons";
import classNames from "classnames";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { ClickableWrapper } from "../../../../../components/clickableWrapper/ClickableWrapper";
import * as style from "./style.less";

type PageTabProps = {
  pageNumber: number;
  bookId: string;
  disabled: boolean;
  hasIssues: boolean;
  audioComplete: boolean;
  renarrated: boolean;
};

export const PageTab = ({
  pageNumber,
  bookId,
  disabled,
  hasIssues,
  audioComplete,
  renarrated,
}: PageTabProps) => {
  const history = useHistory();

  const onClick = useCallback(() => {
    if (disabled) {
      return;
    }
    history.push(`/audio/library/edit/${bookId}/${pageNumber}`);
  }, [bookId, disabled, history, pageNumber]);

  return (
    <ClickableWrapper>
      <div className={style.pageTabContainer} data-disabled={disabled} onClick={onClick}>
        <div>{`Page ${pageNumber}`}</div>
        <div>
          <AudioOutlined
            className={classNames(style.rightIcon, { [style.hidden]: !audioComplete })}
          />
          <RedoOutlined className={classNames(style.rightIcon, { [style.hidden]: !renarrated })} />
          <ExclamationCircleOutlined
            className={classNames(style.rightIcon, { [style.hidden]: !hasIssues })}
          />
        </div>
      </div>
    </ClickableWrapper>
  );
};
