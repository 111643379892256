import React, { useCallback } from "react";
import DefinitionItem from "./DefinitionItem";
import * as style from "../style.less";
import { useUpdateDefinition } from "../../../../../editor/Project/api/api";
import { AudioReviewStatus } from "../../../../../../../__generated__/globalTypes";
import classNames from "classnames";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_definition,
} from "../../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

type Props = {
  definitions: SubscribeToExtendedPage_extendedPage_definition[];
  projectId: string;
  pageMetadata: SubscribeToExtendedPage_extendedPage;
};

export const getDefinitionText = (
  definition: SubscribeToExtendedPage_extendedPage_definition,
  pageMetadata: SubscribeToExtendedPage_extendedPage,
) => {
  const definitionStart = definition.anchors[0].utf16Start;
  const definitionEnd = definition.anchors[0].utf16Start + definition.anchors[0].utf16Size;
  const pageStart = pageMetadata.page.anchors[0].utf16Start;

  // Get the text of the definition
  let textDefinition = pageMetadata.textContent.slice(
    definitionStart - pageStart,
    definitionEnd - pageStart,
  );

  // Add the spaces between each rheses
  pageMetadata.rhese.reverse().forEach((rhese) => {
    const rheseEnd = rhese.anchors[0].utf16Start + rhese.anchors[0].utf16Size;
    if (rheseEnd > definitionStart && rheseEnd < definitionEnd) {
      textDefinition = `${textDefinition.slice(
        0,
        rheseEnd - definitionStart,
      )} ${textDefinition.slice(rheseEnd - definitionStart)}`;
    }
  });
  return textDefinition;
};

const DefinitionsListening = ({ definitions, projectId, pageMetadata }: Props) => {
  const [applyUpdateDefinition] = useUpdateDefinition();

  const handleUpdateDefinition = useCallback(
    async ({ definition, status, comment }) => {
      applyUpdateDefinition({
        variables: {
          projectId,
          definitionId: definition.data.id,
          audioFile: definition.data.audioFile || "",
          audioReviewStatus: status || definition.data.audioReviewStatus || AudioReviewStatus.VALID,
          audioReviewComment: comment || definition.data.audioReviewComment || "",
          description: definition.data.description,
          kind: definition.data.kind,
        },
      });
    },
    [applyUpdateDefinition, projectId],
  );

  const onPlayEnded = useCallback(
    async (definition: SubscribeToExtendedPage_extendedPage_definition) => {
      // Set the definition status to "valid" if no already set
      if (!definition.data.audioReviewStatus) {
        await handleUpdateDefinition({ definition, status: AudioReviewStatus.VALID });
      }
    },
    [handleUpdateDefinition],
  );

  return (
    <div className={classNames(style.definitions, style.definitionsProofListener)}>
      {definitions.map((definition) => {
        const textDefinition = getDefinitionText(definition, pageMetadata);

        return (
          <DefinitionItem
            key={definition.data.id}
            filename={definition.data.audioFile}
            definition={definition}
            onPlayEnded={onPlayEnded}
            updateComment={handleUpdateDefinition}
            updateStatus={handleUpdateDefinition}>
            {`${textDefinition}: ${definition.data.description}`}
          </DefinitionItem>
        );
      })}
    </div>
  );
};

export default DefinitionsListening;
