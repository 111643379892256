@import "../../../../styles/colors.less";

.root :global {
  .rheseWrapper {
    width: 100%;
  }
  .rhese {
    display: inline-block;
    position: relative;
  }

  .rhese:hover .rheseNumber {
    display: initial;
  }
  .rhese .rheseNumber {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(calc(-0.2em - 100%));
    padding: 0.12em 0.48em;
  }

  .rhese .rheseNumber {
    background-color: fade(@grey, 66%);
  }
  .rhese2 .rheseNumber {
    background-color: fade(@light-grey, 99%);
  }

  #preview *::selection {
    background: @blue;
    color: @black;
  }

  .rhese.active {
    background-color: @blue;
    color: @white;
  }

  .paragraph {
    display: flex;
    flex-direction: column;
    row-gap: 0.2em;

    .rhese {
      background-color: fade(@grey, 66%);
      border-left: 0.25em solid @dark-grey;
      width: max-content;
      max-width: 100%;
      padding: 0.12em 0.48em;
    }

    .rhese2 {
      background-color: fade(@light-grey, 99%);
    }

    .space {
      display: none;
    }

    .rhese:global.warningLight {
      border-left: 0.25em solid @yellow;
    }
    .rhese:global.warning {
      border-left: 0.25em solid @orange;
    }
    .rhese:global.warningStrong {
      border-left: 0.25em solid @red;
    }
  }
}

.customRheseWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  button {
    opacity: 0;
    position: absolute;
    right: 0;
  }

  &:hover button {
    opacity: 1;
  }
}

.editRhese .btnBar {
  padding-top: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clonedRheseSyllable {
  text-align: center;
  & :global {
    .rhese {
      font-size: 2em;
      padding: 0.12em 0;
      .pair {
        color: @syllable-blue;
      }
      .impair {
        color: @red;
      }
      .muet {
        color: @grey;
      }
    }
  }
}

.clonedRhesePhoneme {
  text-align: center;
  & :global {
    .rhese {
      font-size: 2em;
      padding: 0.12em 0;
      .consonant {
        color: @phoneme-consonant;
      }
      .vowel {
        color: @phoneme-vowel;
      }
      .complicated {
        color: @phoneme-complicated;
      }
      .muet {
        color: @grey;
      }
    }
  }
}

.clonedRheseDefinition {
  text-align: center;
  & :global {
    .rhese {
      font-size: 2em;
      padding: 0.12em 0;
    }

    .definition.explicit {
      background-color: @light-grey;
      border-left: 0.3em solid @eastern-blue;
    }

    .definition.implicit {
      background-color: @light-grey;
      border-left: 0.3em solid @electric-violet;
    }

    .definition.ndbp {
      background-color: @light-grey;
      border-left: 0.3em solid @purple;
    }

    .definition.image {
      background-color: @light-grey;
      border-left: 0.3em solid @indigo;
    }
  }
}
