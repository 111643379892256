import { gql, useMutation } from "@apollo/client";
import {
  SetPhonemeFromPosition,
  SetPhonemeFromPositionVariables,
} from "./__generated__/SetPhonemeFromPosition";
import {
  UnsetPhonemeFromPosition,
  UnsetPhonemeFromPositionVariables,
} from "./__generated__/UnsetPhonemeFromPosition";

export const useSetPhonemeFromPosition = () =>
  useMutation<SetPhonemeFromPosition, SetPhonemeFromPositionVariables>(
    gql`
      mutation SetPhonemeFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
        $kind: ExtendedPhonemeKind!
      ) {
        setPhonemeFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
          kind: $kind
        )
      }
    `,
  );

export const useUnsetPhonemeFromPosition = () =>
  useMutation<UnsetPhonemeFromPosition, UnsetPhonemeFromPositionVariables>(
    gql`
      mutation UnsetPhonemeFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        unsetPhonemeFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
  );
