import { useMemo } from "react";
import { useSubToLibrary } from "../../api";

type Params = { filterString: string; archivedProjects?: boolean };

export default function useProjectsData({ filterString, archivedProjects }: Params) {
  const { loading, error, data } = useSubToLibrary({ archivedProjects: !!archivedProjects });

  const projects = useMemo(() => {
    const projects = data?.projects || [];
    const isSubStr = (reference: string, subString: string) =>
      reference.toUpperCase().indexOf(subString.toUpperCase()) > -1;
    return projects.filter(
      (p) => isSubStr(p.name, filterString) || isSubStr(p.author, filterString),
    );
  }, [data, filterString]);

  return { projects, loading, error };
}
