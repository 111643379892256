import React from "react";
import * as style from "../TabProgress.style.less";
import { useI18n } from "../../../../../../../../i18n";
import PreparingState from "./PreparingState";
import { PreparationProgressStatus } from "../../../../../../../../../__generated__/globalTypes";

type Props = {
  markAsReadyForNarration: () => void;
  preparationProgress: PreparationProgressStatus;
};

export default function StepPreparing({ markAsReadyForNarration, preparationProgress }: Props) {
  const [t] = useI18n();

  return (
    <div className={style.step}>
      <h4>{t("project.actionBar.progress.preparing.title")}</h4>
      <PreparingState
        markAsReadyForNarration={markAsReadyForNarration}
        preparationProgress={preparationProgress}
      />
    </div>
  );
}
