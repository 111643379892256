@import "../../app/editor/styles/colors.less";

.projectExports {
  position: relative;
  .legendAndDelete {
    display: flex;
    align-items: center;
    position: relative;

    .deleteWrapper {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 15px;
      .deleteBtn {
        border-radius: 6px;
        .deleteIcon {
          font-size: 1.3em;
        }
      }
    }
    .legendAlert {
      margin-left: 80px;
      position: absolute;
      top: 14px;
      :global(.ant-alert-message) {
        opacity: 0.8;
      }
    }
  }
  .projectExportsList {
    :global(.ant-table-wrapper) {
      width: 100%;
      :global(.ant-table-cell) {
        &:global(.cell-config) {
          padding-top: 0;
          padding-bottom: 0;
          p {
            margin-bottom: 0;
            font-size: 0.8em;
            opacity: 0.8;
          }
        }
        :global(.anticon) {
          font-size: 1.4em;
          &.iconWaiting {
            color: @grey;
          }
          &.iconDone {
            color: @green;
          }
          &.iconFailed {
            color: red;
          }
        }
      }
      .cancelIcon {
        color: @orange;
      }
    }
  }
}
