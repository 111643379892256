import React, { useCallback, useEffect, useState } from "react";
import { useI18n } from "../../../../../../i18n";
import ModalComment from "../../../../../../components/ModalComment/ModalComment";
import { usePrevious } from "../../../../../editor/utils/common";

export default function CommentButtonAndModal({
  onValidate,
  defaultValue,
}: {
  defaultValue?: string | null;
  onValidate: (comment: string) => void;
}) {
  const [t] = useI18n();
  const [text, setText] = useState(defaultValue || "");
  const previusDefaultValue = usePrevious(defaultValue);

  const [isModalsOpen, setIsModalOpen] = useState(false);
  const showModal = useCallback(() => setIsModalOpen(true), []);
  const hideModal = useCallback(() => setIsModalOpen(false), []);

  const onConfirm = useCallback(() => {
    onValidate(text);
    hideModal();
  }, [onValidate, text, hideModal]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  }, []);

  useEffect(() => {
    if (defaultValue !== previusDefaultValue) {
      setText(defaultValue || "");
    }
  }, [defaultValue, previusDefaultValue]);

  return (
    <ModalComment
      value={text}
      canConfirm={defaultValue !== text}
      isModalsOpen={isModalsOpen}
      showModal={showModal}
      hideModal={hideModal}
      modalProps={{
        okText: t("audioReview.comment"),
        onOk: onConfirm,
        onCancel: hideModal,
      }}
      onChange={onChange}
      placeholder={t("audioReview.typeYourComment")}
    />
  );
}
