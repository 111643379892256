import _ from "lodash";
import { useMemo } from "react";
import {
  SubToProject_project,
  SubToProject_project_definitionsAudioInfo,
  SubToProject_project_rhesesAudioInfo,
} from "../api/__generated__/SubToProject";

export type ProjectType = SubToProject_project;

export type ProgressStats = {
  canValidate: boolean;
  pages: {
    narrated: number;
    notNarrated: number;
    progress: number;
    total: number;
  };
  definitions: {
    narrated: number;
    notNarrated: number;
    progress: number;
    total: number;
  };
  proofListening: {
    reviewed: number;
    notReviewed: number;
    progress: number;
    total: number;
  };
  fixes: {
    remaining: number;
  };
};

type RhesesAudioInfo = SubToProject_project_rhesesAudioInfo[];

type DefinitionsAudioInfo = SubToProject_project_definitionsAudioInfo[];

const getPagesNarrationProgress = (rhesesAudioInfo: RhesesAudioInfo) => {
  const gpByPageId = _.groupBy(rhesesAudioInfo, "pageId");

  let narrated = 0;
  let notNarrated = 0;

  Object.keys(gpByPageId).map((pageId) => {
    const pageItems = gpByPageId[pageId];

    const isPageNarrated = !pageItems.find((item) => item.count > 0 && item.withAudio === false);
    if (isPageNarrated) {
      narrated++;
    } else {
      notNarrated++;
    }
  });

  const pagesCount = notNarrated + narrated;
  return {
    narrated: narrated,
    notNarrated: notNarrated,
    progress: pagesCount === 0 ? 0 : (narrated / pagesCount) * 100,
    total: pagesCount,
  };
};

const getDefinitionsProgress = (definitionsAudioInfo: DefinitionsAudioInfo) => {
  const gpBy = _.groupBy(definitionsAudioInfo, "withAudio");

  let narrated = 0;
  let notNarrated = 0;

  gpBy?.true?.forEach((definitions) => {
    narrated += definitions.count;
  });

  gpBy?.false?.forEach((definitions) => {
    notNarrated += definitions.count;
  });

  const definitionsCount = notNarrated + narrated;
  return {
    narrated: narrated,
    notNarrated: notNarrated,
    progress: definitionsCount === 0 ? 100 : (narrated / definitionsCount) * 100,
    total: definitionsCount,
  };
};

const getProofListeningProgress = (
  rhesesAudioInfo: RhesesAudioInfo,
  definitionsAudioInfo: DefinitionsAudioInfo,
) => {
  /**
   * Rheses
   */
  const rhesesGpBy = _.groupBy(rhesesAudioInfo, "withReview");
  let rhesesReviewed = 0;
  let rhesesNotReviewed = 0;

  rhesesGpBy?.true?.forEach((rheses) => {
    rhesesReviewed += rheses.count;
  });

  rhesesGpBy?.false?.forEach((rheses) => {
    rhesesNotReviewed += rheses.count;
  });

  /**
   * Definitions
   */
  const definitionsGpBy = _.groupBy(definitionsAudioInfo, "withReview");
  let definitionsReviewed = 0;
  let definitionsNotReviewed = 0;

  definitionsGpBy?.true?.forEach((definitions) => {
    definitionsReviewed += definitions.count;
  });

  definitionsGpBy?.false?.forEach((definitions) => {
    definitionsNotReviewed += definitions.count;
  });

  /**
   * Merge
   */
  const reviewed = rhesesReviewed + definitionsReviewed;
  const notReviewed = rhesesNotReviewed + definitionsNotReviewed;

  const total = reviewed + notReviewed;

  return {
    reviewed,
    notReviewed,
    progress: (reviewed / total) * 100,
    total,
  };
};

const getRemainingFixes = (
  rhesesAudioInfo: RhesesAudioInfo,
  definitionsAudioInfo: DefinitionsAudioInfo,
) => {
  /**
   * Rheses
   */
  const rhesesGpBy = _.groupBy(rhesesAudioInfo, "withReview");
  let rhesesReviewsNotValid = 0;
  rhesesGpBy?.true?.forEach((rheses) => {
    if (!rheses.isReviewValid) {
      rhesesReviewsNotValid += rheses.count;
    }
  });

  /**
   * Definitions
   */
  const definitionsGpBy = _.groupBy(definitionsAudioInfo, "withReview");
  let definitionsReviewsNotValid = 0;
  definitionsGpBy?.true?.forEach((definitions) => {
    if (!definitions.isReviewValid) {
      definitionsReviewsNotValid += definitions.count;
    }
  });

  return {
    remaining: rhesesReviewsNotValid + definitionsReviewsNotValid,
  };
};

export default function useProjectProgressStats(project?: ProjectType): ProgressStats {
  const stats = useMemo(() => {
    if (!project) {
      return {
        canValidate: false,
        pages: {
          narrated: 0,
          notNarrated: 0,
          progress: 0,
          total: 0,
        },
        definitions: {
          narrated: 0,
          notNarrated: 0,
          progress: 0,
          total: 0,
        },
        proofListening: {
          reviewed: 0,
          notReviewed: 0,
          progress: 0,
          total: 0,
        },
        fixes: {
          remaining: 0,
        },
      };
    }
    const { rhesesAudioInfo, definitionsAudioInfo } = project;

    const stats = {
      pages: getPagesNarrationProgress(rhesesAudioInfo),
      definitions: getDefinitionsProgress(definitionsAudioInfo),
      proofListening: getProofListeningProgress(rhesesAudioInfo, definitionsAudioInfo),
      fixes: getRemainingFixes(rhesesAudioInfo, definitionsAudioInfo),
    };

    return {
      canValidate:
        stats.pages.progress === 100 &&
        stats.proofListening.progress === 100 &&
        stats.definitions.progress === 100 &&
        stats.fixes.remaining === 0,
      ...stats,
    };
  }, [project]);

  return {
    ...stats,
  };
}
