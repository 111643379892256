import React, { useState } from "react";
import { useInterval } from "usehooks-ts";
import { getStatusIcon } from "../../../../../../components/ExportsTable/useTable";
import { SubscribeToExports_exports } from "../../../../Project/api/__generated__/SubscribeToExports";
import { getExportDuration } from "../../../../utils/common";
import * as style from "../../../style.less";

const REFRESH_INTERVAL = 5000; // ms

type Props = {
  _export: SubscribeToExports_exports;
};

export default function ExportsPopover({ _export }: Props) {
  const [duration, setDuration] = useState(getExportDuration(_export.createdAt));

  useInterval(() => {
    setDuration(getExportDuration(_export.createdAt));
  }, REFRESH_INTERVAL);

  return (
    <div className={style.exportItemInPopover}>
      {getStatusIcon(_export.exportStatus)}
      <p className={style.exportItemLabel}>
        {_export.projectName} - {_export.author} - {_export.author} - ({duration})
      </p>
    </div>
  );
}
