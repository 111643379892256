import { gql, useMutation } from "@apollo/client";
import {
  CreatePageFromPosition,
  CreatePageFromPositionVariables,
} from "./__generated__/CreatePageFromPosition";
import {
  UpdateExtendedPage,
  UpdateExtendedPageVariables,
} from "./__generated__/UpdateExtendedPage";
import {
  UpdateExtendedPagesPartname,
  UpdateExtendedPagesPartnameVariables,
} from "./__generated__/UpdateExtendedPagesPartname";

export const useCreatePageFromPosition = () =>
  useMutation<CreatePageFromPosition, CreatePageFromPositionVariables>(
    gql`
      mutation CreatePageFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
        $mutePagesAfter: Int
        $partname: String
      ) {
        createPageFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
          mutePagesAfter: $mutePagesAfter
          partname: $partname
        )
      }
    `,
  );

export const useUpdatePage = () =>
  useMutation<UpdateExtendedPage, UpdateExtendedPageVariables>(
    gql`
      mutation UpdateExtendedPage(
        $projectId: UUID!
        $pageId: UUID!
        $mutePagesAfter: Int!
        $partname: String!
      ) {
        updateExtendedPage(
          projectId: $projectId
          pageId: $pageId
          mutePagesAfter: $mutePagesAfter
          partname: $partname
        )
      }
    `,
  );

export const useUpdatePagesPartname = () =>
  useMutation<UpdateExtendedPagesPartname, UpdateExtendedPagesPartnameVariables>(
    gql`
      mutation UpdateExtendedPagesPartname(
        $projectId: UUID!
        $pageIds: [UUID!]!
        $partname: String!
      ) {
        updateExtendedPagesPartname(projectId: $projectId, pageIds: $pageIds, partname: $partname)
      }
    `,
  );
