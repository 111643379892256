import React from "react";
import { colors } from "../../../../Constants/colors";

type Props = {
  fill?: string;
};

export default function PlayIcon({ fill = colors.ORANGE }: Props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 10.2679C22.3333 11.0377 22.3333 12.9623 21 13.7321L9 20.6603C7.66667 21.4301 6 20.4678 6 18.9282L6 5.0718C6 3.5322 7.66667 2.56995 9 3.33975L21 10.2679Z"
        fill={fill}
      />
    </svg>
  );
}
