import { SubToPageContent_page } from "./SubToPageContent";
import { SubToPages_pages } from "./SubToPages";

import { SubToPagesExtended_extendedPages } from "./__generated__/SubToPagesExtended";
import { SubscribeToExtendedPage_extendedPage } from "./__generated__/SubscribeToExtendedPage";

export enum LetterKind {
  CITATION = "CITATION",
  DEFAULT = "DEFAULT",
  EXPOSANT = "EXPOSANT",
  GRAS = "GRAS",
  INDICE = "INDICE",
  ITALIQUE = "ITALIQUE",
  MAJUSCULE = "MAJUSCULE",
  MINUSCULE = "MINUSCULE",
  MUTE = "MUTE",
}

export const convertPageContent = (
  projectId: string,
  pageContent: SubscribeToExtendedPage_extendedPage,
): SubToPageContent_page => {
  const output: SubToPageContent_page = {
    __typename: "Page",
    numberOfRheses: pageContent?.rhese.length,
    paragraphs: [],
    rheses: [],
    definitions: [],
  };

  pageContent?.definition.forEach((definition) => {
    output.definitions.push({
      __typename: "Definition",
      id: definition.data.id,
      kind: definition.data.kind,
      definition: definition.data.description,
      imageName: definition.data.imageName,
      audioFile: definition.data.audioFile,
      audioReviewComment: definition.data.audioReviewComment,
      audioReviewStatus: definition.data.audioReviewStatus,
      start: definition.anchors[0].utf16Start,
      size: definition.anchors[0].utf16Size,
    });
  });
  pageContent?.paragraph.forEach((paragraph) => {
    output.paragraphs.push({
      __typename: "Paragraph",
      id: paragraph.anchors[0].externalId,
      kind: paragraph.data.kind,
      tag: paragraph.data.tag?.name,
      dialogueSpeakerId: paragraph.data.speaker?.id,
      projectId,
    });
  });

  let rhesePosition = 0;
  pageContent?.rhese.forEach((rhese) => {
    const newRhese = {
      __typename: "SqlxRhese",
      id: rhese.anchors[0].externalId,
      projectId,
      ignoreTextForAudioSync: rhese.data.ignoreTextForAudioSync,
      lineBreakAfter: rhese.data.lineBreakAfter,
      start: rhese.anchors[0].utf16Start,
      size: rhese.anchors[0].utf16Size,
      paragraphId: null,
      position: rhesePosition++,
      audioRecordingEnd: null,
      audioRecordingStart: null,
      audioRecordingId: null,
      letters: [],
    };

    const pageStartOffset = pageContent?.page.anchors[0].utf16Start;
    const start = rhese.anchors[0].utf16Start;
    const offsettedStart = start - pageStartOffset;
    const size = rhese.anchors[0].utf16Size;

    const text = pageContent?.textContent.substring(offsettedStart, offsettedStart + size);

    for (let paragraph of pageContent?.paragraph) {
      let paragraphStart = paragraph.anchors[0].utf16Start;
      let paragraphSize = paragraph.anchors[0].utf16Size;
      if (paragraphStart <= start && paragraphStart + paragraphSize >= start + size) {
        newRhese.paragraphId = paragraph.anchors[0].externalId;
        break;
      }
    }

    for (let i = 0; i < text.length; i++) {
      const letterKind = ["DEFAULT"];
      let letterIsMuted = false;

      if (pageContent?.textMuted[0]?.anchors?.length) {
        for (let textMuted of pageContent?.textMuted[0].anchors) {
          let mutedStart = textMuted.utf16Start;
          let mutedSize = textMuted.utf16Size;
          if (start + i >= mutedStart && start + i < mutedStart + mutedSize) {
            letterIsMuted = true;
            break;
          }
        }
      }
      if (pageContent?.textCitation[0]?.anchors?.length) {
        for (let textCitation of pageContent?.textCitation[0].anchors) {
          let citationStart = textCitation.utf16Start;
          let citationSize = textCitation.utf16Size;
          if (start + i >= citationStart && start + i < citationStart + citationSize) {
            letterKind.push("CITATION");
            break;
          }
        }
      }
      if (pageContent?.textWeight[0]?.anchors?.length) {
        for (let textWeight of pageContent?.textWeight[0].anchors) {
          let weightStart = textWeight.utf16Start;
          let weightSize = textWeight.utf16Size;
          if (start + i >= weightStart && start + i < weightStart + weightSize) {
            letterKind.push("GRAS");
            break;
          }
        }
      }
      if (pageContent?.textItalicization[0]?.anchors?.length) {
        for (let textItalicization of pageContent?.textItalicization[0].anchors) {
          let italicizationStart = textItalicization.utf16Start;
          let italicizationSize = textItalicization.utf16Size;
          if (
            start + i >= italicizationStart &&
            start + i < italicizationStart + italicizationSize
          ) {
            letterKind.push("ITALIQUE");
            break;
          }
        }
      }
      for (let textScriptNotation of pageContent?.textScriptNotation) {
        if (textScriptNotation.anchors.length === 0) break;
        if (textScriptNotation.data.textScriptNotation === "SUBSCRIPT") {
          for (let anchor of textScriptNotation.anchors) {
            let subscriptStart = anchor.utf16Start;
            let subscriptSize = anchor.utf16Size;
            if (start + i >= subscriptStart && start + i < subscriptStart + subscriptSize) {
              letterKind.push("INDICE");
              break;
            }
          }
        }
        if (textScriptNotation.data.textScriptNotation === "SUPERSCRIPT") {
          for (let anchor of textScriptNotation.anchors) {
            let superscriptStart = anchor.utf16Start;
            let superscriptSize = anchor.utf16Size;
            if (start + i >= superscriptStart && start + i < superscriptStart + superscriptSize) {
              letterKind.push("EXPOSANT");
              break;
            }
          }
        }
      }
      for (let textCase of pageContent?.textCase) {
        if (textCase.anchors.length === 0) break;
        if (textCase.data.textCase === "UPPER") {
          for (let anchor of textCase.anchors) {
            let upperStart = anchor.utf16Start;
            let upperSize = anchor.utf16Size;
            if (start + i >= upperStart && start + i < upperStart + upperSize) {
              letterKind.push("MAJUSCULE");
              break;
            }
          }
        }
        if (textCase.data.textCase === "LOWER") {
          for (let anchor of textCase.anchors) {
            let lowerStart = anchor.utf16Start;
            let lowerSize = anchor.utf16Size;
            if (start + i >= lowerStart && start + i < lowerStart + lowerSize) {
              letterKind.push("MINUSCULE");
              break;
            }
          }
        }
      }

      let phonemeKind = null;
      for (let phoneme of pageContent?.phoneme) {
        for (let anchor of phoneme.anchors) {
          let phonemeStart = anchor.utf16Start;
          let phonemeSize = anchor.utf16Size;
          if (start + i >= phonemeStart && start + i < phonemeStart + phonemeSize) {
            switch (phoneme.data.kind) {
              case "VOWEL":
                phonemeKind = "vowel";
                break;
              case "CONSONANT":
                phonemeKind = "consonant";
                break;
              case "COMPLEX":
                phonemeKind = "complicated";
                break;
            }
            break;
          }
        }
      }

      let syllableId = null;
      for (let syllable of pageContent?.syllable) {
        let syllableStart = syllable.anchors[0].utf16Start;
        let syllableSize = syllable.anchors[0].utf16Size;
        if (start + i >= syllableStart && start + i < syllableStart + syllableSize) {
          syllableId = syllable.data.id;
          break;
        }
      }

      let customColorationKey = null;
      for (let customColoration of pageContent?.customColoration) {
        let customColorationStart = customColoration.anchors[0].utf16Start;
        let customColorationSize = customColoration.anchors[0].utf16Size;
        if (
          start + i >= customColorationStart &&
          start + i < customColorationStart + customColorationSize
        ) {
          customColorationKey = customColoration.data.customColorationKey;
          break;
        }
      }

      let cssClasses = null;
      for (let customCss of pageContent?.customCss) {
        let customCssStart = customCss.anchors[0].utf16Start;
        let customCssSize = customCss.anchors[0].utf16Size;
        if (start + i >= customCssStart && start + i < customCssStart + customCssSize) {
          cssClasses = customCss.data.customCssClasses;
          break;
        }
      }

      let letter = {
        __typename: "SqlxLetter",
        id: null,
        isMute: letterIsMuted,
        char: text.charAt(i),
        phoneme: phonemeKind,
        absoluteTextPosition: start + i, // Position from the beginning of the text
        position: i, // Position from the beginning of the rhese
        syllableId,
        customColoration: customColorationKey,
        kind: letterKind,
        cssClasses,
      };

      // @ts-ignore
      newRhese.letters.push(letter);
    }
    // @ts-ignore
    output.rheses.push(newRhese);
  });

  // @ts-ignore
  output.rheses.sort((a, b) => a.start - b.start);

  return output;
};

export const convertExtendedPagesToPages = (
  data: SubToPagesExtended_extendedPages | never,
): SubToPages_pages[] => {
  const output: SubToPages_pages[] = [];

  data.computedMetadata?.forEach((computedMetadata) => {
    const extendedPage = data.extendedPages.find(
      (extendedPage) => extendedPage.id === computedMetadata.externalId,
    );
    if (!extendedPage) {
      return;
    }
    output.push({
      __typename: "Page",
      id: extendedPage.id,
      mutePagesAfter: extendedPage.mutePagesAfter,
      pageNumber: extendedPage.pageNumber.pageNumber,
      lastPageNumber: extendedPage.pageNumber.lastPageNumber,
      partName: extendedPage.partname || null,

      // New infos
      start: computedMetadata.utf16Start,
      size: computedMetadata.utf16Size,
      stringVersion: computedMetadata.stringVersion,

      // Old infos that does not meet current requirements
      numberOfRheses: 0, // placeholder
      wordsCount: null, // placeholder
      issuesCount: 0, // placeholder
      renarratedCount: 0, // placeholder
      fullyRecorded: false, // placeholder
    });
  });

  return output.sort((a, b) => a.pageNumber - b.pageNumber);
};
