@import "../../../../app/editor/styles/colors.less";
@import "../../../../app/editor/styles/fonts.less";

.root {
  :global(.ant-layout-header) {
    background: @darkest-grey;
    color: @light-grey;
  }

  :global(.ant-layout-content) {
    display: flex;
    justify-content: center;
    overflow-y: auto;
  }

  :global(.ant-tabs) {
    overflow: visible;
    width: 100%;
    max-width: 150em;
    padding: 5em 3em;
    height: max-content;
  }

  .tabs :global(.ant-tabs-tab) {
    font-size: 1.15em;
    > * {
      text-shadow: none;
    }
  }
}
.userFirstLetterWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.userFirstLetter {
  display: flex;
  align-items: center;
  justify-content: center;
  background: @grey;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  border-radius: 100%;
  height: 2em;
  width: 2em;
}

.title {
  color: white;
}

.menu {
  color: @light-grey;
  font-size: 1.16em;
}

.tabs {
  padding: 5em 0;
}

.tabs :global(.ant-tabs-tab) {
  font-size: 1.16em;
}

.content {
  overflow-y: auto;
}

.libraryTitle {
  .h1();
  font-size: 2.15em;
  padding-right: 5em;
}

:global(.ant-tabs-nav) {
  border-bottom: 1px solid @light-grey;
}

.projects {
  padding-top: 1.2em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: calc(12em + 3vw);
  @media (max-width: 1400px) {
    grid-auto-rows: calc(2.9em + 20vw);
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-auto-rows: calc(2.9em + 30vw);
    grid-template-columns: 1fr 1fr;
  }
  row-gap: 1.41em;
  column-gap: 1.41em;
}
