import React from "react";
import classNames from "classnames";
import { Tooltip } from "antd";
import style from "./EnvBanner.style.less";

/**
 * Display a banner onl on TEDEVELOPMENTST and TEST env
 */

enum EnvEnum {
  "DEVELOPMENT" = "DEVELOPMENT",
  "TEST" = "TEST",
  "PRODUCTION" = "PRODUCTION",
}

export const ENV_NAME: EnvEnum = process.env.ENV_NAME as EnvEnum;
const SHOW_ENV_BANNER = process.env.SHOW_ENV_BANNER === "TRUE";

export default function EnvBanner() {
  if (ENV_NAME === "PRODUCTION" || !SHOW_ENV_BANNER) {
    return null;
  }
  return (
    <Tooltip placement="right" title={ENV_NAME}>
      <div className={classNames(style.container, style[ENV_NAME.toLowerCase()])}></div>
    </Tooltip>
  );
}
