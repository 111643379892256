@import "../../app/editor/styles/colors.less";

:global(#app) {
  display: flex;
}

.container {
  padding-bottom: 1em;
  text-orientation: upright;
  writing-mode: vertical-rl;
  letter-spacing: 0.1em;
  text-align: right;
  padding: 0.25em;
  &.development {
    background: #ffee91;
  }
  &.test {
    background: #ff925b;
  }
}
