import { gql, useMutation } from "@apollo/client";
import {
  CreateParagraphFromPosition,
  CreateParagraphFromPositionVariables,
} from "./__generated__/CreateParagraphFromPosition";

export const useCreateParagraphFromPosition = () =>
  useMutation<CreateParagraphFromPosition, CreateParagraphFromPositionVariables>(
    gql`
      mutation CreateParagraphFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
        $kind: ExtendedParagraphKind!
        $paragraphTagId: UUID
      ) {
        createParagraphFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
          kind: $kind
          paragraphTagId: $paragraphTagId
        )
      }
    `,
  );
