import React from "react";
import { SecureRoute } from "@okta/okta-react";
import Overview from ".";
import { SplitContent } from "../../EditingRouter/components/SplitContent";
import { SubToPages_pages } from "../../../api/SubToPages";
import { useRouteMatch } from "react-router";

const Route = (props: any) => {
  const { params } = useRouteMatch<{ bookId?: string }>();

  return (
    <SplitContent
      component={Overview}
      props={{
        ...props,
        projectId: params.bookId,
      }}>
      {props.allPages}
    </SplitContent>
  );
};

const RouteExport = (props: { allPages: SubToPages_pages[] }) => (
  <SecureRoute path="/editor/books/edit/:bookId/overview" render={() => <Route {...props} />} />
);

export default RouteExport;
