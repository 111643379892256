import React from "react";
import { Modal } from "antd";
import * as style from "./style.less";
import useProjectExports from "../useProjectExports/useProjectExports";
import ExportsTable from "../../../../../../components/ExportsTable/ExportsTable";

type Props = {
  open: boolean;
  onDismiss: () => void;
  projectId: string;
};

export default function PanelProjectExports({ projectId, open, onDismiss }: Props) {
  const { exports, cancelExport, downloadExport, deleteExports, deleteLoading, deletingExportIds } =
    useProjectExports(projectId);

  const projectName = exports.find((exp) => exp.projectId === projectId)?.projectName;

  return (
    <Modal
      className={style.root}
      title={projectName}
      centered
      open={open}
      onOk={onDismiss}
      onCancel={onDismiss}>
      <ExportsTable
        exports={exports}
        cancel={cancelExport}
        download={downloadExport}
        deleteExports={deleteExports}
        deleteLoading={deleteLoading}
        deletingExportIds={deletingExportIds}
        hideTopLegend
      />
    </Modal>
  );
}
