import React from "react";
import { useTranslation } from "react-i18next";
import { SyncOutlined } from "@ant-design/icons";
import * as style from "./style.less";

export default function ReSyncOverlay() {
  const [t] = useTranslation();

  return (
    <div className={style.resyncOverlay}>
      <h2>{t("overlayReSync")}</h2>
      <SyncOutlined spin />
    </div>
  );
}
