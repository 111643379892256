import React, { memo } from "react";
import { Button } from "antd";
import Tooltip from "antd/es/tooltip";
import { motion } from "framer-motion";
import { ApiOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { AudioReviewStatus } from "../../../../../../../__generated__/globalTypes";
import { useAddAudioReview, useUpdateAudioReview } from "../../../../Subscriptions/subscriptions";
import PlayIcon from "../assets/PlayIcon";
import * as style from "../style.less";
import StatusSelector from "./StatusSelector";
import SynchroModal from "./SynchroModal/SynchroModal";
import CommentPopOver from "./CommentButtonAndModal";
import { getAudioOffsetsAndDuration } from "./utils";
import PauseIcon from "../assets/PauseIcon";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

type Props = {
  rhese: SubscribeToExtendedPage_extendedPage_rhese;
  playRhese: (rhese: SubscribeToExtendedPage_extendedPage_rhese) => () => void;
  setRefRheseToPlay: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  selectionAnchor?: string;
  setSelectedRhese: (rheseId: string) => void;
  selectedRheses: null | SubscribeToExtendedPage_extendedPage_rhese[];
  resetSelection: () => void;
  isCurrentRhese: boolean;
  isPlaying: Boolean;
  projectId: string;
  pageMetadata: SubscribeToExtendedPage_extendedPage;
};

const Rhese = ({
  rhese,
  playRhese,
  setRefRheseToPlay,
  selectionAnchor,
  setSelectedRhese,
  selectedRheses,
  resetSelection,
  isCurrentRhese,
  isPlaying,
  projectId,
  pageMetadata,
}: Props) => {
  const [t] = useTranslation();
  const [updateAudioReview] = useUpdateAudioReview();
  const [createAudioReview] = useAddAudioReview();

  const audioInfo = rhese.data.audioInfo;
  const recording = rhese.data.audioRecording;

  const { duration, startOffset, endOffset, isCropped } = getAudioOffsetsAndDuration(rhese, 1);

  return (
    <motion.div whileHover="hover" className={style.rheseContainer}>
      <div
        data-rhese-id={rhese.data.id}
        ref={(ref) => isCurrentRhese && setRefRheseToPlay(ref)}
        data-rhese-to-play={isCurrentRhese ? "true" : "false"}
        data-selected={selectedRheses?.find((r) => r.data.id === rhese.data.id) ? "true" : "false"}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedRhese(rhese.data.id);
        }}
        className={`${style.rhese}`}>
        {selectionAnchor && selectionAnchor === rhese.data.id && selectedRheses?.length === 0 && (
          <div className={style.anchor} />
        )}
        <div className={style.content}>
          <>
            {rhese.data.ignoreTextForAudioSync && (
              <Tooltip title={t("rheseIconSyncroTooltip")}>
                <ApiOutlined className={style.iconIgnoredFromSynchro} />
              </Tooltip>
            )}
            {pageMetadata?.textContent.substring(
              rhese.anchors[0].utf16Start - pageMetadata?.page.anchors[0].utf16Start,
              rhese.anchors[0].utf16Start +
                rhese.anchors[0].utf16Size -
                pageMetadata?.page.anchors[0].utf16Start,
            )}
          </>
        </div>
        {audioInfo && (
          <div onClick={(e) => e.stopPropagation()} className={style.audioReview}>
            <p className={style.rheseAudioDuration}>{duration?.toFixed(2)}s</p>
            <motion.div
              key={isCropped.toString()}
              initial={{ opacity: isCropped ? 1 : 0.3 }}
              variants={{ hover: { opacity: 1 } }}>
              <SynchroModal
                info={audioInfo}
                projectId={projectId}
                recording={recording}
                defaultStartOffset={startOffset}
                defaultEndOffset={endOffset}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: audioInfo.comment ? 1 : 0.3 }}
              animate={{ opacity: audioInfo.comment ? 1 : 0.3 }}
              variants={{ hover: { opacity: 1 } }}>
              <CommentPopOver
                defaultValue={audioInfo.comment}
                onValidate={(comment) =>
                  audioInfo.reviewId
                    ? updateAudioReview({
                        variables: {
                          projectId,
                          reviewId: audioInfo.reviewId,
                          status: audioInfo.status || AudioReviewStatus.VALID,
                          comment,
                        },
                      })
                    : createAudioReview({
                        variables: {
                          projectId,
                          rheseId: rhese.data.id,
                          status: audioInfo.status || AudioReviewStatus.VALID,
                          comment,
                        },
                      })
                }
              />
            </motion.div>
            <StatusSelector
              status={audioInfo.status}
              onChange={(status: AudioReviewStatus) => {
                audioInfo.reviewId
                  ? updateAudioReview({
                      variables: {
                        projectId,
                        reviewId: audioInfo.reviewId,
                        status,
                        comment: audioInfo.comment,
                      },
                    })
                  : createAudioReview({
                      variables: {
                        projectId,
                        rheseId: rhese.data.id,
                        status,
                        comment: audioInfo.comment,
                      },
                    });
                resetSelection();
              }}
            />
          </div>
        )}
      </div>
      <motion.div
        className={style.playRhese}
        initial={{ opacity: 0 }}
        variants={{ hover: { opacity: 1 } }}>
        <Button shape="round" onClick={playRhese(rhese)} disabled={!recording?.fileUuid}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </Button>
      </motion.div>
    </motion.div>
  );
};

export default memo(Rhese, (prevProps, nextProps) => {
  const oneOfSelectedRheseIsMe = prevProps.selectedRheses?.find(
    (r) => r.data.id === prevProps.rhese.data.id,
  );
  const oneOfSelectedRheseIsMeNext = nextProps.selectedRheses?.find(
    (r) => r.data.id === nextProps.rhese.data.id,
  );
  const previousRheseData = JSON.stringify(prevProps.rhese.data);
  const nextRheseData = JSON.stringify(nextProps.rhese.data);
  const previousTextContent = prevProps.pageMetadata?.textContent;
  const nextTextContent = nextProps.pageMetadata?.textContent;

  return (
    prevProps.isCurrentRhese === nextProps.isCurrentRhese &&
    prevProps.isPlaying === nextProps.isPlaying &&
    oneOfSelectedRheseIsMe === oneOfSelectedRheseIsMeNext &&
    previousRheseData === nextRheseData &&
    previousTextContent === nextTextContent
  );
});
