import React, { useState, useRef, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import * as style from "../style.less";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

const STORAGE_KEY = "projects_search";
const persistance = window.sessionStorage;

enum SearchState {
  OPENED = "opened",
  CLOSED = "closed",
}

const SearchBar = ({ onChange: onChangeProps }: { onChange: (str: string) => void }) => {
  const [value, setValue] = useState("");
  const [state, setState] = useState(SearchState.CLOSED);
  const ref = useRef<HTMLInputElement | null>(null);

  // Close handler
  const close = useCallback(() => {
    setState(SearchState.CLOSED);
    setValue("");
    persistance.setItem(STORAGE_KEY, "");
  }, []);

  // Change handler
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setValue(searchValue);
    persistance.setItem(STORAGE_KEY, searchValue);
  }, []);

  // Propagate the search value to the parent
  useEffect(() => {
    onChangeProps(value);
  }, [onChangeProps, value]);

  // Rehydrate search value from persisted value (on mount)
  useEffect(() => {
    const persistedSearchValue = persistance.getItem(STORAGE_KEY);
    if (persistedSearchValue) {
      setState(SearchState.OPENED);
      setValue(persistedSearchValue);
      onChangeProps(persistedSearchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto-focus on opening
  useEffect(() => {
    const input = ref.current;
    if (state === SearchState.OPENED && input) {
      input.focus();
    }
  }, [state, ref]);

  return (
    <motion.div animate={state} className={style.searchBar}>
      {state === SearchState.CLOSED && (
        <motion.div
          whileHover="hovered"
          className={style.open}
          variants={{
            hovered: { scale: 1.1 },
          }}>
          <SearchOutlined
            style={{ cursor: "pointer" }}
            onClick={() => setState(SearchState.OPENED)}
          />
        </motion.div>
      )}
      {state === SearchState.OPENED && (
        <motion.div
          className={style.close}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}>
          <CloseOutlined onClick={close} />
        </motion.div>
      )}
      <motion.div
        className={style.input}
        initial={{ opacity: 0 }}
        style={{
          pointerEvents: state === SearchState.CLOSED ? "none" : "initial",
        }}
        variants={{
          opened: { opacity: 1 },
          closed: { opacity: 0 },
        }}>
        <input ref={ref} onChange={onChange} value={value} />
      </motion.div>
    </motion.div>
  );
};

export default SearchBar;
