import { SelectedTextPosition } from "../../FormattingComp";
import { SubscribeToExtendedPage_extendedPage_customCss } from "../../../../../../../api/__generated__/SubscribeToExtendedPage";

export const hasDifferentClasses = (
  selectedTextPosition: SelectedTextPosition,
  classesMetadata: SubscribeToExtendedPage_extendedPage_customCss[],
) => {
  // Loop through the metadata to verify if the selected text has different classes
  let isDifferent = false;
  let lastClass: string | null | undefined = undefined;

  classesMetadata.forEach((metadata) => {
    const metadataStart = metadata.anchors[0].utf16Start;
    const metadataEnd = metadata.anchors[0].utf16Start + metadata.anchors[0].utf16Size;

    const selectedStart = selectedTextPosition.start;
    const selectedEnd = selectedTextPosition.start + selectedTextPosition.size;

    if (metadataStart >= selectedStart && metadataEnd <= selectedEnd) {
      isDifferent = !!lastClass && lastClass !== metadata.data.customCssClasses;
      lastClass = metadata.data.customCssClasses;
    }
  });

  return isDifferent;
};

export const getCommonClass = (
  selectedTextPosition: SelectedTextPosition,
  classesMetadata: SubscribeToExtendedPage_extendedPage_customCss[] = [],
) => {
  if (!classesMetadata.length || hasDifferentClasses(selectedTextPosition, classesMetadata)) {
    return "";
  }
  return classesMetadata[0].data.customCssClasses;
};
