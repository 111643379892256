import { gql, useMutation } from "@apollo/client";
import {
  CreateColorationFromPosition,
  CreateColorationFromPositionVariables,
} from "./__generated__/CreateColorationFromPosition";
import {
  DeleteColorationFromPosition,
  DeleteColorationFromPositionVariables,
} from "./__generated__/DeleteColorationFromPosition";

export const useCreateColorationFromPosition = () =>
  useMutation<CreateColorationFromPosition, CreateColorationFromPositionVariables>(
    gql`
      mutation CreateColorationFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
        $newColoration: AddExtendedMetadataCustomColoration!
      ) {
        createColorationFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
          newColoration: $newColoration
        )
      }
    `,
  );

export const useDeleteColorationFromPosition = () =>
  useMutation<DeleteColorationFromPosition, DeleteColorationFromPositionVariables>(
    gql`
      mutation DeleteColorationFromPosition(
        $projectId: UUID!
        $start: Int!
        $size: Int!
        $stringVersion: Int!
      ) {
        deleteColorationFromPosition(
          projectId: $projectId
          start: $start
          size: $size
          stringVersion: $stringVersion
        )
      }
    `,
  );
