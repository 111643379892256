import classNames from "classnames";
import React, { ReactElement } from "react";
import * as style from "./style.less";

type ClickableWrapperProps = {
  children: React.ReactNode;
  disabled?: boolean;
};

const tryToAddClassName = (children: React.ReactNode, disabled = false): React.ReactNode => {
  if (Array.isArray(children)) {
    return (children as any[]).map((el) => tryToAddClassName(el, disabled));
  } else {
    const child = React.cloneElement(children as ReactElement, {
      className: classNames((children as ReactElement).props.className, style.clickableWrapper, {
        [style.disabled]: disabled,
      }),
    });
    return child;
  }
};

export const ClickableWrapper = ({ children, disabled }: ClickableWrapperProps): any => {
  try {
    return tryToAddClassName(children, disabled);
  } catch (e) {
    return (
      <div className={classNames(style.clickableWrapper, { [style.disabled]: disabled })}>
        {children}
      </div>
    );
  }
};
