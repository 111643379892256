import { preciseFloat } from "../../../../../Utils/common";
import { hasHumanEnd, hasHumanStart } from "../../Rheses/utils";
import {
  SubscribeToExtendedPage_extendedPage_rhese,
  SubscribeToExtendedPage_extendedPage_rhese_data_audioInfo,
} from "../../../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";
import { Files } from "./audioPlayerMachine";

export const getSrc = (
  currentRheseId: string,
  rhesesWithRecording: SubscribeToExtendedPage_extendedPage_rhese[],
  loadedFiles: Files,
) => {
  const recording = rhesesWithRecording.find((r) => r.data.id === currentRheseId)!.data
    .audioRecording;
  if (!recording) {
    throw new Error("No recording found");
  }
  return loadedFiles.find((file) => file.filename === recording.fileUuid)!.url;
};

export const getRealStartAndEnd = (
  audioRheseData: SubscribeToExtendedPage_extendedPage_rhese_data_audioInfo | null,
) => {
  if (!audioRheseData) {
    return [0, 0];
  }

  const start = hasHumanStart(audioRheseData) ? audioRheseData.humanStart : audioRheseData.start;
  const end = hasHumanEnd(audioRheseData) ? audioRheseData.humanEnd : audioRheseData.end;

  const realStart = start ? preciseFloat(start, 2) : 0;
  const realEnd = end ? preciseFloat(end, 2) : 0;

  return [realStart, realEnd];
};
