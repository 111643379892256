import { createContext } from "react";
import { errorMessageType, setErrorMessageType } from "../Types/errorMessage";
import { recordType } from "../Types/record";
import { SubscribeToExtendedPage_extendedPage_rhese } from "../../editor/Project/api/__generated__/SubscribeToExtendedPage";

interface audioAnalyzerContextType {
  errorMessage: errorMessageType;
  setErrorMessage: setErrorMessageType;
  selectedRheses: SubscribeToExtendedPage_extendedPage_rhese[];
  recordedRheses: SubscribeToExtendedPage_extendedPage_rhese[];
  updateRecordedRheses: () => void;
  clearRecordedRheses: () => void;
  sendRecordedRhesesToDatabase: (blob: recordType) => void;
  uploadAudioFileToDatabase: (
    file: File,
    rheses: SubscribeToExtendedPage_extendedPage_rhese[],
  ) => Promise<void>;
}

const audioAnalyzerContextObj: audioAnalyzerContextType = {
  errorMessage: undefined,
  setErrorMessage: () => {},
  selectedRheses: [],
  recordedRheses: [],
  updateRecordedRheses: () => {},
  clearRecordedRheses: () => {},
  sendRecordedRhesesToDatabase: () => {},
  uploadAudioFileToDatabase: async () => {},
};

const AudioAnalyzerContext = createContext(audioAnalyzerContextObj);

export default AudioAnalyzerContext;
