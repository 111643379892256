@import "../../app/editor/styles/colors.less";

.header:global(.ant-layout-header) {
  background: @darkest-grey;
  color: @light-grey;
  height: auto !important;
  svg {
    fill: white;
  }
}

.tooltipContent {
  min-width: 7.2em;
  .version {
    font-size: 1em;
    margin-bottom: 0;
  }
}

.userFirstLetterWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.userFirstLetter {
  display: flex;
  align-items: center;
  justify-content: center;
  background: @grey;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  border-radius: 100%;
  height: 2em;
  width: 2em;
}
