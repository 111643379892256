export const colors = {
  UNFOCUSED_REGION: "rgba(0, 40, 0, 0.1)",
  FOCUSED_REGION: "rgba(0, 0, 255, 0.3)",
  UNFOCUSED_HANDLE: "black",
  FOCUSED_HANDLE: "red",
  OUTSIDE_REGIONS: "rgba(0,0,0,0)",
  BLUE_GREY: "#353a52",
  GREEN: "#61c374",
  ORANGE: "#E66525",
  GREY_DISABLED: "#c5c5c5",
};
