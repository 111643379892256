import React from "react";
import * as style from "../TabProgress.style.less";
import { useI18n } from "../../../../../../../../i18n";
import PreparingState from "./PreparingState";
import NarrationState from "./NarrationState";
import { PreparationProgressStatus } from "../../../../../../../../../__generated__/globalTypes";
import { ProgressStats } from "../../../../../hooks/useProjectProgressStats";

type Props = {
  preparationProgress: PreparationProgressStatus;
  isNarrationComplete: boolean;
  completePreparation: () => void;
  completeNarration: () => void;
  finalComplete: () => void;
  stats: ProgressStats;
};

const getTitleKey = (
  isNarrationComplete: boolean,
  preparationProgress: PreparationProgressStatus,
) => {
  if (isNarrationComplete && preparationProgress === PreparationProgressStatus.COMPLETE) {
    return "project.actionBar.progress.title_state_narrationAndFixComplete";
  }
  if (isNarrationComplete) {
    return "project.actionBar.progress.title_state_narrationComplete";
  }
  return "project.actionBar.progress.title_state_narration";
};

export default function StepNarration({
  preparationProgress,
  isNarrationComplete,
  completePreparation,
  completeNarration,
  finalComplete,
  stats,
}: Props) {
  const [t] = useI18n();

  return (
    <div className={style.step}>
      <h4>{t(getTitleKey(isNarrationComplete, preparationProgress))}</h4>
      <div className={style.container}>
        <div>
          <h5>{t("project.actionBar.progress.narration.title")}</h5>
          <NarrationState
            stats={stats}
            preparationProgress={preparationProgress}
            isNarrationComplete={isNarrationComplete}
            completeNarration={completeNarration}
            finalComplete={finalComplete}
          />
        </div>
        <div className={style.divider}>
          <div></div>
        </div>
        <div>
          <h5>{t("project.actionBar.progress.preparing.title")}</h5>
          <PreparingState
            preparationProgress={preparationProgress}
            completePreparation={completePreparation}
          />
        </div>
      </div>
    </div>
  );
}
