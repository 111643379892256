@import "../../../styles/fonts.less";
@import "../../../styles/colors.less";

.importType {
  text-align: center;
}

.disableColorationOnSimpleWords {
  text-align: start;
}

.modal {
  :global(.ant-radio-group) {
    justify-content: start;
  }

  :global(.ant-modal-body) {
    :global(.ant-upload) {
      button {
        font-size: 1.16em;
        font-style: italic;
        color: @grey;
        height: 3.214em;
        line-height: 1;
        padding: 0 1.43em;
        background-color: fade(@light-grey, 20%);
        border-color: fade(@dark-grey, 25%);
        border-radius: 3px;

        &:hover {
          border-color: fade(@dark-grey, 25%);
          background-color: fade(@light-grey, 50%);
        }
      }

      &:global(.ant-upload-select) {
        width: 100%;

        :global(.ant-btn) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row-reverse;

          & > * {
            margin: 0;
          }
        }
      }
    }
  }
}
