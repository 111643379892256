.resyncOverlay {
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  :global(.anticon) {
    font-size: 1.8em;
    margin-top: 0.3em;
  }
  h2 {
    font-size: 1.5em;
  }
}
