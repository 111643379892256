import { AudioOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import StopIconSvg from "../../../../../../assets/svg/StopIconSvg";
import { AudioRecorderState } from "../../audioRecorder/AudioRecorder";

type RecorderButtonProps = {
  audioRecorderState: AudioRecorderState;
  fileUploadMode: boolean;
  onClick: () => void;
  disabled: boolean;
};

const RecorderButton = ({
  audioRecorderState,
  fileUploadMode,
  onClick,
  disabled,
}: RecorderButtonProps) => {
  const [recordButtonState, setRecordButtonState] = useState(AudioRecorderState.idle);

  useEffect(() => {
    if (audioRecorderState === AudioRecorderState.recording) {
      setRecordButtonState(AudioRecorderState.recording);
    } else if (audioRecorderState === AudioRecorderState.idle) {
      setRecordButtonState(AudioRecorderState.idle);
    }
    return () => {};
  }, [audioRecorderState]);

  return (
    <>
      {recordButtonState === AudioRecorderState.recording && (
        <Button
          style={{ height: "4em", width: "4em", padding: "1em" }}
          disabled={disabled}
          onClick={onClick}
          shape="circle"
          icon={<StopIconSvg style={{ fontSize: "4em" }} />}
        />
      )}
      {recordButtonState === AudioRecorderState.idle && (
        <Button
          style={{ height: "4em", width: "4em", padding: "1em" }}
          disabled={disabled}
          onMouseUp={() => setTimeout(onClick, 50)}
          shape="circle"
          icon={
            fileUploadMode ? (
              <CloudUploadOutlined style={{ fontSize: "2em" }} />
            ) : (
              <AudioOutlined style={{ fontSize: "2em" }} />
            )
          }
        />
      )}
    </>
  );
};

export default RecorderButton;
