@import "../../../style/colors.less";

.bookPanelHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 0%;
  padding: 0em 1.5em;
  height: 6em;
  box-shadow: inset 0px -1px 0px rgba(91, 96, 121, 0.12);
}

.bookPanelHeaderTitle {
  font-style: normal;
  font-weight: 500;
  color: @darkest-grey;
  font-size: 1em;
  font-family: Roboto Slab;
}

.filters {
  margin: 0.4em 0;
  .filterCheckboxContainer {
    margin-top: 0.2em;
    padding: 0.6em 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    label {
      opacity: 0.8;
    }
    > p {
      font-size: 0.82em;
      opacity: 0.95;
      margin-bottom: 3px;
    }
  }
}

.pageTabContainer,
.definitions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 1.5em;
  box-shadow: inset 0px -1px 0px rgba(48, 50, 58, 0.12);
  .rightIcon {
    font-size: 18px;
  }
  &[data-disabled="true"] {
    opacity: 0.5;
    cursor: not-allowed;
    .rightIcon {
      opacity: 0.35;
    }
  }

  &.pageTabContainer {
    .rightIcon {
      margin-left: 0.7em;

      &.hidden {
        opacity: 0;
      }
    }
  }
}

.pageTabContainer:hover,
.definitions:hover {
  background-color: @light-white;
}

.researchInputContainer {
  height: 2em;
  width: 100%;
  border-bottom: 1px solid @light-grey;
}

.searchInput {
  padding: 1em;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  color: @dark-grey;
  background-color: @light-grey;
}

.bookPanelPageContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
}

.drawer :global(.ant-drawer-body) {
  padding: 0;
  font-size: 1em;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.searchIcon {
  position: absolute;
  right: 2em;
}

.spinner {
  margin-top: 3em;
}
