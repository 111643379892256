@import "../../style/colors.less";

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recorderInstructionContainer {
  background-color: @light-red;
  padding: 8px 16px 16px 16px;
  margin-bottom: 8px;
}

.recorderInstruction {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: @red;
}

.recorderContainer {
  z-index: 2;
  width: 100%;
  max-width: 40em;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto auto;
  justify-content: space-between;
  height: max-content;
  flex-direction: row;
  align-items: center;
  background-color: @white;
  border-radius: 16px;
  padding: 1em 4em;
  box-shadow: 0 0 15px #00000014;
  border: 1px solid #5b607913;
  min-height: 6em;
  row-gap: 1em;
  margin-bottom: 0.5em;
}

.rowOne {
  grid-row: 1;
  justify-self: center;
}

.recorderSwitchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: end;
  grid-row: 2;
  grid-column: 1/4;
  justify-self: center;
  margin-top: 5px;
}

.recorderSwitchContainerIcon {
  color: gray;
  margin-right: 0.2em;
  margin-bottom: 0.2em;
  margin-left: 0.2em;
}

.recordingArea {
  display: flex;
  justify-content: center;
  height: max-content;
  flex-direction: column;
  align-items: center;
  grid-column: 2/3;
}

.wrapper {
  position: sticky;
  display: flex;
  justify-content: center;
  bottom: 0;
  z-index: 2;
  width: 100%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 16px;
}
.cancelButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 16px;
  background-color: @light-grey;
}

.recorderInstructionContainer {
  background-color: @light-red;
  padding: 8px 16px 16px 16px;
  margin-bottom: 8px;
}

.recorderInstruction {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: @red;
}

.validationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  gap: 8px;

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1em;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 16px;
}
.cancelButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 16px;
  background-color: @light-grey;
}

.settingsButton {
  margin-left: 1.5rem;
  width: 26px;
  height: 26px;
  border: none;
}
