import { useCallback, useState } from "react";
import { UploadFile } from "antd/lib/upload/interface";
import { notification } from "antd";
import { useI18n } from "../../../../../../i18n";

const MAX_FILE_SIZE_IN_MIB = 15;
const EXTENSIONS_ALLOWED = ["mp3", "ogg", "wav"];

type Options = {
  setDefinitionAudioFile: (definitionId: string, filename: string) => void;
};

const control = {
  size: (file: UploadFile) => {
    if (!file.size) {
      return false;
    }
    const sizeInMiB = file.size / 1024 / 1024;
    return sizeInMiB <= MAX_FILE_SIZE_IN_MIB;
  },
  extension: (file: UploadFile) => {
    const strArr = file.name.split(".");
    const extension = strArr[strArr.length - 1];
    return EXTENSIONS_ALLOWED.includes(extension);
  },
};

export default function useUploadFile({ setDefinitionAudioFile }: Options) {
  const [t] = useI18n();
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = useCallback(
    async (definitionId: string, putUrl: string, filename: string, file: UploadFile<any>) => {
      if (!file?.name) {
        console.log("uploadFile stopped, no file provided");
        return;
      }
      if (isUploading) {
        console.log("uploadFile stopped, alread uploading");
        return;
      }
      // Control extension
      if (!control.extension(file)) {
        notification.error({
          message: `${t("definitionAudioUploadErrorExtension")}: ${EXTENSIONS_ALLOWED.join(", ")}`,
        });
        return;
      }
      // Control size
      if (!control.size(file)) {
        notification.error({
          message: `${t("definitionAudioUploadErrorSize")} ${MAX_FILE_SIZE_IN_MIB} MB`,
        });
        return;
      }

      // Upload the file
      try {
        setIsUploading(true);

        await fetch(putUrl, {
          method: "PUT",
          body: file.originFileObj,
        });

        await setDefinitionAudioFile(definitionId, filename);
        notification.success({ message: t("definitionAudioUploadSuccess") });
      } catch (e: any) {
        console.log("useUploadFile error", e);
        notification.error({ message: `${t("definitionAudioUploadError")}: ${e?.message || ""}` });
      } finally {
        setIsUploading(false);
      }
    },
    [isUploading, setDefinitionAudioFile, t],
  );
  return {
    uploadFile,
    isUploading,
  };
}
