export const subroutesPath = {
  base: "/editor",
  bookBase: "/editor/books/edit/:bookId",
  format: "/editor/books/edit/:bookId/format",
  syllable: "/editor/books/edit/:bookId/syllable",
  rhese: "/editor/books/edit/:bookId/rhese",
  narration: "/editor/books/edit/:bookId/narration",
  definition: "/editor/books/edit/:bookId/definition",
  overview: (bookId?: string) => `/editor/books/edit/${bookId}/overview`,
};
