import { useOktaAuth } from "@okta/okta-react";
import { createContext, Dispatch, useContext, useEffect, useMemo, useReducer } from "react";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router";

export const useIdentity = () => {
  const { authState } = useOktaAuth();
  const idToken = authState?.idToken?.idToken;
  if (!idToken || !authState?.isAuthenticated) {
    throw new Error("Shouldn't be called on unauthenticated user");
  }
  return useMemo(
    (): { email: string; groups: GROUPS[]; firstName: string; lastName: string } =>
      jwtDecode(idToken),
    [idToken],
  );
};

export enum GROUPS {
  CONTENT_ADMINISTRATOR = "CONTENT_ADMINISTRATOR",
  NARRATOR = "NARRATOR",
  PROOF_LISTENER = "PROOF_LISTENER",
  EDITOR = "EDITOR",
}

export const useIsContentAdministrator = () => {
  const identity = useIdentity();

  return useMemo(
    () => !!identity?.groups.find((group) => group === GROUPS.CONTENT_ADMINISTRATOR),
    [identity],
  );
};

const SelectedRoleContext = createContext<{
  state: FrogRoles;
  dispatch: Dispatch<FrogRoles>;
} | null>(null);

export const SelectedRoleProvider = SelectedRoleContext.Provider;

export const useSelectedRole = () => {
  const context = useContext(SelectedRoleContext);
  if (!context) {
    throw new Error("Selected role context has to be set in a provider");
  }

  return context;
};

export enum FrogRoles {
  Narrator = "narrator",
  ProofListener = "prooflistener",
  Editor = "editor",
}

enum FrogModes {
  Audio = "audio",
  Editor = "editor",
}

const ROLE_KEY = "ROLE";

export function useCanSwitchRole() {
  const identity = useIdentity();
  const groups = identity.groups;

  return groups.length > 1;
}

export function useIsAdmin() {
  return !!useIdentity().groups.includes(GROUPS.CONTENT_ADMINISTRATOR);
}

export function useIsEditor() {
  return !!useIdentity().groups.includes(GROUPS.EDITOR);
}

export function useIsNarrator() {
  return !!useIdentity().groups.includes(GROUPS.NARRATOR);
}

export function useIsProofListener() {
  return !!useIdentity().groups.includes(GROUPS.PROOF_LISTENER);
}

function useStoredRole() {
  return sessionStorage.getItem(ROLE_KEY) as FrogRoles | null;
}

function useStoreRole(role: FrogRoles) {
  useEffect(() => sessionStorage.setItem(ROLE_KEY, role), [role]);
}

export function useRedirectOnRoleChange(role: FrogRoles) {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  useEffect(() => {
    const newMode = role === FrogRoles.Editor ? FrogModes.Editor : FrogModes.Audio;
    if (pathname.includes(FrogModes.Audio) || pathname.includes(FrogModes.Editor)) {
      history.push(pathname.replace(FrogModes.Audio, newMode).replace(FrogModes.Editor, newMode));
    }
  }, [history, pathname, role]);
}

const useGetRoleFromURL = (storedRole: FrogRoles | null) => {
  const history = useHistory();
  const isEditor = useIsEditor();
  const isNarrator = useIsNarrator();
  const isProofListener = useIsProofListener();

  // When launching on home page
  const isHomePage = history.location.pathname === "/";
  if (isHomePage) {
    if (storedRole) {
      return storedRole;
    }
    // Default role
    if (isEditor) {
      return FrogRoles.Editor;
    }
    if (isNarrator) {
      return FrogRoles.Narrator;
    }
    if (isProofListener) {
      return FrogRoles.ProofListener;
    }
  }

  // When launching on non-home page, the role will be either given from the stored one, or from from the url
  const modeFromUrl = history.location.pathname.includes(FrogModes.Editor)
    ? FrogModes.Editor
    : FrogModes.Audio;

  if (modeFromUrl === FrogModes.Editor && isEditor) {
    return FrogRoles.Editor;
  }
  if (modeFromUrl === FrogModes.Audio) {
    if (storedRole === FrogRoles.Narrator || storedRole === FrogRoles.ProofListener) {
      return storedRole;
    }
    if (isNarrator) {
      return FrogRoles.Narrator;
    }
    if (isProofListener) {
      return FrogRoles.ProofListener;
    }
  }

  return null;
};

export function useDefaultRole() {
  const isEditor = useIsEditor();
  const isNarrator = useIsNarrator();
  const isProofListener = useIsProofListener();
  const storedRole = useStoredRole();
  const roleFromUrl = useGetRoleFromURL(storedRole);

  if (roleFromUrl) {
    return roleFromUrl;
  }
  if (storedRole) return storedRole;

  if (isEditor) return FrogRoles.Editor;
  if (isNarrator) return FrogRoles.Narrator;
  if (isProofListener) return FrogRoles.ProofListener;

  throw new Error("Shoulh have at least one role");
}

export const useSelectedRoleReducer = () => {
  const defaultRole = useDefaultRole();
  const [state, dispatch] = useReducer((_: FrogRoles, message: FrogRoles) => message, defaultRole);

  useStoreRole(state);
  return useMemo(() => ({ state, dispatch }), [state, dispatch]);
};
