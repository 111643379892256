import React, { useCallback, useEffect, useState } from "react";
import { Button } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { AudioReviewStatus } from "../../../../../../../__generated__/globalTypes";
import { useI18n } from "../../../../../../i18n";
import { useAddAudioReview, useUpdateAudioReview } from "../../../../Subscriptions/subscriptions";
import * as style from "../style.less";
import StatusSelector from "./StatusSelector";
import ResetSelectionStatusBtn from "./ResetSelectionStatusBtn";
import Rhese from "./Rhese";
import useAudioPlayerMachine from "../AudioPlayer/audioPlayerMachine/useAudioPlayerMachine";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

export type RhesesByParagraphId = {
  paragraphId: string;
  rheses: SubscribeToExtendedPage_extendedPage_rhese[];
};

type Props = {
  selectionAnchor?: string;
  groupedRheses: RhesesByParagraphId[];
  setSelectedRhese: (rheseId: string) => void;
  selectedRheses: null | SubscribeToExtendedPage_extendedPage_rhese[];
  resetSelection: () => void;
  resetSelectionStatus: () => void;
  currentRheseId: null | string;
  isPlaying: boolean;
  isWaiting: boolean;
  projectId: string;
  pageMetadata: SubscribeToExtendedPage_extendedPage;
};

const Rheses = ({
  selectionAnchor,
  groupedRheses,
  setSelectedRhese,
  selectedRheses,
  resetSelection,
  resetSelectionStatus,
  currentRheseId,
  isPlaying,
  isWaiting,
  projectId,
  pageMetadata,
}: Props) => {
  const audioPlayerMachine = useAudioPlayerMachine();

  const onPlayRhesePressed = useCallback(
    (rhese) => () => {
      if (rhese.id === currentRheseId && isPlaying) {
        audioPlayerMachine.send({
          type: "Pause",
        });
      } else if (rhese.data.id === currentRheseId && !isPlaying && !isWaiting) {
        audioPlayerMachine.send({
          type: "Resume",
        });
      } else {
        audioPlayerMachine.send({
          type: "Play this rhese",
          rheseId: rhese.data.id,
        });
      }
    },
    [audioPlayerMachine, currentRheseId, isPlaying, isWaiting],
  );

  const [updateAudioReview] = useUpdateAudioReview();
  const [addAudioReview] = useAddAudioReview();
  const [t] = useI18n();

  // Scroll logic
  const [refRheseToPlay, setRefRheseToPlay] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    refRheseToPlay?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [refRheseToPlay]);

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {selectedRheses && selectedRheses.length > 0 && (
          <motion.div
            initial={{ y: "150%" }}
            exit={{ y: "150%" }}
            animate={{ y: "0%" }}
            transition={{ bounce: 0 }}
            className={style.setStatusBar}>
            <span>{t("audioReview.addAReviewToSelectedRheses")}</span>
            <div className={style.actions}>
              <Button shape="round" onClick={resetSelection}>
                {t("cancel")}
              </Button>
              <ResetSelectionStatusBtn resetSelectionStatus={resetSelectionStatus} />
              <StatusSelector
                onChange={(status) => {
                  selectedRheses.forEach((rhese) => {
                    if (rhese.data.audioInfo?.status === status) return;
                    if (rhese.data.audioInfo?.status && rhese.data.audioInfo.reviewId) {
                      updateAudioReview({
                        variables: {
                          projectId,
                          reviewId: rhese.data.audioInfo.reviewId,
                          status: status as AudioReviewStatus,
                        },
                      });
                    } else {
                      addAudioReview({
                        variables: {
                          projectId,
                          rheseId: rhese.data.id,
                          status: status as AudioReviewStatus,
                        },
                      });
                    }
                  });

                  resetSelection();
                }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className={style.paragraphs} onClick={resetSelection}>
        {groupedRheses.map(({ rheses, paragraphId }) => {
          return (
            <div key={paragraphId}>
              {rheses
                .sort((a, b) => a.anchors[0].utf16Start - b.anchors[0].utf16Start)
                .map((rhese) => {
                  return (
                    <Rhese
                      key={rhese.data.id}
                      projectId={projectId}
                      rhese={rhese}
                      playRhese={onPlayRhesePressed}
                      setRefRheseToPlay={setRefRheseToPlay}
                      selectionAnchor={selectionAnchor}
                      setSelectedRhese={setSelectedRhese}
                      selectedRheses={selectedRheses}
                      resetSelection={resetSelection}
                      isCurrentRhese={currentRheseId === rhese.data.id}
                      isPlaying={isPlaying && currentRheseId === rhese.data.id}
                      pageMetadata={pageMetadata}
                    />
                  );
                })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Rheses;
