import React from "react";
import StepPreparing from "./steps/StepPreparing";
import * as parentStyle from "../../style.less";
import StepNarration from "./steps/StepNarration";
import StepBookCompleted from "./steps/StepBookCompleted";
import { SubToProject_project } from "../../../../api/__generated__/SubToProject";
import useProjectProgress from "../../../../hooks/useProjectProgress";
import {
  BookStatus,
  PreparationProgressStatus,
} from "../../../../../../../../__generated__/globalTypes";
import { Spinner } from "../../../../../../../components/Spinner";
import StepArchived from "./steps/StepArchived";

type Props = {
  project: SubToProject_project;
  projectProgressHook: ReturnType<typeof useProjectProgress>;
};

const defaultProject = {
  preparationProgress: undefined,
  status: undefined,
  isNarrationComplete: false,
};

export default function TabProgress({ projectProgressHook }: Props) {
  const {
    project,
    markAsReadyForNarration,
    completePreparation,
    completeNarration,
    finalComplete,
    archiveProject,
    unarchiveProject,
    isLoading,
    stats,
  } = projectProgressHook;

  const { preparationProgress, status, isNarrationComplete } = project || defaultProject;

  return (
    <div className={parentStyle.progress}>
      {preparationProgress === PreparationProgressStatus.NOT_READY && (
        <StepPreparing
          preparationProgress={preparationProgress}
          markAsReadyForNarration={markAsReadyForNarration}
        />
      )}
      {(preparationProgress === PreparationProgressStatus.READY_FOR_NARRATION ||
        preparationProgress === PreparationProgressStatus.COMPLETE) &&
        status !== BookStatus.COMPLETE &&
        status !== BookStatus.ARCHIVED && (
          <StepNarration
            stats={stats}
            preparationProgress={preparationProgress}
            isNarrationComplete={isNarrationComplete}
            completePreparation={completePreparation}
            completeNarration={completeNarration}
            finalComplete={finalComplete}
          />
        )}
      {status === BookStatus.COMPLETE && <StepBookCompleted archiveProject={archiveProject} />}
      {status === BookStatus.ARCHIVED && <StepArchived unarchiveProject={unarchiveProject} />}
      {isLoading && (
        <div className={parentStyle.spinnerContainer}>
          <Spinner />
        </div>
      )}
    </div>
  );
}
