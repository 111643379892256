@import "../../../../../styles/colors.less";

/* 
Generals 
*/

.step {
  h4 {
    line-height: 1.1em;
    margin-bottom: 1em;
  }
}

.button {
  min-width: 10em;
  height: unset;
  padding: 0.3em 1em;
  border-radius: 6px;
  background-color: @darkest-grey;
  border-color: @darkest-grey;
  font-size: 0.7em !important;
  font-weight: normal;

  &:global(.ant-btn-primary) {
    border: none;
  }
}

.legendAndButton {
  display: block;
  margin-bottom: 2em;
  margin-top: 2em;

  .legend {
    display: block;
    font-weight: normal;
    font-style: italic;
    font-size: 0.7em;
    margin-bottom: 1em;
  }
}

.complete {
  background-color: @light-green;
  border-color: @light-green;
  color: @green-button-complete;
  pointer-events: none;
  box-shadow: none;
}

.divider {
  margin: 2em 2em;
  background-color: @light-grey;

  div {
    width: 2px;
  }
}

/*
  NarrationState
*/
.narrationState {
  margin-top: 2em;

  .progressContainer {
    position: relative;
    margin-bottom: 1em;

    .textContainer {
      display: flex;
      flex: 1;
      justify-content: space-between;
      margin-bottom: -0.5em;

      .progressTitle {
        font-weight: normal;
        font-size: 0.8em;
      }

      .iconChecked {
        color: green;
        font-size: 0.7em;
        margin-right: 0.5em;
      }

      .progressBarLegend {
        font-weight: normal;
        font-size: 0.7em;
      }
    }
  }

  .buttonMarkBookAsDone {
    margin-top: 1em;
    width: 100%;
  }
}

/*
  StepPreparing
*/
.container {
  display: flex;
}

/*
  StepBookCompleted
*/

.stepBookCompleted {
  .legend {
    max-width: 60%;
    min-width: 15em;
  }

  .button {
    background-color: transparent !important;
    border-width: 2px;
  }
}
