import React, { JSXElementConstructor, useMemo, createContext, useContext, useEffect } from "react";
import { Spinner } from "../../..";
import { useSubscribeToExtendedPage } from "../../../api/api";
import {
  SubscribeToExtendedPage,
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_paragraph,
  SubscribeToExtendedPage_extendedPage_paragraph_data_image,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../api/__generated__/SubscribeToExtendedPage";
import { SubToPages_pages } from "../../../api/SubToPages";
import { sortByMetadataStart } from "../../../../utils/common";

const PageContext = createContext<SubscribeToExtendedPage | undefined>(undefined);

export const usePageContext = (): SubscribeToExtendedPage | null => {
  const context = useContext(PageContext);
  if (!context) {
    // Needed to avoid throwing error on components when these are being removed from the dom "too slowly"
    console.error("usePageContext must be used within a PageContentLoader");
    // throw new Error("usePageContext must be used within a PageContentLoader");
    return {} as SubscribeToExtendedPage;
  }
  return context;
};

export type PageContentLoaderProps = {
  rheses: SubscribeToExtendedPage_extendedPage_rhese[];
  paragraphs: SubscribeToExtendedPage_extendedPage_paragraph[];
  images?: SubscribeToExtendedPage_extendedPage_paragraph_data_image[];
  page?: SubToPages_pages;
  projectId: string;
  pageId: number;
};

export function PageContentLoader<T>({
  page,
  component: ChildComponent,
  pageId,
  props,
  onContentChange,
  projectId,
  setPageMetadata,
  setLoadingMetadata,
  pageSizeFactor = 2,
}: {
  pageId: number;
  pageSizeFactor?: number;
  projectId: string;
  page: SubToPages_pages;
  props: T;
  onContentChange?: () => void;
  setPageMetadata?: (newPageMetadata: SubscribeToExtendedPage_extendedPage | undefined) => void;
  setLoadingMetadata?: (loading: boolean) => void;
  component: JSXElementConstructor<T & PageContentLoaderProps>;
}) {
  const { loading, data } = useSubscribeToExtendedPage({
    projectId: projectId,
    pageId: page.id,
    pageSizeFactor,
  });

  const rheses = useMemo(() => data?.extendedPage?.rhese?.sort(sortByMetadataStart) || [], [data]);
  const paragraphs = useMemo(
    () => data?.extendedPage?.paragraph?.sort(sortByMetadataStart) || [],
    [data],
  );
  const images = useMemo(
    () => data?.extendedPage?.paragraph?.map((p) => p.data?.image) || [],
    [data],
  );

  useEffect(() => {
    onContentChange?.();
  }, [onContentChange, data?.extendedPage]);
  useEffect(() => {
    setPageMetadata?.(data?.extendedPage);
  }, [setPageMetadata, data?.extendedPage]);
  useEffect(() => {
    setLoadingMetadata?.(loading);
  }, [setLoadingMetadata, loading]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <PageContext.Provider value={data}>
      {/* @ts-ignore */}
      <ChildComponent
        {...(props as T)}
        rheses={rheses}
        paragraphs={paragraphs}
        images={images}
        page={page}
        pageId={pageId}
        projectId={projectId}
        loading={loading}
      />
    </PageContext.Provider>
  );
}
