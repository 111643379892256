@import "../../../styles/colors.less";

.root {
  padding: 0;
}

.search {
  input {
    font-size: 1.2em;
    border: none;
    width: 100%;
  }
}

:global(.ant-dropdown-trigger) {
  font-size: 0.9em;
}
.dropDownMenu * {
  font-size: 1em;
}

.result {
  font-size: 1em;
  &:hover {
    cursor: pointer;
    background-color: @light-grey;
  }

  display: grid;
  grid-auto-flow: column;

  .arrow {
    transform: rotate(45deg);
    opacity: 0;
  }

  &:hover .arrow {
    opacity: 1;
  }
}

.nbResults {
  border-top: 1px solid @light-grey;
  padding: 1em;
  text-align: center;
  font-style: italic;
  font-size: 1.3em;
  margin: 0;
}

.pagination {
  align-self: center;
}
