@import "../../../style/colors.less";

.validationButtonText {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  padding: 12px 16px;
  color: @green;
}

.validationButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-radius: 16px;
  background-color: @light-green;
  padding-right: 16px;
}
