@import "../../app/editor/styles/colors.less";

.sideBarContainer {
  right: 0px;
  top: 0px;
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.background {
  height: 100%;
  width: 100%;
  background-color: @black;
  opacity: 0.5;
}

.sideBar {
  position: absolute;
  background-color: @white;
}

.top,
.bottom {
  width: 100%;
}

.right,
.left {
  height: 100%;
}

.top {
  top: 0;
  left: 0;
}

.bottom {
  bottom: 0;
  left: 0;
}

.right {
  top: 0;
  right: 0;
}

.left {
  top: 0;
  left: 0;
}
