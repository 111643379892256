import React, { RefObject, useCallback } from "react";
import { motion } from "framer-motion";
import { Menu as AntMenu, Dropdown } from "antd";
import IcnDots from "../../assets/IcnDots.svg";
import { useIsContentAdministrator } from "../../../../../identity";
import { useI18n } from "../../../../../i18n";
import { ProjectMethods } from "../hooks/useProjectMethods";
import * as style from "../../style.less";
import { getMenuItem, MenuItem } from "../../../styles/antDesignTheme";
import { SubToProjectForHomepage_projects } from "../../__generated__/SubToProjectForHomepage";

type DropdownProps = {
  project: SubToProjectForHomepage_projects;
  projectMethods: ProjectMethods;
  menuProps: {
    animate: { y: string };
    initial: { y: string };
  };
  refProp: RefObject<HTMLDivElement>;
};

const i18nKey = "library.index";

const DropdownMenu = ({ menuProps, project, refProp, projectMethods }: DropdownProps) => {
  const projectCardI18nKey = `${i18nKey}.projectCard`;
  const menuI18nKey = `${projectCardI18nKey}.menu`;
  const [t] = useI18n();

  const isProjectAdministrator = useIsContentAdministrator();

  const ePubExportEnable = project.kind === "HTML_TEMPLATE";

  // Note: using directly a component instead of a useMemo will cause a bug (onHoverEnd related)
  const renderDropdownContent = useCallback(() => {
    const items: MenuItem[] = [
      getMenuItem({
        key: "modifies",
        label: t(`${menuI18nKey}.modifies`),
        onClick: () => projectMethods.edit(project.id),
      }),
      getMenuItem({
        key: "export-submenu",
        label: t(`${menuI18nKey}.export`),
        children: [
          getMenuItem({
            key: "export",
            label: t(`${menuI18nKey}.export`),
            onClick: () => projectMethods.export(project.id, project.name),
          }),
          getMenuItem({
            key: "exportEPub",
            label: t(`${menuI18nKey}.exportEPub`),
            onClick: () => projectMethods.export(project.id, project.name, true),
            disabled: !ePubExportEnable,
          }),
          getMenuItem({
            key: "exportNarration",
            label: t(`${menuI18nKey}.exportNarration`),
            onClick: () => projectMethods.exportNarration(project.id),
          }),
        ],
      }),
      getMenuItem({
        key: "viewExports",
        label: t(`${menuI18nKey}.viewExports`),
        onClick: () => projectMethods.viewExports(project.id),
      }),
      getMenuItem({
        key: "invite",
        label: t(`${menuI18nKey}.invite`),
        onClick: () => projectMethods.invite(project.id),
      }),
      getMenuItem({
        key: "revoke",
        label: t("revokeUserAccess"),
        onClick: () => projectMethods.revoke(project.id),
      }),
      getMenuItem({
        key: "resync",
        label: t(`${menuI18nKey}.resync`),
        onClick: () => projectMethods.resync(project.id),
        className: style.dropdownItemReSync,
      }),
      getMenuItem({
        key: "delete",
        label: t(`${menuI18nKey}.delete`),
        onClick: () => projectMethods.delete(project.id),
        danger: true,
      }),
    ];

    return (
      <AntMenu style={{ zIndex: 999, position: "absolute", minWidth: "15em" }} items={items} />
    );
  }, [ePubExportEnable, menuI18nKey, project.id, project.name, projectMethods, t]);

  return (
    <motion.div
      {...menuProps}
      initial={{
        ...menuProps.initial,
        position: "absolute",
        top: "0.9em",
        right: "1.25em",
        lineHeight: "0",
      }}>
      {isProjectAdministrator && (
        <Dropdown
          dropdownRender={renderDropdownContent}
          getPopupContainer={() => refProp?.current || document.body}>
          <IcnDots rotate={90} style={{ fill: "white", fontSize: "1.5em" }} />
        </Dropdown>
      )}
    </motion.div>
  );
};

export default DropdownMenu;
