import React from "react";
import { Checkbox } from "antd";
import useFilterOnAudio from "./useFilterOnAudio";
import { useTranslation } from "react-i18next";

type Props = {
  className: string;
  label: string;
  state: ReturnType<typeof useFilterOnAudio>["state"];
  toggle: ReturnType<typeof useFilterOnAudio>["toggle"];
};

export enum FilterGroupState {
  "off" = "off",
  "with" = "with",
  "without" = "without",
}

export enum FilterGroupOptions {
  "with" = "with",
  "without" = "without",
}

export default function FilterGroup({ className, state, toggle, label }: Props) {
  const [t] = useTranslation();
  return (
    <div className={className}>
      <p>{label}</p>
      <div>
        <Checkbox checked={state === "with"} onChange={() => toggle(FilterGroupOptions.with)}>
          {t("filterLabelWith")}
        </Checkbox>
        <Checkbox checked={state === "without"} onChange={() => toggle(FilterGroupOptions.without)}>
          {t("filterLabelWithout")}
        </Checkbox>
      </div>
    </div>
  );
}
