import React from "react";
import * as style from "../../style.less";
import IcnPlus from "../../assets/IcnPlus.svg";
import { Row } from "antd";
import { useI18n } from "../../../../../i18n";

const NewProjectCard = ({
  onClick,
  i18nKey,
}: {
  i18nKey: string;
  onClick: (v: boolean) => void;
}) => {
  const [t] = useI18n();
  return (
    <div className={style.newProject} onClick={() => onClick(true)}>
      <Row justify="center">
        <span>
          <IcnPlus />
        </span>
      </Row>
      <Row justify="center">{t(`${i18nKey}.newProject.title`)}</Row>
    </div>
  );
};

export default NewProjectCard;
