import React, { useCallback, useMemo, useState } from "react";
import { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";
import { MenuProps, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useUpdateRhese } from "../../editor/Project/api/api";
import { SubscribeToExtendedPage_extendedPage_rhese_data } from "../../editor/Project/api/__generated__/SubscribeToExtendedPage";

export default function useSkipSynchro(
  projectId: string,
  rhese: SubscribeToExtendedPage_extendedPage_rhese_data,
) {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [applyUpdateRhese] = useUpdateRhese();

  /**
   * Requests
   */
  const setIgnore = useCallback(async () => {
    try {
      setLoading(true);
      await applyUpdateRhese({
        variables: {
          rheseId: rhese.id,
          projectId,
          lineBreakAfter: rhese.lineBreakAfter,
          ignoreTextForAudioSync: true,
        },
      });
      setLoading(false);
    } catch (e) {
      console.log("useSkipSynchro", e);
    }
  }, [applyUpdateRhese, projectId, rhese?.id, rhese.lineBreakAfter]);

  const unignore = useCallback(
    async (e: any) => {
      if (e?.stopPropagation) {
        e?.stopPropagation(); // prevent rhese selection
      }
      try {
        setLoading(true);
        await applyUpdateRhese({
          variables: {
            rheseId: rhese.id,
            projectId,
            lineBreakAfter: rhese.lineBreakAfter,
            ignoreTextForAudioSync: false,
          },
        });
        setLoading(false);
      } catch (e) {
        console.log("useSkipSynchro", e);
      }
    },
    [applyUpdateRhese, projectId, rhese?.id, rhese.lineBreakAfter],
  );

  /**
   * Modal confirm
   */
  const [isModal, setIsModal] = useState(false);

  const modalConfirmUnskip = useCallback(() => {
    Modal.confirm({
      open: isModal,
      onOk: unignore,
      onCancel: () => setIsModal(false),
      title: t("rheseUseSyncroDialogTitle"),
      okText: t("rheseUseSyncroDialogOk"),
      cancelText: t("rheseUseSyncroDialogCancel"),
    });
  }, [isModal, t, unignore]);

  /**
   * Righ-click rhese menu
   */
  const contextMenuItems: MenuProps["items"] = useMemo(
    () =>
      rhese?.ignoreTextForAudioSync
        ? [
            {
              label: t("rheseUseSyncroMenuItemLabel"),
              key: "unskipSynchro",
              icon: <AudioOutlined />,
            },
          ]
        : [
            {
              label: t("rheseIgnoreSyncroMenuItemLabel"),
              key: "skipSynchro",
              icon: <AudioMutedOutlined />,
            },
          ],
    [rhese?.ignoreTextForAudioSync, t],
  );

  const onItemMenuSelected = useCallback(
    (key: string) => {
      if (key == "unskipSynchro") {
        modalConfirmUnskip();
      } else {
        setIgnore();
      }
    },
    [modalConfirmUnskip, setIgnore],
  );

  return {
    unignore,
    loading,
    contextMenuItems,
    onItemMenuSelected,
  };
}
