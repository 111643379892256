import React, { useCallback, useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import SynchroModalContent from "./SynchroModalContent";
import { useI18n } from "../../../../../../../i18n";
import useAudioPlayerMachine from "../../AudioPlayer/audioPlayerMachine/useAudioPlayerMachine";
import {
  SubscribeToExtendedPage_extendedPage_rhese_data_audioInfo,
  SubscribeToExtendedPage_extendedPage_rhese_data_audioRecording,
} from "../../../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";

export type SynchroModalContentProps = {
  projectId: string;
  recording: SubscribeToExtendedPage_extendedPage_rhese_data_audioRecording | null;
  info: SubscribeToExtendedPage_extendedPage_rhese_data_audioInfo;
  defaultStartOffset: number | null;
  defaultEndOffset: number | null;
};

type SynchroModalProps = SynchroModalContentProps & {};

export default function SynchroModal({
  projectId,
  recording,
  info,
  defaultStartOffset,
  defaultEndOffset,
}: SynchroModalProps) {
  const [t] = useI18n();

  const audioPlayerMachine = useAudioPlayerMachine();

  const [isOpen, setIsOpen] = useState(false);
  const showModal = useCallback(() => setIsOpen(true), []);
  const hideModal = useCallback(() => setIsOpen(false), []);

  const onDone = useCallback(() => {
    hideModal();
  }, [hideModal]);

  useEffect(() => {
    if (isOpen) {
      audioPlayerMachine.send({
        type: "Pause",
      });
    }
    // Only "isOpen" in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Button shape="circle" onClick={showModal}>
        <EditOutlined />
      </Button>
      <Modal
        // NOTE: "key" is required to avoid a bug (slider tooltip stays visible after modal closes)
        key={isOpen.toString()}
        title={t("audioOffset")}
        open={isOpen}
        footer={null}
        onCancel={hideModal}
        // NOTE: "maskClosable" is need to avoid modal closing when dropping the slider drag outside of the modal
        maskClosable={false}>
        <SynchroModalContent
          projectId={projectId}
          recording={recording}
          info={info}
          onDone={onDone}
          defaultStartOffset={defaultStartOffset}
          defaultEndOffset={defaultEndOffset}
        />
      </Modal>
    </>
  );
}
