.pageContent {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  padding: 0 2em;

  > div {
    padding: 2em 0;
    width: 100%;
    max-width: 70em;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
