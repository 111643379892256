import { CloseOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import MagnifyingGlass from "../../../../../../assets/svg/MagnifyingGlass";
import useFilterOnComments from "./useFilterOnComments";
import { PageTab } from "./PageTab";
import * as style from "./style.less";
import { FrogRoles, useSelectedRole } from "../../../../../identity";
import { useI18n } from "../../../../../i18n";
import { Spinner } from "../../../../../components/Spinner";
import {
  SubToProject_project_definitionsAudioInfo,
  SubToProject_project_rhesesAudioInfo,
} from "../../../../editor/Project/api/__generated__/SubToProject";
import { Dictionary, groupBy } from "lodash";
import useFilterOnAudio from "./useFilterOnAudio";
import useFilterOnRenarrated from "./useFilterOnRenarrated";
import FilterGroup from "./FilterGroup";
import { SubToPages_pages } from "../../../../editor/Project/api/SubToPages";

type BookPanelProps = {
  isPanelOpen: boolean;
  bookId: string;
  onClose: () => void;
  bookRhesesAudioInfo?: SubToProject_project_rhesesAudioInfo[];
  bookDefinitionsAudioInfo?: SubToProject_project_definitionsAudioInfo[];
  allPages: SubToPages_pages[];
  loading: boolean;
};

export type PageAudioInfo = {
  [key: string]: (
    | SubToProject_project_rhesesAudioInfo
    | SubToProject_project_definitionsAudioInfo
  )[];
};

const mergeGroupedAudioInfo = (
  rheses: Dictionary<SubToProject_project_rhesesAudioInfo[]>,
  definitions: Dictionary<SubToProject_project_definitionsAudioInfo[]>,
) => {
  const merged: PageAudioInfo = {};

  const rhesesIds = Object.keys(rheses);
  const definitionsIds = Object.keys(definitions);

  const uniqueIds = [...new Set([...rhesesIds, ...definitionsIds])];

  uniqueIds.forEach((id) => {
    const rhesesAudioInfo = rheses[id];
    const definitionsAudioInfo = definitions[id];

    if (rhesesAudioInfo && definitionsAudioInfo) {
      merged[id] = [];

      rhesesAudioInfo.forEach((rheseAudioInfo) => {
        const definitionAudioInfo = definitionsAudioInfo.find(
          (d) =>
            d.isReviewReNarrated === rheseAudioInfo.isReviewReNarrated &&
            d.isReviewValid === rheseAudioInfo.isReviewValid &&
            d.withAudio === rheseAudioInfo.withAudio &&
            d.withReview === rheseAudioInfo.withReview,
        );
        merged[id].push({
          ...rheseAudioInfo,
          count: (definitionAudioInfo?.count || 0) + (rheseAudioInfo.count || 0),
        });
      });
    } else if (!rhesesAudioInfo) {
      merged[id] = definitionsAudioInfo;
    } else {
      merged[id] = rhesesAudioInfo;
    }
  });

  return merged;
};

export const BookPanel = ({
  isPanelOpen,
  bookId,
  onClose,
  bookRhesesAudioInfo,
  bookDefinitionsAudioInfo,
  allPages,
  loading,
}: BookPanelProps) => {
  const [t] = useI18n();
  /**
   * Data
   */
  const { state: selectedRole } = useSelectedRole();
  const isProofListener = selectedRole === FrogRoles.ProofListener;
  const [textFilter, setTextFilter] = useState("");

  const groupedDefinitionsAudioInfo = groupBy(bookDefinitionsAudioInfo, "pageId");
  const groupedRhesesAudioInfo = groupBy(bookRhesesAudioInfo, "pageId");

  const groupedAudioInfos = mergeGroupedAudioInfo(
    groupedRhesesAudioInfo,
    groupedDefinitionsAudioInfo,
  );

  /**
   * Filter based on pages comments
   */
  const {
    show: showFilterOnComments,
    state: filteredOnCommentsState,
    toggle: toggleFilterOnComments,
    filter: filterOnComments,
    applyField: applyIssuesCount,
  } = useFilterOnComments(groupedAudioInfos);

  /**
   * Filter based on pages comments
   */
  const {
    show: showFilterOnAudio,
    state: filteredOnAudioState,
    toggle: toggleFilterOnAudio,
    filter: filterOnAudio,
    applyField: applyFullyRecorded,
  } = useFilterOnAudio(groupedAudioInfos);

  /**
   * Filter based on "renarrated" status
   */
  const {
    show: showFilterOnRenarrated,
    state: filteredOnRenarratedState,
    toggle: toggleFilterOnRenarrated,
    filter: filterOnRenarrated,
    applyField: applyRenarratedCount,
  } = useFilterOnRenarrated(groupedAudioInfos);

  /**
   * Search and filters
   */
  const [isResearchOpen, setIsResearchOpen] = useState(false);

  const filteredPages = useMemo(() => {
    let _filteredPages = allPages.filter((page) => {
      return page.pageNumber.toString().includes(textFilter);
    });

    // Filters
    _filteredPages = filterOnComments(_filteredPages);
    _filteredPages = filterOnAudio(_filteredPages);
    _filteredPages = filterOnRenarrated(_filteredPages);

    // Fields
    _filteredPages = applyIssuesCount(_filteredPages);
    _filteredPages = applyFullyRecorded(_filteredPages);
    _filteredPages = applyRenarratedCount(_filteredPages);

    return _filteredPages;
  }, [
    allPages,
    filterOnComments,
    filterOnAudio,
    filterOnRenarrated,
    applyFullyRecorded,
    applyIssuesCount,
    applyRenarratedCount,
    textFilter,
  ]);

  /**
   * Handlers
   */
  const handleResearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setTextFilter(e.target.value),
    [],
  );

  const openResearch = useCallback(() => setIsResearchOpen(true), []);

  const onResearchClose = useCallback(() => {
    setIsResearchOpen(false);
    setTextFilter("");
  }, []);

  return (
    <Drawer
      closable={false}
      placement={"right"}
      open={isPanelOpen}
      onClose={onClose}
      className={style.drawer}>
      {loading ? (
        <Spinner className={style.spinner} small />
      ) : (
        <>
          <div className={style.bookPanelHeader}>
            {isResearchOpen ? (
              <div className={style.researchInputContainer}>
                <input
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  onChange={handleResearch}
                  className={style.searchInput}
                  value={textFilter}
                />
              </div>
            ) : (
              <div className={style.bookPanelHeaderTitle}>Pages</div>
            )}
            {isResearchOpen ? (
              <CloseOutlined className={style.searchIcon} onClick={onResearchClose} />
            ) : (
              <MagnifyingGlass className={style.searchIcon} onClick={openResearch} />
            )}
          </div>
          <div className={style.filters}>
            {showFilterOnComments && (
              <FilterGroup
                className={style.filterCheckboxContainer}
                state={filteredOnCommentsState}
                toggle={toggleFilterOnComments}
                label={t("filterWithIssues")}
              />
            )}
            {showFilterOnAudio && (
              <FilterGroup
                className={style.filterCheckboxContainer}
                state={filteredOnAudioState}
                toggle={toggleFilterOnAudio}
                label={t("filterAudioComplete")}
              />
            )}
            {showFilterOnRenarrated && (
              <FilterGroup
                className={style.filterCheckboxContainer}
                state={filteredOnRenarratedState}
                toggle={toggleFilterOnRenarrated}
                label={t("filterRenarrated")}
              />
            )}
          </div>
          <div className={style.bookPanelPageContainer}>
            {filteredPages.map(({ pageNumber, fullyRecorded, issuesCount, renarratedCount }) => {
              return (
                <PageTab
                  key={pageNumber}
                  pageNumber={pageNumber}
                  bookId={bookId}
                  disabled={isProofListener ? !fullyRecorded : false}
                  hasIssues={issuesCount > 0}
                  audioComplete={fullyRecorded}
                  renarrated={renarratedCount > 0}
                />
              );
            })}
          </div>
        </>
      )}
    </Drawer>
  );
};
