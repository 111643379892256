@import "../../../../app/editor/styles/colors.less";
@import "../../../../app/editor/styles/fonts.less";

.project {
  justify-self: center;
  align-self: center;
  border-radius: 4px;
  background: #9093a5;
  height: 100%;
  width: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1.5em;
  overflow-wrap: anywhere;

  position: relative;

  &.isInReSync {
    cursor: unset;
  }
}

.badge {
  // opacity: 0.3;
  position: absolute;
  top: 0;
  right: 0;
}
