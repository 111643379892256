@import "../styles/colors.less";
@import "../styles/fonts.less";

.root {
  :global(.ant-layout-content) {
    display: flex;
    justify-content: center;
    overflow-y: auto;
  }

  :global(.ant-tabs) {
    width: 100%;
    max-width: 150em;
    padding: 5em 3em;
    border-bottom: 1px solid @light-grey;
    height: max-content;
  }

  .tabs :global(.ant-tabs-tab) {
    font-size: 1.15em;
    & > * {
      text-shadow: none;
    }
  }

  .title {
    color: white;
  }

  .menu {
    color: @light-grey;
    font-size: 1.16em;
  }

  .content {
    overflow-y: auto;
  }

  .libraryTitle {
    .h1();
    font-size: 2.15em;
    padding-right: 5em;
  }

  .projectCard:hover:not(.isInReSync),
  .newProject:hover {
    cursor: pointer;
  }

  .newProject:hover {
    width: 95%;
    height: 95%;
  }

  .projectCard,
  .newProject {
    justify-self: center;
    align-self: center;
    border-radius: 4px;
    background: @grey;
    height: 100%;
    width: 100%;
    color: @white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > *:nth-child(1) {
      font-size: 1.66em;
      line-height: 1.1;
      text-align: center;
      padding: 0 1em;
      padding-bottom: 0.15em;
    }

    & > *:nth-child(2) {
      font-size: 1.33em;
      color: @dark-grey;
    }
    & > *:nth-child(3) {
      color: @light-grey;
    }
  }

  .projectCard {
    & > *:nth-child(2) {
      font-size: 1em;
      text-transform: uppercase;
      color: @light-grey;
    }
  }

  .newProject {
    transition: width 0.3s, height 0.3s;
    & > *:nth-child(1) > * {
      border: 1px solid fade(@dark-grey, 10%);
      border-radius: 50%;
      height: 2em;
      width: 2em;
      text-align: center;
      line-height: 1.9em;
    }
    row-gap: 1em;
    border-radius: 4px;
    background: @light-grey;
    color: @dark-grey;
    & > *:nth-child(2) {
      text-transform: inherit;
    }
  }

  .projects {
    padding-top: 1.2em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: calc(12em + 3vw);
    @media (max-width: 1400px) {
      grid-auto-rows: calc(2.9em + 20vw);
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (max-width: 900px) {
      grid-auto-rows: calc(2.9em + 30vw);
      grid-template-columns: 1fr 1fr;
    }
    row-gap: 1.41em;
    column-gap: 1.41em;
  }

  .projectCard {
    position: relative;

    .badge {
      position: absolute;
      padding: 0.15em 0.5em;
    }

    .countryCode {
      padding: 0;
      font-size: 1.2em;
    }

    .projectHover {
      position: absolute;
      background: fade(@dark-grey, 70%);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      > div:nth-child(1) {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;
      }
    }
    button {
      border: 1px solid white;
      font-size: 1.167em;
      border-radius: 2em;
      padding: 0.6em 2em;
      font-weight: bold;
    }
    button:hover {
      cursor: pointer;
    }
  }

  :global(.ant-tabs) {
    overflow: visible;
  }
}

.buttonWrapper {
  margin-top: 5em;
}
.users {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 2em;
  padding: 1em 0;

  > div {
    border-radius: 50%;
    height: 3em;
    width: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .email {
    position: absolute;
    background-color: @light-grey;
    padding: 0.5em;
    border-radius: 4px;
    bottom: 0em;
    color: @darkest-grey;
  }
}

.breakText {
  overflow-wrap: anywhere;
}

.searchBar {
  position: relative;
  font-size: 1.5em;
  min-width: 20em;
  height: 2em;

  .open,
  .close,
  .input {
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    height: 100%;
  }

  .input {
    width: 100%;
    input {
      padding: 0 0.5em;

      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }
      border: none;
      border-radius: 3px;
      width: 100%;
      height: 100%;
    }
  }

  .open,
  .close {
    transform-origin: right;
    z-index: 1;
    padding: 0 0.5em;
    right: 0;
  }

  .close {
    pointer-events: none;

    span {
      pointer-events: initial;
    }
  }
}

.exportCountInPopover {
  margin-left: -0.1em;
  padding-left: 0.2em;
  padding-right: 0.2em;
  color: @orange;
}

.exportItemInPopover {
  display: flex;
  padding: 0.4em;
  .exportItemLabel {
    margin: 0;
    margin-left: 0.8em;
  }
}

.dropdownItemReSync {
  color: @warning !important;
  &:hover {
    background-color: @warning !important;
    color: white !important;
  }
}
