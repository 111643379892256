@import "../../../../../editor/styles/colors.less";

.popover {
  pointer-events: initial;
  align-self: center;
  border-radius: 120px;
}

.content {
  padding: 0.6em;
  background-color: white;
  border-radius: 20px;
  width: 20em;
}

.popoverButton {
  padding: 0em 1em;
  min-width: 6.6em;
  height: 3.3em;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 100px;

  &:focus {
    color: @darkest-grey;
    border-color: @darkest-grey;
  }
}

.speedIcon {
  font-size: 20px;
}

:global {
  .ant-popover-inner {
    border-radius: 14px;
  }
  .ant-slider-dot-active {
    background-color: white;
    border: 2px solid #f0f0f0;
  }
}
