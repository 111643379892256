import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import * as style from "./style.less";

const wrapperVariants = {
  opened: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

const DEFAULT_POSITION = {
  x: "0%",
  y: "0%",
};
const panelVariantsTable = {
  top: {
    opened: DEFAULT_POSITION,
    closed: {
      x: "0%",
      y: "-100%",
    },
  },
  right: {
    opened: DEFAULT_POSITION,
    closed: {
      x: "100%",
      y: "0%",
    },
  },
  bottom: {
    opened: DEFAULT_POSITION,
    closed: {
      x: "0%",
      y: "100%",
    },
  },
  left: {
    opened: DEFAULT_POSITION,
    closed: {
      x: "-100%",
      y: "0%",
    },
  },
};

enum SibeBarPosition {
  Top = "top",
  Right = "right",
  Bottom = "bottom",
  Left = "left",
}

type SideBarProps = {
  isOpen: boolean;
  close: () => void;
  children?: React.ReactNode;
  position?: SibeBarPosition;
};

const TRANSITION_CONFIG = {
  duration: 0.2,
  bounce: 0,
  ease: "linear",
};

export function SideBar({
  isOpen,
  close,
  children,
  position = SibeBarPosition.Right,
}: SideBarProps) {
  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <motion.div
          transition={TRANSITION_CONFIG}
          style={isOpen ? {} : { pointerEvents: "none" }}
          className={style.sideBarContainer}
          animate={isOpen ? "opened" : "closed"}
          initial={"closed"}
          exit={"closed"}
          variants={wrapperVariants}
        >
          <motion.div onClick={close} className={style.background}></motion.div>
          <motion.div
            transition={TRANSITION_CONFIG}
            variants={panelVariantsTable[position]}
            className={style.sideBar.concat(" ", style[position])}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
