import React from "react";
import { ClickableWrapper } from "../../modules/components/clickableWrapper/ClickableWrapper";

type BookSvgProps = {
  onClick?: () => void;
};

export const BookSvg = ({ onClick }: BookSvgProps) => {
  return (
    <ClickableWrapper>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}>
        <path
          d="M17.4844 14.3438C18.2969 14.3438 19.1406 14.4219 20.0156 14.5781V16.0781C19.3906 15.9219 18.5469 15.8438 17.4844 15.8438C15.6094 15.8438 14.1094 16.1719 12.9844 16.8281V15.1406C14.1719 14.6094 15.6719 14.3438 17.4844 14.3438ZM12.9844 12.4688C14.2656 11.9375 15.7656 11.6719 17.4844 11.6719C18.3281 11.6719 19.1719 11.75 20.0156 11.9062V13.4062C19.3906 13.25 18.5469 13.1719 17.4844 13.1719C15.6094 13.1719 14.1094 13.5 12.9844 14.1562V12.4688ZM17.4844 10.5C15.5781 10.5 14.0781 10.8281 12.9844 11.4844V9.84375C14.2344 9.28125 15.7344 9 17.4844 9C18.2656 9 19.1094 9.07812 20.0156 9.23438V10.7812C19.2656 10.5938 18.4219 10.5 17.4844 10.5ZM21 18.5156V6.98438C19.9375 6.67187 18.7656 6.51562 17.4844 6.51562C15.4531 6.51562 13.625 7.01563 12 8.01562V19.5C13.625 18.5 15.4531 18 17.4844 18C18.7031 18 19.875 18.1719 21 18.5156ZM17.4844 4.5C19.8594 4.5 21.7031 5 23.0156 6V20.5781C23.0156 20.7031 22.9531 20.8281 22.8281 20.9531C22.7031 21.0469 22.5938 21.0938 22.5 21.0938C22.4062 21.0938 22.3281 21.0781 22.2656 21.0469C20.9844 20.3594 19.3906 20.0156 17.4844 20.0156C15.4531 20.0156 13.625 20.5156 12 21.5156C10.6562 20.5156 8.82812 20.0156 6.51562 20.0156C4.85938 20.0156 3.26562 20.3594 1.73438 21.0469L1.5 21.1406C1.375 21.1406 1.25 21.0938 1.125 21C1.03125 20.9062 0.984375 20.7969 0.984375 20.6719V6C2.32812 5 4.15625 4.5 6.46875 4.5C8.8125 4.5 10.6562 5 12 6C13.3438 5 15.1719 4.5 17.4844 4.5Z"
          fill="white"
        />
      </svg>
    </ClickableWrapper>
  );
};
