import React from "react";
import { SubToPages_pages } from "../../../api/SubToPages";
import { SecureRoute } from "@okta/okta-react";
import RheseEdit from "../../EditingModes/Rhese";
import { subroutesPath } from "./subroutesPath";

type Props = {
  allPages: SubToPages_pages[];
  projectId: string;
};

// Logic when merging and splitting rheses for ignoreTextForAudioSync and lineBreakAfter:
// ignoreTextForAudioSync is taken from the first rhese
// lineBreakAfter is taken from the last rhese

export default function SubRouteRhese({ allPages, projectId }: Props) {
  return (
    <SecureRoute
      path={subroutesPath.rhese}
      render={() => <RheseEdit pages={allPages} projectId={projectId} />}
    />
  );
}
