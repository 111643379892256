import React from "react";
import { Select } from "antd";
import { AudioReviewStatus } from "../../../../../../../__generated__/globalTypes";
import { useI18n } from "../../../../../../i18n";
import * as style from "../style.less";

const { Option } = Select;

type StatusSelectorProps = {
  status?: AudioReviewStatus | null;
  onChange: (status: AudioReviewStatus) => void;
};

const orderedOptionsKeys = [
  AudioReviewStatus.BAD_SPEECH,
  AudioReviewStatus.RECORDING,
  AudioReviewStatus.BAD_TEXT,
  AudioReviewStatus.BAD_TEXT_AND_NARRATION,
  AudioReviewStatus.RENARRATED,
  AudioReviewStatus.VALID,
];

const StatusSelector = ({ status, onChange }: StatusSelectorProps) => {
  const [t] = useI18n();
  return (
    <Select
      className={style.statusSelector}
      value={status}
      placeholder={t("select_recorgind_issue_type")}
      onChange={onChange}>
      {orderedOptionsKeys.map((key) => (
        <Option key={key} value={key} disabled={key === AudioReviewStatus.RENARRATED}>
          {t(`audioReview.${key.toLowerCase()}`)}
        </Option>
      ))}
    </Select>
  );
};

export default StatusSelector;
