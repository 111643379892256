import React, { useCallback } from "react";
import { Button, Row } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { preciseFloat } from "../../../../../../Utils/common";
import * as style from "./ExtremityExtender.style.less";
import { ADDITIONAL_TIME_STEP } from "../SynchroModalContent";

type Props = {
  playInfoDuration: number;
  playInfoEnd: number;
  fullAudioDuration?: number;
  minOffsetStart: number;
  hasMaxOffsetEnd: boolean;
  additionalTimeBefore: number;
  additionalTimeAfter: number;
  setAdditionalTimeBefore: React.Dispatch<React.SetStateAction<number>>;
  setAdditionalTimeAfter: React.Dispatch<React.SetStateAction<number>>;
  sliderDefaultValues: {
    start: number;
    end: number;
  };
};

export default function ExtremityExtender({
  playInfoDuration,
  playInfoEnd,
  fullAudioDuration,
  minOffsetStart,
  hasMaxOffsetEnd,
  additionalTimeBefore,
  additionalTimeAfter,
  setAdditionalTimeBefore,
  setAdditionalTimeAfter,
  sliderDefaultValues,
}: Props) {
  const canDecrementTimeBefore = minOffsetStart !== 0 && -minOffsetStart > additionalTimeBefore;
  const canIncrementTimeBefore = additionalTimeBefore !== 0;

  const canDecrementTimeAfter = additionalTimeAfter !== 0;
  const canIncrementTimeAfter =
    hasMaxOffsetEnd && fullAudioDuration ? fullAudioDuration > playInfoEnd : false;

  const updateAdditionalTimeBefore = useCallback(
    (type: "decrement" | "increment") => () => {
      if (!fullAudioDuration) {
        return;
      }

      const step = ADDITIONAL_TIME_STEP;

      if (type === "decrement") {
        const canDecrementOneStep = -additionalTimeBefore - step > minOffsetStart;
        if (canDecrementOneStep) {
          setAdditionalTimeBefore((time) => time + step);
        } else {
          const remainingTimeToAdd = preciseFloat(-minOffsetStart - additionalTimeBefore, 2);
          setAdditionalTimeBefore((time) => time + remainingTimeToAdd);
        }
      } else {
        const canIncrementOneStep = additionalTimeBefore > step;
        if (canIncrementOneStep) {
          setAdditionalTimeBefore((time) => time - step);
        } else {
          const remainingTimeToAdd = preciseFloat(step - (step - additionalTimeBefore), 2);
          setAdditionalTimeBefore((time) => time - remainingTimeToAdd);
        }
      }
    },
    [fullAudioDuration, additionalTimeBefore, minOffsetStart, setAdditionalTimeBefore],
  );

  const updateAdditionalTimeAfter = useCallback(
    (type: "decrement" | "increment") => () => {
      if (!fullAudioDuration) {
        return;
      }

      const step = ADDITIONAL_TIME_STEP;
      const currentDuration = playInfoDuration + additionalTimeAfter;

      if (type === "decrement") {
        const canDecreaseOneStep = additionalTimeAfter - step >= 0;
        if (canDecreaseOneStep) {
          setAdditionalTimeAfter((time) => preciseFloat(time - step, 2));
        } else {
          const remaining = preciseFloat(
            currentDuration - (sliderDefaultValues.end - sliderDefaultValues.start),
            2,
          );
          setAdditionalTimeAfter((time) => preciseFloat(time - remaining, 2));
        }
      } else {
        const canExtendOneStep = currentDuration + step < fullAudioDuration;
        if (!canExtendOneStep) {
          const remaining = preciseFloat(fullAudioDuration - currentDuration, 2);
          setAdditionalTimeAfter((time) => preciseFloat(time + remaining, 2));
        } else {
          setAdditionalTimeAfter((time) => preciseFloat(time + step, 2));
        }
      }
    },
    [
      fullAudioDuration,
      playInfoDuration,
      additionalTimeAfter,
      setAdditionalTimeAfter,
      sliderDefaultValues,
    ],
  );

  return (
    <Row className={style.root} justify="space-between">
      <div className={style.subContainer}>
        <Button
          disabled={!canDecrementTimeBefore}
          onClick={updateAdditionalTimeBefore("decrement")}
          className={style.button}
          shape="circle">
          <DoubleLeftOutlined className={style.icon} />
        </Button>
        <Button
          disabled={!canIncrementTimeBefore}
          onClick={updateAdditionalTimeBefore("increment")}
          className={style.button}
          shape="circle">
          <DoubleRightOutlined className={style.icon} />
        </Button>
      </div>

      <div className={style.subContainer}>
        <Button
          disabled={!canDecrementTimeAfter}
          onClick={updateAdditionalTimeAfter("decrement")}
          className={style.button}
          shape="circle">
          <DoubleLeftOutlined className={style.icon} />
        </Button>
        <Button
          disabled={!canIncrementTimeAfter}
          onClick={updateAdditionalTimeAfter("increment")}
          className={style.button}
          shape="circle">
          <DoubleRightOutlined className={style.icon} />
        </Button>
      </div>
    </Row>
  );
}
