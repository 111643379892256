/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AudioRecordingSyncStatus {
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCEEDED = "SUCCEEDED",
  TO_DO = "TO_DO",
}

export enum AudioReviewStatus {
  BAD_SPEECH = "BAD_SPEECH",
  BAD_TEXT = "BAD_TEXT",
  BAD_TEXT_AND_NARRATION = "BAD_TEXT_AND_NARRATION",
  RECORDING = "RECORDING",
  RENARRATED = "RENARRATED",
  VALID = "VALID",
}

export enum BookStatus {
  ARCHIVED = "ARCHIVED",
  COMPLETE = "COMPLETE",
  WIP = "WIP",
}

export enum ExportStatus {
  DONE = "DONE",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  WAITING = "WAITING",
}

export enum ExportType {
  DEFINITIONS = "DEFINITIONS",
  FULL = "FULL",
  HTML = "HTML",
  INTO_IMPORTED_EPUB = "INTO_IMPORTED_EPUB",
  NARRATION = "NARRATION",
  SIMPLE = "SIMPLE",
}

export enum ExtendedDefinitionKind {
  EXPLICIT = "EXPLICIT",
  IMPLICIT = "IMPLICIT",
  NDBP = "NDBP",
}

export enum ExtendedParagraphKind {
  CENTRE = "CENTRE",
  CITATION = "CITATION",
  COURRIER = "COURRIER",
  DIALOGUE = "DIALOGUE",
  DIDASCALIE = "DIDASCALIE",
  DROITE = "DROITE",
  EMAIL = "EMAIL",
  ESPACE = "ESPACE",
  GRAS = "GRAS",
  INTRO = "INTRO",
  LIST_DEFINITION = "LIST_DEFINITION",
  LIST_ORDERED = "LIST_ORDERED",
  LIST_UNORDERED = "LIST_UNORDERED",
  NORMAL = "NORMAL",
  NOTE = "NOTE",
  RESUME = "RESUME",
  TEXTO = "TEXTO",
  TITRE_1 = "TITRE_1",
  TITRE_2 = "TITRE_2",
  TITRE_3 = "TITRE_3",
  TITRE_4 = "TITRE_4",
  TITRE_5 = "TITRE_5",
  TITRE_6 = "TITRE_6",
  VERS = "VERS",
}

export enum ExtendedPhonemeKind {
  COMPLEX = "COMPLEX",
  CONSONANT = "CONSONANT",
  VOWEL = "VOWEL",
}

export enum ExtendedTextCase {
  LOWER = "LOWER",
  UPPER = "UPPER",
}

export enum ExtendedTextScriptNotation {
  SUBSCRIPT = "SUBSCRIPT",
  SUPERSCRIPT = "SUPERSCRIPT",
}

export enum ImageKind {
  NORMAL = "NORMAL",
  POPUP = "POPUP",
}

export enum ImagePosition {
  AFTER = "AFTER",
  BEFORE = "BEFORE",
}

export enum ImportStatus {
  DONE = "DONE",
  FAILED = "FAILED",
  IMPORTING_CONTENT = "IMPORTING_CONTENT",
  WAITING_FOR_CONTENT = "WAITING_FOR_CONTENT",
}

export enum MetadataFamily {
  CASE = "CASE",
  CITATION = "CITATION",
  CUSTOM_COLORATION = "CUSTOM_COLORATION",
  CUSTOM_CSS_CLASSES = "CUSTOM_CSS_CLASSES",
  DEFINITION = "DEFINITION",
  ITALICIZATION = "ITALICIZATION",
  MUTE = "MUTE",
  PAGE = "PAGE",
  PARAGRAPH = "PARAGRAPH",
  PHONEME = "PHONEME",
  RHESE = "RHESE",
  SCRIPT_NOTATION = "SCRIPT_NOTATION",
  SYLLABLE = "SYLLABLE",
  WEIGHT = "WEIGHT",
}

export enum PreparationProgressStatus {
  COMPLETE = "COMPLETE",
  NOT_READY = "NOT_READY",
  READY_FOR_NARRATION = "READY_FOR_NARRATION",
}

export enum ProjectKind {
  HTML_TEMPLATE = "HTML_TEMPLATE",
  JSON_IMPORT = "JSON_IMPORT",
  TEXT = "TEXT",
}

export enum RheseSearchMode {
  CONTENT = "CONTENT",
  ID = "ID",
}

export enum StringOpAnchor {
  AFTER_INDEX = "AFTER_INDEX",
  BEFORE_INDEX = "BEFORE_INDEX",
}

export enum UserRole {
  CORRECTOR = "CORRECTOR",
  PREPARER = "PREPARER",
}

export enum UserTask {
  DEFINITION = "DEFINITION",
  DIALOGUES = "DIALOGUES",
  FORMATTING = "FORMATTING",
  NARRATION = "NARRATION",
  RHESE = "RHESE",
  SYLLABLES = "SYLLABLES",
}

export interface AddAudioRecording {
  projectId: any;
  fileUuid: any;
  audioRheses: AddAudioRhese[];
}

export interface AddAudioRhese {
  rheseId: any;
  start?: number | null;
  end?: number | null;
}

export interface AddExtendedMetadataCustomCSS {
  customCssClasses: string;
}

export interface AddExtendedMetadataCustomColoration {
  customColorationKey: string;
}

export interface DeleteTextContent {
  utf16Start: number;
  utf16Size: number;
}

export interface ExportConfig {
  coloration: ExportConfigColoration;
  includeAudio: boolean;
}

export interface ExportConfigColoration {
  syllable: boolean;
  phoneme: boolean;
  custom: boolean;
}

export interface ExportRequest {
  exportId: any;
  projectId: any;
}

export interface InsertTextContent {
  text: string;
  utf16Start: number;
  anchor?: StringOpAnchor | null;
}

export interface UpdateTextContent {
  insert?: InsertTextContent | null;
  delete?: DeleteTextContent | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
